





















































import TreatmentApi from "@/modules/treatment/api/treatment.api";
import TreatmentForm from "../components/treatment/TreatmentForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PriceList, Treatment } from "@/modules/treatment/types/treatment.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    TreatmentForm,
    SettingsHeader
  }
})
export default class ManageTreatmentsPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/priceLists") priceLists: PriceList[];

  public loadErrors: any = false;
  public showTreatmentForm = false;
  public editTreatment = null;
  public loading = false;
  public treatments = [];
  public headers = [
    { width: "30%", text: this.$t("headers.name"), value: "name" },
    { width: "15%", text: this.$t("headers.code"), value: "code" },
    { width: "10%", text: this.$t("headers.price"), value: "price" },
    { width: "10%", text: this.$t("headers.order"), value: "order" },
    { width: "20%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];


  public openTreatmentFormDialog(treatment: Treatment) {
    this.editTreatment = treatment;
    this.showTreatmentForm = true;
  }

  public closeTreatmentForm() {
    this.editTreatment = null;
    this.showTreatmentForm = false;
  }


  public reload() {
    this.loadTreatments();
  }

  public loadTreatments() {
    this.loadErrors = null;
    this.loading = true;
    this.treatments = [];

    TreatmentApi.getAll()
      .then(treatments => {
        this.loading = false;
        this.treatments = treatments;
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.loadErrors = err.response.data.errors;
        } else {
          this.loadErrors = this.$t("temporary_error");
        }
      });
  }

  public mounted() {
    this.loadTreatments();
  }

  public hasPriceList(treatment: Treatment) {
    return treatment.attributes && treatment.attributes.priceList;
  }

  public hasUserPrice(treatment: Treatment) {
    return treatment.attributes && treatment.attributes.userPrice;
  }
}
