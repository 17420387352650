import { render, staticRenderFns } from "./SettingsHeader.vue?vue&type=template&id=4e5e3ddd&"
import script from "./SettingsHeader.vue?vue&type=script&lang=ts&"
export * from "./SettingsHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/settings-page.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fsettings%2Fcomponents%2FSettingsHeader.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports