<template>
  <VDialog @input="closeDialog()" max-width="360" :value="true">
    <VCard outlined raised elevation="4">
      <VCardTitle class="justify-space-between">
        {{ $t('title') }}
        <VBtn icon @click="closeDialog()" class="float-left">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <p>{{ $t('message') }}</p>
        <VList>
          <VListItem :href="`https://api.whatsapp.com/send?phone=972552724499&text=${$t('whatsapp_message')}`"
            target="_blank">
            <VListItemIcon>
              <VIcon>mdi-whatsapp</VIcon>
            </VListItemIcon>
            <VListItemContent>
              <VListItemTitle>
                {{ $t('whatsapp') }}
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem href="mailto:talk@smilecloud.co.il">
            <VListItemIcon>
              <VIcon>mdi-email</VIcon>
            </VListItemIcon>
            <VListItemContent>
              <VListItemTitle>
                talk@smilecloud.co.il
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem href="tel:055-272-4499">
            <VListItemIcon>
              <VIcon>mdi-phone</VIcon>
            </VListItemIcon>
            <VListItemContent>
              <VListItemTitle>
                055-272-4499
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
</style>

<script>
export default {
  methods: {
    closeDialog() {
      this.open = false;
      this.$emit("close");
    },
  }
};
</script>

<i18n>
{
  "he": {
    "title": "יש שאלות?",
    "message": "אנחנו כאן בשבילך",
    "whatsapp": "שליחת הודעה בוואטסאפ",
    "whatsapp_message": "היי, רציתי לשאול "
  },
  "en": {
    "title": "Any questions?",
    "message": "We are here for you",
    "whatsapp": "Send Whatsapp Message",
    "whatsapp_message": "Hi there, I have a question "
  }
}
</i18n>
