




































































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../../reports/constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { User } from "@/modules/users/types/user.type";
import { LabWork } from "../types/lab-work.type";
import labWorkApi from "../api/lab-work.api";
import ContactInformationDialog from "@/modules/patient/components/ContactInformationDialog/ContactInformationDialog.vue";
import { Contact } from "@/modules/contacts/types/contact.type";
import LabWorkFormDialog from "../components/LabWorkFormDialog.vue";
import { Patient } from "@/modules/patient/types/patient.type";
import patientApi from "@/modules/patient/api/patient.api";

@Component({
  components: {
    ContactInformationDialog,
    LabWorkFormDialog
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportLabWorksPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest: CancelTokenSource = null;
  public reportResults: LabWork[] = null;
  public sentAtfFromDate: string = moment().add(-2, "months").startOf("month").format("YYYY-MM-DD");
  public sentAtToDate: string = null;
  public showByReturned = "0";
  public usersIds: number[] = null;
  public labId: number = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;
  public isLoading = false;
  public labContact: Contact = null;
  public labWork: LabWork = null;
  public patient: Patient = null;
  public loadErrors: any = false;
  public isFiltersAppliedFromQuery = false;

  get headers() {
    return [
      { text: this.$t("headers.sent_at"), value: "created_at", sortable: false },
      { text: this.$t("headers.returned_at"), value: "returned_at", sortable: false },
      { text: this.$t("headers.sent_by"), value: "user_id", sortable: false },
      { text: this.$t("headers.patient_name"), value: "patient_id", sortable: false },
      { text: this.$t("headers.contact"), value: "contact_id", sortable: false },
      { text: this.$t("headers.notes"), value: "notes", sortable: false },
      { text: "", value: "actions", sortable: false }
    ];
  }

  get showByReturnedOptions() {
    return [
      {
        text: this.$t("is_returned_options.show_all"),
        value: null
      },
      {
        text: this.$t("is_returned_options.returned"),
        value: "1"
      },
      {
        text: this.$t("is_returned_options.not_returned"),
        value: "0"
      },
    ];
  }

  get users() {
    return this.$store.getters["auth/usersWithCalendar"]
      .map((user: User) => ({
        "text": user.name,
        "value": user.id
      }));
  }

  get labs() {
    let contacts = [];
    const flattenContacts = [];
    if (this.$store.getters["auth/contacts"]) {
      contacts = this.$store.getters["auth/contacts"].filter(contact => contact.display_type && contact.display_type.includes("labs"));
    }
    if (contacts) {
      contacts.map(contactBookmark => contactBookmark.contacts.map(contact => flattenContacts.push(contact)));
    }
    return flattenContacts;
  }

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }


  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await labWorkApi.get();
      this.loadErrors = false;
      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }
      this.isLoading = false;
      this.loadErrors = this.$t("temporary_error").toString();
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async editLabWork(labWork: LabWork) {
    this.isLoading = true;
    try {
      this.patient = await patientApi.get(labWork.patient_id);
      this.labWork = labWork;
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    this.reload();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return this.sentAtfFromDate ? moment(this.sentAtfFromDate).format("DD/MM/YYYY") : null;
  }

  get toDateFormatted() {
    return this.sentAtToDate ? moment(this.sentAtToDate).format("DD/MM/YYYY") : null;
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  get filteredLabWorks() {
    if (!this.reportResults) {
      return [];
    }

    let filteredResults = this.reportResults;
    if (this.showByReturned) {
      filteredResults = filteredResults.filter(labWork => !!labWork.returned_at === !!+this.showByReturned);
    } if (this.labId) {
      filteredResults = filteredResults.filter(labWork => +labWork.contact_id === +this.labId);
    } if (this.sentAtfFromDate) {
      filteredResults = filteredResults.filter(labWork => moment(labWork.created_at) > moment(this.sentAtfFromDate));
    } if (this.sentAtToDate) {
      filteredResults = filteredResults.filter(labWork => moment(labWork.created_at) < moment(this.sentAtToDate));
    }
    if (this.usersIds && this.usersIds.length) {
      filteredResults = filteredResults.filter(labWork => this.usersIds.includes(labWork.user_id));
    }
    return filteredResults;
  }

  public applyFromQueryStringFilters() {
    this.isFiltersAppliedFromQuery = false;
    const querySentAtfFromDate = this.$route.query.from && this.$route.query.from.toString();
    const querySentAtToDate = this.$route.query.to && this.$route.query.to.toString();
    const queryUsersIds = this.$route.query.users && (this.$route.query.users as string).split(",").map(userId => +userId);
    const queryLab = this.$route.query.lab && +this.$route.query.lab;
    const queryIsReturned = this.$route.query.returned;

    if (querySentAtfFromDate) {
      this.sentAtfFromDate = querySentAtfFromDate;
    }
    if (!querySentAtfFromDate) {
      this.sentAtfFromDate = moment().add(-2, "months").startOf("month").format("YYYY-MM-DD");
    }
    if (querySentAtToDate) {
      this.sentAtToDate = querySentAtToDate;
    }
    if (queryIsReturned) {
      this.showByReturned = queryIsReturned.toString();

    }
    if (queryUsersIds) {
      this.usersIds = queryUsersIds;
    } else {
      const user = this.$store.getters["auth/user"];
      if (user && user.type === "caregiver" && !user.use_calendar_user_id) {
        this.usersIds = [user.id];
      }
    }
    if (queryLab) {
      this.labId = queryLab;
    }
    this.isFiltersAppliedFromQuery = true;

  }

  @Watch("sentAtfFromDate")
  @Watch("sentAtToDate")
  @Watch("usersIds")
  @Watch("labId")
  @Watch("showByReturned")
  public updateQueryStringFilters() {
    const query = {
      from: this.sentAtfFromDate ? this.sentAtfFromDate : moment().add(-2, "months").startOf("month").format("YYYY-MM-DD"),
      to: this.sentAtToDate ? this.sentAtToDate : undefined,
      lab: this.labId ? this.labId.toString() : undefined,
      users: (this.usersIds && this.usersIds.length) ? this.usersIds && this.usersIds.join(",") : undefined,
      returned: this.showByReturned ? this.showByReturned : undefined
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public async onReturnedClick(labWork: LabWork) {
    const res = await this.$confirm(this.$t("returned_confirm_message").toString(), {
      buttonTrueColor: "cyan",
      buttonTrueText: this.$t("ok_btn").toString(),
      buttonFalseText: this.$t("cancel_btn").toString(),
    });

    if (!res) {
      return;
    }
    try {
      const payload = {
        ...labWork,
        returned_at: moment().format("YYYY-MM-DD HH:mm")
      };
      await labWorkApi.update(labWork.id, payload);
      this.reload();
    } catch (err) {
      this.$toastr.e(this.$t("update_error"));
    }
  }

}
