import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.treatmentsSummary.length || _vm.isLoading)?_c(VRow,[_c(VCol,{staticClass:"pb-12",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"page":_vm.page,"options":_vm.dataTableOptions,"dense":"","mobile-breakpoint":1,"hide-default-footer":_vm.isLoading,"headers":_vm.headers,"items":_vm.treatmentsSummary,"loading":_vm.isLoading,"loading-text":_vm.$t('loading'),"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [_vm.itemsPerPage] }},on:{"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.treatment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px","min-width":"100px"}},[_c('span',[_vm._v(_vm._s(_vm.treatmentName(item.treatment_id)))])])]}},{key:"item.totalprice",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"50px"}},[_c('span',[_vm._v(_vm._s((+item.totalprice).toLocaleString())+"₪")])])]}}],null,false,3907224954)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-file-document-edit-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_treatments')))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }