






























import { Component, Vue } from "vue-property-decorator";

const localStorageNotificationKey = "notifications-v11";

@Component
export default class Notifications extends Vue {
  public isUnread: boolean = null;

  public markRead() {
    localStorage.setItem(localStorageNotificationKey, "1");
    this.isUnread = false;
  }

  mounted() {
    this.isUnread = !localStorage.getItem(localStorageNotificationKey);
    this.isUnread = false;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
