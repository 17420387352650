import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"task-page"},[_c(VContainer,{staticClass:"mb-6",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',{staticClass:"mb-2"},[_c(VIcon,[_vm._v("mdi-check")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n          "),_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){return _vm.openTaskFormDialog(null)}}},[_vm._v("\n            "+_vm._s(_vm.$t('add_task'))+"\n            "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.isMultipleTaskUsers)?_c(VTabs,{attrs:{"color":"cyan darken-4"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,[_vm._v(_vm._s(_vm.$t('tabs.my_tasks')))]),_c(VTab,[_vm._v(_vm._s(_vm.$t('tabs.task_created_by_me')))]),(_vm.shouldDisplayOtherTasks)?_c(VTab,[_vm._v(_vm._s(_vm.$t('tabs.other_tasks')))]):_vm._e()],1):_vm._e(),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabItem,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedTodoTasksHeaders,"items":_vm.myTodoTasks,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                ")])]}},{key:"item.task_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm._f("date")(item.task_date))+"\n                ")])]}},{key:"item.creator_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm.userName(item.creator_user_id))+"\n                ")])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('show_more'))+"\n                  ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                    "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                  ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                  "+_vm._s(_vm.$t('edit_btn'))+"\n                ")],1),_c('br'),_c(VBtn,{staticClass:"mt-2 mb-1",attrs:{"x-small":"","depressed":"","rounded":"","color":"light-green lighten-1","loading":_vm.markDoneTaskId === item.id},on:{"click":function($event){return _vm.onMarkDoneClicked(item)}}},[_c(VIcon,{attrs:{"x-small":"","color":"green darken-3"}},[_vm._v("mdi-check-bold")]),_vm._v("\n                  "+_vm._s(_vm.$t('done_btn'))+"\n                ")],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-4 text-center"},[_c('img',{staticClass:"mr-1 ml-1",staticStyle:{"vertical-align":"middle","height":"100px"},attrs:{"src":require('@/assets/meditate.gif')}}),_c('br'),_vm._v("\n                  "+_vm._s(_vm.$t('no_data'))+"\n                ")])]},proxy:true}])}),_c('hr',{staticClass:"mt-10 mb-4"}),_c(VExpansionPanels,{attrs:{"hover":"","flat":"","accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('future_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedFutureTasksHeaders,"items":_vm.myFutureTasksWithRecurrings,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                      ")])]}},{key:"item.task_date",fn:function(ref){
          var item = ref.item;
return [(item.task_date)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.task_date))+"\n                      ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.next_task_date))+"\n                        "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'VIcon',attrs,false),on),[_vm._v("mdi-timer-refresh-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('recurring_task')))])])],1)]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.creator_user_id))+"\n                      ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 &&
            !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.next_task_date + item.id)}}},[_vm._v("\n                          "+_vm._s(_vm.$t('show_more'))+"\n                        ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                          "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                        ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                        "+_vm._s(_vm.$t('edit_btn'))+"\n                      ")],1)]}}])})],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('done_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedDoneTasksHeaders,"items":_vm.myDoneTasks,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                      ")])]}},{key:"item.done_date",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.done_at))+"\n                      ")])]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.creator_user_id))+"\n                      ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                          "+_vm._s(_vm.$t('show_more'))+"\n                        ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                          "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                        ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                        "+_vm._s(_vm.$t('edit_btn'))+"\n                      ")],1)]}}])})],1)],1)],1)],1),_c(VTabItem,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.createdTodoTasksHeaders,"items":_vm.createdTodoTasks,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                ")])]}},{key:"item.task_date",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm._f("date")(item.task_date))+"\n                ")])]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm.userName(item.creator_user_id))+"\n                ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('show_more'))+"\n                  ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                    "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                  ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                  "+_vm._s(_vm.$t('edit_btn'))+"\n                ")],1),_c('br'),_c(VBtn,{staticClass:"mt-2 mb-1",attrs:{"x-small":"","depressed":"","rounded":"","color":"light-green lighten-1","loading":_vm.markDoneTaskId === item.id},on:{"click":function($event){return _vm.onMarkDoneClicked(item)}}},[_c(VIcon,{attrs:{"x-small":"","color":"green darken-3"}},[_vm._v("mdi-check-bold")]),_vm._v("\n                  "+_vm._s(_vm.$t('done_btn'))+"\n                ")],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-4 text-center"},[_c('img',{staticClass:"mr-1 ml-1",staticStyle:{"vertical-align":"middle","height":"100px"},attrs:{"src":require('@/assets/meditate.gif')}}),_c('br'),_vm._v("\n                  "+_vm._s(_vm.$t('no_data'))+"\n                ")])]},proxy:true}])}),_c('hr',{staticClass:"mt-10 mb-4"}),_c(VExpansionPanels,{attrs:{"hover":"","flat":"","accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('future_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.createdFutureTasksHeaders,"items":_vm.createdFutureTasksWithRecurrings,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                      ")])]}},{key:"item.task_date",fn:function(ref){
          var item = ref.item;
return [(item.task_date)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.task_date))+"\n                      ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.next_task_date))+"\n                        "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'VIcon',attrs,false),on),[_vm._v("mdi-timer-refresh-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('recurring_task')))])])],1)]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.creator_user_id))+"\n                      ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 &&
            !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.next_task_date + item.id)}}},[_vm._v("\n                          "+_vm._s(_vm.$t('show_more'))+"\n                        ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                          "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                        ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                        "+_vm._s(_vm.$t('edit_btn'))+"\n                      ")],1)]}}])})],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('done_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.createdDoneTasksHeaders,"items":_vm.createdDoneTasks,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                      ")])]}},{key:"item.done_date",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.done_at))+"\n                      ")])]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.creator_user_id))+"\n                      ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                          "+_vm._s(_vm.$t('show_more'))+"\n                        ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                          "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                        ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                        "+_vm._s(_vm.$t('edit_btn'))+"\n                      ")],1)]}}])})],1)],1)],1)],1),(_vm.shouldDisplayOtherTasks)?_c(VTabItem,[_c(VRow,[_c(VCol,{staticClass:"pt-4",attrs:{"cols":"12"}},[_c(VSelect,{staticStyle:{"max-width":"220px"},attrs:{"item-text":"name","hide-details":"","outlined":"","dense":"","items":_vm.taskUsers,"item-value":"id","label":_vm.$t('other_assignee_label'),"item-color":"cyan darken-1","color":"cyan darken-2","loading":_vm.isOtherAssigneeLoading},model:{value:(_vm.otherAssignedId),callback:function ($$v) {_vm.otherAssignedId=$$v},expression:"otherAssignedId"}})],1),(_vm.otherAssignedId && !_vm.isOtherAssigneeLoading)?_c(VCol,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedTodoTasksHeaders,"items":_vm.otherAssignedTodoTasks,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                      "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                    ")])]}},{key:"item.task_date",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                      "+_vm._s(_vm._f("date")(item.task_date))+"\n                    ")])]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                      "+_vm._s(_vm.userName(item.creator_user_id))+"\n                    ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                        "+_vm._s(_vm.$t('show_more'))+"\n                      ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                        "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                      ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                      "+_vm._s(_vm.$t('edit_btn'))+"\n                    ")],1),_c('br'),_c(VBtn,{staticClass:"mt-2 mb-1",attrs:{"x-small":"","depressed":"","rounded":"","color":"light-green lighten-1","loading":_vm.markDoneTaskId === item.id},on:{"click":function($event){return _vm.onMarkDoneClicked(item)}}},[_c(VIcon,{attrs:{"x-small":"","color":"green darken-3"}},[_vm._v("mdi-check-bold")]),_vm._v("\n                      "+_vm._s(_vm.$t('done_btn'))+"\n                    ")],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-4 text-center"},[_c('img',{staticClass:"mr-1 ml-1",staticStyle:{"vertical-align":"middle","height":"100px"},attrs:{"src":require('@/assets/meditate.gif')}}),_c('br'),_vm._v("\n                      "+_vm._s(_vm.$t('no_data'))+"\n                    ")])]},proxy:true}],null,false,2102824165)}),_c('hr',{staticClass:"mt-10 mb-4"}),_c(VExpansionPanels,{attrs:{"hover":"","flat":"","accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('future_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedFutureTasksHeaders,"items":_vm.otherAssignedFutureTasksWithRecurrings,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                          ")])]}},{key:"item.task_date",fn:function(ref){
          var item = ref.item;
return [(item.task_date)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm._f("date")(item.task_date))+"\n                          ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm._f("date")(item.next_task_date))+"\n                            "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'VIcon',attrs,false),on),[_vm._v("mdi-timer-refresh-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('recurring_task')))])])],1)]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm.userName(item.creator_user_id))+"\n                          ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 &&
            !_vm.showMoreTaskIds.includes(item.next_task_date + item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.next_task_date + item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.next_task_date + item.id)}}},[_vm._v("\n                              "+_vm._s(_vm.$t('show_more'))+"\n                            ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                              "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                            ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                            "+_vm._s(_vm.$t('edit_btn'))+"\n                          ")],1)]}}],null,false,883066529)})],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('done_tasks')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.assignedDoneTasksHeaders,"items":_vm.otherAssignedDoneTasksSorted,"no-data-text":_vm.$t('no_tasks'),"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.assignee_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm.userName(item.assignee_user_id))+"\n                          ")])]}},{key:"item.done_date",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm._f("date")(item.done_at))+"\n                          ")])]}},{key:"item.creator_user",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                            "+_vm._s(_vm.userName(item.creator_user_id))+"\n                          ")])]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"450px","min-width":"120px"},style:({ 'white-space': item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ? 'auto' : 'pre-line' })},[_c('span',[_vm._v(_vm._s(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id) ?
            item.notes.substring(0, 150).trim() +
            '...' : item.notes))]),(item.notes.length > 150 && !_vm.showMoreTaskIds.includes(item.id))?_c(VBtn,{staticClass:"d-block",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.showMoreTaskIds.push(item.id)}}},[_vm._v("\n                              "+_vm._s(_vm.$t('show_more'))+"\n                            ")]):_vm._e()],1)]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [(item.patient_id)?_c('RouterLink',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.patientRouteName,
            params: {
              id: item.patient_id
            }
          }}},[_c('span',{staticStyle:{"color":"#00838f","min-width":"60px","display":"inline-block"}},[_vm._v("\n                              "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+"\n                            ")])]):_c('span',[_vm._v(_vm._s(_vm.$t('clinic_task')))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openTaskFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                            "+_vm._s(_vm.$t('edit_btn'))+"\n                          ")],1)]}}],null,false,2441260824)})],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1),(_vm.isDisplayTaskFormDialog)?_c('TaskFormDialog',{attrs:{"edit-task":_vm.editingTask},on:{"close":function($event){_vm.isDisplayTaskFormDialog = false},"taskUpdated":function($event){return _vm.reload()},"taskCreated":function($event){return _vm.reload()},"taskDeleted":function($event){return _vm.reload()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }