










































import Vue from "vue";
import Component from "vue-class-component";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Getter } from "vuex-class";
import SettingsHeader from "../components/SettingsHeader.vue";
import pinkasApi from "@/modules/pinkas/api/pinkas.api";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import PinkasForm from "@/modules/pinkas/components/PinkasForm.vue";

@Component({
  components: {
    ErrorLogo,
    PinkasForm,
    SettingsHeader
  },
})

export default class ManagePinkasPage extends Vue {

  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isSmileSupport") isSmileSupport: boolean;
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public showPinkasForm = false
  public editPinkas: Pinkas = null
  public loading = false;
  public pinkases: Pinkas[] = [];
  public headers = [
    { width: "33%", text: this.$t("headers.business_id"), value: "business_id" },
    { width: "33%", text: this.$t("headers.business_name"), value: "business_name" },
    { width: "33%", text: this.$t("headers.is_tranzila_enabled"), value: "is_tranzila_enabled", sortable: false },
    { width: "33%", value: "actions", sortable: false },
  ]

  public openPinkasFormDialog(pinkas: Pinkas) {
    this.editPinkas = pinkas;
    this.showPinkasForm = true;
  }
  public closePinkasForm() {
    this.editPinkas = null;
    this.showPinkasForm = false;
  }

  public reload() {
    this.loadPinkases();
  }

  public async loadPinkases() {
    this.loadErrors = null;
    this.loading = true;
    this.pinkases = [];
    try {
      this.pinkases = await pinkasApi.getAll();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }
  mounted() {
    this.loadPinkases();
  }
}
