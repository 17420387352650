















































































































































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { PlanTreatment } from "@/modules/treatment/types/plan-treatment.type";
import { DENTURES_MAP } from "@/modules/treatment/constants/denture-map";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Treatment } from "../types/treatment.type";
import { Getter } from "vuex-class";

@Component
export default class PlanDenture extends mixins(SmileMixin) {
  @Prop({ required: true }) public treatments: PlanTreatment[];
  @Prop({ required: true }) public denture: string;
  @Prop({ required: true }) public newTreatmentTeeth: number[];
  @Prop({ required: true }) public selectedPlanTreatmentIds: number[];
  @Getter("auth/isShowingTreatmentCode") isShowingTreatmentCode: boolean;
  @Getter("auth/isPlanTreatmentStatusWorkingOnIt") isPlanTreatmentStatusWorkingOnIt: boolean;

  public addingInlineTreatmentTooth: number = null;
  public addingInlineQueryText: string = null;
  public treatmentEnterDelayTimeout: number = null;

  public teethInDenture(teeth: number) {
    const dentureConfig = DENTURES_MAP
      .filter(denture => (denture.name === this.denture));

    if (dentureConfig.length === 0)
      return false;

    return dentureConfig[0]
      .teeth
      .indexOf(teeth) !== -1;
  }

  public teethSelected(teeth: number) {
    return this.newTreatmentTeeth.indexOf(teeth) !== -1;
  }

  public selectTeeth(teeth: number) {
    if (this.teethSelected(teeth)) {
      this.newTreatmentTeeth.splice(this.newTreatmentTeeth.indexOf(teeth), 1);
    } else {
      this.newTreatmentTeeth.push(teeth);
    }
  }

  public treatmentsInTeeth(teeth: number): PlanTreatment[] {
    return this.treatments
      .filter(treatment => (
        treatment.attributes.teeth
        && treatment.attributes.teeth.toString().split(",").indexOf(teeth.toString()) !== -1));
  }

  public selectTreatment(planTreatment: PlanTreatment) {
    this.$emit("treatmentSelected", planTreatment);
  }

  public deleteSelectedTreatmentsClicked(planTreatment: PlanTreatment) {
    this.$emit("deleteSelectedTreatments", planTreatment.id);
  }

  public editPlanTreatmentClicked(planTreatment: PlanTreatment) {
    this.$emit("editPlanTreatmentClicked", planTreatment);
  }

  public markTreatmentWorkingClicked(planTreatment: PlanTreatment) {
    this.$emit("markTreatmentWorkingClicked", planTreatment);
  }

  public markTreatmentDoneClicked(planTreatment: PlanTreatment) {
    this.$emit("markTreatmentDoneClicked", planTreatment);
  }

  public treatmentsFilter(item: any, queryText: string) {
    return `${item.text} ${item.code}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  get maxTreatmentsPerTeeth() {
    const teethCount: Record<string, number> = {};
    for (let i = 0; i < this.treatments.length; i++) {
      if (!this.treatments[i].attributes.teeth) {
        continue;
      }
      const teeth = this.treatments[i].attributes.teeth.toString().split(",");
      for (let j = 0; j < teeth.length; j++) {
        if (!teethCount[teeth[j]]) {
          teethCount[teeth[j]] = 0;
        }
        teethCount[teeth[j]]++;
      }
    }
    return Math.max(...Object.values(teethCount));
  }

  public onAddTreatmentClicked(tooth: number) {
    this.addingInlineTreatmentTooth = tooth;
  }

  get treatmentOptions() {
    return (this.$store.getters["auth/treatments"] as Treatment[])
      .filter(treatment => !treatment.deleted_at)
      .map(treatment => ({
        "text": treatment.name,
        "code": treatment.code,
        "value": treatment.id
      }));
  }

  public async newTreatmentSelected(treatmentId: number, tooth: number) {
    if (this.treatmentEnterDelayTimeout) {
      clearTimeout(this.treatmentEnterDelayTimeout);
    }
    this.addingInlineTreatmentTooth = null;

    this.$emit("addInlineTreatment", treatmentId, { teeth: tooth });
  }

  public onTreatmentBlured(tooth: number) {
    if (!this.addingInlineTreatmentTooth) {
      return;
    }
    this.addingInlineTreatmentTooth = null;

    if (!this.addingInlineQueryText) {
      return;
    }
    const filteredTreatments = this.treatmentOptions
      .filter(({ text, code }) => (`${text} ${code}`.toLocaleLowerCase().indexOf(this.addingInlineQueryText.toLocaleLowerCase()) > -1));

    this.addingInlineQueryText = null;
    if (filteredTreatments.length !== 1) {
      return;
    }

    this.newTreatmentSelected(filteredTreatments[0].value, tooth);
  }

  public onTreatmentEnterClicked(tooth: number) {
    if (!this.addingInlineQueryText) {
      return;
    }
    const filteredTreatments = this.treatmentOptions
      .filter(({ text }) => (text.toLocaleLowerCase().indexOf(this.addingInlineQueryText.toLocaleLowerCase()) > -1));

    this.addingInlineQueryText = null;
    if (filteredTreatments.length !== 1) {
      return;
    }

    this.treatmentEnterDelayTimeout = setTimeout(() => {
      this.treatmentEnterDelayTimeout = null;
      this.newTreatmentSelected(filteredTreatments[0].value, tooth);
    }, 10);
  }

  public onUpdateSearchInput(query: string) {
    this.addingInlineQueryText = query;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
