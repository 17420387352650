




































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import ReportApi from "../api/report.api";
import { ReportSentEmailsResult } from "../types/report.type";
import { User } from "@/modules/users/types/user.type";
import EmailContactSearch from "@/modules/contacts/components/EmailContactSearch.vue";

@Component({
  components: {
    EmailContactSearch,
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportSentEmailsPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest: CancelTokenSource = null;
  public reportResults: ReportSentEmailsResult = null;
  public fromDate: string = null;
  public toDate: string = null;
  public usersIds: number[] = null;
  public sentTo: string = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;
  public dateMenuOpen = false;
  public isLoading = false;
  public isFiltersAppliedFromQuery = false;

  get headers() {
    return [
      { text: this.$t("headers.date"), value: "created_at", sortable: false },
      { text: this.$t("headers.time"), value: "time", sortable: false },
      { text: this.$t("headers.sent_by"), value: "user_id", sortable: false },
      { text: this.$t("headers.patients_name"), value: "patient_id", sortable: false },
      { text: this.$t("headers.sent_to"), value: "to", sortable: false },
      { text: this.$t("headers.document_name"), value: "patient_file_name", sortable: false },
    ];
  }

  get users() {
    return this.$store.getters["auth/users"]
      .map((user: User) => ({
        "text": user.name,
        "value": user.id
      }));
  }

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  @Watch("fromDate")
  @Watch("toDate")
  @Watch("usersIds")
  @Watch("sentTo")
  onFiltersChanged() {
    if (this.isFiltersAppliedFromQuery) {
      this.reload();
    }
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await ReportApi
        .getSentEmailsReport(this.usersIds, null, this.sentTo, this.fromDate, this.toDate, this.activeRequest);
      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return this.fromDate ? moment(this.fromDate).format("DD/MM/YYYY") : null;
  }

  get toDateFormatted() {
    return this.toDate ? moment(this.toDate).format("DD/MM/YYYY") : null;
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public applyFromQueryStringFilters() {
    this.isFiltersAppliedFromQuery = false;
    const queryFromDate = this.$route.query.from && this.$route.query.from.toString();
    const queryToDate = this.$route.query.to && this.$route.query.to.toString();
    const queryUsersIds = this.$route.query.users && (this.$route.query.users as string).split(",").map(userId => +userId);
    const queryTo = this.$route.query.email && this.$route.query.email.toString();

    if (queryFromDate) {
      this.fromDate = queryFromDate;
    }
    if (!queryFromDate) {
      this.fromDate = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (queryToDate) {
      this.toDate = queryToDate;
    }
    if (queryUsersIds) {
      this.usersIds = queryUsersIds;
    } else {
      if (!this.usersIds && this.$store.getters["auth/isTherapist"]) {
        this.usersIds = [this.$store.getters["auth/userId"]];
      }
    }
    if (queryTo) {
      this.sentTo = queryTo;
    }
    this.isFiltersAppliedFromQuery = true;

  }

  public updateQueryStringFilters() {
    const query = {
      from: this.fromDate ? this.fromDate : undefined,
      to: this.toDate ? this.toDate : undefined,
      email: this.sentTo ? this.sentTo : undefined,
      users: (this.usersIds && this.usersIds.length) ? this.usersIds && this.usersIds.join(",") : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

}
