
























































































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import ReportApi from "../api/report.api";
import ShowPaymentDocumentFileDialog from "@/modules/patient/components/PatientPayment/ShowPaymentDocumentFileDialog.vue";
import { HeshbonitMasKabala, Kabala, Payment } from "@/modules/patient/types/payment.type";
import { Patient } from "@/modules/patient/types/patient.type";
import { Treatment } from "@/modules/treatment/types/treatment.type";

@Component({
  components: {
    ShowPaymentDocumentFileDialog,
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportDoctorCalendarPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest: CancelTokenSource = null;
  public displayDocument: Kabala | HeshbonitMasKabala = null;
  public reportResults: Patient[] = null;
  public date: string = null;
  public displayDatePicker = false;
  public dateMenuOpen = false;
  public isLoading = false;

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  @Watch("date")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.patient_name"), value: "patient_name", sortable: false },
      { text: this.$t("headers.total_amount"), value: "total_amount", sortable: false },
      { text: this.$t("headers.document_details"), value: "document_details", sortable: false },
      { text: this.$t("headers.user_id"), value: "user_id", sortable: false },
      { text: this.$t("headers.cash"), value: "cash", sortable: false },
      { text: this.$t("headers.credit_card"), value: "credit_card", sortable: false },
      { text: this.$t("headers.bank_transfer"), value: "bank_transfer", sortable: false },
      { text: this.$t("headers.check"), value: "check", sortable: false },
    ];
  }

  public getCashAmount(payments: Payment[]) {
    return payments.filter(({ deleted_at }) => (!deleted_at))
      .map(({ attributes }) => (parseFloat(attributes.cash.toString()))).reduce((a, b) => (a + b), 0);
  }

  public getBankTransferAmount(payments: Payment[]) {
    return payments.filter(({ deleted_at }) => (!deleted_at))
      .map(({ attributes }) => (parseFloat(attributes.bank_transfer.toString()))).reduce((a, b) => (a + b), 0);
  }

  public getCreditCardAmount(payments: Payment[]) {
    return payments.filter(({ deleted_at }) => (!deleted_at))
      .map(({ attributes }) => (parseFloat(attributes.credit_card.toString()))).reduce((a, b) => (a + b), 0);
  }

  public getCheckAmount(payments: Payment[]) {
    return payments.filter(({ deleted_at }) => (!deleted_at))
      .map(({ attributes }) => (parseFloat(attributes.check.toString()))).reduce((a, b) => (a + b), 0);
  }

  public getTotalAmount(payments: Payment[]) {
    return payments.filter(({ deleted_at }) => (!deleted_at))
      .map(({ amount }) => (parseFloat(amount.toString()))).reduce((a, b) => (a + b), 0);
  }

  public getUserNames(patient: Patient) {
    const userIds = new Set();
    let paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    if (!paymentTreatment) {
      paymentTreatment = this.$store.getters["auth/treatments"]
        .find((treatment: Treatment) => (treatment.name === "תשלום"));
    }

    for (let i = 0; i < patient.treatments.length; i++) {
      if (patient.treatments[i].treatment_id === paymentTreatment.id) {
        continue;
      }

      userIds.add(patient.treatments[i].user_id);
    }

    return ([...userIds] as number[]).map((id) => (this.userName(id))).join(", ");
  }

  get summaryCashAmount() {
    return this.reportResults.map(({ payments }) => (
      payments.filter(({ deleted_at }) => (!deleted_at))
        .map(({ attributes }) => (parseFloat(attributes.cash.toString())))
        .reduce((a, b) => (a + b), 0)
    ))
      .reduce((a, b) => (a + b), 0);
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  get summaryBankTransferAmount() {
    return this.reportResults.map(({ payments }) => (
      payments.filter(({ deleted_at }) => (!deleted_at))
        .map(({ attributes }) => (parseFloat(attributes.bank_transfer.toString())))
        .reduce((a, b) => (a + b), 0)
    ))
      .reduce((a, b) => (a + b), 0);
  }

  get summaryCreditCardAmount() {
    return this.reportResults.map(({ payments }) => (
      payments.filter(({ deleted_at }) => (!deleted_at))
        .map(({ attributes }) => (parseFloat(attributes.credit_card.toString())))
        .reduce((a, b) => (a + b), 0)
    ))
      .reduce((a, b) => (a + b), 0);
  }

  get summaryCheckAmount() {
    return this.reportResults.map(({ payments }) => (
      payments.filter(({ deleted_at }) => (!deleted_at))
        .map(({ attributes }) => (parseFloat(attributes.check.toString())))
        .reduce((a, b) => (a + b), 0)
    ))
      .reduce((a, b) => (a + b), 0);
  }

  get summaryTotalAmount() {
    return this.reportResults.map(({ payments }) => (
      payments.filter(({ deleted_at }) => (!deleted_at))
        .map(({ amount }) => (parseFloat(amount.toString())))
        .reduce((a, b) => (a + b), 0)
    ))
      .reduce((a, b) => (a + b), 0);
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await ReportApi
        .getDoctorCalendarReport(this.date, this.activeRequest);

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    if (!this.date) {
      this.date = moment().format("YYYY-MM-DD");
    }
    this.reload();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get dateFormatted() {
    return moment(this.date).format("DD/MM/YYYY");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();

    if (queryDate) {
      this.date = queryDate;
    }
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.date,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }
}
