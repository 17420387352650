






































































































import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { PatientFile } from "../../types/patient-file.type";
import { Getter } from "vuex-class";

@Component({
  components: {
  },
})
export default class ExplorerFileActionButton extends mixins(SmileMixin) {
  @Prop({ required: true }) public file: PatientFile;
  @Prop({ required: true }) public patientHasPhone: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public onRenameClicked() {
    this.$emit("renameFile", this.file);
  }

  public onMoveClicked() {
    this.$emit("moveFile", this.file);
  }

  public onDeleteClicked() {
    this.$emit("deleteFile", this.file);
  }

  public onDownloadClicked() {
    this.$emit("downloadFile", this.file);
  }

  public onSelectClicked() {
    this.$emit("selectFile", this.file);
  }

  public onShareWhatsappClicked() {
    this.$emit("shareWhatsapp", this.file);
  }

  public onShareEmailClicked() {
    this.$emit("shareEmail", this.file);
  }

  public onShareWhatsappWebClicked() {
    this.$emit("shareWhatsappWeb", this.file);
  }

}
