import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"report-all-receipts-page"},[_c(VContainer,{staticStyle:{"padding":"12px 22px"},attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c('RouterLink',{attrs:{"to":{ name: _vm.reportsPageRouteName }}},[_vm._v(_vm._s(_vm.$t('title')))]),_c(VIcon,[_vm._v(_vm._s(_vm.isRtl ? 'mdi-menu-left' : 'mdi-menu-right'))]),_vm._v("\n          "+_vm._s(_vm.$t('reports.all-receipts-report.title'))+"\n        ")],1)])],1),_c(VRow,{staticStyle:{"border-bottom":"1px solid #ccc"}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"4","md":"3","lg":"3","xl":"2"}},[_c(VSelect,{attrs:{"item-text":"business_name","items":_vm.pinkasList,"item-value":"id","label":_vm.$t('pinkas_label'),"item-color":"cyan darken-1","color":"cyan darken-2","error":!_vm.pinkasId},model:{value:(_vm.pinkasId),callback:function ($$v) {_vm.pinkasId=$$v},expression:"pinkasId"}})],1),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"4","md":"3","lg":"3","xl":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":_vm.$t('fromDate'),"readonly":"","color":!_vm.fromDate ? 'red' : 'cyan'}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.displayFromDatePicker),callback:function ($$v) {_vm.displayFromDatePicker=$$v},expression:"displayFromDatePicker"}},[(_vm.displayFromDatePicker)?_c(VDatePicker,{staticClass:"fix-date-picker-adjacent-months",attrs:{"no-title":"","color":"cyan darken-1","show-adjacent-months":""},on:{"input":function($event){_vm.displayFromDatePicker = false},"update:active-picker":_vm.enableAdjacentMonths,"update:picker-date":_vm.enableAdjacentMonths},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}):_vm._e()],1)],1),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"4","md":"3","lg":"3","xl":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":_vm.$t('toDate'),"readonly":"","color":!_vm.toDate ? 'red' : 'cyan'}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.displayToDatePicker),callback:function ($$v) {_vm.displayToDatePicker=$$v},expression:"displayToDatePicker"}},[(_vm.displayToDatePicker)?_c(VDatePicker,{staticClass:"fix-date-picker-adjacent-months",attrs:{"no-title":"","color":"cyan darken-1","show-adjacent-months":""},on:{"input":function($event){_vm.displayToDatePicker = false},"update:active-picker":_vm.enableAdjacentMonths,"update:picker-date":_vm.enableAdjacentMonths},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}):_vm._e()],1)],1)],1),(_vm.reportResults)?_c(VRow,[_c(VCol,{staticStyle:{"max-width":"350px"}},[_c(VCard,{staticClass:"d-inline-block",attrs:{"color":"blue lighten-5","max-width":"300"}},[_c(VCardSubtitle,[_vm._v("\n            "+_vm._s(_vm.reportResults.businessName)+"\n            "),_c('span',{staticStyle:{"font-size":"25px","color":"#222"}},[_vm._v(_vm._s(_vm.reportResults.businessId))])])],1)],1),_c(VCol,{staticStyle:{"max-width":"350px"}},[_c(VCard,{staticClass:"d-block",attrs:{"color":"blue lighten-5","max-width":"300"}},[_c(VCardText,[_c('span',{staticStyle:{"color":"#888"}},[_c('span',{staticStyle:{"font-size":"25px","color":"#222"}},[_vm._v(_vm._s(_vm.reportResults.sumAmount.toLocaleString())+"₪")]),_vm._v("\n              "+_vm._s(_vm.$t('insight_total_receipts'))+"\n            ")])])],1)],1)],1):_vm._e(),(_vm.reportResults || _vm.isLoading)?_c(VRow,[_c(VCol,{staticClass:"pb-12",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.reportResults ? _vm.reportResults.allIncomes : [],"loading":_vm.isLoading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.row",fn:function(ref){
var index = ref.index;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s((index + 1).toLocaleString())+"\n            ")])]}},{key:"item.maam",fn:function(){return [_vm._v("\n            0₪\n          ")]},proxy:true},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [(item.document.payment.deleted_at)?_c('span',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(parseFloat(item.income.amount).toLocaleString().replace('.00', ''))+"₪")]),_c('small',[_vm._v("\n                "+_vm._s(_vm.$t('payment_canceled'))+"\n              ")])]):_c('span',[_vm._v("\n              "+_vm._s(parseFloat(item.income.amount).toLocaleString().replace('.00', ''))+"₪\n            ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm._f("date")(item.document.created_at))+"\n            ")])]}},{key:"item.document_number",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"underline","white-space":"nowrap"},on:{"click":function($event){_vm.displayDocument = item.document}}},[_vm._v(_vm._s(item.document.document_number))])]}},{key:"item.document_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm.$t(item.document.document_type))+"\n            ")])]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm.$t(item.income.type))+"\n            ")])]}},{key:"item.document_details",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.document.description)+"\n          ")]}},{key:"item.value_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n\n              "+_vm._s(_vm._f("date")((_vm.getIncomeDate(item.income) || item.document.created_at)))+"\n            ")])]}},{key:"item.patient_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('RouterLink',{attrs:{"to":{
                name: _vm.patientRouteName,
                params: {
                  id: item.document.payment.attributes.patient.id
                }
              }}},[_vm._v("\n                "+_vm._s(item.document.payment.attributes.patient.first_name)+"\n                "+_vm._s(item.document.payment.attributes.patient.last_name))])],1)]}}],null,false,622867422)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-file-document-edit-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_treatments')))])],1)])],1)],1),(_vm.displayDocument)?_c('ShowPaymentDocumentFileDialog',{attrs:{"patient":_vm.displayDocument.payment.attributes.patient,"document":_vm.displayDocument},on:{"close":function($event){_vm.displayDocument = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }