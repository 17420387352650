














































































import { Component, Prop, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SupplierPaymentApi from "../api/supplier-payment.api";
import { SupplierPayment, SupplierPaymentDocumentType } from "../types/supplier-payment.type";
import { Getter } from "vuex-class";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";

@Component({
  components: {
  },
})
export default class SupplierPaymentFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public editSupplierPayment: SupplierPayment;
  @Prop({ required: false }) public defaultSupplierId: number;
  @Prop({ required: false }) public defaultPinkasId: number;

  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/isRtl") isRtl: boolean;
  public isFormLoading = false;
  public isLoadingDelete = false;
  public pinkasId: number = null;
  public supplierId: number = null;
  public documentType: SupplierPaymentDocumentType = null;
  public documentNumber: string = null;
  public createdAt: string = null;
  public amount: number = null;
  public maamAmount: string = null;
  public notes = "";
  public showErrors = false;
  public displayDatePicker = false;
  public supplierPaymentCategoryId: number = null;

  get dateFormatted() {
    if (!this.createdAt) {
      return "";
    }
    return moment(this.createdAt).format("DD-MM-YYYY");
  }

  public updateMaamAmount() {
    if (this.amount && this.supplierPaymentCategoryId) {
      const supplierCategory = this.supplierPaymentCategories.find(category => category.id === this.supplierPaymentCategoryId);
      const calculatedMaam = +this.amount - (+this.amount / (1 + +supplierCategory.maam_rate));
      this.maamAmount = (Math.floor(calculatedMaam * 100) / 100).toString();
    } if (this.supplierPaymentCategoryId && !this.amount) {
      this.maamAmount = null;
    }
  }

  get shouldShowMaam() {
    return this.documentType !== "kabala";
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public mounted() {
    if (this.editSupplierPayment) {
      this.pinkasId = this.editSupplierPayment.pinkas_id;
      this.supplierId = this.editSupplierPayment.supplier_id;
      this.documentType = this.editSupplierPayment.document_type;
      this.amount = this.editSupplierPayment.amount;
      this.maamAmount = parseFloat(this.editSupplierPayment.maam_amount.toString()).toFixed(2).replace(".00", "");
      this.documentNumber = this.editSupplierPayment.document_number;
      this.createdAt = moment(this.editSupplierPayment.created_at).format("YYYY-MM-DD");
      this.notes = this.editSupplierPayment.notes;
      this.supplierPaymentCategoryId = this.editSupplierPayment.supplier_payment_category_id;
    } else {
      if (this.defaultSupplierId) {
        this.supplierId = this.defaultSupplierId;
      }
      if (this.defaultPinkasId) {
        this.pinkasId = this.defaultPinkasId;
      } else if (this.pinkasList.length === 1) {
        this.pinkasId = this.pinkasList[0].id;
      }

      if (this.supplierPaymentCategories.length === 1) {
        this.supplierPaymentCategoryId = this.supplierPaymentCategories[0].id;
      }
    }
  }

  get supplierPaymentCategories() {
    return this.$store.getters["auth/supplierPaymentCategories"].filter(({ deleted_at }) => (!deleted_at));
  }

  @Watch("supplierId")
  public onSupplierChanged() {
    if (!this.supplierPaymentCategoryId) {
      this.supplierPaymentCategoryId = (this.$store.getters["auth/suppliers"]).find(({ id }) => (id === this.supplierId)).default_supplier_payment_category_id;
    }
  }
  get documentTypeOptions() {
    return [
      {
        text: this.$t("documentType.heshbonit_mas"),
        value: "heshbonit_mas",
      },
      {
        text: this.$t("documentType.kabala"),
        value: "kabala",
      },
      {
        text: this.$t("documentType.heshbonit_zikoi"),
        value: "heshbonit_zikoi",
      }
    ];
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onDeleteClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    this.isLoadingDelete = true;
    try {
      await SupplierPaymentApi.delete(this.editSupplierPayment.id);
      this.$emit("reload");
      this.$toastr.s(this.$t("deleted_successfully"));
      this.isLoadingDelete = false;
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingDelete = false;
    }
  }

  public async submitForm() {
    this.showErrors = true;

    if (!this.documentType || !this.amount || !this.createdAt || !this.supplierId || !this.supplierPaymentCategoryId) {
      return;
    }

    this.isFormLoading = true;
    const payload = {
      pinkas_id: this.pinkasId,
      supplier_id: this.supplierId,
      document_type: this.documentType,
      document_number: this.documentNumber,
      amount: parseFloat(this.amount.toString()) || 0,
      maam_amount: parseFloat(this.maamAmount) || 0,
      amount_without_maam: parseFloat(this.amount.toString()) - parseFloat(this.maamAmount) || 0,
      maam_rate: ((parseFloat(this.maamAmount) || 0) / (parseFloat(this.amount.toString()) - (parseFloat(this.maamAmount) || 0))).toFixed(2),
      notes: this.notes,
      supplier_payment_category_id: this.supplierPaymentCategoryId,
      created_at: this.createdAt,
    };

    try {
      this.editSupplierPayment
        ? await SupplierPaymentApi.update(this.editSupplierPayment.id, payload)
        : await SupplierPaymentApi.create(payload);

      this.$toastr.s(this.$t(this.editSupplierPayment ? "supplier_payment_updated" : "supplier_payment_created"));
      this.isFormLoading = false;
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.isFormLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  get suppliersList() {
    return this.$store.getters["auth/suppliers"].filter(({ deleted_at }) => (!deleted_at));
  }
}
