import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.patientTreatments.length || _vm.isLoading)?_c(VRow,[_c(VCol,{staticClass:"pr-0 pl-0 pb-0",attrs:{"cols":"12"}},[_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl, 'mt-3': _vm.isMobile },attrs:{"loading":_vm.isExportLoading,"color":"cyan lighten-3"},on:{"click":function($event){return _vm.exportReport()}}},[_c(VIcon,{attrs:{"color":"#0e783f"}},[_vm._v("mdi-file-excel")]),_vm._v("\n                "+_vm._s(_vm.$t('export_btn'))+"\n            ")],1)],1),_c(VCol,{staticClass:"pb-12",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"page":_vm.page,"dense":"","mobile-breakpoint":1,"hide-default-footer":_vm.isLoading,"headers":_vm.headers,"options":_vm.dataTableOptions,"items":_vm.patientTreatments,"loading":_vm.isLoading,"loading-text":_vm.$t('loading'),"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [_vm.itemsPerPage] }},on:{"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("time")(item.created_at))+"\n                    ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm._f("date")(item.created_at))+"\n                    ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                        "+_vm._s(_vm.userName(item.user_id))+"\n                    ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"50px"}},[(item.discount != 0)?_c('span',[_c('s',{staticStyle:{"margin-right":"3px"}},[_vm._v(_vm._s(item.price.toLocaleString())+"₪")]),_c('strong',[_vm._v(_vm._s((item.price - item.discount).toLocaleString())+"₪")])]):_c('span',[_vm._v(_vm._s(item.price.toLocaleString())+"₪")])])]}},{key:"item.treatment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px","min-width":"100px"}},[_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.onEditTreatmentClicked(item)}}},[_vm._v(_vm._s(_vm.treatmentName(item.treatment_id)))])])]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px","min-width":"100px"}},[_c('RouterLink',{attrs:{"to":{
                            name: _vm.patientRouteName,
                            params: {
                                id: item.patient.id
                            }
                        }}},[_vm._v("\n                            "+_vm._s(item.patient.first_name)+"\n                            "+_vm._s(item.patient.last_name))])],1)]}}],null,false,3872159994)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-file-document-edit-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_treatments')))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }