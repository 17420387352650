import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height cyan lighten-3",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[_c('SmileLogo'),_c(VForm,{attrs:{"accept-charset":"UTF-8"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleLogin()}}},[_c(VCard,{staticClass:"elevation-12",attrs:{"color":"white"}},[_c(VCardText,[_c('ErrorMessage',{attrs:{"errors":_vm.errors}}),_c(VTextField,{attrs:{"color":"cyan","label":_vm.$t('new_password'),"name":"password","prepend-icon":"mdi-account","append-icon":_vm.show ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.show ? 'text' : 'password'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","block":"","color":"cyan","loading":_vm.loading}},[_vm._v("\n              "+_vm._s(_vm.$t('reset_password'))+"\n            ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }