import { render, staticRenderFns } from "./PriceListForm.vue?vue&type=template&id=4db6eac0&"
import script from "./PriceListForm.vue?vue&type=script&lang=ts&"
export * from "./PriceListForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/modules/settings/locales/settings-price-lists.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fsettings%2Fcomponents%2Ftreatment%2FPriceListForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports