import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { WaitingAppointmentApiRouteParameters, WaitingAppointmentApiRoutes } from "../constants/api-routes";
import { WaitingAppointment, WaitingAppointmentMandatory } from "../types/waiting-appointment.type";

class WaitingAppointmentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async create(payload: WaitingAppointmentMandatory): Promise<WaitingAppointment> {
    try {
      const response = await this.httpClient.post(
        WaitingAppointmentApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, params: WaitingAppointmentMandatory): Promise<WaitingAppointment> {
    const { data } = await this.httpClient.put(
      WaitingAppointmentApiRoutes.update.replace(WaitingAppointmentApiRouteParameters.id, id.toString()),
      params
    );
    return data;
  }

  async find(): Promise<WaitingAppointment[]> {
    try {
      const { data } = await this.httpClient.get(WaitingAppointmentApiRoutes.find);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        WaitingAppointmentApiRoutes.delete.replace(WaitingAppointmentApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

}

export default new WaitingAppointmentApi();
