import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"smile-patients-page"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c(VIcon,[_vm._v("mdi-folder-account-outline")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n          "),_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"disabled":!_vm.isEditEnabled,"small":"","color":"cyan"},on:{"click":function($event){return _vm.openPatientFormDialog(null)}}},[_vm._v("\n            "+_vm._s(_vm.$t('add_patient'))+"\n            "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],1),_c(VRow,[_c(VCol,[_c('PatientSearchTable',{ref:"searchTable"})],1)],1)],1),(_vm.showPatientForm)?_c('PatientForm',{attrs:{"edit-patient":_vm.editPatient},on:{"close":function($event){return _vm.closePatientForm()},"patientCreated":_vm.onPatientCreated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }