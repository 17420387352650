





















































import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { PatientFolder } from "../../types/patient-folder.type";

@Component({
  components: {
  },
})
export default class ExplorerFolderActionButton extends mixins(SmileMixin) {
  @Prop({ required: true }) public folder: PatientFolder;

  public onRenameClicked() {
    this.$emit("renameFolder", this.folder);
  }

  public onMoveClicked() {
    this.$emit("moveFolder", this.folder);
  }

  public onDeleteClicked() {
    this.$emit("deleteFolder", this.folder);
  }

  public onDownloadClicked() {
    this.$emit("downloadFolder", this.folder);
  }

  public onSelectClicked() {
    this.$emit("selectFolder", this.folder);
  }
}
