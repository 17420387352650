import TaskApi from "../api/task.api";
import { prepareTaskResponse } from "../helpers/task.helpers";

const taskActions = {
  async loadTasks({ commit }, userId: number) {
    const tasksData = await TaskApi.getAll(userId);

    const {
      assignedFutureTasks,
      createdFutureTasks,
      assignedTodoTasks,
      createdTodoTasks,
      assignedDoneTasks,
      createdDoneTasks,
      assignedTaskRecurrings,
      createdTaskRecurrings,
    } = prepareTaskResponse(userId, tasksData);

    commit("setTasks", {
      myFutureTasks: assignedFutureTasks,
      createdFutureTasks,
      myTodoTasks: assignedTodoTasks,
      createdTodoTasks,
      myDoneTasks: assignedDoneTasks,
      createdDoneTasks,
      myTaskRecurrings: assignedTaskRecurrings,
      createdTaskRecurrings,
    });
  },
};

export default taskActions;
