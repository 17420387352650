






































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SentenceMenu extends Vue {
  @Prop() displayType: string;
  public sentencesOpen = false;

  get sentences() {
    if (!this.$store.getters["auth/sentences"]) {
      return [];
    }

    return this.$store.getters["auth/sentences"]
      .filter(sentenceCategory => {
        // Hide categories without sentences
        return sentenceCategory.sentence.length > 0;
      })
      .filter(sentenceCategory => (!this.displayType || this.displayType === sentenceCategory.display_type));
  }

  get isSingleCategory() {
    return this.sentences.length === 1;
  }

  get isEmpty() {
    return this.sentences.length === 0;
  }

  public sentenceSelected(contents) {
    this.sentencesOpen = false;
    this.$emit("sentenceSelected", contents);
  }
}
