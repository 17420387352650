import Request from "../request";

class ImagingAgentApi {
  constructor () {
    this.request = new Request();
  }

  get () {
    return this.request.get("/imaging_agent");
  }

  delete (imagingAgentId) {
    return this.request.delete(`/imaging_agent/${imagingAgentId}`);
  }

  update (imagingAgentId, payload = {}) {
    return this.request.put(`/imaging_agent/${imagingAgentId}`, payload);
  }

  create (payload = {}) {
    return this.request.post("/imaging_agent", payload);
  }

}

export default new ImagingAgentApi();
