import { render, staticRenderFns } from "./PatientNoteFormDialog.vue?vue&type=template&id=72dcfe9a&"
import script from "./PatientNoteFormDialog.vue?vue&type=script&lang=ts&"
export * from "./PatientNoteFormDialog.vue?vue&type=script&lang=ts&"
import style0 from "./PatientNoteFormDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/modules/patient/locales/patient-note-form-dialog.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpatient%2Fcomponents%2FPatientNoteFormDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports