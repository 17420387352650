



































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Patient } from "@/modules/patient/types/patient.type";
import { ContactBookmark } from "../types/contact-bookmark.type";
import { advancedContactSearch } from "../helpers/contacts-helpers";

@Component({
})
export default class ShareContactsDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  public contactsIds: number[] = [];
  public isLoading = false;
  public isInvalidEmail = false;

  public onAdvancedContactSearch(item: any,
    queryText: string,
    itemText: string) {

    return advancedContactSearch(item, queryText, itemText);
  }

  get contacts() {
    if (!this.$store.getters["auth/contacts"]) {
      return [];
    }
    const contacts = this.$store.getters["auth/contacts"] as ContactBookmark[];
    const flattenContacts = [];

    contacts
      .map(contactBookmark => {
        contactBookmark.contacts.map((contact) => {
          if (contact.email) {
            if (contacts.length > 1) {
              flattenContacts.push({ ...contact, category: contactBookmark.name });
            } else {
              flattenContacts.push(contact);
            }

          }
        }
        );

      });

    return flattenContacts;
  }

  public openPatientCreateLetterDialog() {
    const selectedContacts = this.contactsIds.map(contactId => this.contacts.find(contact => contact.id === contactId));
    const templateContactLetter = selectedContacts.map(contact => {
      const details: string[] = [];
      if (contact.name) {
        details.push(contact.name);
      } if (contact.address) {
        details.push(contact.address);
      }
      const phones = [contact.phone, contact.phone2].filter(Boolean).join(", ");
      if (phones) {
        details.push(phones);
      }
      const emails = [contact.email, contact.email2].filter(Boolean).join(", ");
      if (emails) {
        details.push(emails);
      }
      if (contact.notes) {
        details.push(contact.notes);
      }
      return details.join("\n");
    }).join("\n\n");

    this.$emit("onOpenCreateLetterDialog", this.$t("share_contacts_title"), this.$t("share_contacts_title"), this.$t("letter_to"), templateContactLetter);
  }

  public closeDialog() {
    this.$emit("close");
  }

}
