















































































import { Component, Prop } from "vue-property-decorator";
import { date, time } from "@/filters/date.filter";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import { HeshbonitMas, HeshbonitMasKabala, HeshbonitZikoi, Kabala } from "../../types/payment.type";
import SharePatientPaymentEmailDialog from "./SharePatientPaymentEmailDialog.vue";
import SharePatientPaymentWhatsappDialog from "./SharePatientPaymentWhatsappDialog.vue";
import paymentDocumentApi from "@/modules/payment/api/payment-document.api";


@Component({
  filters: {
    date,
    time
  },
  components: {
    SharePatientPaymentEmailDialog,
    SharePatientPaymentWhatsappDialog,
  },
})
export default class ShowPaymentDocumentFileDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public document: HeshbonitMas | HeshbonitMasKabala | HeshbonitZikoi | Kabala;
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public showShareEmailDialog = false;
  public showShareWhatsappDialog = false;
  public isFileActionsLoading = false;
  public isImageLoading = true;
  public closeDialog() {
    this.$emit("close");
  }


  public onDownloadFileClicked() {
    this.downloadFile(this.document.download_url, this.document.download_url.split("/").pop());
  }

  public onShareEmailClicked() {
    this.showShareEmailDialog = true;
  }

  public async onShareWhatsappClicked() {
    this.showShareWhatsappDialog = true;
  }

  public onShareWhatsappWebClicked() {
    this.shareWatsappWebMessage(this.patient.phone, this.document.temporary_url);
  }

  public onPrintClicked() {
    const iframeEl = (this.$refs["preview-iframe"] as any);
    iframeEl.contentWindow.print();
  }

  get documentPreviewUrl() {
    return paymentDocumentApi.getPreviewPdfUrl(this.document.document_type, this.document.id) + "#zoom=80&navpanes=0";
    // TODO add &toolbar=0
  }
}
