






































import { Component, Prop, Vue } from "vue-property-decorator";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";

@Component
export default class ContactMenu extends Vue {
  @Prop() displayType: string;
  public contactsOpen = false;

  get contacts() {
    if (!this.$store.getters["auth/contacts"]) {
      return [];
    }

    return (this.$store.getters["auth/contacts"] as ContactBookmark[])
      .filter(contactBookmark => {
        // Hide bookmarks without contacts
        return contactBookmark.contacts.filter(({ email }) => (email)).length > 0;
      })
      .filter(contactBookmark => (!this.displayType || (contactBookmark.display_type && contactBookmark.display_type.includes(this.displayType))))
      .map(contactBookmark => ({
        ...contactBookmark,
        contacts: contactBookmark.contacts.filter(({ email }) => (email)),
      }));
  }

  get isSingleBookmark() {
    return this.contacts.length === 1;
  }

  get isEmpty() {
    return this.contacts.length === 0;
  }

  public contactSelected(contents) {
    this.contactsOpen = false;
    this.$emit("contactSelected", contents);
  }
}
