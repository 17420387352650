















































import moment from "moment";
import TimeClockApi from "../../api/time-clock.api";
import TimeEntryTypeApi from "../../api/time-entry-type.api";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { MandatoryTimeClockEntryData } from "@/modules/time-clock/types/time-clock.type";
import get from "lodash/get";
import { AxiosError } from "axios";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";

@Component({
  components: {
    ErrorMessage
  }
})
export default class AddTimeClockEntry extends Vue {
  public errors: string = null;
  public startDate = new Date().toISOString().substr(0, 10);
  public submitting = false;
  public dateMenuOpen = false;
  public startHour = moment().add(-1, "hour").format("HH:mm");
  public endHour = moment().format("HH:mm");
  public type = null;
  @Getter("auth/timeEntryTypes") public timeEntryTypes;

  mounted() {
    TimeEntryTypeApi.getLastUsedType()
      .then(lastUsedType => {
        if (!this.type) {
          this.type = lastUsedType;
        }
      });
  }

  get formattedDate() {
    if (!this.startDate) {
      return null;
    }
    const [year, month, day] = this.startDate.split("-");

    return `${day}/${month}/${year}`;
  }

  get isMultiDate() {
    const start = `${this.startDate} ${this.startHour}`;
    const end = `${this.startDate} ${this.endHour}`;

    return moment.duration(moment(end).diff(start)).asMinutes() <= 0;
  }

  get nextDay() {
    return moment(this.startDate).add(1, "day").format("DD-MM-YYYY");
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  public getTimeEntryRecord(): MandatoryTimeClockEntryData {
    const start = moment(`${this.startDate} ${this.startHour}`);
    let end = moment(`${this.startDate} ${this.endHour}`);

    if (this.isMultiDate) {
      end = end.add(1, "day");
    }

    return {
      start: start.local().format("YYYY-MM-DD HH:mm:ss"),
      end: end.local().format("YYYY-MM-DD HH:mm:ss"),
      type_id: this.type
    };
  }

  public async createNewEntry() {
    this.submitting = true;
    this.errors = null;

    await TimeClockApi
      .create(this.getTimeEntryRecord())
      .then((entry) => {
        this.$toastr.s(this.$t("entry_created"));
        this.$emit("entryCreated", entry);
        this.resetFields();
      })
      .catch((error: AxiosError) => {
        this.errors = get(error, "response.data.errors") || this.$t("temporary_error");
      })
      .finally(() => this.submitting = false);
  }

  public resetFields() {
    this.startDate = new Date().toISOString().substr(0, 10);
    this.startHour = moment().add(-1, "hour").format("HH:mm");
    this.endHour = moment().format("HH:mm");
    this.type = null;
  }
}
