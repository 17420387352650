






















































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import { Patient } from "@/modules/patient/types/patient.type";
import AppointmentApi from "@/modules/appointment/api/appointment.api";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import moment from "moment";
import { AppointmentType } from "@/modules/appointment/types/appointment-type.type";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { age, time } from "@/filters/date.filter";
import { AppointmentStatus } from "../../types/appointment-status.type";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import { CALENDAR_ROUTE_NAME } from "../../constants/route-config";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import TrackChangesAppointmentDialog from "./TrackChangesAppointmentDialog.vue";

@Component({
  components: {
    SentenceMenu,
    SearchPatient,
    ErrorMessage,
    TrackChangesAppointmentDialog,
  },
  filters: {
    age,
    time,
  }
})
export default class EditAppointmentFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) editAppointment: Appointment;
  @Prop({ required: false }) patient: Patient;
  @Prop({ required: true }) openedFrom: string;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/trackChangesAppointment") trackChangesAppointment: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/isShowingAppointmentTypeSelector") isShowingAppointmentTypeSelector: boolean;
  @Getter("auth/calendarViewColorType") calendarViewColorType: "patient-status" | "appointment-status" | "appointment-type";

  public patientId: number = null;
  public userId: number = null;
  public typeId: number = null;
  public statusId: number = null;
  public startHour = "";
  public endHour = "";
  public date = moment().utc().format("YYYY-MM-DD");
  public treatmentDuration = 0;
  public showErrors = false;
  public sendFutureAppointmentsReminder = false;
  public isDirty = false;
  public activeTab = null;
  public errors: any = false;
  public savingLoading = false;
  public deleteLoading = false;
  public showReminderNotes = false;
  public notes = "";
  public reminderNotes = "";
  public displayTreatmentDatePicker = false;
  public displayNoteDatePicker = false;
  public displayTrackChanges = false;

  public onTrackChangesClicked() {
    this.displayTrackChanges = true;
  }

  public onCopyClicked() {
    this.$emit("copy", this.editAppointment.id);
    this.closeDialog();
  }

  public onMoveClicked() {
    this.$emit("move", this.editAppointment.id);
    this.closeDialog();
  }

  @Watch("reminderNotes")
  public onReminderNotesChanged() {
    if (!this.reminderNotes) {
      return;
    }

    this.reminderNotes = this.reminderNotes.replace(/\n/g, " ");
  }

  public appendNotesSentence(sentence: string) {
    if (this.notes) {
      this.notes += " " + sentence;
    } else {
      this.notes = sentence;
    }
  }

  public appendReminderNotesSentence(sentence: string) {
    if (this.reminderNotes) {
      this.reminderNotes += " " + sentence.replace(/\n/g, " ");
    } else {
      this.reminderNotes = sentence.replace(/\n/g, " ");
    }
  }

  get displayDate() {
    return moment(this.date).format("dddd, MMMM Do YYYY");
  }

  get patientGeneralNotes() {
    return this.patient.notes.filter(({ pinned }) => (!pinned));
  }

  mounted() {
    if (this.editAppointment) {
      this.userId = this.editAppointment.user_id;
      this.typeId = this.editAppointment.type_id;
      this.statusId = this.editAppointment.status_id;
      this.date = this.editAppointment.start_date.substring(0, 10);
      this.startHour = this.editAppointment.start_date.substring(11, 16);
      this.endHour = this.editAppointment.end_date.substring(11, 16);
      this.notes = this.editAppointment.notes;
      this.reminderNotes = this.editAppointment.reminder_notes;
      if (this.reminderNotes) {
        this.showReminderNotes = true;
      }
    }

    if (this.patient) {
      this.patientId = this.patient.id;
    }
  }

  public markDirty() {
    this.isDirty = true;
  }

  onStartHourChanged(newStartHour) {
    if (!newStartHour) {
      return;
    }

    const treatmentDuration = (this.endHour ? moment(`${this.date} ${this.endHour}:00`).diff(moment(`${this.date} ${this.startHour}:00`), "minutes") : 0) || 15;
    this.startHour = this.reformatTimeInput(newStartHour);

    if (!this.startHour) {
      return;
    }

    if (!this.endHour) {
      this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(treatmentDuration, "minutes").format("HH:mm");
      return;
    }

    this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(treatmentDuration, "minutes").format("HH:mm");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public durationText(durationMinutes: number) {
    if (durationMinutes < 60) {
      return `${durationMinutes} ${this.$t("minutes")}`;
    }

    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    if (hours === 1) {
      if (!minutes) {
        return `${this.$t("hour")}`;
      }

      return `${this.$t("hour")} ${this.$t("and")}${minutes} ${this.$t("minutes")}`;
    }

    if (!minutes) {
      return `${hours} ${this.$t("hours")}`;
    }

    return `${hours} ${this.$t("hours")} ${this.$t("and")}${minutes} ${this.$t("minutes")}`;
  }

  get appointmentDateFormatted() {
    const momentDate = moment(this.date);
    return this.$t(`day-in-week.${momentDate.format("d")}`) + ", " +
      momentDate.format("D") + " " + this.$t("date_in") + this.$t(`months.${parseInt(momentDate.format("M")) - 1}`) + " " + momentDate.format("Y");
  }

  get appointmentTypeOptions() {
    return (this.$store.getters["auth/appointmentTypes"] as AppointmentType[]).filter((appointmentType) => {
      return !appointmentType.deleted_at || appointmentType.id === this.typeId;
    });
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    if (hour === 0 && min === 0) {
      return "";
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public typeChanged() {
    this.markDirty();
    const selectedType = (this.$store.getters["auth/appointmentTypes"] as AppointmentType[])
      .find(appointment => (appointment.id === this.typeId));

    this.treatmentDuration = selectedType.duration_minutes;
    this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(this.treatmentDuration, "minutes").format("HH:mm");
  }

  public displayAppointmentTypeDuration(appointmentType: AppointmentType) {
    if (appointmentType.id === this.typeId) {
      const start = `${this.date} ${this.startHour}`;
      const end = `${this.date} ${this.endHour}`;

      const durationMinutes = moment.duration(moment(end).diff(start)).asMinutes();
      if (durationMinutes > 0) {
        return this.durationText(durationMinutes);
      }
    }

    return this.durationText(appointmentType.duration_minutes);
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public closeDialog() {
    this.$emit("close");
  }

  public submitForm() {
    this.errors = false;
    this.showErrors = true;

    if (!this.isEditEnabled) {
      return;
    }

    const startDate = moment(`${this.date} ${this.startHour}`);
    const endDate = moment(`${this.date} ${this.endHour}`);
    const treatmentDuration = endDate.diff(startDate, "minutes");

    if (treatmentDuration < 0) {
      this.endHour = "";
    }

    if (!this.userId || !this.startHour || !this.endHour || (!this.patientId && !this.notes.length)) {
      this.errors = this.$t("missing_required");
      return;
    }

    this.savingLoading = true;


    const payload = {
      patient_id: this.patientId,
      user_id: this.userId,
      type_id: this.typeId,
      status_id: this.statusId,
      start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
      notes: this.notes,
      reminder_notes: this.reminderNotes,
    };

    // TODO await
    const attempt = AppointmentApi.update(this.editAppointment.id, payload);

    attempt
      .then(appointment => {
        this.savingLoading = false;
        this.$toastr.s(this.$t("appointment_updated"));
        this.$emit("updated", appointment);
        if (this.sendFutureAppointmentsReminder && this.isWhatsappBotEnabled && this.patient.phone) {
          WhatsappApi.sendSinglePatientFutureReminders(appointment.patient_id)
            .then(() => {
              this.$toastr.s(this.$t("send_reminders_success_toastr"));
            })
            .catch((err) => {
              this.$toastr.e(err && err.smileMessage ? err.smileMessage : this.$t("send_reminders_error_toastr"));
            });
        }
        this.closeDialog();
      })
      .catch(err => {
        this.savingLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public setEndHour(time: string) {
    this.endHour = time;
    setTimeout(() => {
      // workaround
      this.endHour = time;
    }, 50);
  }

  get appointmentStatuses() {
    return this.$store.getters["auth/appointmentStatuses"]
      .filter((appointmentStatus: AppointmentStatus) => (appointmentStatus.is_enabled));
  }

  public async deleteButtonClicked() {
    const res = await this.$confirm(
      this.$t(this.patientId ? "delete_confirm_message" : "delete_confirm_message_note").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.deleteLoading = true;
    AppointmentApi
      .delete(this.editAppointment.id)
      .then(() => {
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("deleted", this.editAppointment.id);
        this.closeDialog();
      })
      .catch(() => {
        this.$toastr.e(this.$t("temporary_error"));
      })
      .finally(() => this.deleteLoading = false);
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get startTimeSuggestionList() {
    if (!this.startHour || !moment(`${this.date} ${this.startHour}:00`).isValid()) {
      return [];
    }
    const intervals = this.$store.getters["auth/calendarStartTimeIntervals"];

    return intervals.map(intervalMinutes => ({
      time: moment(`${this.date} ${this.startHour}:00`).add(intervalMinutes, "minutes").format("HH:mm"),
    }));
  }

  get endTimeSuggestionList() {
    if (!this.startHour || !moment(`${this.date} ${this.startHour}:00`).isValid()) {
      return [];
    }
    let durations = this.$store.getters["auth/calendarAppointmentDurations"];

    const treatmentDuration = moment(`${this.date} ${this.endHour}:00`).diff(moment(`${this.date} ${this.startHour}:00`), "minutes");
    if (!durations.includes(treatmentDuration) && parseInt(treatmentDuration.toString()) > 0) {
      durations = [...durations, parseInt(treatmentDuration.toString())].sort(function (a, b) {
        return a - b;
      });
    }

    return durations.map(durationMinutes => ({
      time: moment(`${this.date} ${this.startHour}:00`).add(durationMinutes, "minutes").format("HH:mm"),
      duration: durationMinutes,
    }));
  }

  public goToCalendar() {
    this.$router.push({
      name: CALENDAR_ROUTE_NAME,
      query: {
        view: "day",
        calendar: this.userId.toString(),
        date: this.date,
      }
    });
  }
}
