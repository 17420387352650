import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"560px","persistent":_vm.isDirty},on:{"input":_vm.closeDialog}},[_c(VCard,{staticClass:"patient-hidden-note-form-dialog",attrs:{"outlined":"","raised":"","elevation":"4"},on:{"change":function($event){return _vm.markDirty()}}},[_c(VCardTitle,{staticClass:"justify-space-between align-start"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('title'))+"\n      ")]),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","color":"cyan","rows":"2","auto-grow":"","label":_vm.$t('hidden_note_label'),"hide-details":""},model:{value:(_vm.hiddenNoteModel),callback:function ($$v) {_vm.hiddenNoteModel=$$v},expression:"hiddenNoteModel"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"pl-5 pr-5",attrs:{"color":"cyan darken-1","type":"submit","loading":_vm.isSubmitLoading,"disabled":!_vm.isEditEnabled},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("\n        "+_vm._s(_vm.$t('save_button'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }