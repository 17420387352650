































import Vue from "vue";
import Component from "vue-class-component";
import { APPOINTMENT_STATUS_PAGE_ROUTE_NAME, APPOINTMENT_TYPE_PAGE_ROUTE_NAME, MANAGE_PINKAS_PAGE_ROUTE_NAME, CONTACT_BOOKMARKS_PAGE_ROUTE_NAME, DRUGS_PAGE_ROUTE_NAME, IMAGINING_AGENT_PAGE_ROUTE_NAME, PATIENT_STATUSES_PAGE_ROUTE_NAME, PRICE_LISTS_PAGE_ROUTE_NAME, QUICK_ACTION_PAGE_ROUTE_NAME, SENTENCES_CATAGORIES_PAGE_ROUTE_NAME, SHIFTS_PAGE_ROUTE_NAME, SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME, TAGS_PAGE_ROUTE_NAME, TIME_ENTRY_TYPES_PAGE_ROUTE_NAME, TREATMENTS_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {

  },
})
export default class SettingsPage extends Vue {

  public settingsPages = [
    {
      settingTitle: this.$t("patient"),
      settings: [
        {
          title: this.$t("tags_tab"),
          routeName: TAGS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("patient_tab"),
          routeName: PATIENT_STATUSES_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("quick_action_and_template_letter_tab"),
          routeName: QUICK_ACTION_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
      ]
    },
    {
      settingTitle: this.$t("treatments_title"),
      settings: [
        {
          title: this.$t("drugs_tab"),
          routeName: DRUGS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("treatments_tab"),
          routeName: TREATMENTS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("price_list_tab"),
          routeName: PRICE_LISTS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
      ]
    },
    {
      settingTitle: this.$t("appointments"),
      settings: [
        {
          title: this.$t("sentences_tab"),
          routeName: SENTENCES_CATAGORIES_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("appointments_tab"),
          routeName: APPOINTMENT_TYPE_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("appointments_status"),
          routeName: APPOINTMENT_STATUS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("shift_tab"),
          routeName: SHIFTS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/shouldDisplayManageShifts"],
          isShow: true
        },
      ]
    },
    {
      settingTitle: this.$t("general"),
      settings: [
        {
          title: this.$t("time_clock_tab"),
          routeName: TIME_ENTRY_TYPES_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: this.$store.getters["auth/isTimeClockFeatureEnabled"]
        },
        {
          title: this.$t("contact_bookmarks_tab"),
          routeName: CONTACT_BOOKMARKS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
        {
          title: this.$t("imaging_tab"),
          routeName: IMAGINING_AGENT_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isSmileSupport"],
          isShow: this.$store.getters["auth/isSmileSupport"]
        },
        {
          title: this.$t("supplier_payment_category_tab"),
          routeName: SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: this.$store.getters["auth/isExpensesEnabled"]
        },
        {
          title: this.$t("pinkas_tab"),
          routeName: MANAGE_PINKAS_PAGE_ROUTE_NAME,
          disable: !this.$store.getters["auth/isAdmin"],
          isShow: true
        },
      ]
    },
  ]
}
