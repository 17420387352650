
































import { Component, Vue } from "vue-property-decorator";
import GoogleContactsSyncApi from "../api/google-contacts-sync.api";

@Component({
})
export default class GoogleContactsSyncConnect extends Vue {
    public isSyncConnected = null;
    public loadErrors = false;
    public connectedEmail: string = null;

    public async mounted() {
        // check code status
        console.log(this.$route.query.code);
        if (this.$route.query.code) {
            try {
                await GoogleContactsSyncApi.postAfterConnect(this.$route.query.code.toString());
                this.$toastr.s(this.$t("connected-successfully-toastr"));
            } catch (err) {
                this.$toastr.e(this.$t("temporary_error"));
            } finally {
                window.history.replaceState({}, document.title, location.pathname);
            }
        }


        await this.loadSyncStatus();
    }

    public async loadSyncStatus() {
        this.loadErrors = false;


        // TODO remove
        this.isSyncConnected = false;
        return;

        // eslint-disable-next-line
        try {
            const connectionInfo = await GoogleContactsSyncApi.getConnectionInfo();
            this.isSyncConnected = connectionInfo.isConnected;
            this.connectedEmail = connectionInfo.email;
        } catch (err) {
            this.loadErrors = true;
        }
    }

    public async connectClicked() {
        this.$toastr.i("עדיין בבנייה. תכף יהיה זמין לשימוש");
        return;
        // TODO
        // eslint-disable-next-line no-unreachable
        const confirmUrl = await GoogleContactsSyncApi.generateConnectUrl(location.origin + location.pathname);

        const res = await this.$confirm(
            this.$t("connect_confirm_message").toString(),
            {
                buttonTrueColor: "cyan darken-1",
                buttonTrueText: this.$t("connect_ok_btn").toString(),
                buttonFalseText: this.$t("connect_cancel_btn").toString(),
            });

        if (!res) {
            return;
        }

        window.location.href = confirmUrl;
    }

    public async disconnectClicked() {
        const res = await this.$confirm(
            this.$t("disconnect_confirm_message").toString(),
            {
                buttonTrueColor: "red",
                buttonTrueText: this.$t("disconnect_ok_btn").toString(),
                buttonFalseText: this.$t("disconnect_cancel_btn").toString(),
            });

        if (!res) {
            return;
        }

        try {
            await GoogleContactsSyncApi.disconnect();
            this.$toastr.s(this.$t("disconnected_toastr"));
            this.isSyncConnected = false;
            this.connectedEmail = null;
        } catch (err) {
            this.$toastr.e(this.$t("temporary_error"));
        }
    }
}
