












































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Patient } from "@/modules/patient/types/patient.type";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";

@Component({
})
export default class CalendarPatientCard extends mixins(SmileMixin) {
  @Prop({ required: true }) focusPatient: Patient;


  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onRemoveFocusPatientClicked() {
    this.$emit("onRemoveFocusPatientClicked");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get patientGeneralNotes() {
    return this.focusPatient.notes.filter(({ pinned }) => (!pinned));
  }
}
