import { render, staticRenderFns } from "./CalendarViewSelect.vue?vue&type=template&id=e3a268d6&"
import script from "./CalendarViewSelect.vue?vue&type=script&lang=ts&"
export * from "./CalendarViewSelect.vue?vue&type=script&lang=ts&"
import style0 from "./CalendarViewSelect.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../../locales/calendar-page.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fappointment%2Fcomponents%2Fcalendar-page%2Fcalendar-header%2FCalendarViewSelect.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports