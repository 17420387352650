import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import {
  PaymentVerifoneApiRouteParameters,
  PaymentVerifoneApiRoutes,
} from "@/modules/payment-verifone/constants/api-routes";
import {
  PaymentVerifoneReportResults,
  PaymentVerifoneReportTransaction,
} from "../types/payment-verifone.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class PaymentVerifoneApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getTransactions(
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<PaymentVerifoneReportResults> {
    try {
      const { data } = await this.httpClient.get(
        PaymentVerifoneApiRoutes.getTransactions,
        {
          params: {
            fromDate,
            toDate,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getTransaction(
    fromDate: string,
    toDate: string,
    verifoneUid: string
  ): Promise<PaymentVerifoneReportTransaction> {
    try {
      const { data } = await this.httpClient.get(
        PaymentVerifoneApiRoutes.getTransaction.replace(
          PaymentVerifoneApiRouteParameters.uid,
          verifoneUid
        ),
        {
          params: {
            fromDate,
            toDate,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getVoucherUrl(
    fromDate: string,
    toDate: string,
    transactionId: string
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        PaymentVerifoneApiRoutes.getTransactionVoucher.replace(
          PaymentVerifoneApiRouteParameters.transactionId,
          transactionId
        ),
        {
          params: {
            fromDate,
            toDate,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PaymentVerifoneApi();
