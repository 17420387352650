import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,[(_vm.isEditEnabled)?_c(VBtn,{staticClass:"mb-2",attrs:{"elevation":"1","small":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.goToScheduleAppointment()}}},[_vm._v("\n    "+_vm._s(_vm.$t('create_btn'))+"\n    "),_c(VIcon,{attrs:{"right":_vm.isRtl,"left":!_vm.isRtl}},[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.patient.appointments.length)?_c(VRow,[_c(VCol,{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.patient.appointments},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap","text-decoration":"underline"},on:{"click":function($event){return _vm.onEditAppointmentClicked(item)}}},[_vm._v(_vm._s(_vm._f("date")(item.start_date)))])]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n            "+_vm._s(_vm._f("time")(item.start_date))+"\n          ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n            "+_vm._s(_vm.getDuration(item))+"\n          ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status_id === 3)?_c('span',{staticStyle:{"min-width":"100px","display":"inline-block"}},[_c(VIcon,{attrs:{"color":_vm.getAppointmentStatusColor(item.status_id)}},[_vm._v("mdi-circle")]),_vm._v("\n            "+_vm._s(_vm.getAppointmentStatusText(item.status_id))+"\n          ")],1):_vm._e(),(item.status_id === 4)?_c('span',{staticStyle:{"min-width":"100px","display":"inline-block"}},[_c(VIcon,{attrs:{"color":_vm.getAppointmentStatusColor(item.status_id)}},[_vm._v("mdi-circle")]),_vm._v("\n            "+_vm._s(_vm.getAppointmentStatusText(item.status_id))+"\n          ")],1):_vm._e(),(item.status_id === 8)?_c('span',{staticStyle:{"min-width":"100px","display":"inline-block","white-space":"nowrap"}},[_c(VIcon,{attrs:{"color":_vm.getAppointmentStatusColor(item.status_id)}},[_vm._v("mdi-circle")]),_vm._v("\n            "+_vm._s(_vm.getAppointmentStatusText(item.status_id))+"\n          ")],1):_vm._e(),(item.status_id === 9)?_c('span',{staticStyle:{"min-width":"100px","display":"inline-block"}},[_c(VIcon,{attrs:{"color":'#FC72EE'}},[_vm._v("mdi-circle")]),_vm._v("\n            "+_vm._s(_vm.getAppointmentStatusText(item.status_id))+"\n          ")],1):_vm._e(),(item.status_id === 1)?_c('span',{staticStyle:{"text-weight":"bold"}},[_c(VIcon,{attrs:{"color":_vm.getAppointmentStatusColor(item.status_id)}},[_vm._v("mdi-circle")]),_vm._v("\n            "+_vm._s(_vm.getAppointmentStatusText(item.status_id))+"\n          ")],1):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"110px","max-width":"150px"}},[_vm._v("\n            "+_vm._s(item.patient ? item.patient.first_name : '')+"\n            "+_vm._s(item.patient ? item.patient.last_name : '')+"\n          ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n            "+_vm._s(_vm.userName(item.user_id))+"\n          ")])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px"}},[_vm._v("\n            "+_vm._s(item.notes)+"\n          ")])]}}],null,false,3784347606)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-calendar-blank-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_appointments')))])],1)])],1),(_vm.displayAppointmentDialog)?_c('NewAppointmentFormDialog',{attrs:{"showPatientTabOnly":true,"patient":_vm.patient},on:{"close":function($event){return _vm.closeAppointmentDialog()},"created":_vm.onAppointmentCreated}}):_vm._e(),(_vm.editAppointment)?_c('EditAppointmentFormDialog',{attrs:{"editAppointment":_vm.editAppointment,"patient":_vm.patient,"openedFrom":'PatientAppointments'},on:{"deleted":_vm.onAppointmentDeleted,"updated":_vm.onAppointmentUpdated,"close":_vm.closeEditAppointmentDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }