import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"settings-page",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c(VIcon,[_vm._v("mdi-cog")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n        ")],1)])],1),_c(VRow,_vm._l((_vm.settingsPages),function(settingPage){return _c(VCol,{key:settingPage.settingTitle,staticClass:"pl-3 pr-3 pb-8",attrs:{"sm":"6","lg":"3"}},[_c(VCard,{staticClass:"settings-card"},[_c(VListItemTitle,{staticClass:"card-title",domProps:{"textContent":_vm._s(settingPage.settingTitle)}}),_c('div',{staticClass:"divider"}),_c(VList,{staticStyle:{"padding":"0"}},_vm._l((settingPage.settings),function(page){return (page.isShow)?_c(VListItem,{key:page.title,attrs:{"router-link":"","to":{ name: page.routeName },"rounded":"xl","disabled":page.disable}},[_c(VListItemSubtitle,{staticStyle:{"font-size":"16px !important"},domProps:{"textContent":_vm._s(page.title)}})],1):_vm._e()}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }