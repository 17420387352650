import { Route } from "vue-router";
import store from "@/store";
import {
  APPOINTMENT_STATUS_PAGE_ROUTE_NAME,
  APPOINTMENT_TYPE_PAGE_ROUTE_NAME,
  MANAGE_PINKAS_PAGE_ROUTE_NAME,
  CONTACT_BOOKMARKS_PAGE_ROUTE_NAME,
  DRUGS_PAGE_ROUTE_NAME,
  IMAGINING_AGENT_PAGE_ROUTE_NAME,
  PATIENT_STATUSES_PAGE_ROUTE_NAME,
  PRICE_LISTS_PAGE_ROUTE_NAME,
  QUICK_ACTION_PAGE_ROUTE_NAME,
  SENTENCES_CATAGORIES_PAGE_ROUTE_NAME,
  SETTINGS_PAGE_ROUTE_NAME,
  SHIFTS_PAGE_ROUTE_NAME,
  SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME,
  TAGS_PAGE_ROUTE_NAME,
  TIME_ENTRY_TYPES_PAGE_ROUTE_NAME,
  TREATMENTS_PAGE_ROUTE_NAME,
} from "../constants/route-config";

const ALLOWED_ADMIN_ONLY_ROUTE_NAMES = [
  TAGS_PAGE_ROUTE_NAME,
  PATIENT_STATUSES_PAGE_ROUTE_NAME,
  QUICK_ACTION_PAGE_ROUTE_NAME,
  DRUGS_PAGE_ROUTE_NAME,
  TREATMENTS_PAGE_ROUTE_NAME,
  PRICE_LISTS_PAGE_ROUTE_NAME,
  SENTENCES_CATAGORIES_PAGE_ROUTE_NAME,
  APPOINTMENT_TYPE_PAGE_ROUTE_NAME,
  APPOINTMENT_STATUS_PAGE_ROUTE_NAME,
  CONTACT_BOOKMARKS_PAGE_ROUTE_NAME,
  MANAGE_PINKAS_PAGE_ROUTE_NAME,
];

export const settingsGuardMiddleware = (to: Route, from: Route, next) => {
  if (ALLOWED_ADMIN_ONLY_ROUTE_NAMES.includes(to.name)) {
    if (!store.getters["auth/isAdmin"]) {
      return next({ name: SETTINGS_PAGE_ROUTE_NAME });
    }
  }
  if (IMAGINING_AGENT_PAGE_ROUTE_NAME === to.name) {
    if (!store.getters["auth/isSmileSupport"]) {
      return next({ name: SETTINGS_PAGE_ROUTE_NAME });
    }
  }
  if (SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME === to.name) {
    if (!store.getters["auth/isExpensesEnabled"]) {
      return next({ name: SETTINGS_PAGE_ROUTE_NAME });
    }
  }
  if (TIME_ENTRY_TYPES_PAGE_ROUTE_NAME === to.name) {
    if (!store.getters["auth/isTimeClockFeatureEnabled"]) {
      return next({ name: SETTINGS_PAGE_ROUTE_NAME });
    }
  }
  if (SHIFTS_PAGE_ROUTE_NAME === to.name) {
    if (!store.getters["auth/shouldDisplayManageShifts"]) {
      return next({ name: SETTINGS_PAGE_ROUTE_NAME });
    }
  }
};
