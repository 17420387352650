import {AppHttpClient} from "@/core/http-client/http-client";
import {AxiosInstance} from "axios";
import {GoogleContactsSyncApiRoutes} from "../constants/api-routes";
import {ApiResponse} from "@/core/http-client/http-client.types";
import { GoogleContactsSyncConnectionInfo } from "../types/google-contacts-sync.type";

class GoogleContactsSyncApi {
    private httpClient: AxiosInstance;

    constructor() {
        this.httpClient = AppHttpClient.instance;
    }

    async getConnectionInfo() {
        try {
            const response: ApiResponse<GoogleContactsSyncConnectionInfo> = await this.httpClient.get(
                GoogleContactsSyncApiRoutes.getConnectionInfo
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async generateConnectUrl(redirectBackUrl: string) {
        try {
            const response: ApiResponse<{url: string}> = await this.httpClient.post(
                GoogleContactsSyncApiRoutes.generateConnectUrl,
                {
                    redirectBackUrl,
                }
            );

            return response.data.url;
        } catch (e) {
            throw e;
        }
    }

    async postAfterConnect(code: string) {
        const response: ApiResponse<boolean> = await this.httpClient.post(
            GoogleContactsSyncApiRoutes.postAfterConnect,
            {
                code,
            }
        );

        return response.data;
    }

    async disconnect() {
        try {
            const response: ApiResponse<boolean> = await this.httpClient.delete(
                GoogleContactsSyncApiRoutes.disconnect
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default new GoogleContactsSyncApi();
