import {AppModule} from "@/types/app-module";
import settingsRoutes from "@/modules/settings/routes/routes";
import settingsStore from "@/modules/settings/store";
import settingsPolicy from "@/modules/settings/policy";

const settingsModule: AppModule = {
    name: "settings",
    routes: settingsRoutes,
    store: settingsStore,
    policy: settingsPolicy,
};

export default settingsModule;
