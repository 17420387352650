import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"360"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_vm._v("\n      "+_vm._s(_vm.$t('edit_title'))+"\n      "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{attrs:{"accept-charset":"UTF-8"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submitForm()}}},[_c('smile-error-message',{attrs:{"errors":_vm.errors}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"name":"name","label":_vm.$t('name'),"color":"cyan darken-1","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VColorPicker,{attrs:{"label":_vm.$t('color'),"color":"cyan darken-1","hide-inputs":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCheckbox,{attrs:{"name":"is_enabled","label":_vm.$t('is_enabled'),"color":"cyan darken-1"},model:{value:(_vm.is_enabled),callback:function ($$v) {_vm.is_enabled=$$v},expression:"is_enabled"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"cyan","loading":_vm.loading,"type":"submit"}},[_vm._v("\n                "+_vm._s(_vm.$t('submit'))+"\n              ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }