import {Module} from "vuex";
import settingsState from "@/modules/settings/store/settings.state";
import settingsGetters from "@/modules/settings/store/settings.getters";
import settingsActions from "@/modules/settings/store/settings.actions";
import settingsMutations from "@/modules/settings/store/settings.mutations";

const settingsStore: Module<any, any> = {
    namespaced: true,
    state: settingsState,
    getters: settingsGetters,
    actions: settingsActions,
    mutations: settingsMutations,
};

export default settingsStore;
