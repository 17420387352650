import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"manage-pinkas",attrs:{"fluid":""}},[_c('SettingsHeader',{attrs:{"title":_vm.$t('title'),"icon":'mdi-cog'}}),_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,{staticClass:"pr-0 pl-0 pb-0",attrs:{"cols":"12"}},[_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan","disabled":!_vm.isSmileSupport},on:{"click":function($event){return _vm.openPinkasFormDialog(null)}}},[_vm._v("\n          "+_vm._s(_vm.$t('add_business'))+" "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VCol,{staticClass:"pr-0 pl-0",attrs:{"cols":"12"}},[_c('smile-error-message',{attrs:{"errors":_vm.deleteErrors}}),_c(VDataTable,{attrs:{"mobile-breakpoint":1,"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.pinkases,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"no-date-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.loadErrors)?{key:"no-data",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.reload()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c('ErrorLogo')]},proxy:true}:null,{key:"item.is_tranzila_enabled",fn:function(ref){
var item = ref.item;
return [(item.is_tranzila_enabled)?_c(VIcon,{class:_vm.isRtl ? 'pr-11' : 'pl-11',attrs:{"color":"green"}},[_vm._v(" mdi-check")]):_c(VIcon,{class:_vm.isRtl ? 'pr-11' : 'pl-11',attrs:{"color":"red"}},[_vm._v("mdi-minus")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4","disabled":!_vm.isSmileSupport},on:{"click":function($event){return _vm.openPinkasFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n              "+_vm._s(_vm.$t('edit_btn'))+"\n            ")],1)]}}],null,true)})],1)],1)],1),(_vm.showPinkasForm)?_c('PinkasForm',{attrs:{"editPinkas":_vm.editPinkas},on:{"close":function($event){return _vm.closePinkasForm()},"reload":function($event){return _vm.reload()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }