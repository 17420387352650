import Request from "../request";

class SentenceApi {
  constructor () {
    this.request = new Request();
  }

  get () {
    return this.request.get("/sentence");
  }

  delete (id) {
    return this.request.delete(`/sentence/${id}`);
  }

  create (contents, sentenceCategoryId, order) {
    return this.request.post("/sentence", {
      contents,
      sentence_category_id: sentenceCategoryId,
      order
    });
  }

  update (id, contents, sentenceCategoryId, order) {
    return this.request.put(`/sentence/${id}`, {
      contents,
      sentence_category_id: sentenceCategoryId,
      order
    });
  }

}

export default new SentenceApi();
