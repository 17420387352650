
























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PatientFile } from "../../types/patient-file.type";
import PatientLetterApi from "@/modules/patient/api/patient-letter.api";
import { Patient } from "@/modules/patient/types/patient.type";
import EmailContactSearch from "@/modules/contacts/components/EmailContactSearch.vue";

@Component({
  components: {
    EmailContactSearch
  },
})
export default class SharePatientFileEmailDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public file: PatientFile;
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  public emailTo = "";
  public isLoading = false;
  public isInvalidEmail = false;

  public mounted() {
    if (this.patient.email) {
      this.emailTo = this.patient.email;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public onContactSelected(email: string) {
    this.emailTo = email;
  }

  public async onShareClicked() {
    if (!this.isValidEmail(this.emailTo)) {
      this.isInvalidEmail = true;
      return;
    } else {
      this.isInvalidEmail = false;
    }

    try {
      this.isLoading = true;
      await PatientLetterApi.sendEmailFile(this.emailTo, this.patient.id, this.file.id);
      this.$toastr.s(this.$t("sent_success_toastr"));
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

}
