














































import TemplateLetterApi from "../api/template-letter-api";
import QuickActionCategoryApi from "../api/quick-action-category-api";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { TemplateLetter } from "../types/template-letter.type";
import { QuickActionCategory } from "../types/quick-action-category.type";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import TemplateLetterForm from "../components/quick-actions/TemplateLetterForm.vue";

@Component({
  components: {
    ErrorLogo,
    TemplateLetterForm,
  }
})

export default class ManageTemplateLettersPge extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public quickActionCategoryId: number = null;
  public quickActionCategories: QuickActionCategory[] = [];
  public deleteErrors = false;
  public loadErrors: any = false;
  public showTemplateLetterForm = false;
  public editTemplateLetter: TemplateLetter = null;
  public loading = false;
  public templateLetterCategories: TemplateLetter[] = [];
  public headers = [
    { width: "35%", text: this.$t("templateletter_headers.name"), value: "name" },
    { width: "25%", text: this.$t("templateletter_headers.quickActionCategoryId"), value: "quick_action_category.name" },
    { width: "20%", text: this.$t("templateletter_headers.order"), value: "order" },
    { width: "20%", text: this.$t("templateletter_headers.actions"), value: "actions", sortable: false }];

  get displayTemplateLetters() {
    if (!this.quickActionCategoryId) {
      return this.templateLetterCategories;
    }
    return this.templateLetterCategories.filter(({ quick_action_category_id }) => (quick_action_category_id === this.quickActionCategoryId));
  }
  public openTemplateLetterFormDialog(templateLetter: TemplateLetter) {
    this.editTemplateLetter = templateLetter;
    this.showTemplateLetterForm = true;
  }

  public closeTemplateLetterForm() {
    this.editTemplateLetter = null;
    this.showTemplateLetterForm = false;
  }

  public reload() {
    this.loadTemplateLetters();
  }

  public async loadTemplateLetters() {
    this.loadErrors = null;
    this.loading = true;
    this.templateLetterCategories = [];

    try {
      this.quickActionCategories = await QuickActionCategoryApi.getAll();
      this.templateLetterCategories = await TemplateLetterApi.getAll();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }

  }
  mounted() {
    this.quickActionCategoryId = +this.$route.params.categoryId;
    this.loadTemplateLetters();

  }

}
