var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-info"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('phone'))+":\n    "),_c('a',{on:{"click":function($event){return _vm.sendWhatsapp(_vm.whatsappContact.phone)}}},[_vm._v("\n      "+_vm._s(_vm.whatsappContact.phone)+"\n    ")])]),(_vm.whatsappContact.patients.length === 1)?_c('p',{staticClass:"mb-0"},[_vm._v("\n    "+_vm._s(_vm.$t('signle-patient'))+"\n    "),_c('RouterLink',{attrs:{"to":{
      name: _vm.patientRouteName,
      params: {
        id: _vm.whatsappContact.patients[0].id
      }
    }}},[_vm._v("\n      "+_vm._s(_vm.whatsappContact.patients[0].first_name)+"\n      "+_vm._s(_vm.whatsappContact.patients[0].last_name))]),(_vm.whatsappContact.patients[0].birth_date)?_c('span',[_vm._v("\n      ("+_vm._s(_vm.$t('gender.' + (_vm.whatsappContact.patients[0].gender || 'unknown')))+"\n      "),_c('span',[_vm._v("\n        "+_vm._s(_vm._f("age")(_vm.whatsappContact.patients[0].birth_date)))]),_vm._v(")\n    ")]):_vm._e()],1):(_vm.whatsappContact.patients.length > 1)?_c('p',{staticClass:"mb-0"},[_vm._v("\n    "+_vm._s(_vm.$t('many-patients', { length: _vm.whatsappContact.patients.length }))+"\n  "),_c('ul',_vm._l((_vm.whatsappContact.patients),function(patient,index){return _c('li',{key:'patient' + index},[_c('RouterLink',{attrs:{"to":{
        name: _vm.patientRouteName,
        params: {
          id: patient.id
        }
      }}},[_vm._v(_vm._s(patient.first_name)+" "+_vm._s(patient.last_name))]),(patient.birth_date)?_c('span',[_vm._v("\n        ("+_vm._s(_vm.$t('gender.' + (patient.gender || 'unknown')))+"\n        "),_c('span',[_vm._v("\n          "+_vm._s(_vm._f("age")(patient.birth_date)))]),_vm._v(")\n      ")]):_vm._e()],1)}),0)]):_vm._e(),(_vm.whatsappContact.contacts.length)?_c('p',{staticClass:"mb-0"},[_vm._v("\n    "+_vm._s(_vm.whatsappContact.contacts.length > 1 ? _vm.$t('contacts') : _vm.$t('contact'))+"\n  "),(_vm.whatsappContact.contacts.length > 1)?_c('ul',_vm._l((_vm.whatsappContact.contacts),function(contact){return _c('li',{key:'contact' + contact.id},[_c('a',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"100%"},on:{"click":function($event){return _vm.openContactInformationModal(contact)}}},[_vm._v("\n        "+_vm._s(contact.name)+"\n      ")])])}),0):_vm._e(),(_vm.whatsappContact.contacts.length === 1)?_c('a',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"100%"},on:{"click":function($event){return _vm.openContactInformationModal(_vm.whatsappContact.contacts[0])}}},[_vm._v("\n    "+_vm._s(_vm.whatsappContact.contacts[0].name)+"\n  ")]):_vm._e()]):(!_vm.whatsappContact.patients.length && !_vm.whatsappContact.contacts.length)?_c('p',{staticClass:"mb-0"},[_vm._v("\n    "+_vm._s(_vm.$t('no-patients'))+"\n  ")]):_vm._e(),(_vm.contactInformation)?_c('ContactInformationDialog',{attrs:{"contact":_vm.contactInformation},on:{"closeContactInformationModal":function($event){_vm.contactInformation = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }