















































































































































































































































































































































































































































































































































































































































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import { TASK_ROUTE_NAME } from "@/modules/task/constants/route-config";
import { Task, TaskRecurring } from "../types/task.type";
import { DataTableHeader } from "vuetify";
import { date } from "@/filters/date.filter";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import TaskApi from "../api/task.api";
import TaskFormDialog from "../components/TaskFormDialog.vue";
import { User } from "@/modules/users/types/user.type";
import axios, { CancelTokenSource } from "axios";
import { prepareTaskResponse } from "../helpers/task.helpers";

@Component({
  components: {
    TaskFormDialog,
  },
  filters: {
    date,
  }
})
export default class TaskPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/shouldDisplayTodoTasks") shouldDisplayTodoTasks: boolean;
  @Getter("task/myFutureTasks") myFutureTasks: Task[];
  @Getter("task/createdFutureTasks") createdFutureTasks: Task[];
  @Getter("task/myTodoTasks") myTodoTasks: Task[];
  @Getter("task/createdTodoTasks") createdTodoTasks: Task[];
  @Getter("task/myDoneTasks") myDoneTasks: Task[];
  @Getter("task/createdDoneTasks") createdDoneTasks: Task[];
  @Getter("task/myTaskRecurrings") myTaskRecurrings: TaskRecurring[];
  @Getter("task/createdTaskRecurrings") createdTaskRecurrings: TaskRecurring[];
  @Getter("auth/taskUsers") taskUsers: User[];

  public otherAssignedTodoTasks: Task[] = [];
  public otherAssignedFutureTasks: Task[] = [];
  public otherAssignedDoneTasks: Task[] = [];
  public otherAssignedTaskRecurrings: TaskRecurring[] = [];
  private activeRequest: CancelTokenSource = null;
  public isDisplayTaskFormDialog = false;
  public isOtherAssigneeLoading = false;
  public otherAssignedId: number = null;
  public editingTask: Task = null;
  public markDoneTaskId: number = null;
  public assignedTodoTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.task_date").toString(), value: "task_date", sortable: false },
    { text: this.$t("headers.creator_user").toString(), value: "creator_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  public assignedFutureTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.future_date").toString(), value: "task_date", sortable: false },
    { text: this.$t("headers.creator_user").toString(), value: "creator_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  public assignedDoneTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.done_date").toString(), value: "done_date", sortable: false },
    { text: this.$t("headers.creator_user").toString(), value: "creator_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
  ];
  public createdTodoTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.task_date").toString(), value: "task_date", sortable: false },
    { text: this.$t("headers.assignee_user").toString(), value: "assignee_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  public createdFutureTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.future_date").toString(), value: "task_date", sortable: false },
    { text: this.$t("headers.assignee_user").toString(), value: "assignee_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  public createdDoneTasksHeaders: DataTableHeader[] = [
    { text: this.$t("headers.done_date").toString(), value: "done_date", sortable: false },
    { text: this.$t("headers.assignee_user").toString(), value: "assignee_user", sortable: false },
    { text: this.$t("headers.patient").toString(), value: "patient", sortable: false },
    { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
  ];

  get myFutureTasksWithRecurrings() {
    return [
      ...this.myFutureTasks,
      ...this.myTaskRecurrings,
    ]
      .sort((a, b) => {
        const aDate = "task_date" in a ? a.task_date : a.next_task_date;
        const bDate = "task_date" in b ? b.task_date : b.next_task_date;
        if (aDate > bDate) { return 1; }
        if (aDate < bDate) { return -1; }
        if (a.urgency < b.urgency) { return -1; }
        if (a.urgency > b.urgency) { return 1; }
        return 0;
      });
  }

  get otherAssignedDoneTasksSorted() {
    return this.otherAssignedDoneTasks
      .sort((a, b) => {
        const aDate = a.done_at;
        const bDate = b.done_at;
        if (aDate > bDate) { return -1; }
        if (aDate < bDate) { return 1; }
        return 0;
      });
  }

  get otherAssignedFutureTasksWithRecurrings() {
    return [
      ...this.otherAssignedFutureTasks,
      ...this.otherAssignedTaskRecurrings,
    ]
      .sort((a, b) => {
        const aDate = "task_date" in a ? a.task_date : a.next_task_date;
        const bDate = "task_date" in b ? b.task_date : b.next_task_date;
        if (aDate > bDate) { return 1; }
        if (aDate < bDate) { return -1; }
        if (a.urgency < b.urgency) { return -1; }
        if (a.urgency > b.urgency) { return 1; }
        return 0;
      });
  }

  get createdFutureTasksWithRecurrings() {
    return [
      ...this.createdFutureTasks,
      ...this.createdTaskRecurrings,
    ]
      .sort((a, b) => {
        const aDate = "task_date" in a ? a.task_date : a.next_task_date;
        const bDate = "task_date" in b ? b.task_date : b.next_task_date;
        if (aDate > bDate) { return 1; }
        if (aDate < bDate) { return -1; }
        if (a.urgency < b.urgency) { return -1; }
        if (a.urgency > b.urgency) { return 1; }
        return 0;
      });
  }

  public showMoreTaskIds: number[] = [];
  public showMoreTaskRecurringIds: number[] = [];
  public activeTab = 0;
  public reloadTimeout: number = null;

  @Watch("otherAssignedId")
  public async loadAssigneeTasks() {

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.isOtherAssigneeLoading = true;
      const tasksData = await TaskApi.getAll(this.otherAssignedId, this.activeRequest);
      this.isOtherAssigneeLoading = false;

      const {
        assignedFutureTasks,
        assignedTodoTasks,
        assignedDoneTasks,
        assignedTaskRecurrings,
      } = prepareTaskResponse(this.otherAssignedId, tasksData);

      this.otherAssignedFutureTasks = assignedFutureTasks;
      this.otherAssignedTodoTasks = assignedTodoTasks;
      this.otherAssignedDoneTasks = assignedDoneTasks;
      this.otherAssignedTaskRecurrings = assignedTaskRecurrings;

    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.isOtherAssigneeLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async reload() {
    await this.$store.dispatch("task/loadTasks", this.$store.getters["auth/userId"]);
    if (this.otherAssignedId) {
      await this.loadAssigneeTasks();
    }
  }

  public async mounted() {
    await this.autoReload(true);
  }

  public async autoReload(isFirst: boolean) {
    try {
      await this.$store.dispatch("task/loadTasks", this.$store.getters["auth/userId"]);
    } finally {
      if ((isFirst || this.reloadTimeout) && this.$route.name === TASK_ROUTE_NAME) {
        this.reloadTimeout = setTimeout(async () => {
          if (this.reloadTimeout) {
            await this.autoReload(false);
          }
        }, 1 * 60 * 1000); // 1 min
      }
    }
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public openTaskFormDialog(task: Task) {
    this.isDisplayTaskFormDialog = true;
    this.editingTask = task;
  }

  public async onMarkDoneClicked(task: Task) {
    const res = await this.$confirm(
      this.$t("mark_done_confirm_message").toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("mark_done_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("mark_done_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.markDoneTaskId = task.id;
      await TaskApi.markDone(task.id);
      await this.$store.dispatch("task/loadTasks", this.$store.getters["auth/userId"]);
      this.markDoneTaskId = null;
    } catch (err) {
      this.markDoneTaskId = null;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  beforeDestroy() {
    if (this.reloadTimeout) {
      this.reloadTimeout = null;
      clearTimeout(this.reloadTimeout);
    }
  }

  get isMultipleTaskUsers() {
    return this.$store.getters["auth/taskUsers"].length > 1;
  }

  public rowClass(item: Task | TaskRecurring) {
    if (item.urgency === 1) {
      return "orange lighten-5";
    }

    return "";
  }

  get shouldDisplayOtherTasks() {
    return this.isMultipleTaskUsers && this.$store.getters["auth/isAdmin"];
  }
}
