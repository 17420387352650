






































import { time, date } from "@/filters/date.filter";
import TimeClockApi from "../../api/time-clock.api";
import { User } from "@/modules/users/types/user.type";
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { TimeClockEntry } from "../../types/time-clock.type";
import { Getter } from "vuex-class";
import { removeTimeEntryCheckedAt, setTimeEntryCheckedAt } from "@/serivce/time-entry-checked-at.service";

@Component({
  filters: {
    time,
    date,
  }
})
export default class TimeClockLoggingDialog extends Vue {
  @Getter("auth/timeEntryTypes") public timeEntryTypes: { text: string; value: number }[];
  @Getter("auth/isTimeEntryRequired") public isTimeEntryRequired: boolean;

  public typeId: number = null;
  public isLoading = false;
  public isSubmitting = false;
  public userId: number = null;
  public lastTimeEntry: TimeClockEntry = null;

  public closeDialog() {
    this.$emit("close");
  }

  public mounted() {
    if (this.$store.getters["auth/user"].is_time_clock_enabled) {
      this.userId = this.$store.getters["auth/userId"];
    }
  }

  get userOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at, id }) => (!deleted_at || id === this.userId))
      .filter(({ is_time_clock_enabled }) => (is_time_clock_enabled))
      .map(user => ({
        "text": user.name,
        "value": user.id
      }));
  }

  @Watch("userId")
  public async onUserIdChanged() {
    const yearMonth = moment().format("YYYY-MM");
    this.isLoading = true;
    this.lastTimeEntry = null;

    try {
      const { entries: allMonthEntries } = await TimeClockApi.getAll({
        user_id: this.userId,
        year_month: yearMonth
      });

      const monthEntries = allMonthEntries.filter(entry => {
        return moment(entry.start).format("HH:mm") !== "00:00" && moment(entry.end).format("HH:mm") !== "00:00";
      });

      const lastTimeEntry = monthEntries && monthEntries.sort((a, b) => {
        if (a.start < b.start) return 1;
        if (a.start > b.start) return -1;
        return 0;
      })[0];

      if (!lastTimeEntry) {
        // Enter
        const { entries: prevMonthEntries } = await TimeClockApi.getAll({
          user_id: this.userId,
          year_month: moment().subtract(1, "month").format("YYYY-MM")
        });

        const prevMonthTimeEntry = prevMonthEntries && prevMonthEntries.sort((a, b) => {
          if (a.start < b.start) return 1;
          if (a.start > b.start) return -1;
          return 0;
        })[0];

        if (prevMonthTimeEntry) {
          this.typeId = prevMonthTimeEntry.type_id;
        } else if (this.timeEntryTypes.length === 1) {
          this.typeId = this.timeEntryTypes[0].value;
        }
      } else if (lastTimeEntry.start !== lastTimeEntry.end) {
        // Enter 
        this.typeId = lastTimeEntry.type_id;
      } else {
        this.lastTimeEntry = lastTimeEntry;
      }

      this.isLoading = false;
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

  get dayDiff(): boolean {
    if (!this.lastTimeEntry) {
      return false;
    }

    return moment(this.lastTimeEntry.start).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD");
  }

  public async logTimeClicked() {
    this.isSubmitting = true;


    try {
      if (this.lastTimeEntry) {
        let end = moment().endOf("minute").format("YYYY-MM-DD HH:mm:00");
        if (end === this.lastTimeEntry.start) {
          end = moment().endOf("minute").add(1, "minute").format("YYYY-MM-DD HH:mm:00");
        }
        await TimeClockApi.update(this.lastTimeEntry.id, {
          type_id: this.lastTimeEntry.type_id,
          user_id: this.lastTimeEntry.user_id,
          start: this.lastTimeEntry.start,
          end,
        });
        removeTimeEntryCheckedAt();
      } else {
        const now = moment().format("YYYY-MM-DD HH:mm:00");
        await TimeClockApi.create({
          type_id: this.typeId,
          user_id: this.userId,
          start: now,
          end: now,
        });
        setTimeEntryCheckedAt();
      }
      this.isSubmitting = false;
      this.$emit("reload");
      this.closeDialog();
      this.$toastr.s(this.$t("success_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isSubmitting = false;
    }
  }
}
