<template>
  <VDialog
    @input="closeDialog()"
    :value="true"
    max-width="360"
  >
    <v-card outlined raised elevation="4">
      <v-card-title class="justify-space-between">
        {{ $t('edit_title') }}
        <v-btn icon @click="closeDialog()"><VIcon>mdi-close</VIcon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form accept-charset="UTF-8" @submit.stop.prevent="submitForm()">
          <smile-error-message :errors="errors"></smile-error-message>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  name="name"
                  v-model="name"
                  :label="$t('name')"
                  color="cyan darken-1"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-color-picker
                    v-model="color"
                    :label="$t('color')"
                    color="cyan darken-1"
                    hide-inputs
                ></v-color-picker>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-checkbox
                    name="is_enabled"
                    v-model="is_enabled"
                    :label="$t('is_enabled')"
                    color="cyan darken-1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block color="cyan" :loading="loading" type="submit">
                  {{ $t('submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </VDialog>
</template>

<script>
import AppointmentStatusApi from "../../../../serivce/api/appointment-status-api";

export default {
  props: [ "editAppointmentStatus" ],
  data () {
    return {
      errors: false,
      name: this.editAppointmentStatus.name,
      color: this.editAppointmentStatus.color,
      is_enabled: this.editAppointmentStatus.is_enabled,
      loading: false
    };
  },
  methods: {
    closeDialog () {
      this.$emit("close");
    },
    submitForm () {
      if (this.loading) return;

      this.loading = true;
      this.errors = false;

      const payload = {
        name: this.name,
        color: this.color,
        is_enabled: this.is_enabled
      };

      const attempt = AppointmentStatusApi.update(this.editAppointmentStatus.id, payload);
      attempt
        .then(() => {
          this.loading = false;

          this.$toastr.s(this.$t("status_updated"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
    }
  }
};
</script>

<i18n>
{
  "he": {
    "name": "שם הסטטוס",
    "color": "צבע",
    "is_enabled": "פעיל?",
    "edit_title": "עריכת סטטוס פגישה",
    "submit": "שמירה",
    "status_updated": "סטטוס עודכן בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "name": "Title",
    "edit_title": "Edit Appointment Status",
    "submit": "Submit",
    "status_updated": "Appointment status updated successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
