import {Module} from "vuex";
import whatsappState from "@/modules/whatsapp/store/whatsapp.state";
import whatsappGetters from "@/modules/whatsapp/store/whatsapp.getters";
import whatsappActions from "@/modules/whatsapp/store/whatsapp.actions";
import whatsappMutations from "@/modules/whatsapp/store/whatsapp.mutations";

const whatsappStore: Module<any, any> = {
    namespaced: true,
    state: whatsappState,
    getters: whatsappGetters,
    actions: whatsappActions,
    mutations: whatsappMutations,
};

export default whatsappStore;
