import { TaskStoreState } from "../types/task.type";

const taskGetters = {

  myFutureTasks: (state: TaskStoreState) => {
    return state.myFutureTasks;
  },

  createdFutureTasks: (state: TaskStoreState) => {
    return state.createdFutureTasks;
  },

  myTodoTasks: (state: TaskStoreState) => {
    return state.myTodoTasks;
  },

  createdTodoTasks: (state: TaskStoreState) => {
    return state.createdTodoTasks;
  },

  myDoneTasks: (state: TaskStoreState) => {
    return state.myDoneTasks;
  },

  createdDoneTasks: (state: TaskStoreState) => {
    return state.createdDoneTasks;
  },

  myTaskRecurrings: (state: TaskStoreState) => {
    return state.myTaskRecurrings;
  },

  createdTaskRecurrings: (state: TaskStoreState) => {
    return state.createdTaskRecurrings;
  },

  todoTasksCount: (state: TaskStoreState) => {
    return state.myTodoTasks.length;
  },
};

export default taskGetters;
