































































































import UserApi from "../api/user.api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MandatoryUserData, User } from "@/modules/users/types/user.type";
import get from "lodash/get";
import { AxiosError } from "axios";
import { OPTIONAL_LANGUAGES } from "@/modules/auth/constants/language-options";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { Getter } from "vuex-class";

@Component({
  components: { ErrorMessage }
})
export default class UserForm extends Vue {
  @Prop() readonly user!: User;
  @Getter("auth/isTimeClockFeatureEnabled") isTimeClockFeatureEnabled: boolean;
  @Getter("auth/isKupaReportFeatureEnabled") isKupaReportFeatureEnabled: boolean;
  @Getter("auth/usersWithCalendar") usersWithCalendar: User[];

  public errors = null;
  public color = this.$store.getters["auth/userColor"];
  public locale = "he";
  public name = "";
  public email = "";
  public phone = "";
  public type = "";
  public calendarUserId: number = null;
  public default_letter_header = "";
  public default_letter_signature = "";
  public isWaLinkDirect = false;
  public isTimeClockEnabled = false;
  public isTimeClockEditEnabled = false;
  public isTasksEnabled = false;
  public isFinanceReportsEnabled = false;
  public isManageShiftsEnabled = false;
  public isKupaReportEnabled = false;
  public isTimeEntryRequired = false;
  public isAdmin = false;
  public isDocumentTreatmentsEnabled = true;
  public loading = false;
  public deleteLoading = false;

  public mounted() {
    this.color = get(this.user, "color", this.$store.getters["auth/userColor"]);
    this.locale = get(this.user, "locale", this.$store.getters["auth/locale"]);
    this.name = get(this.user, "name", "");
    this.email = get(this.user, "email", "");
    this.phone = get(this.user, "phone", "");
    this.type = get(this.user, "type", "");
    this.calendarUserId = get(this.user, "use_calendar_user_id", null);
    this.default_letter_header = get(this.user, "default_letter_header", "");
    this.default_letter_signature = get(this.user, "default_letter_signature", "");
    this.isWaLinkDirect = get(this.user, "is_wa_link_direct", false);
    this.isTimeClockEnabled = get(this.user, "is_time_clock_enabled", false);
    this.isTimeEntryRequired = get(this.user, "is_time_entry_required", false);
    this.isDocumentTreatmentsEnabled = get(this.user, "is_document_treatments_enabled", true);
    this.isTimeClockEditEnabled = get(this.user, "is_time_clock_edit_enabled", false);
    this.isTasksEnabled = get(this.user, "is_tasks_enabled", false);
    this.isFinanceReportsEnabled = get(this.user, "is_finance_reports_enabled", false);
    this.isManageShiftsEnabled = get(this.user, "is_manage_shifts_enabled", false);
    this.isKupaReportEnabled = get(this.user, "is_kupa_report_enabled", false);
    this.isAdmin = get(this.user, "is_admin", false);
  }

  @Watch("isAdmin")
  public onIsAdminChanged() {
    if (this.isAdmin) {
      this.isFinanceReportsEnabled = true;
      this.isManageShiftsEnabled = true;
      this.isKupaReportEnabled = true;
      this.isTimeClockEditEnabled = true;
    }
  }

  get hasAssignsTo() {
    const therapistUsers = this.$store.getters["auth/therapistUsers"];
    return this.user ? !!therapistUsers.find((user: User) => user.use_calendar_user_id === this.user.id) : false;
  }

  get typeOptions() {
    return [
      {
        value: "caregiver",
        text: this.$t("types.caregiver")
      },
      {
        value: "assistant",
        text: this.$t("types.assistant")
      },
      {
        value: "accountant",
        text: this.$t("types.accountant")
      }
    ];
  }

  get localeOptions() {
    return OPTIONAL_LANGUAGES.map(lang => ({
      value: lang,
      text: this.$t("locale_options." + lang)
    }));
  }

  get editMode(): boolean {
    return Boolean(this.user);
  }

  get userPayload(): MandatoryUserData {
    return {
      email: this.email,
      name: this.name,
      phone: this.phone,
      type: this.type,
      is_admin: this.isAdmin,
      is_wa_link_direct: this.isWaLinkDirect,
      is_time_clock_enabled: this.isTimeClockEnabled,
      is_time_clock_edit_enabled: this.isTimeClockEditEnabled,
      is_time_entry_required: this.isTimeEntryRequired,
      is_tasks_enabled: this.isTasksEnabled,
      is_finance_reports_enabled: this.isFinanceReportsEnabled,
      is_manage_shifts_enabled: this.isManageShiftsEnabled,
      is_kupa_report_enabled: this.isKupaReportEnabled,
      is_document_treatments_enabled: this.isDocumentTreatmentsEnabled,
      use_calendar_user_id: this.calendarUserId,
      locale: this.locale,
      color: this.color,
      default_letter_header: this.default_letter_header,
      default_letter_signature: this.default_letter_signature,
    };
  }

  public closeDialog() {
    this.$emit("close");
  }

  public submitForm() {
    if (this.loading) {
      return;
    }

    this.errors = false;
    this.loading = true;

    // Performing the action based on the form mode.
    const userAction = this.editMode ?
      UserApi.update(this.user.id, this.userPayload) :
      UserApi.create(this.userPayload);

    userAction
      .then(user => {
        if (user.id === this.$store.getters["auth/userId"]) {
          this.$store.commit("auth/setUser", user);
        }

        this.$toastr.s(this.$t(this.editMode ? "user_updated" : (user.email ? "user_created_with_email" : "user_created")));
        this.closeDialog();

        this.$emit(this.editMode ? "userUpdated" : "userCreated", user);
      })
      .catch((error: AxiosError) => {
        const errors = get(error, "response.data.errors");
        this.errors = errors || this.$t("temporary_error");
      })
      .finally(() => this.loading = false);
  }

  public async deleteButtonClicked() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message", { "name": this.user.name }).toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    this.deleteLoading = true;
    try {
      await UserApi.delete(this.user.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.deleteLoading = false;
      this.$emit("userDeleted", this.user.id);
      this.closeDialog();

    } catch (error) {
      this.$toastr.s(this.$t("delete_error"));
      this.deleteLoading = false;
    }
  }
}
