





























































import { Component, Prop } from "vue-property-decorator";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import { age, date } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { Note } from "../types/note.type";
import PatientNoteApi from "../api/patient-note.api";
import { Patient } from "../types/patient.type";

@Component({
  components: {
    SentenceMenu,
  },
  filters: {
    age,
    date,
  },
})
export default class PatientNoteFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: false }) public editingNote: Note;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public isSubmitLoading = false;
  public isDeleteLoading = false;
  public treatmentId: number = null;
  public showDiscount = false;
  public price = 0;
  public totalPrice = 0;
  public discountPercent = 0;
  public isPinned = false;;
  public noteText = "";
  public isPopup = false;
  public isDirty = false;

  mounted() {
    if (this.editingNote) {
      this.noteText = this.editingNote.text;
      this.isPinned = this.editingNote.pinned;
      this.isPopup = this.editingNote.is_popup;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public markDirty() {
    this.isDirty = true;
  }

  public async deleteTreatment() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.isDeleteLoading = true;
    try {
      await PatientNoteApi.delete(this.editingNote.id);
      this.$emit("noteDeleted", this.editingNote.id);
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("toasrt_deleting_error"));
    } finally {
      this.isDeleteLoading = false;
    }
  }

  public async submitForm() {
    this.isSubmitLoading = true;

    try {
      const note = this.editingNote
        ? await PatientNoteApi.update(this.editingNote.id, {
          text: this.noteText,
          pinned: this.isPinned,
          is_popup: this.isPopup
        })
        : await PatientNoteApi.create({
          patient_id: this.patient.id,
          pinned: this.isPinned,
          text: this.noteText,
          is_popup: this.isPopup
        });
      this.isSubmitLoading = false;

      if (this.editingNote) {
        this.$emit("noteUpdated", note);
      } else {
        this.$emit("noteCreated", note);
      }
      this.closeDialog();
    } catch (err) {
      this.isSubmitLoading = false;
      this.$toastr.e(this.$t("toastr_saving_error"));
    }
  }

  get pinnedOptions() {
    return [
      {
        value: false,
        text: this.$t("is_pinned_false_option"),
      },
      {
        value: true,
        text: this.$t("is_pinned_true_option"),
      }
    ]
      ;
  }


  get caregiverName() {
    return this.$store.getters["auth/fullName"];
  }

  get updatedAtDate() {
    if (this.editingNote) {
      return this.editingNote.updated_at;
    }

    return new Date();
  }

}
