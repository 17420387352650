<template>
  <VDialog @input="closeDialog()" :value="true" max-width="360">
    <VCard outlined raised elevation="4" class="sentence-form">
      <VCardTitle class="justify-space-between">
        {{ $t(editSentence ? 'edit_title' : 'add_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <smile-error-message :errors="errors"></smile-error-message>
        <VContainer>
          <VRow>
            <VCol cols="12">
              <VSelect color="cyan" item-color="cyan darken-1" v-model="sentenceCategoryId" :items="sentenceCategories"
                :label="$t('sentenceCategoryId')" required hide-details />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField name="order" hide-details v-model="order" :label="$t('order')" color="cyan darken-1"
                type="text" />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextarea name="contents" v-model="contents" auto-grow rows="2" :label="$t('contents')"
                color="cyan darken-1" outlined hide-details />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VBtn color="red" small text type="button" v-if="editSentence" @click="deleteButtonClicked()"
          :loading="deleteLoading">
          {{ $t('delete_btn') }}
        </VBtn>
        <VSpacer />
        <VBtn color="cyan" :loading="loading" @click="submitForm()">
          {{ $t('submit') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import SentenceApi from "@/serivce/api/sentence-api";
import SentenceCategoryApi from "@/serivce/api/sentence-category-api";

export default {
  props: ["editSentence", "selectedSentenceCategoryId"],
  data() {
    return {
      errors: false,
      sentenceCategories: [],
      contents: this.editSentence ? this.editSentence.contents : "",
      sentenceCategoryId: this.editSentence ? this.editSentence.sentence_category_id : this.selectedSentenceCategoryId,
      order: this.editSentence ? this.editSentence.order : "",
      loading: false,
      deleteLoading: false,
    };
  },
  mounted() {
    SentenceCategoryApi.get()
      .then(results => {
        this.sentenceCategories = results.data.sentence_categories
          .map(category => ({
            value: category.id,
            text: category.name
          }));
      });
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitForm() {
      if (this.loading)
        return;
      this.loading = true;
      this.errors = false;
      const attempt = this.editSentence
        ? SentenceApi.update(this.editSentence.id, this.contents, this.sentenceCategoryId, this.order)
        : SentenceApi.create(this.contents, this.sentenceCategoryId, this.order);
      attempt
        .then(() => {
          this.loading = false;
          this.$toastr.s(this.editSentence ? this.$t("sentence_updated") : this.$t("sentence_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }
          else {
            this.errors = this.$t("temporary_error");
          }
        });
    },
    async deleteButtonClicked() {
      const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });
      if (!res) {
        return;
      }

      this.deleteLoading = true;
      try {
        await SentenceApi.delete(this.editSentence.id);
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("reload");
        this.closeDialog();
      } catch (err) {
        this.deleteLoading = false;
        this.$toastr.e(this.$t("delete_error"));
        this.$emit("reload");
      }
    }
  },
};
</script>

<style lang="scss">
.sentence-form {
  .v-text-field__details {
    display: none;
  }
}
</style>
<i18n>
{
  "he": {
    "delete_btn": "מחיקה",
    "delete_confirm_message": "למחוק משפט?",
    "delete_cancel_btn": "ביטול",
    "delete_ok_btn": "מחיקה",
    "deleted_toastr": "משפט נמחק בהצלחה",
    "delete_error": "שגיאה במחיקה. יש לנסות שוב",
    "contents": "המשפט",
    "sentenceCategoryId": "קטגוריה",
    "order": "סדר",
    "add_title": "הוספת משפט",
    "edit_title": "עריכת משפט",
    "submit": "שמירה",
    "sentence_created": "משפט נוצר בהצלחה",
    "sentence_updated": "משפט עודכן בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "delete_btn": "Delete",
    "delete_confirm_message": "Delete sentence?",
    "delete_cancel_btn": "Cancel",
    "delete_ok_btn": "Delete",
    "deleted_toastr": "Job deleted successfully",
    "delete_error": "Error deleting data, try again",
    "contents": "Contents",
    "sentenceCategoryId": "Category",
    "order": "Order",
    "add_title": "Add Sentence",
    "edit_title": "Edit Sentence",
    "submit": "Submit",
    "sentence_created": "Sentence created successfully",
    "sentence_updated": "Sentence updated successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
