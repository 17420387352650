
































import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContactBookmark } from "../types/contact-bookmark.type";
import { Getter } from "vuex-class";

@Component({})

export default class EmailContactSearch extends mixins(SmileMixin) {
  @Prop({ required: true }) public inputLabel: string;
  @Prop({ required: false }) public isMarkedSendEmail: boolean;
  @Prop({ required: false }) displayType: string;
  @Prop({ required: false }) isFullWidth: string;
  @Prop({ required: false }) value: string;
  @Prop({ required: false, default: false }) isClearable: boolean;
  @Getter("auth/isRtl") public isRtl: boolean;

  public email = "";
  public isAutoComplete = false;
  public inputTextDirection = "";
  public isInvalidEmail = false;
  @Watch("value")
  public updateEmailFromProp() {
    this.email = this.value;
  }

  public onEmailInputBlur() {
    this.isAutoComplete = false;

    if (this.email && !this.isValidEmail(this.email)) {
      this.isInvalidEmail = true;
      this.$emit("emailMarked", false);
    } else {
      this.isInvalidEmail = false;
      this.$emit("emailMarked", true);
    }

    this.$emit("input", this.email);
  }

  public advancedContactSearch(item: any, queryText: string, itemText: string) {
    const queries = queryText.split(" ").map((subText) => {
      return itemText.includes(subText);
    });
    return !queries.includes(false);

  }

  public markEmail() {
    this.$emit("emailMarked", !this.isMarkedSendEmail);
  }

  public clearEmail() {
    this.$emit("input", null);
  }

  get contacts() {
    if (!this.$store.getters["auth/contacts"]) {
      return [];
    }
    const contacts = this.$store.getters["auth/contacts"] as ContactBookmark[];
    const flattenContacts = [];

    contacts.filter(contactBookmark => (!this.displayType || (contactBookmark.display_type && contactBookmark.display_type.includes(this.displayType))))
      .map(contactBookmark => {
        contactBookmark.contacts.map((contact) => {
          if (contact.email) {
            if (contacts.length > 1) {
              flattenContacts.push({ category: contactBookmark.name, value: contact.email, text: contact.name });
            } else {
              flattenContacts.push({ value: contact.email, text: contact.name });
            }
          }
        }
        );

      });

    return flattenContacts;
  }
  mounted() {
    this.inputTextDirection = this.isRtl ? "rtl" : "ltr";
    this.updateEmailFromProp();
  }
}

