<template>
  <VDialog @input="closeDialog()" :value="true" max-width="360">
    <VCard outlined raised elevation="4">
      <VCardTitle class="justify-space-between">
        {{ $t(editQuickActionCategory ? 'edit_title' : 'add_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <smile-error-message :errors="errors"></smile-error-message>
        <VContainer>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="name" :label="$t('name')" color="cyan darken-1" type="text" />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="order" :label="$t('order')" color="cyan darken-1" type="text" />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VBtn color="red" small text type="button" v-if="editQuickActionCategory" @click="deleteButtonClicked()"
          :loading="deleteLoading">
          {{ $t('delete_btn') }}
        </VBtn>
        <VSpacer />
        <VBtn color="cyan" :loading="loading" type="submit" @click="submitForm()">
          {{ $t('submit') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import QuickActionCategoryApi from "../../api/quick-action-category-api";

export default {
  props: ["editQuickActionCategory"],
  data() {
    return {
      errors: false,
      name: this.editQuickActionCategory ? this.editQuickActionCategory.name : "",
      order: this.editQuickActionCategory ? this.editQuickActionCategory.order : "",
      loading: false,
      deleteLoading: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitForm() {
      if (this.loading) return;

      this.loading = true;
      this.errors = false;


      const attempt = this.editQuickActionCategory
        ? QuickActionCategoryApi.update(this.editQuickActionCategory.id, this.name, this.order)
        : QuickActionCategoryApi.create(this.name, this.order);

      attempt
        .then(() => {
          this.loading = false;

          this.$toastr.s(this.editQuickActionCategory ? this.$t("type_updated") : this.$t("type_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
    },
    async deleteButtonClicked() {

      const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });
      if (!res) {
        return;
      }

      this.deleteLoading = true;
      try {
        await QuickActionCategoryApi.delete(this.editQuickActionCategory.id);
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("reload");
        this.closeDialog();
      } catch (err) {
        this.deleteLoading = false;
        this.$toastr.e(this.$t("delete_error"));
        this.$emit("reload");
      }
    }
  }
};
</script>

<i18n>
{
  "he": {
    "name": "קטגוריות",
    "order": "סדר",
    "delete_btn": "מחיקה",
    "delete_confirm_message": "האם למחוק את הקטגוריה? פעולה זו תמחק את כל המכתבים בקטגוריה זו",
    "delete_cancel_btn": "ביטול",
    "delete_ok_btn": "מחיקה",
    "deleted_toastr": "קטגוריה נמחקה בהצלחה",
    "delete_error": "שגיאה במחיקה. יש לנסות שוב",
    "edit_title": "עריכת קטגוריה",
    "add_title": "הוספת קטגוריה",
    "submit": "שמירה",
    "type_created": "קטגוריה נוצרה בהצלחה",
    "type_updated": "קטגוריה עודכנה בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "name": "Sentence Categories",
    "order": "Order",
    "delete_btn": "Delete",
    "delete_confirm_message": "Delete Category? This will delete all sentences in this category",
    "delete_cancel_btn": "Cancel",
    "delete_ok_btn": "Delete",
    "deleted_toastr": "Job deleted successfully",
    "delete_error": "Error deleting data, try again",
    "edit_title": "Edit Sentence Category",
    "add_title": "Add Category",
    "submit": "Submit",
    "type_created": "Sentence category created successfully",
    "type_updated": "Sentence category updated successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
