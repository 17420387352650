import axios from "axios";

export default class Request {
    static store;
    static router;

    static bind(store, router) {
        Request.store = store;
        Request.router = router;
    }

    async post(endpoint, params = {}) {
        return axios.post(endpoint, params, this.getRequestConfig())
            .catch(this.authErrorHandling);
    }

    async delete(endpoint) {
        return axios.delete(endpoint, this.getRequestConfig())
            .catch(this.authErrorHandling);
    }

    async put(endpoint, params = {}) {
        return axios.put(endpoint, params, this.getRequestConfig())
            .catch(this.authErrorHandling);
    }

    async get(endpoint, params = {}) {
        return axios.get(endpoint, { ...this.getRequestConfig(), params })
            .catch(this.authErrorHandling);
    }

    authErrorHandling(err) {
        if (err.response && err.response.status === 401) {
            Request.store.dispatch("auth/logout")
                .then(() => {
                    Request.router.go("/");
                });
        }

        throw err;
    }

    getRequestConfig() {
        return {
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : `Bearer ${Request.store.getters["auth/token"]}`
            }
        };
    }
}
