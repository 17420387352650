








































































import { Component, Prop } from "vue-property-decorator";
import { Patient } from "../../types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import PatientApi from "@/modules/patient/api/patient.api";
import FamilyApi from "@/modules/patient/api/family.api";
import { PATIENT_ROUTE_NAME } from "../../constants/route-config";


@Component({
  components: {
    SearchPatient,
  },
})
export default class PatientFamilyDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: true }) public patientBalance: number;

  public showSearchPatientInput = false;
  public isLoadingPatient = false;

  public closeDialog() {
    this.$emit("close");
  }

  public async onSelectedPatient(selectedPatient?: Patient) {
    this.isLoadingPatient = true;
    this.showSearchPatientInput = false;
    const patient = await PatientApi.get(selectedPatient.id);

    try {
      const linkResponse = await FamilyApi.markAsFamily(this.patient.id, patient.id, false);
      if (linkResponse === true) {
        this.$toastr.s(this.$t("toastr_patient_linked"));
      } else {
        const currentFamilyNames = this.patient.first_name + " " + this.patient.last_name + ", " +
          this.patient.family_balance.map(({ patient }) => (patient.first_name + " " + patient.last_name)).join(", ");

        const unexpectedFamilyBalancePatientIds: number[] = [];
        linkResponse.forEach(patientI => {
          if (patientI.id === patient.id || patientI.id === this.patient.id) {
            return;
          }
          if (this.patient.family_balance.map(({ patient }) => (patient.id)).includes(patientI.id)) {
            return;
          }

          unexpectedFamilyBalancePatientIds.push(patientI.id);
        });
        const addingPatientFamilyNames = linkResponse.filter(({ id }) => (unexpectedFamilyBalancePatientIds.includes(id)))
          .map((patient) => (patient.first_name + " " + patient.last_name)).join(", ");

        const addAddingFamilyFirstNames = linkResponse.filter(({ id }) => (unexpectedFamilyBalancePatientIds.includes(id) || id === patient.id))
          .map((patient) => (patient.first_name)).join(", ");
        const res = await this.$confirm(this.$t("force_merge_confirm_message", {
          addingPatientName: patient.first_name + " " + patient.last_name,
          addingPatientFamilyNames,
          addAddingFamilyFirstNames,
          currentFamilyNames,
        }).toString(), {
          buttonTrueColor: "cyan darken-2",
          buttonTrueText: this.$t("force_merge_ok_btn").toString(),
          buttonFalseText: this.$t("force_merge_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }

        await FamilyApi.markAsFamily(this.patient.id, patient.id, true);
      }
    } catch (err) {
      const errorMessage = err.response && err.response.data && err.response.data.errors && err.response.data.errors[""][0];
      if (errorMessage) {
        this.$toastr.e(errorMessage);
      }
    } finally {
      this.isLoadingPatient = false;
      this.$emit("reloadPatient");
    }
  }

  get headers() {
    return [
      { text: this.$t("headers.name"), value: "name", sortable: false },
      { text: this.$t("headers.balance"), value: "balance", sortable: false },
      { text: "", value: "actions", sortable: false },
    ];
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  get familyBalancePatients(): { patient: Patient; balance: number }[] {
    return [
      { patient: this.patient, balance: this.patientBalance },
      ...this.patient.family_balance
    ];
  }

  public async removeFromFamily(patientId: number) {

    const res = await this.$confirm(this.$t("remove_family_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("remove_family_ok_btn").toString(),
      buttonFalseText: this.$t("remove_family_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    await FamilyApi.removeFamily(patientId);

    this.$toastr.s(this.$t("toastr_patient_link_removed"));
    this.$emit("reloadPatient");
  }

  public abs(number: number) {
    return Math.abs(number);
  }
}
