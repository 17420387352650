import { RouteConfig } from "vue-router";
import TaskPage from "../pages/TaskPage.vue";
import {
  TASK_ROUTE_NAME,
  TASK_ROUTE_PATH,
} from "@/modules/task/constants/route-config";

const taskRoutes: RouteConfig[] = [
  {
    name: TASK_ROUTE_NAME,
    path: TASK_ROUTE_PATH,
    component: TaskPage,
  },
];

export default taskRoutes;
