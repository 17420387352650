



































































































import TreatmentApi from "@/modules/treatment/api/treatment.api";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Component, Prop } from "vue-property-decorator";
import { PriceList, Treatment, TreatmentAttributes } from "@/modules/treatment/types/treatment.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";

@Component({
  components: {
    ErrorMessage
  }
})
export default class TreatmentForm extends mixins(SmileMixin) {
  @Prop({ required: false }) public editTreatment: Treatment;
  @Getter("auth/priceLists") priceLists: PriceList[];
  @Getter("auth/therapistUsers") therapistUsers: User[];
  @Getter("auth/treatmentAttributesType") treatmentAttributesType: string;

  public errors: any = false;
  public name = "";
  public code = "";
  public defaultNotes = "";
  public price = 0;
  public color = "#FFFFFF";
  public order = 0;
  public loading = false;
  public deleteLoading = false;
  public isTreatmentHasPriceList = false;
  public isTreatmentHasUserPrice = false;
  public isDuplicatePerTooth = false;
  public priceListAttributes: {
    [priceListId: number]: number;
  } = {};
  public userPriceAttributes: {
    [userId: number]: number;
  } = {};

  public async deletePriceList() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await TreatmentApi.delete(this.editTreatment.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

  mounted() {
    if (this.editTreatment) {
      this.name = this.editTreatment.name;
      this.code = this.editTreatment.code;
      this.defaultNotes = this.editTreatment.default_notes;
      if (this.editTreatment.color) {
        this.color = this.editTreatment.color;
      }
      this.price = this.editTreatment.price;
      this.order = this.editTreatment.order;
      this.isDuplicatePerTooth = this.editTreatment.attributes && this.editTreatment.attributes.isDuplicatePerTooth;
      this.isTreatmentHasPriceList = !!(this.editTreatment.attributes && this.editTreatment.attributes.priceList);
      this.isTreatmentHasUserPrice = !!(this.editTreatment.attributes && this.editTreatment.attributes.userPrice);
      this.priceLists.forEach((priceList) => {
        this.priceListAttributes[priceList.id] = this.isTreatmentHasPriceList ? this.editTreatment.attributes.priceList[priceList.id] : this.editTreatment.price;
      });

      this.therapistUsers.forEach((user) => {
        this.userPriceAttributes[user.id] = this.editTreatment.attributes && this.editTreatment.attributes.userPrice ? this.editTreatment.attributes.userPrice[user.id] : null;
      });
    } else {
      this.priceLists.forEach((priceList) => {
        this.priceListAttributes[priceList.id] = 0;
      });
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public submitForm() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.errors = false;

    const attributes: TreatmentAttributes = {};

    if (this.isTreatmentHasPriceList) {
      attributes.priceList = this.priceListAttributes;
    }

    let isTreatmentHasPriceList = false;
    const userPriceAttributes = {};
    for (const [userId, price] of Object.entries(this.userPriceAttributes)) {
      if (price && parseFloat(price.toString()) !== parseFloat(this.price.toString())) {
        isTreatmentHasPriceList = true;
        userPriceAttributes[userId] = price;
      }
    }

    if (isTreatmentHasPriceList) {
      attributes.userPrice = userPriceAttributes;
    }

    if (this.isDuplicatePerTooth) {
      attributes.isDuplicatePerTooth = true;
    }

    const attempt = this.editTreatment
      ? TreatmentApi.update(this.editTreatment.id, this.name, this.code, this.price, this.order, this.defaultNotes, Object.keys(attributes).length ? attributes : null, this.color)
      : TreatmentApi.create(this.name, this.code, this.price, this.order, this.defaultNotes, Object.keys(attributes).length ? attributes : null, this.color);

    attempt
      .then(() => {
        this.loading = false;

        this.$toastr.s(this.editTreatment ? this.$t("treatment_updated") : this.$t("treatment_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  get colorOptions() {
    return [
      ["#FFFFFF", "#FD7B7B"],
      ["#FFFF9F", "#FBAEFF"],
      ["#B8FFB8", "#FFE787"],
      ["#81FFFF", "#C2C2FF"],
    ];
  }
}
