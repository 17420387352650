import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  DiagnocatApiRoutes,
  DiagnocatApiRouteParameters,
} from "../constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";

class DiagnocatApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getPatientUrl(patientId: number) {
    try {
      const response: ApiResponse<string> = await this.httpClient.get(
        DiagnocatApiRoutes.getPatientUrl.replace(
          DiagnocatApiRouteParameters.id,
          patientId.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new DiagnocatApi();
