
























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import { Patient } from "@/modules/patient/types/patient.type";

@Component({
})
export default class SharePatientShareWhatsappDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public url: string;
  @Prop({ required: false }) public templateName: string;
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  public phone = "";
  public isLoading = false;
  public isInvalidWhatsapp = false;

  public mounted() {
    if (this.patient.phone) {
      this.phone = this.patient.phone;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onShareClicked() {
    if (!this.isValidPhone(this.phone)) {
      this.isInvalidWhatsapp = true;
      return;
    } else {
      this.isInvalidWhatsapp = false;
    }

    try {
      this.isLoading = true;
      if (this.url) {
        await WhatsappApi.sendImage(this.phone, this.url);
      } else {
        await WhatsappApi.sendTemplate(this.phone, this.templateName, []);
      }
      this.$toastr.s(this.$t("sent_success_toastr"));
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(err && err.smileMessage ? err.smileMessage : this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

}
