import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import { ApiResponse } from "@/core/http-client/http-client.types";
import moment from "moment";
import { ReportApiRoutes } from "../constants/api-routes";
import {
  ReportDocumentNumberResults,
  ReportAllReceiptsResults,
  ReportAllIncomesResults,
  ReportPatientsDebtsResults,
  ClinicKupaRecord,
  ReportSentEmailsResult,
} from "../types/report.type";
import { Patient } from "@/modules/patient/types/patient.type";

class ReportApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getRepositsExportUrl(
    pinkasId: number,
    depositDate: string,
    incomeType: string
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        ReportApiRoutes.exportKupaDepositsReport,
        {
          params: {
            pinkas_id: pinkasId,
            deposit_date: depositDate,
            income_type: incomeType,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getExportUrlOpenFormat(
    pinkasId: number,
    fromDate: string,
    toDate: string
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        ReportApiRoutes.exportOpenFormatReport,
        {
          params: {
            pinkas_id: pinkasId,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getAllInvoicesUrl(
    pinkasId: number,
    fromDate: string,
    toDate: string,
    reportType: "pdf" | "excel"
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        ReportApiRoutes.exportAllInvoicesReport,
        {
          params: {
            pinkas_id: pinkasId,
            reportType,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getExportUrlHashavshevet(
    pinkasId: number,
    fromDate: string,
    toDate: string
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        ReportApiRoutes.exportHashavshevetReport,
        {
          params: {
            pinkas_id: pinkasId,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getDocumentNumberReport(
    pinkasId: number,
    documentType: string,
    fromDate: string,
    toDate: string
  ): Promise<ReportDocumentNumberResults> {
    try {
      const {
        data,
      }: ApiResponse<ReportDocumentNumberResults> = await this.httpClient.get(
        ReportApiRoutes.documentNumberReport,
        {
          params: {
            pinkas_id: pinkasId,
            document_type: documentType,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
  async getSentEmailsReport(
    userIds: number[],
    patientId: number,
    to: string,
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<ReportSentEmailsResult> {
    try {
      const {
        data,
      }: ApiResponse<ReportSentEmailsResult> = await this.httpClient.get(
        ReportApiRoutes.sentEmailsReport,
        {
          params: {
            user_ids: userIds,
            patient_id: patientId,
            to: to,
            from_date: fromDate
              ? moment(fromDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm")
              : "",
            to_date: toDate
              ? moment(toDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm")
              : "",
          },
          cancelToken: activeRequest.token,
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getAllReceiptsReport(
    pinkasId: number,
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<ReportAllReceiptsResults> {
    try {
      const {
        data,
      }: ApiResponse<ReportAllReceiptsResults> = await this.httpClient.get(
        ReportApiRoutes.allReceiptsReport,
        {
          params: {
            pinkas_id: pinkasId,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getAllIncomesReport(
    pinkasId: number,
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<ReportAllIncomesResults> {
    try {
      const {
        data,
      }: ApiResponse<ReportAllIncomesResults> = await this.httpClient.get(
        ReportApiRoutes.allIncomesReport,
        {
          params: {
            pinkas_id: pinkasId,
            from_date: moment(fromDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            to_date: moment(toDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm"),
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getYearlyAppointmentsReport(
    year: number,
    activeRequest: CancelTokenSource
  ) {
    try {
      const {
        data,
      }: ApiResponse<{
        [month: string]: {
          first_appointments_count: number;
          next_appointments_count: number;
        };
      }> = await this.httpClient.get(ReportApiRoutes.yearlyAppointmentsReport, {
        params: {
          year,
        },
        cancelToken: activeRequest.token,
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getYearlyIncomesReport(
    pinkasId: number,
    year: number,
    activeRequest: CancelTokenSource
  ) {
    try {
      const {
        data,
      }: ApiResponse<{ [month: string]: number }> = await this.httpClient.get(
        ReportApiRoutes.yearlyIncomesReport,
        {
          params: {
            pinkas_id: pinkasId,
            year,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getDoctorCalendarReport(
    date: string,
    activeRequest: CancelTokenSource
  ): Promise<Patient[]> {
    try {
      const { data }: ApiResponse<Patient[]> = await this.httpClient.get(
        ReportApiRoutes.doctorCalendarReport,
        {
          params: {
            date,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getClinicKupaReport(
    pinkasId: number,
    depositDate: string,
    incomeType: string,
    activeRequest: CancelTokenSource
  ): Promise<ClinicKupaRecord[]> {
    try {
      const {
        data,
      }: ApiResponse<ClinicKupaRecord[]> = await this.httpClient.get(
        ReportApiRoutes.clinicKupaReport,
        {
          params: {
            pinkas_id: pinkasId,
            deposit_date: depositDate,
            income_type: incomeType,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getClinicKupaDepositDates(pinkasId: number): Promise<string[]> {
    try {
      const { data }: ApiResponse<string[]> = await this.httpClient.get(
        ReportApiRoutes.clinicKupaDeposits,
        {
          params: {
            pinkas_id: pinkasId,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async markDepositIncome(
    docType: string,
    docId: number,
    incomeIndex: number,
    depositDate: string
  ): Promise<void> {
    try {
      const { data }: ApiResponse<void> = await this.httpClient.post(
        ReportApiRoutes.markDepositIncome,
        {
          docType,
          docId,
          incomeIndex,
          depositDate,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getPatientsDebtsReport(
    minDebt: number,
    activeRequest: CancelTokenSource
  ): Promise<ReportPatientsDebtsResults> {
    try {
      const {
        data,
      }: ApiResponse<ReportPatientsDebtsResults> = await this.httpClient.get(
        ReportApiRoutes.patientsDebtsReport,
        {
          params: {
            min_debt: minDebt,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getPatientsDebtsExportUrl(minDebt: number): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        ReportApiRoutes.patientsDebtsExport,
        {
          params: {
            min_debt: minDebt,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
}

export default new ReportApi();
