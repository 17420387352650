import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import moment from "moment";
import {AppLogger} from "@/core/logger/logger";
import {HttpClientInterface} from "@/core/http-client/http-client.interface";
import {AppConfig} from "@/core/config/config";

class HttpClient implements HttpClientInterface {
    /**
     * The axios instance.
     */
    public instance: AxiosInstance = null;
    /**
     * Axios basic configuration
     * Some general configuration can be added like timeout, headers, params etc.
     * More details can be found on https://github.com/axios/axios
     * */
    protected config: AxiosRequestConfig = {
        baseURL: AppConfig.apiBaseUrl,
        headers: {
            "Content-Type": "application/json"
        }
    };

    constructor() {
        this.instance = axios.create(this.config);
        this.setInterceptors();
    }

    public setInterceptors() {
        if (this.instance) {
            if (AppConfig.logApiCall) {
                this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
                    const dateTime = moment().format("MMMM Do YYYY, h:mm:ss a");
                    AppLogger.info(`${dateTime}: A ${config.method} API call to ${config.baseURL}/${config.url} has been sent.`);

                    return config;
                });
            }

            this.instance.interceptors.request.use(function (config) {
                const token = localStorage.getItem("token");
                config.headers.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            this.instance.interceptors.response.use(response => {
                return response;
            }, error => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    location.reload();
                }
                return Promise.reject(error);
            });

            this.instance.interceptors.response.use((response) => response.data);
        }
    }
}

const AppHttpClient = new HttpClient();

export {
    HttpClient,
    AppHttpClient,
};
