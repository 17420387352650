import ContactsPage from "../pages/ContactsPage.vue";
import {RouteConfig} from "vue-router";
import {CONTACTS_ROUTE_NAME, CONTACTS_ROUTE_PATH} from "@/modules/contacts/constants/route-config";

const contactsRoutes: RouteConfig[] = [
    {
        name: CONTACTS_ROUTE_NAME,
        path: CONTACTS_ROUTE_PATH,
        component: ContactsPage,
    }
];

export default contactsRoutes;
