import { AppModule } from "@/types/app-module";
import { Store } from "vuex";
import { AppStoreState } from "@/store/store.types";
import { VueRouter } from "vue-router/types/router";
import { RouteConfig } from "vue-router";

class ModuleRegistrar {
    constructor(
        private readonly store: Store<AppStoreState>,
        private readonly router: VueRouter,
    ) {
    }

    public register(module: AppModule) {
        if (this.store) {
            this.registerStoreModule(module.name, module.store);
        }

        if (this.router) {
            this.registerRoutes(module.routes);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private registerStoreModule(moduleName: string, module) {
        // this.store.registerModule(moduleName, module);
        // console.log(moduleName, module);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private registerRoutes(routes: RouteConfig[]) {
        // console.log(routes);
        // this.router.addRoutes(routes);x
    }
}

export default ModuleRegistrar;
