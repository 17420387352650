






















































import RecurringShiftApi from "@/modules/shift/api/recurring-shift.api";
import RecurringShiftForm from "./RecurringShiftForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { RecurringShift } from "@/modules/shift/types/recurring-shift.type";
import moment from "moment";
import { User } from "@/modules/users/types/user.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { date, time } from "@/filters/date.filter";

@Component({

  filters: {
    date,
    time
  },
  components: {
    ErrorLogo,
    RecurringShiftForm
  }
})
export default class ManageShifts extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  public displayUserIds: number[] = [];
  public fromDateFilter = moment().startOf("month").format("YYYY-MM-DD");
  public toDateFilter = moment().endOf("month").format("YYYY-MM-DD");
  public fromDateMenuOpen = false;
  public toDateMenuOpen = false;
  public loadErrors: any = false;
  public showRecurringShiftForm = false;
  public editRecurringShift: RecurringShift = null;
  public loading = false;
  public recurringShifts: RecurringShift[] = [];
  public headers = [
    { text: this.$t("headers.user_id"), value: "user_id", sortable: false },
    { text: this.$t("headers.day_in_week"), value: "day_in_week", sortable: false },
    { text: this.$t("headers.start_time"), value: "start_time", sortable: false },
    { text: this.$t("headers.end_time"), value: "end_time", sortable: false },
    { text: this.$t("headers.notes"), value: "notes", sortable: false },
    { text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];


  public openRecurringShiftFormDialog(recurringShift: RecurringShift) {
    this.editRecurringShift = recurringShift;
    this.showRecurringShiftForm = true;
  }

  public closeRecurringShiftForm() {
    this.editRecurringShift = null;
    this.showRecurringShiftForm = false;
  }

  @Watch("displayUserIds")
  public reload() {
    this.loadRecurringShifts();
  }

  public async loadRecurringShifts() {
    this.loadErrors = null;
    this.loading = true;
    this.recurringShifts = [];

    try {
      this.recurringShifts = await RecurringShiftApi.getAll(this.displayUserIds);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }
    }
  }

  public mounted() {
    this.loadRecurringShifts();
  }
}
