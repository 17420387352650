








































import { Tag } from "@/modules/patient/types/tag.type";
import TagApi from "../../../serivce/api/tag-api";
import TagForm from "../components/tag/TagForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    TagForm,
    SettingsHeader
  },
})
export default class ManageTagsPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;

  public loadErrors: any = false;
  public showTagForm = false;
  public editTag: Tag = null;
  public loading = false;
  public tags: Tag[] = [];

  get headers() {
    return [
      { width: "25%", text: this.$t("headers.name"), value: "name", sortable: false },
      { width: "25%", text: this.$t("headers.color"), value: "color", sortable: false },
      { width: "25%", text: this.$t("headers.order"), value: "order", sortable: false },
      { width: "25%", text: this.$t("headers.actions"), value: "actions", sortable: false }
    ];
  }

  public mounted() {
    this.loadTags();
  }

  public isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public openTagFormDialog(tag: Tag) {
    this.editTag = tag;
    this.showTagForm = true;
  }

  public closeTagForm() {
    this.editTag = null;
    this.showTagForm = false;
  }

  public reload() {
    this.loadTags();
  }

  public async loadTags() {
    this.loadErrors = null;
    this.loading = true;
    this.tags = [];

    try {

      this.tags = await TagApi.getAll();
      this.loading = false;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }

      this.loading = false;

    }
  }
}
