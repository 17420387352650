import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  ShiftApiRouteParameters,
  ShiftApiRoutes,
} from "@/modules/shift/constants/api-routes";
import { Shift } from "@/modules/shift/types/shift.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class ShiftApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(
    userIds: number[],
    fromDate: string,
    toDate: string
  ): Promise<Shift[]> {
    try {
      const response: ApiResponse<Shift[]> = await this.httpClient.get(
        ShiftApiRoutes.getAll,
        {
          params: {
            user_ids: userIds,
            from_date: fromDate,
            to_date: toDate,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    userId: number,
    startDate: string,
    endDate: string,
    notes: string
  ): Promise<Shift> {
    try {
      const response: ApiResponse<Shift> = await this.httpClient.put(
        ShiftApiRoutes.updateShift.replace(
          ShiftApiRouteParameters.id,
          id.toString()
        ),
        {
          user_id: userId,
          start_date: startDate,
          end_date: endDate,
          notes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    userId: number,
    startDate: string,
    endDate: string,
    notes: string
  ): Promise<Shift> {
    try {
      const response: ApiResponse<Shift> = await this.httpClient.post(
        ShiftApiRoutes.createShift,
        {
          user_id: userId,
          start_date: startDate,
          end_date: endDate,
          notes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        ShiftApiRoutes.deleteShift.replace(
          ShiftApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new ShiftApi();
