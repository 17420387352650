import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import {
  PatientTreatmentApiRouteParameters,
  PatientTreatmentApiRoutes,
  PatientTreatmentGroupByApiRoutes,
} from "@/modules/patient/constants/api-routes";
import {
  PatientTreatment,
  TreatmentGroupByTreatmentName,
} from "@/modules/patient/types/patient-treatment.type";
import { ApiResponse } from "@/core/http-client/http-client.types";
import moment from "moment";

class PatientTreatmentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async update(
    id: number,
    userId: number,
    appointmentId: number,
    treatmentId: number,
    attributes: any,
    price: number,
    discount: number,
    notes: string,
    color?: string,
    date?: string,
    quantity?: number
  ): Promise<PatientTreatment> {
    try {
      const response: ApiResponse<PatientTreatment> = await this.httpClient.put(
        PatientTreatmentApiRoutes.update.replace(
          PatientTreatmentApiRouteParameters.id,
          id.toString()
        ),
        {
          appointment_id: appointmentId,
          treatment_id: treatmentId,
          user_id: userId,
          attributes,
          price,
          discount,
          notes,
          color,
          date,
          quantity,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async getTreatmentGroupByTreatmentName(
    itemsPerPage: number,
    page: number,
    userIds: number[],
    treatmentIds: number[],
    excludeTreatmentIds: number[],
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<{
    totalPrice: number;
    paginate: { data: TreatmentGroupByTreatmentName[]; total: number };
  }> {
    try {
      const {
        data,
      }: ApiResponse<{
        totalPrice: number;
        paginate: { data: TreatmentGroupByTreatmentName[]; total: number };
      }> = await this.httpClient.get(
        PatientTreatmentGroupByApiRoutes.getTreatmentGroupByTreatmentName,
        {
          params: {
            page,
            itemsPerPage,
            userIds,
            treatmentIds,
            excludeTreatmentIds,
            fromDate: fromDate
              ? moment(fromDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            toDate: toDate
              ? moment(toDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
  async find(
    itemsPerPage: number,
    page: number,
    userIds: number[],
    treatmentIds: number[],
    excludeTreatmentIds: number[],
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<{
    totalPrice: number;
    paginate: { data: PatientTreatment[]; total: number };
  }> {
    try {
      const {
        data,
      }: ApiResponse<{
        totalPrice: number;
        paginate: { data: PatientTreatment[]; total: number };
      }> = await this.httpClient.get(PatientTreatmentApiRoutes.find, {
        params: {
          page,
          itemsPerPage,
          userIds,
          treatmentIds,
          excludeTreatmentIds,
          fromDate: fromDate
            ? moment(fromDate)
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          toDate: toDate
            ? moment(toDate)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
        cancelToken: activeRequest.token,
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  async getExportUrl(
    userIds: number[],
    treatmentIds: number[],
    excludeTreatmentIds: number[],
    fromDate: string,
    toDate: string
  ): Promise<string> {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.get(
        PatientTreatmentApiRoutes.export,
        {
          params: {
            userIds,
            treatmentIds,
            excludeTreatmentIds,
            fromDate: fromDate
              ? moment(fromDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            toDate: toDate
              ? moment(toDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    patientId: number,
    userId: number,
    appointmentId: number,
    treatmentId: number,
    attributes: any,
    price: number,
    discount: number,
    notes: string,
    color?: string,
    date?: string,
    quantity?: number
  ): Promise<PatientTreatment> {
    try {
      const response: ApiResponse<PatientTreatment> = await this.httpClient.post(
        PatientTreatmentApiRoutes.create,
        {
          appointment_id: appointmentId,
          patient_id: patientId,
          user_id: userId,
          treatment_id: treatmentId,
          attributes,
          price,
          discount,
          notes,
          color,
          date,
          quantity,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PatientTreatmentApiRoutes.delete.replace(
          PatientTreatmentApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientTreatmentApi();
