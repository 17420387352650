




















































































































































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import moment from "moment";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import ReportApi from "../api/report.api";
import ShowPaymentDocumentFileDialog from "@/modules/patient/components/PatientPayment/ShowPaymentDocumentFileDialog.vue";
import { HeshbonitMasKabala, Kabala } from "@/modules/patient/types/payment.type";
import { ClinicKupaRecord } from "@/modules/reports/types/report.type";

@Component({
  components: {
    ShowPaymentDocumentFileDialog,
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportClinicKupaPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  private activeRequest: CancelTokenSource = null;
  public displayDocument: Kabala | HeshbonitMasKabala = null;
  public reportResults: ClinicKupaRecord[] = null;
  public depositDate: string = null;
  public pinkasId: number = null;
  public incomeType: string = null;
  public displayDatePicker = false;
  public dateMenuOpen = false;
  public isLoading = false;
  public loadingIndex: number = null;
  public depositDates: string[] = [];
  public isExportLoading = false;
  public isDepositAllLoading = false;
  public showDepositDatePicker = false;
  public activeTab = 0;

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public async onExportClicked() {
    this.isExportLoading = true;

    try {
      const url = await ReportApi.getRepositsExportUrl(this.pinkasId, this.depositDate, this.incomeType);
      this.isExportLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, this.depositDate + ".pdf");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportLoading = false;
    }
  }

  public async onMarkDepositClicked(record: ClinicKupaRecord, index: number) {
    const res = await this.$confirm(
      this.$t("mark_deposit_confirm_message").toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("mark_deposit_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("mark_deposit_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.loadingIndex = index;
      const today = moment().format("YYYY-MM-DD");
      await ReportApi.markDepositIncome(record.document.document_type, record.document.id, record.incomeIndex, today);
      this.loadingIndex = null;

      record.income.deposit_date = moment().format("YYYY-MM-DD");

      this.depositDates.push(record.income.deposit_date);
      this.$toastr.s(this.$t("marked_as_deposited"));
    } catch (err) {
      this.loadingIndex = null;
      this.$toastr.e(this.$t("unexpected_error") + " " + err);
    }
  }

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  public datePickerAllowedDates(date: string) {
    return this.depositDates.includes(date);
  }

  @Watch("pinkasId")
  public async getClinicKupaDepositDates() {
    this.depositDates = await ReportApi.getClinicKupaDepositDates(this.pinkasId);
  }

  @Watch("depositDate")
  @Watch("incomeType")
  @Watch("pinkasId")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.receipt_date"), value: "receipt_date", sortable: false },
      { text: this.$t("headers.patient_name"), value: "patient_name", sortable: false },
      { text: this.$t("headers.document_details"), value: "document_details", sortable: false },
      { text: this.$t("headers.income_type"), value: "income_type", sortable: false },
      { text: this.$t("headers.income_amount"), value: "income_amount", sortable: false },
      { text: this.$t("headers.income_details"), value: "income_details", sortable: false },
      { text: this.$t("headers.deposit_date"), value: "deposit_date", sortable: false },
    ];
  }

  get futureHeaders() {
    return [
      { text: this.$t("headers.receipt_date"), value: "receipt_date", sortable: false },
      { text: this.$t("headers.patient_name"), value: "patient_name", sortable: false },
      { text: this.$t("headers.document_details"), value: "document_details", sortable: false },
      { text: this.$t("headers.income_type"), value: "income_type", sortable: false },
      { text: this.$t("headers.income_amount"), value: "income_amount", sortable: false },
      { text: this.$t("headers.income_details"), value: "income_details", sortable: false },
    ];
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await ReportApi
        .getClinicKupaReport(this.pinkasId, this.depositDate, this.incomeType, this.activeRequest);

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();

    if (!this.pinkasId) {
      this.pinkasId = this.pinkasList[0].id;
    }

    this.getClinicKupaDepositDates();
    this.reload();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get dateFormatted() {
    if (this.depositDate) {
      return moment(this.depositDate).format("DD/MM/YYYY");
    }

    return this.$t("not_yet_deposited");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public async onMarkAllDepositClicked(date?: string) {
    const res = await this.$confirm(this.$t(date ? "mark_deposit_date_confirm_message" : "mark_deposit_all_confirm_message", { date: moment(date).format("DD-MM-YYYY") }).toString(), {
      buttonTrueColor: "cyan darken-2",
      buttonTrueText: this.$t("mark_deposit_confirm_ok_btn").toString(),
      buttonFalseText: this.$t("mark_deposit_confirm_cancel_btn").toString()
    });
    if (!res) {
      return;
    }

    this.isDepositAllLoading = true;
    const depositPromises = [];
    this.readyForDeposit.forEach((record) => {
      if (date && moment(record.document.created_at).isAfter(moment(date).endOf("day"))) {
        return;
      }
      if (record.income.deposit_date) {
        return;
      }
      depositPromises.push(ReportApi.markDepositIncome(record.document.document_type, record.document.id, record.incomeIndex, date));
    });

    try {
      await Promise.allSettled(depositPromises);
      this.depositDate = date ? date : moment().format("YYYY-MM-DD");
      this.activeTab = 1;
      this.incomeType = null;
      await this.reload();
      await this.onExportClicked();
      this.isDepositAllLoading = false;
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isDepositAllLoading = false;
    }
  }

  get readyForDeposit() {
    if (!this.reportResults) {
      return [];
    }
    return this.reportResults.filter(({ income }) => (income.type === "cash" || moment(income.check_info.date).isSameOrBefore(moment())));
  }

  get futureForDeposit() {
    if (!this.reportResults) {
      return [];
    }
    return this.reportResults.filter(({ income }) => (income.type === "check" && moment(income.check_info.date).isAfter(moment())));
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();
    const queryBusiness = parseInt(this.$route.query.business && this.$route.query.business.toString());
    const queryIncome = this.$route.query.income && this.$route.query.income.toString();

    if (queryDate) {
      this.depositDate = queryDate;
    }
    if (queryBusiness) {
      this.pinkasId = queryBusiness;
    }
    if (queryIncome) {
      this.incomeType = queryIncome;
    }
  }

  get summaryCashAmount() {
    return this.readyForDeposit.filter(({ income }) => (income.type === "cash"))
      .map(({ income }) => (parseFloat(income.amount.toString())))
      .reduce((a, b) => (a + b), 0);
  }

  get summaryCheckAmount() {
    return this.readyForDeposit.filter(({ income }) => (income.type === "check"))
      .map(({ income }) => (income.amount))
      .reduce((a, b) => (parseFloat(a.toString()) + parseFloat(b.toString())), 0);
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.depositDate ? this.depositDate : undefined,
      income: this.incomeType ? this.incomeType : undefined,
      business: this.pinkasId ? this.pinkasId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }
}
