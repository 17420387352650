






























































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Contact } from "@/modules/contacts/types/contact.type";
import { Getter } from "vuex-class";
import PatientWhatsappChatDialog from "@/modules/whatsapp/components/PatientWhatsappChatDialog.vue";


@Component({
  components: {
    PatientWhatsappChatDialog
  }
})
export default class ContactInformationDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) contact: Contact;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  public displayWhatsappChatDialog = false;
  public contactPhone = "";

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public closeDialog() {
    this.$emit("closeContactInformationModal");
  }
  public closeWhatsappChatDialog() {
    this.displayWhatsappChatDialog = false;
  }

  public openWhatsappChatDialog(contactPhone: string) {
    this.contactPhone = contactPhone;
    this.displayWhatsappChatDialog = true;
  }

}
