import { render, staticRenderFns } from "./SharePlanWhatsappDialog.vue?vue&type=template&id=0b528d44&"
import script from "./SharePlanWhatsappDialog.vue?vue&type=script&lang=ts&"
export * from "./SharePlanWhatsappDialog.vue?vue&type=script&lang=ts&"
import style0 from "./SharePlanWhatsappDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales/share-plan-whatsapp-dialog.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Ftreatment%2Fcomponents%2Fplan%2FSharePlanWhatsappDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports