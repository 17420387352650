import NotificationsCenterPage from "../pages/NotificationsCenterPage.vue";
import {RouteConfig} from "vue-router";
import {
    NOTIFICATIONS_CENTER_ROUTE_NAME,
    NOTIFICATIONS_CENTER_ROUTE_PATH
} from "@/modules/notifications/constants/route-config";

const timeClockRoutes: RouteConfig[] = [
    {
        name: "notifications-center-page", // TODO const
        path: "/schedule", // TODO const
        component: NotificationsCenterPage,
    },
    {
        name: NOTIFICATIONS_CENTER_ROUTE_NAME,
        path: NOTIFICATIONS_CENTER_ROUTE_PATH,
        component: NotificationsCenterPage,
    },
];

export default timeClockRoutes;
