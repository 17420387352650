import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { PatientStatus } from "@/modules/patient/types/patient-status.type";
import {
  PatientStatusApiRouteParameters,
  PatientStatusApiRoutes,
} from "@/modules/settings/constants/api-routes";

class PatientStatusApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<PatientStatus[]> {
    try {
      const response: ApiResponse<PatientStatus[]> = await this.httpClient.get(
        PatientStatusApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PatientStatusApiRoutes.delete.replace(
          PatientStatusApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    color: string,
    order: number
  ): Promise<PatientStatus> {
    try {
      const response: ApiResponse<PatientStatus> = await this.httpClient.put(
        PatientStatusApiRoutes.update.replace(
          PatientStatusApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          color,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    name: string,
    color: string,
    order: number
  ): Promise<PatientStatus> {
    try {
      const response: ApiResponse<PatientStatus> = await this.httpClient.post(
        PatientStatusApiRoutes.create,
        {
          name,
          color,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientStatusApi();
