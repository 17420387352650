import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"360","value":true},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_vm._v("\n      "+_vm._s(_vm.$t('title'))+"\n      "),_c(VBtn,{staticClass:"float-left",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t('message')))]),_c(VList,[_c(VListItem,{attrs:{"href":("https://api.whatsapp.com/send?phone=972552724499&text=" + (_vm.$t('whatsapp_message'))),"target":"_blank"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-whatsapp")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n              "+_vm._s(_vm.$t('whatsapp'))+"\n            ")])],1)],1),_c(VListItem,{attrs:{"href":"mailto:talk@smilecloud.co.il"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-email")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n              talk@smilecloud.co.il\n            ")])],1)],1),_c(VListItem,{attrs:{"href":"tel:055-272-4499"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-phone")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n              055-272-4499\n            ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }