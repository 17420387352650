import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  RecurringShiftApiRouteParameters,
  RecurringShiftApiRoutes,
} from "@/modules/shift/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import {
  RecurringShift,
  RecurringShiftMandatory,
} from "../types/recurring-shift.type";

class RecurringShiftApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(userIds: number[]): Promise<RecurringShift[]> {
    try {
      const response: ApiResponse<RecurringShift[]> = await this.httpClient.get(
        RecurringShiftApiRoutes.getAll,
        {
          params: {
            user_ids: userIds,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    payload: RecurringShiftMandatory
  ): Promise<RecurringShift> {
    try {
      const response: ApiResponse<RecurringShift> = await this.httpClient.put(
        RecurringShiftApiRoutes.updateRecurringShift.replace(
          RecurringShiftApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: RecurringShiftMandatory): Promise<RecurringShift> {
    try {
      const response: ApiResponse<RecurringShift> = await this.httpClient.post(
        RecurringShiftApiRoutes.createRecurringShift,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        RecurringShiftApiRoutes.deleteRecurringShift.replace(
          RecurringShiftApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new RecurringShiftApi();
