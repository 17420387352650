
































































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import { WhatsappContact } from "../types/whatsapp.type";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Contact } from "@/modules/contacts/types/contact.type";
import ContactInformationDialog from "@/modules/patient/components/ContactInformationDialog/ContactInformationDialog.vue";

@Component({
  components: {
    ContactInformationDialog
  },
  filters: {
    age,
    date,
    time
  }
})
export default class WhatsappContactInfo extends mixins(SmileMixin) {
  @Prop({ required: true }) public whatsappContact: WhatsappContact;
  public contactInformation: Contact = null;

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public openContactInformationModal(referredByInformation: Contact) {
    this.contactInformation = referredByInformation;
  }

}
