import {Module} from "vuex";
import timeClockState from "@/modules/time-clock/store/time-clock.state";
import timeClockGetters from "@/modules/time-clock/store/time-clock.getters";
import timeClockActions from "@/modules/time-clock/store/time-clock.actions";
import timeClockMutations from "@/modules/time-clock/store/time-clock.mutations";

const timeClockStore: Module<any, any> = {
    namespaced: true,
    state: timeClockState,
    getters: timeClockGetters,
    actions: timeClockActions,
    mutations: timeClockMutations,
};

export default timeClockStore;
