import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"displayPatientFilesWrapper",class:_vm.isDragOver && 'drag-over',attrs:{"elevation":"3"}},[_c(VCardTitle,{staticStyle:{"padding-bottom":"0"}},[_c('a',{on:{"click":function($event){return _vm.onOpenPatientFileExplorerClicked()}}},[_vm._v(_vm._s(_vm.$t('title')))]),_c(VSpacer),_c('div',{staticClass:"buttons-wrapper"},[(_vm.isDiagnocatEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isDiagnocatLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenDiagnocatClicked()}}},'VBtn',attrs,false),on),[_c('img',{staticStyle:{"vertical-align":"middle","height":"17px"},attrs:{"src":require('@/assets/diagnocat-logo.svg'),"alt":"Diagnocat logo"}})])]}}],null,false,1981262522)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-diagnocat-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingMediadentEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isMediadentLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenMediadentClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-m")])],1)]}}],null,false,836610160)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-mediadent-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingIrysEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isIrysLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenIrysClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-i")])],1)]}}],null,false,3612357908)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-irys-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingRomexisEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isRomexisLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenRomexisClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-r")])],1)]}}],null,false,3228434703)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-romexis-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingScanoraEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isScanoraLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenScanoraClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-s")])],1)]}}],null,false,1602751662)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-scanora-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingDimaxisEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isDimaxisLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenDimaxisClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-d")])],1)]}}],null,false,3924536665)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-dimaxis-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingVistaSoftEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isVistaSoftLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenVistaSoftClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-v")])],1)]}}],null,false,2823163851)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-vistasoft-btn')))])]):_vm._e(),(!_vm.isMobile && _vm.isImagingArchimedEnabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","loading":_vm.isArchimedLoading,"icon":"","color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.onOpenArchimedClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alpha-a")])],1)]}}],null,false,2487602972)},[_c('span',[_vm._v(_vm._s(_vm.$t('open-archimed-btn')))])]):_vm._e(),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"elevation":"4","icon":"","color":"cyan darken-2","loading":_vm.uploadLoading},on:{"click":function($event){return _vm.uploadFileClicked()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-upload")]),_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*;capture=camera","name":"file","multiple":"multiple"},on:{"change":function($event){return _vm.uploadFiles($event.target.files)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('upload-file-btn')))])])],1)],1),_c(VList,{staticClass:"files"},[_c(VListItemGroup,_vm._l((_vm.patientFiles.slice(0, _vm.showingFilesCount)),function(file,index){return _c('PatientFileListItem',{key:index,attrs:{"file":file},on:{"click":function($event){return _vm.openFile(file)}}})}),1)],1),(_vm.patientFiles.length === 0)?_c(VCol,{staticClass:"text-center",staticStyle:{"height":"140px","color":"#ccc","padding-top":"35px"},attrs:{"cols":"12"}},[_c('span',[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("\n        mdi-file\n      ")]),_c('br'),_vm._v("\n      "+_vm._s(_vm.$t('no_files'))+"\n    ")],1)]):_vm._e(),(_vm.patientFiles.length && _vm.showingFilesCount < _vm.patientFiles.length)?_c(VCol,{staticClass:"text-center"},[_c(VBtn,{attrs:{"x-small":"","outlined":"","color":"cyan darken-2"},on:{"click":function($event){_vm.showingFilesCount += 10}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-chevron-double-down")]),_vm._v("\n      "+_vm._s(_vm.$t('show_more'))+"\n    ")],1)],1):_vm._e(),(_vm.showPatientFile)?_c('ShowPatientFileDialog',{attrs:{"patient":_vm.patient,"file":_vm.showPatientFile,"navigateImages":_vm.navigateImages},on:{"fileDeleted":_vm.fileDeleted,"fileNameUpdated":_vm.fileNameUpdated,"close":_vm.closeShowPatientFileDialog,"selectedFile":_vm.onFileSelected}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }