import { Tag } from "@/modules/patient/types/tag.type";
import { AuthState } from "../types/auth.type";

const authMutations = {
  token(state: AuthState, token) {
    state.token = token;
  },
  setUser(state: AuthState, user) {
    state.user = user;
  },
  setSettings(state: AuthState, settings) {
    state.settings = settings;
  },
  logout(state: AuthState) {
    state.token = null;
    state.user = null;
    state.settings = null;
  },
  setTag(state: AuthState, updatedTag: Tag) {
    const index = state.settings.tags.findIndex(tag => (tag.id === updatedTag.id));
    if (index === -1) {
      state.settings.tags.push(updatedTag);
    } else {
      state.settings.tags[index] = updatedTag;
    }
  },
};

export default authMutations;
