import {RouteConfig} from "vue-router";
import WhatsappPage from "../pages/WhatsappPage.vue";
import {
    WHATSAPP_ROUTE_NAME,
    WHATSAPP_ROUTE_PATH,
} from "@/modules/whatsapp/constants/route-config";

const whatsappRoutes: RouteConfig[] = [
    {
        name: WHATSAPP_ROUTE_NAME,
        path: WHATSAPP_ROUTE_PATH,
        component: WhatsappPage,
    },
];

export default whatsappRoutes;
