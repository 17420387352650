import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { AppConfig } from "../config/config";
import VueRouter from "vue-router";

export const initSentry = (router: VueRouter) => {
  if (!AppConfig.inProductionMode) {
    return;
  }

  Sentry.init({
    Vue,
    logErrors: true,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: "smile-frontend@" + process.env.VUE_APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  });
};

export const identifyUser = (email: string) => {
  if (!AppConfig.inProductionMode) {
    return;
  }

  Sentry.setUser({ email });
};

