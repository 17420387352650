import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"patient-plans"},[_c(VBtn,{staticClass:"mb-2",attrs:{"small":"","outlined":"","color":"black","elevation":"1"},on:{"click":function($event){return _vm.createPlan()}}},[_vm._v("\n    "+_vm._s(_vm.$t('create_btn'))+"\n    "),_c(VIcon,{attrs:{"right":_vm.isRtl,"left":!_vm.isRtl}},[_vm._v("mdi-plus")])],1),(_vm.plans.length > 0)?_c('div',{staticClass:"mt-3"},[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('headers.user')))]),_c('th',[_vm._v(_vm._s(_vm.$t('headers.date')))]),_c('th',[_vm._v(_vm._s(_vm.$t('headers.name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('headers.price')))])])]),_c('tbody',_vm._l((_vm.plans),function(plan,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.userName(plan.user_id)))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(plan.created_at)))]),_c('td',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.open(plan)}}},[_vm._v(_vm._s(plan.name))]),_vm._v("\n                ("+_vm._s(plan.completed_at ? _vm.$t('plan_completed') : _vm.$t('plan_active'))+")\n              ")])]),_c('td',[_vm._v(_vm._s(_vm.totalPrice(plan).toLocaleString())+"₪")])])}),0)]},proxy:true}],null,false,3420474299)})],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-archive-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('no_plans')))])],1)])],1),(_vm.displayPlanDialog)?_c('PlanDialog',{attrs:{"patient":_vm.patient,"plan":_vm.planDisplayed},on:{"close":function($event){return _vm.closePlanDialog()},"planDeleted":_vm.planDeleted}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }