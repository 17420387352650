import AuthApi from "../api/auth.api";
import EchoService from "@/serivce/echo-service";
import { initFullStory } from "@/core/fullstory/fullstory";
import { identifyUser } from "@/core/sentry/sentry";

const authActions = {
  login({ commit }, credentials) {
    return AuthApi.login(credentials.email, credentials.password).then(
      (data) => {
        if (data.two_factor_token) {
          return data;
        }

        localStorage.setItem("token", data.token);
        commit("token", data.token);
        commit("setUser", data.user);
        commit("setSettings", data.initData);

        initFullStory(data.user.email);
        identifyUser(data.user.email);
      }
    );
  },
  twoFactor({ commit }, credentials) {
    return AuthApi.twoFactor(
      credentials.email,
      credentials.password,
      credentials.twoFactorToken,
      credentials.code
    ).then((data) => {
      localStorage.setItem("token", data.token);
      commit("token", data.token);
      commit("setUser", data.user);
      commit("setSettings", data.initData);

      initFullStory(data.user.email);
      identifyUser(data.user.email);
    });
  },
  logout({ commit }) {
    EchoService.logout();
    AuthApi.logout().finally(() => {
      commit("logout");
      localStorage.clear();
      location.reload();
    });
  },
  loadInitData({ commit }) {
    return AuthApi.loadInitData().then((data) => {
      commit("setSettings", data.initData);
      commit("setUser", data.user);
    });
  },
};

export default authActions;
