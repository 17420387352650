import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"360"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.editSentenceCategory ? 'edit_title' : 'add_title'))+"\n      "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('smile-error-message',{attrs:{"errors":_vm.errors}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"hide-details":"","label":_vm.$t('name'),"color":"cyan darken-1","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.displayTypeOptions,"hide-details":"","label":_vm.$t('display_type'),"color":"cyan darken-1","item-color":"cyan","type":"text"},model:{value:(_vm.displayType),callback:function ($$v) {_vm.displayType=$$v},expression:"displayType"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"hide-details":"","label":_vm.$t('order'),"color":"cyan darken-1","type":"text"},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})],1)],1)],1)],1),_c(VCardActions,[(_vm.editSentenceCategory)?_c(VBtn,{attrs:{"color":"red","small":"","text":"","type":"button","loading":_vm.deleteLoading},on:{"click":function($event){return _vm.deleteButtonClicked()}}},[_vm._v("\n        "+_vm._s(_vm.$t('delete_btn'))+"\n      ")]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"cyan","loading":_vm.loading,"type":"submit"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("\n        "+_vm._s(_vm.$t('submit'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }