





























































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { date, time } from "@/filters/date.filter";
import { Getter } from "vuex-class";
import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";

@Component({
  filters: {
    date,
    time,
  }
})
export default class TrackChangesPatientTreatmentDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) patientTreatment: PatientTreatment;
  @Getter("auth/isRtl") isRtl: boolean;

  public closeDialog() {
    this.$emit("close");
  }

  get changesLogItems() {
    return this.patientTreatment.changes_log.reverse();
  }

  public getAttribute(attributesString: string, key: string) {
    if (!attributesString) {
      return null;
    }

    const json = JSON.parse(attributesString);
    return json[key];
  }
}
