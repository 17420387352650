







































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import { WhatsappContact, WhatsappDate, WhatsappReceivedMessage, WhatsappSentMessage } from "../types/whatsapp.type";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import { Getter } from "vuex-class";
import WhatsappDisplayImageDocumentDialog from "./WhatsappDisplayImageDocumentDialog.vue";
import { VEmojiPicker } from "v-emoji-picker";
import EchoService from "@/serivce/echo-service";
import moment from "moment";

@Component({
  components: {
    VEmojiPicker,
    WhatsappDisplayImageDocumentDialog,
  },
  filters: {
    age,
    date,
    time
  }
})
export default class SingleWhatsappChat extends mixins(SmileMixin) {
  @Prop({ required: true }) public phone: string;
  @Prop({ required: false }) public showPhone: boolean;
  @Prop({ required: true }) public markRead: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/waAllowFreeText") waAllowFreeText: boolean;

  public displayMessageId: number = null
  public isLoading = true;
  public chatHistory: (WhatsappDate | WhatsappSentMessage | WhatsappReceivedMessage)[] = [];
  public freeTextMessage = "";
  public isSendingLoading = false;
  public isEmojiPickerOpen = false;

  $refs!: {
    "free-text-input": HTMLFormElement;
  }

  public onEmojiSelected(emoji: { data: string }) {
    this.freeTextMessage += emoji.data;
    this.isEmojiPickerOpen = false;
  }

  public onNewLineClicked() {
    const splitInChar = this.$refs["free-text-input"].$refs.input.selectionStart;

    const firstPart = this.freeTextMessage.substring(0, splitInChar);
    const lastPart = this.freeTextMessage.substring(splitInChar, this.freeTextMessage.length);

    this.freeTextMessage = firstPart + "\n" + lastPart;

    // move cursor:
    setTimeout(() => {
      this.$refs["free-text-input"].$refs.input.selectionStart = splitInChar + 1;
      this.$refs["free-text-input"].$refs.input.selectionEnd = splitInChar + 1;
    }, 10);
  }

  public async sendFreeText() {
    if (this.isSendingLoading) {
      return;
    }
    try {
      this.isSendingLoading = true;
      await WhatsappApi.sendFreeText(this.phone, this.freeTextMessage);
      this.isSendingLoading = false;
      this.freeTextMessage = "";
      this.$toastr.s(this.$t("whatsapp_message_sent_success_toastr"));
      await this.fetchChatHistory(false);
    } catch (err) {
      this.isSendingLoading = false;
      this.$toastr.e(err && err.smileMessage ? err.smileMessage : this.$t("whatsapp_message_sent_error_toastr"));
    }
  }

  destroyed() {
    EchoService.stopListenFrontendWhatsappPhonePush();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public async mounted() {
    await this.fetchChatHistory(true);
    this.listenFrontendWhatsappReceived();
  }

  private listenFrontendWhatsappReceived() {
    EchoService.listenFrontendWhatsappPhonePush(async (eventData: { phone: string }) => {
      if (eventData.phone !== this.phone) {
        return;
      }

      await this.fetchChatHistory(false);
    });
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get todayDate() {
    return moment().format("YYYY-MM-DD");
  }

  @Watch("phone")
  public onPhoneChanged() {
    this.freeTextMessage = "";
    this.fetchChatHistory(true);
  }

  public async fetchChatHistory(cleanChatHistory: boolean) {
    this.isLoading = true;
    if (cleanChatHistory) {
      this.chatHistory = [];
    }

    try {
      this.chatHistory = await WhatsappApi.getChatHistory(this.phone);
      this.isLoading = false;
      this.scrollToBottom();
      if (this.markRead) {
        const contact = (this.$store.getters["whatsapp/contacts"] as WhatsappContact[])
          .find(({ phone }) => (phone === this.phone));

        if (contact && contact.isUnread) {
          await this.doMarkAsRead();
        }
      }
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.$emit("error");
      this.isLoading = false;
    }
  }

  public async doMarkAsRead() {
    await WhatsappApi.markAsRead(this.phone, true);
    this.$store.dispatch("whatsapp/markAsRead", this.phone);
  }

  public scrollToBottom() {
    if (this.chatHistory.length === 0) {
      return;
    }

    // Scroll chat to bottom
    if (this.$refs["whatsapp-chat-wrapper"]) {
      (this.$refs["whatsapp-chat-wrapper"] as Element).scrollTop = 99999999999;
    }
    setTimeout(() => {
      if (this.$refs["whatsapp-chat-wrapper"]) {
        (this.$refs["whatsapp-chat-wrapper"] as Element).scrollTop = 99999999999;
      }
    }, 20);
  }
}
