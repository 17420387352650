










































import Vue from "vue";
import AppointmentTypeApi from "../../../serivce/api/appointment-type-api";
import AppointmentTypeForm from "../components/appointment-type/AppointmentTypeForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import SettingsHeader from "../components/SettingsHeader.vue";
import { AppointmentType } from "../types/appointment-type.type";

@Component({
  components: {
    ErrorLogo,
    AppointmentTypeForm,
    SettingsHeader
  },
})

export default class ManageAppointmentTypesPage extends Vue {

  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public showAppointmentTypeForm = false;
  public editAppointmentType: AppointmentType = null;
  public loading = false;
  public appointmentTypes: AppointmentType[] = [];
  public headers = [
    { width: "25%", text: this.$t("headers.name"), value: "name" },
    { width: "25%", text: this.$t("headers.duration_minutes"), value: "duration_minutes" },
    { width: "25%", text: this.$t("headers.color"), value: "color" },
    { width: "25%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];

  public openAppointmentTypeFormDialog(appointmentType: AppointmentType) {
    this.editAppointmentType = appointmentType;
    this.showAppointmentTypeForm = true;
  }
  public closeAppointmentTypeForm() {
    this.editAppointmentType = null;
    this.showAppointmentTypeForm = false;
  }
  public reload() {
    this.loadAppointmentTypes();
  }
  public async loadAppointmentTypes() {
    this.loadErrors = null;
    this.loading = true;
    this.appointmentTypes = [];
    try {
      this.loading = false;
      const results = await AppointmentTypeApi.get();
      this.appointmentTypes = results.data.data;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }
  mounted() {
    this.loadAppointmentTypes();
  }
}

