/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV !== "development") {
  register(`${process.env.BASE_URL}service-worker.js?`, {
    ready () {
      console.log(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered () {
      console.log("Service worker has been registered.");
    },
    cached () {
      console.log("Content has been cached for offline use.");
    },
    updatefound () {
      console.log("New content is downloading.");
    },
    updated () {
      console.log("New content is available; please refresh...");

      const HOUR = 60 * 60 * 1000;
      const lastForcedUpdateRefreshTimestamp = parseInt(localStorage.getItem("lastForcedUpdateRefresh"));
      const nowTimestamp = (new Date()).getTime();
      if (lastForcedUpdateRefreshTimestamp + HOUR > nowTimestamp) {
        console.log("already forced refresh");
        return;
      }

      setTimeout(() => {
        localStorage.setItem("lastForcedUpdateRefresh", nowTimestamp.toString());
        window.location.reload();
      }, 2500);
    },
    offline () {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error (error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
