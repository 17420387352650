import {AppHttpClient} from "@/core/http-client/http-client";
import {AxiosInstance} from "axios";
import {UserApiRoutes} from "@/modules/users/constants/api-routes";
import {MandatoryUserData, User} from "@/modules/users/types/user.type";
import {CreateOrUpdateApiResponse, GetAllUsersApiResponse} from "@/modules/users/types/api-response.type";

class UserApi {
    private httpClient: AxiosInstance;

    constructor() {
        this.httpClient = AppHttpClient.instance;
    }

    async getAll(params = {}): Promise<User[]> {
        try {
            const response: GetAllUsersApiResponse = await this.httpClient.get(UserApiRoutes.getAll, {params});

            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async create(payload: MandatoryUserData): Promise<User> {
        try {
            const response: CreateOrUpdateApiResponse = await this.httpClient.post(UserApiRoutes.create, payload);

            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async update(id, payload: MandatoryUserData): Promise<User> {
        try {
            const response: CreateOrUpdateApiResponse = await this.httpClient.put(
                UserApiRoutes.update.replace("{id}", id),
                payload
            );

            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(id): Promise<boolean> {
        try {
            const response = await this.httpClient.delete(UserApiRoutes.delete.replace("{id}", id));

            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default new UserApi();
