import { render, staticRenderFns } from "./PatientFamilyDialog.vue?vue&type=template&id=da662f6e&"
import script from "./PatientFamilyDialog.vue?vue&type=script&lang=ts&"
export * from "./PatientFamilyDialog.vue?vue&type=script&lang=ts&"
import style0 from "./PatientFamilyDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales/patient-family-dialog.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpatient%2Fcomponents%2FPatientActionDialog%2FPatientFamilyDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports