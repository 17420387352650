




















































































import RecurringShiftApi from "@/modules/shift/api/recurring-shift.api";
import { Component, Prop, Vue } from "vue-property-decorator";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import moment from "moment";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import { RecurringShift } from "@/modules/shift/types/recurring-shift.type";

@Component({
  components: {
    ErrorMessage,
  }
})
export default class RecurringShiftForm extends Vue {
  @Prop({ required: false }) public editRecurringShift: RecurringShift;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  public errors: any = false;
  public userId: number = null;
  public startDate = "";
  public endDate = "";
  public startTime = "";
  public endTime = "";
  public notes = "";
  public dayInWeek: number = null;
  public recurringInterval = 1;
  public weeksBeforeShiftOpen = 52;
  public startDateMenuOpen = false;
  public endDateMenuOpen = false;
  public isLoading = false;
  public deleteLoading = false;

  mounted() {
    if (this.editRecurringShift) {
      this.userId = this.editRecurringShift.user_id;
      this.notes = this.editRecurringShift.notes;
      this.dayInWeek = this.editRecurringShift.day_in_week;
      this.recurringInterval = this.editRecurringShift.recurring_interval;
      this.weeksBeforeShiftOpen = this.editRecurringShift.weeks_before_shift_open;
      this.startDate = this.editRecurringShift.start_date ? moment(this.editRecurringShift.start_date).format("YYYY-MM-DD") : null;
      this.endDate = this.editRecurringShift.end_date ? moment(this.editRecurringShift.end_date).format("YYYY-MM-DD") : null;
      this.startTime = this.editRecurringShift.shift_start_time.substring(0, 5);
      this.endTime = this.editRecurringShift.shift_end_time.substring(0, 5);
    } else {
      if (this.therapistUsers.length === 1) {
        this.userId = this.therapistUsers[0].id;
      }
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  get computedStartDateFormatted() {
    if (!this.startDate) {
      return null;
    }

    const [year, month, day] = this.startDate.split("-");

    return `${day}/${month}/${year}`;
  }


  get computedEndDateFormatted() {
    if (!this.endDate) {
      return null;
    }

    const [year, month, day] = this.endDate.split("-");

    return `${day}/${month}/${year}`;
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    if (hour === 0 && min === 0) {
      return "";
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  get daysOfWeekOptions() {
    return [1, 2, 3, 4, 5, 6, 7]
      .map(dayOfWeek => ({
        "text": this.$t("day-in-week." + dayOfWeek),
        "value": dayOfWeek
      }));
  }

  public async submitForm() {
    if (this.isLoading) {
      return;
    }

    this.errors = false;

    this.isLoading = true;
    const payload = {
      user_id: this.userId,
      notes: this.notes,
      shift_start_time: this.startTime,
      shift_end_time: this.endTime,
      start_date: this.startDate,
      end_date: this.endDate,
      day_in_week: this.dayInWeek,
      weeks_before_shift_open: this.weeksBeforeShiftOpen,
      recurring_interval: this.recurringInterval,
    };
    const attempt = this.editRecurringShift
      ? RecurringShiftApi.update(this.editRecurringShift.id, payload)
      : RecurringShiftApi.create(payload);

    attempt
      .then(() => {
        this.isLoading = false;

        this.$toastr.s(this.editRecurringShift ? this.$t("recurring_shift_updated") : this.$t("recurring_shift_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public async deleteShift() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await RecurringShiftApi.delete(this.editRecurringShift.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

}
