import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEmpty)?_c('div'):(_vm.isSingleCategory)?_c(VMenu,{attrs:{"offset-x":"","top":"","right":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'VIcon',attrs,false),on),[_vm._v("mdi-auto-fix")])]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.sentences[0].sentence),function(sentence,i){return _c(VListItem,{key:i,staticStyle:{"max-width":"350px","padding-top":"6px","padding-bottom":"6px"},on:{"click":function($event){return _vm.sentenceSelected(sentence.contents)}}},[_vm._v("\n        "+_vm._s(sentence.contents)+"\n      ")])}),1)],1):_c(VMenu,{attrs:{"right":"","top":"","offset-y":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'VIcon',attrs,false),on),[_vm._v("mdi-auto-fix")])]}}]),model:{value:(_vm.sentencesOpen),callback:function ($$v) {_vm.sentencesOpen=$$v},expression:"sentencesOpen"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.sentences),function(category,index){return _c(VMenu,{key:index,attrs:{"offset-x":"","top":"","right":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({},'VListItem',attrs,false),on),[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])]}}],null,true)},[_c(VList,{attrs:{"dense":""}},_vm._l((category.sentence),function(sentence,i){return _c(VListItem,{key:i,staticStyle:{"max-width":"350px","padding-top":"6px","padding-bottom":"6px"},on:{"click":function($event){return _vm.sentenceSelected(sentence.contents)}}},[_vm._v("\n            "+_vm._s(sentence.contents)+"\n          ")])}),1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }