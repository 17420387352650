

















































































































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import { WhatsappChatHistoryApiResponse } from "@/modules/whatsapp/types/whatsapp.type";
import { WHATSAPP_TEMPLATE_QUESTIONNAIRE_ADULT, WHATSAPP_TEMPLATE_QUESTIONNAIRE_CHILD } from "@/modules/whatsapp/constants/whatsapp-templates";
import PatientCreateLetterDialog from "./PatientActionDialog/PatientCreateLetterDialog.vue";
import moment from "moment";
import PatientDrugPrescriptionFormDialog from "./PatientActionDialog/PatientDrugPrescriptionFormDialog.vue";
import PatientXrayReferenceFormDialog from "./PatientActionDialog/PatientXrayReferenceFormDialog.vue";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";
import ShowPatientFileDialog from "@/modules/patient-file/components/patient-file/ShowPatientFileDialog.vue";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import { User } from "@/modules/users/types/user.type";
import SharePatientShareWhatsappDialog from "./SharePatientShareWhatsappDialog.vue";
import ShareSinglePatientFutureAppointmentsWhatsappDialog from "./ShareSinglePatientFutureAppointmentsWhatsappDialog.vue";
import { QuickActionCategory } from "@/modules/settings/types/quick-action-category.type";
import { Form } from "@/modules/form/types/form.type";
import CreateFormDialog from "@/modules/form/components/CreateFormDialog.vue";
import ShareContactsDialog from "@/modules/contacts/components/ShareContactsDialog.vue";
import LabWorkFormDialog from "@/modules/lab-work/components/LabWorkFormDialog.vue";

@Component({
  components: {
    ShareSinglePatientFutureAppointmentsWhatsappDialog,
    PatientCreateLetterDialog,
    PatientDrugPrescriptionFormDialog,
    LabWorkFormDialog,
    ShowPatientFileDialog,
    CreateFormDialog,
    SharePatientShareWhatsappDialog,
    PatientXrayReferenceFormDialog,
    ShareContactsDialog
  },
  filters: {
    age,
    date,
    time
  },
})
export default class PatientQuickActionsButton extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isSmileSupport") isSmileSupport: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/activeForms") forms: Form[];
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/defaultLetterSignature") defaultLetterSignature: string;
  @Getter("auth/isTherapist") isTherapist: boolean;
  @Getter("auth/isLabWorksEnabled") isLabWorksEnabled: boolean;
  @Getter("auth/userEmail") userEmail: string;
  @Getter("auth/user") user: User;
  @Getter("auth/clinicName") clinicName: string;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/isShowingXrayReference") isShowingXrayReference: boolean;
  @Getter("auth/isShowingContactsReference") isShowingContactsReference: boolean;
  @Getter("auth/quickActions") quickActions: QuickActionCategory[];

  public whatsappMessages: WhatsappChatHistoryApiResponse = null;
  public isLoading = false;
  public isLoadingChatHistory = false;
  public showPatientCreateLetterDialog = false;
  public showPatientDrugPrescriptionFormDialog = false;
  public showPatientXrayReferenceFormDialog = false;
  public showPatientDynamicLetterDialog = false;
  public showShareContactsFormDialog = false;
  public showLabWorkFormDialog = false;
  public createLetterTitle = "";
  public createLetterSubject = "";
  public createLetterTo = "";
  public createLetterContent = "";
  public showPatientFile: PatientFile = null;
  public showShareWhatsappDialog = false;
  public showShareFutureAppointmentsWhatsappDialog = false;
  public whatsappShareUrl: string = null;
  public whatsappShareTemplateName: string = null;
  public showCreateFormDialog = false;
  public selectedForm: Form = null;

  public async mounted() {
    await this.fetchChatHistory();
  }

  public async onWhatsappSendImageClicked(imageUrl: string) {
    this.whatsappShareUrl = imageUrl;
    this.whatsappShareTemplateName = null;
    this.showShareWhatsappDialog = true;
  }

  public onWhatsappSendTemplateClicked(templateName: string) {
    this.whatsappShareUrl = null;
    this.whatsappShareTemplateName = templateName;
    this.showShareWhatsappDialog = true;
  }

  public async fetchChatHistory() {
    this.isLoadingChatHistory = true;

    try {
      if (this.isWhatsappBotEnabled) {
        this.whatsappMessages = await WhatsappApi.getMessages(this.patient.phone);
      } else {
        this.whatsappMessages = {
          sentMessages: [],
          receivedMessages: [],
        };
      }
      this.isLoadingChatHistory = false;
    } catch (err) {
      // report sentry?
    }
  }

  public getLastSent(templateName: string, params: string[] = null) {
    if (!this.whatsappMessages) {
      return null;
    }

    const sentDates = this.whatsappMessages.sentMessages
      .filter(({ type, attributes }) => {
        if (type !== templateName) {
          return false;
        }

        if (params === null) {
          return true;
        }

        return attributes && attributes.extra && attributes.extra.params && JSON.stringify(attributes.extra.params.map(({ text }) => (text))) === JSON.stringify(params);
      })
      .map(({ created_at }) => (created_at))
      .sort()
      .reverse();

    return sentDates[0];
  }

  public replaceParams(text: string) {
    const futureAppointments = this.patient.appointments.filter(({ start_date }) => (start_date > moment().endOf("day").format("YYYY-MM-DD HH:mm"))).sort((a, b) => {
      if (a.start_date < b.start_date) return -1;
      if (a.start_date > b.start_date) return 1;
      return 0;
    }).map(({ start_date }) => {
      const dayInWeek = moment(start_date, undefined, "he").format("dddd");
      const date = moment(start_date).format("D/M/YY HH:mm");
      return `- יום ${dayInWeek} ${date}`;
    })
      .join("\n");

    return text.replace(/{patientFirstName}/g, this.patient.first_name)
      .replace(/{patientLastName}/g, this.patient.last_name)
      .replace(/{patientIdNumber}/g, this.patient.id_number ? this.patient.id_number : "")
      .replace(/{today}/g, moment().format("DD-MM-YYYY"))
      .replace(/{patientNextAppointments}/g, futureAppointments)
      .replace(/{patientGenderLabel}/g, this.$t("gender." + (this.patient.gender || "unknown")).toString())
      .replace(/{patientAge}/g, this.patient.birth_date ? age(this.patient.birth_date).toString() : "")
      ;
  }

  public async onSendQuestionnaireChildClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_questionnaire_child_confirm_message", { name: this.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_questionnaire_child_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_questionnaire_child_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.isLoading = true;
      await WhatsappApi.sendQuestionnaireChildMessage(this.patient.phone, this.patient.first_name);
      this.$toastr.s(this.$t("whatsapp_bot_questionnaire_child_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.isLoading = false;
      await this.fetchChatHistory();
    }
  }

  public filterOnlyFuture(appointments: Appointment[]) {
    const now = moment().format("YYYY-MM-DD HH:mm");
    return appointments.filter(({ start_date }) => (start_date > now));
  }

  public onSendFutureAppointmentsWebClicked() {
    const futureAppointments = this.filterOnlyFuture(this.patient.appointments).sort((a, b) => {
      if (a.start_date < b.start_date) return -1;
      if (a.start_date > b.start_date) return 1;
      return 0;
    });

    const futureAppointmentsHebrew: string[] = [];
    const futureAppointmentsRussian: string[] = [];
    futureAppointments.forEach(({ start_date, user_id }) => {
      const dayInWeek = moment(start_date, undefined, "he").format("dddd");
      const dayInWeekRussian = moment(start_date, undefined, "ru").format("dddd")
        .replaceAll("воскресенье", "воскресенье")
        .replaceAll("понедельник", "понедельник")
        .replaceAll("вторник", "вторник")
        .replaceAll("среда", "среду")
        .replaceAll("четверг", "четверг")
        .replaceAll("пятница", "пятницу")
        .replaceAll("суббота", "субботу");

      const date = moment(start_date).format("D/M/YY HH:mm");
      futureAppointmentsHebrew.push(`🔹 יום ${dayInWeek} ${date} ל${this.userName(user_id)}`);
      futureAppointmentsRussian.push(`🔹 в ${dayInWeekRussian} ${date} ${this.userName(user_id)}`);
    });

    const message = this.$store.getters["auth/whatsappWebFutureAppointmentsTemplate"]
      .replaceAll("{patientFirstName}", this.patient.first_name)
      .replaceAll("{clinicName}", this.clinicName)
      .replaceAll("{futureAppointmentsHebrew}", futureAppointmentsHebrew.join("\n"))
      .replaceAll("{futureAppointmentsRussian}", futureAppointmentsRussian.join("\n"));

    this.shareWhatsapp(this.patient.phone, message);
  }

  public fileDeleted() {
    this.$emit("refreshPatientFiles");
  }

  public fileNameUpdated(id: number, newName: string) {
    const file = this.patient.files.find(file => (file.id === id));
    if (file) {
      file.name = newName;
    }
    this.$emit("refreshPatientFiles");
  }

  public async onSendQuestionnaireAdultClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_questionnaire_adult_confirm_message", { name: this.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_questionnaire_adult_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_questionnaire_adult_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.isLoading = true;
      await WhatsappApi.sendQuestionnaireAdultMessage(this.patient.phone, this.patient.first_name);
      this.$toastr.s(this.$t("whatsapp_bot_questionnaire_adult_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.isLoading = false;
      await this.fetchChatHistory();
    }
  }

  public async onSendFastInstructionsEstiClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_fast_instructions_confirm_message", { name: this.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_fast_instructions_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_fast_instructions_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.isLoading = true;
      await WhatsappApi.sendFastInstructionsEsti(this.patient.phone, this.patient.first_name);
      this.$toastr.s(this.$t("whatsapp_bot_fast_instructions_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.isLoading = false;
      await this.fetchChatHistory();
    }
  }

  public async onSendFastInstructionsKidoClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_fast_instructions_confirm_message", { name: this.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_fast_instructions_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_fast_instructions_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.isLoading = true;
      await WhatsappApi.sendFastInstructionsKido(this.patient.phone, this.patient.first_name);
      this.$toastr.s(this.$t("whatsapp_bot_fast_instructions_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.isLoading = false;
      await this.fetchChatHistory();
    }
  }

  public onCreateFormClicked(form: Form) {
    this.selectedForm = form;
    this.showCreateFormDialog = true;
  }

  public onCloseFormClicked() {
    this.selectedForm = null;
    this.showCreateFormDialog = false;
  }

  public async onSendFutureAppointmentsClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_future_appointments_confirm_message", { name: this.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_future_appointments_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_future_appointments_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.isLoading = true;
      await WhatsappApi.sendFutureReminders(this.patient.phone);
      this.$toastr.s(this.$t("whatsapp_bot_future_appointments_sent"));
    } catch (err) {
      this.$toastr.e(err && err.smileMessage ? err.smileMessage : err);
    } finally {
      this.isLoading = false;
    }
  }

  public onDrugPrescriptionCreated(patientFile: PatientFile) {
    this.showPatientFile = patientFile;
  }

  public closeShowPatientFileDialog() {
    this.showPatientFile = null;
  }

  public onXrayReferenceClicked() {
    this.showPatientXrayReferenceFormDialog = true;
  }

  public onDrugPrescriptionClicked() {
    this.showPatientDrugPrescriptionFormDialog = true;
  }

  public onShareContactsClicked() {
    this.showShareContactsFormDialog = true;
  }

  public onExpertReferenceClicked() {
    this.showPatientCreateLetterDialog = true;
  }

  public openCreateLetterDialogSharedContacts(createLetterTitle: string, createLetterSubject: string, createLetterTo: string, createLetterContent: string) {
    this.openCreateLetter(createLetterTitle, createLetterSubject, createLetterTo, createLetterContent);
    this.showShareContactsFormDialog = false;
  }

  public openCreateLetter(createLetterTitle: string, createLetterSubject: string, createLetterTo: string, createLetterContent: string) {
    this.createLetterTitle = createLetterTitle;
    this.createLetterSubject = createLetterSubject;
    this.createLetterTo = createLetterTo;
    this.createLetterContent = createLetterContent;

    this.showPatientDynamicLetterDialog = true;
  }

  get whatsappTemplateNameQuestionnaireAdult() {
    return WHATSAPP_TEMPLATE_QUESTIONNAIRE_ADULT;
  }

  get whatsappTemplateNameQuestionnaireChild() {
    return WHATSAPP_TEMPLATE_QUESTIONNAIRE_CHILD;
  }

}
