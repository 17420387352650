import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"manage-sentences",attrs:{"elevation":"3","fluid":""}},[_c('SettingsHeader',{attrs:{"title":_vm.$t('page_title'),"icon":'mdi-cog'}}),_c(VTabs,{staticClass:"quick-action-nav",attrs:{"color":"cyan darken-4"}},[_c(VTab,{attrs:{"to":{ name: _vm.manageQuickActionCatagoriesName }}},[_vm._v(_vm._s(_vm.$t('categories')))]),_c(VTab,{attrs:{"to":{
        name: _vm.manageTemplateLettersPageName, params: {
          categoryId: _vm.quickCategoryId,
        }
      }}},[_vm._v(_vm._s(_vm.$t('template_letters'))+"\n      ")])],1),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }