import { Treatment } from "../types/treatment.type";

export function calculateTreatmentPrice(
  treatment: Treatment,
  quantity: number,
  userId: number,
  priceListId: number
) {
  let price: number;
  if (
    userId &&
    treatment.attributes &&
    treatment.attributes.userPrice &&
    treatment.attributes.userPrice[userId] !== undefined
  ) {
    price = +treatment.attributes.userPrice[userId] * +quantity;
  } else if (
    priceListId &&
    treatment.attributes &&
    treatment.attributes.priceList &&
    treatment.attributes.priceList[priceListId] !== undefined
  ) {
    price = +treatment.attributes.priceList[priceListId] * +quantity;
  } else {
    price = +treatment.price * +quantity;
  }
  return price;
}
