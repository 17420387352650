export enum PatientFileApiRoutes {
  getAll = "/patient-file",
  create = "/patient-file",
  update = "/patient-file/{id}",
  delete = "/patient-file/{id}",
}

export enum PatientFileApiRouteParameters {
  id = "{id}",
}

export enum PatientFolderApiRoutes {
  export = "/patient-folder-export",
  create = "/patient-folder",
  update = "/patient-folder/{id}",
  delete = "/patient-folder/{id}",
}

export enum PatientFolderApiRouteParameters {
  id = "{id}",
}
