import moment from "moment";
import { TaskApiResponse } from "../types/task.type";

export const prepareTaskResponse = (
  userId: number,
  tasksData: TaskApiResponse
) => {
  const today = moment();
  const assignedFutureTasks = tasksData.tasks
    .filter(({ assignee_user_id }) => assignee_user_id === userId)
    .filter(({ done_at: doneAt }) => !doneAt)
    .filter(({ task_date: taskDate }) =>
      moment(taskDate).isAfter(today, "day")
    );

  const createdFutureTasks = tasksData.tasks
    .filter(({ creator_user_id }) => creator_user_id === userId)
    .filter(({ done_at: doneAt }) => !doneAt)
    .filter(({ task_date: taskDate }) =>
      moment(taskDate).isAfter(today, "day")
    );

  const assignedTodoTasks = tasksData.tasks
    .filter(({ assignee_user_id }) => assignee_user_id === userId)
    .filter(({ done_at: doneAt }) => !doneAt)
    .filter(({ task_date: taskDate }) =>
      moment(taskDate).isSameOrBefore(today, "day")
    );

  const createdTodoTasks = tasksData.tasks
    .filter(({ creator_user_id }) => creator_user_id === userId)
    .filter(({ done_at: doneAt }) => !doneAt)
    .filter(({ task_date: taskDate }) =>
      moment(taskDate).isSameOrBefore(today, "day")
    );

  const assignedDoneTasks = tasksData.tasks
    .filter(({ assignee_user_id }) => assignee_user_id === userId)
    .filter(({ done_at: doneAt }) => doneAt);

  const createdDoneTasks = tasksData.tasks
    .filter(({ creator_user_id }) => creator_user_id === userId)
    .filter(({ done_at: doneAt }) => doneAt);

  const assignedTaskRecurrings = tasksData.taskRecurrings.filter(
    ({ assignee_user_id }) => assignee_user_id === userId
  );

  const createdTaskRecurrings = tasksData.taskRecurrings.filter(
    ({ creator_user_id }) => creator_user_id === userId
  );

  return {
    assignedFutureTasks,
    createdFutureTasks,
    assignedTodoTasks,
    createdTodoTasks,
    assignedDoneTasks,
    createdDoneTasks,
    assignedTaskRecurrings,
    createdTaskRecurrings,
  };
};
