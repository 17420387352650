import Vue from "vue";
import Vuex from "vuex";
// import auth from './modules/auth'
import VuexPersistence from "vuex-persist";
import authStore from "@/modules/auth/store";
import whatsappStore from "@/modules/whatsapp/store";
import taskStore from "@/modules/task/store";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [new VuexPersistence().plugin],
  modules: {
    auth: authStore,
    whatsapp: whatsappStore,
    task: taskStore,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  }
});
