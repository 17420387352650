export enum ShiftApiRoutes {
  getAll = "/shift",
  createShift = "/shift",
  updateShift = "/shift/{id}",
  deleteShift = "/shift/{id}",
}

export enum ShiftApiRouteParameters {
  id = "{id}",
}

export enum RecurringShiftApiRoutes {
  getAll = "/recurring-shift",
  createRecurringShift = "/recurring-shift",
  updateRecurringShift = "/recurring-shift/{id}",
  deleteRecurringShift = "/recurring-shift/{id}",
}

export enum RecurringShiftApiRouteParameters {
  id = "{id}",
}
