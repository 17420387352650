import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import {
  TaskApiRouteParameters,
  TaskApiRoutes,
  TaskRecurringApiRouteParameters,
  TaskRecurringApiRoutes,
} from "@/modules/task/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { Task, TaskApiResponse } from "../types/task.type";

interface TaskPayload {
  creator_user_id: number;
  assignee_user_id: number;
  patient_id: number;
  urgency: number;
  notes: string;
  task_date: string;
}

interface TaskRecurringPayload {
  creator_user_id: number;
  assignee_user_id: number;
  patient_id: number;
  urgency: number;
  recurring_interval: number;
  recurring_every: string;
  notes: string;
  start_date: string;
}

class TaskApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(userId: number, activeRequest?: CancelTokenSource) {
    try {
      const { data }: ApiResponse<TaskApiResponse> = await this.httpClient.get(
        TaskApiRoutes.getAll,
        {
          params: {
            user_id: userId,
          },
          cancelToken: activeRequest ? activeRequest.token : undefined,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async markDone(taskId: number) {
    try {
      const { data }: ApiResponse<Task> = await this.httpClient.post(
        TaskApiRoutes.markDone,
        {
          task_id: taskId,
          is_done: true,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async create(taskPayload: TaskPayload) {
    try {
      const { data }: ApiResponse<Task> = await this.httpClient.post(
        TaskApiRoutes.create,
        taskPayload
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async update(taskId: number, taskPayload: TaskPayload) {
    try {
      const { data }: ApiResponse<Task> = await this.httpClient.put(
        TaskApiRoutes.update.replace(
          TaskApiRouteParameters.id,
          taskId.toString()
        ),
        taskPayload
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async createRecurring(taskRecurringPayload: TaskRecurringPayload) {
    try {
      const { data }: ApiResponse<Task> = await this.httpClient.post(
        TaskRecurringApiRoutes.create,
        taskRecurringPayload
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async updateRecurring(
    taskRecurringId: number,
    taskRecurringPayload: TaskRecurringPayload
  ) {
    try {
      const { data }: ApiResponse<Task> = await this.httpClient.put(
        TaskRecurringApiRoutes.update.replace(
          TaskRecurringApiRouteParameters.id,
          taskRecurringId.toString()
        ),
        taskRecurringPayload
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        TaskApiRoutes.delete.replace(TaskApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async deleteRecurring(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        TaskRecurringApiRoutes.delete.replace(
          TaskRecurringApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new TaskApi();
