import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";
import {
  PinkasApiRouteParameters,
  PinkasApiRoutes,
} from "../constans/api-routes";
import { Pinkas, PinkasMandatory } from "../types/pinkas.type";

class PinkasApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<Pinkas[]> {
    try {
      const response = await this.httpClient.get(PinkasApiRoutes.getAll);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: PinkasMandatory): Promise<Pinkas> {
    try {
      const response = await this.httpClient.post(
        PinkasApiRoutes.create,
        payload
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, payload: PinkasMandatory): Promise<PatientFile> {
    try {
      const response = await this.httpClient.put(
        PinkasApiRoutes.update.replace(
          PinkasApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PinkasApiRoutes.delete.replace(PinkasApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PinkasApi();
