


















import { Component, Vue, Watch } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import ReportApi from "../../api/report.api";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";

@Component({
  components: {
    Chart,
  }
})
export default class YearlyAppointmentsChart extends Vue {
  private activeRequest: CancelTokenSource = null;
  public year: number = null;
  public isLoading = false;
  public yearlyAppointmentsData: {
    [month: string]: {
      first_appointments_count: number;
      next_appointments_count: number;
    };
  } = null;

  @Watch("year")
  onFiltersChanged() {
    this.reload();
  }

  public async reload() {
    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();
    this.isLoading = true;
    this.yearlyAppointmentsData = null;

    try {
      this.yearlyAppointmentsData = await ReportApi.getYearlyAppointmentsReport(this.year, this.activeRequest);
      this.isLoading = false;
      this.$nextTick(() => {
        if (this.$refs.chartComponent && (this.$refs.chartComponent as any).chart && (this.$refs.chartComponent as any).chart.reflow) {
          (this.$refs.chartComponent as any).chart.reflow();
        }
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }

  }

  public async mounted() {
    this.year = parseInt(moment().format("YYYY"));
    await this.reload();
  }

  get yearOptions() {
    const options: { text: string; value: number }[] = [];

    for (let i = 1; i >= -4; i--) {
      const year = parseInt(moment().add(i, "years").format("YYYY"));
      options.push({
        text: year.toString(),
        value: year,
      });
    }

    return options;
  }

  get chartOptions() {
    // eslint-disable-next-line  @typescript-eslint/no-this-alias
    const that = this;
    return {
      chart: {
        type: "column"
      },
      credits: {
        enabled: false
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: this.xAxisCategories,
        labels: {
          useHTML: true,
        }
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return (this as any).cumulative.toLocaleString();
          },
        }
      },
      legend: {
        borderColor: "#CCC",
        borderWidth: 1,
        useHTML: true,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormatter: function () { return (this as any).series.name + ": " + ((this as any).y as number).toLocaleString() + "<br>" + that.$t("chart_total") + ": " + (this as any).stackTotal.toLocaleString(); },
        useHTML: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            formatter: function () {
              return (this as any).y.toLocaleString();
            },
          }
        }
      },
      series: [{
        color: "#083685",
        name: this.$t("chart_first_appointments"),
        data: this.firstSeriesData,
      }, {
        color: "#4a85e8",
        name: this.$t("chart_next_appointments"),
        data: this.nextSeriesData,
      }]
    };
  }

  get firstSeriesData() {
    return Object.values(this.yearlyAppointmentsData).map(({ first_appointments_count }) => (first_appointments_count));
  }

  get nextSeriesData() {
    return Object.values(this.yearlyAppointmentsData).map(({ next_appointments_count }) => (next_appointments_count));
  }

  get colors() {
    const colors: string[] = [];

    Object.keys(this.yearlyAppointmentsData).forEach(month => {
      if (moment(month).endOf("month").isBefore(moment())) {
        colors.push("#4a85e8");
      } else if (moment(month).isBefore(moment())) {
        colors.push("#093685");
      } else {
        colors.push("#bed6ff");
      }
    });

    return colors;
  }

  get xAxisCategories() {
    const categories: string[] = [];
    Object.keys(this.yearlyAppointmentsData).forEach(yearMonth => {
      const [year, month] = yearMonth.split("-");
      categories.push(this.$t(`months.${parseInt(month) - 1}`) + " " + year.slice(-2));
    });
    return categories;
  }
}
