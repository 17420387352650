

























































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SupplierApi from "../api/supplier.api";
import { Supplier } from "../types/supplier.type";
import { Getter } from "vuex-class";

@Component({
  components: {
  },
})
export default class SupplierFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public editSupplier: Supplier;

  @Getter("auth/isRtl") isRtl: boolean;
  public isFormLoading = false;
  public isLoadingDelete = false;
  public name = "";
  public address = "";
  public city = "";
  public zip_code = "";
  public email = "";
  public phone = "";
  public notes = "";
  public showErrors = false;

  public mounted() {
    if (this.editSupplier) {
      this.name = this.editSupplier.name;
      this.address = this.editSupplier.address;
      this.city = this.editSupplier.city;
      this.zip_code = this.editSupplier.zip_code;
      this.email = this.editSupplier.email;
      this.phone = this.editSupplier.phone;
      this.notes = this.editSupplier.notes;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onDeleteClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    this.isLoadingDelete = true;
    try {
      await SupplierApi.delete(this.editSupplier.id);
      this.$emit("reload");
      this.$toastr.s(this.$t("deleted_successfully"));
      this.isLoadingDelete = false;
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingDelete = false;
    }
  }

  public async submitForm() {
    this.showErrors = true;

    if (!this.name) {
      return;
    }

    this.isFormLoading = true;

    const payload = {
      name: this.name,
      address: this.address,
      city: this.city,
      zip_code: this.zip_code,
      email: this.email,
      phone: this.phone,
      notes: this.notes,
    };

    try {
      this.editSupplier
        ? await SupplierApi.update(this.editSupplier.id, payload)
        : await SupplierApi.create(payload);

      this.$toastr.s(this.$t(this.editSupplier ? "supplier_updated" : "supplier_created"));
      this.isFormLoading = false;
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.isFormLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

}
