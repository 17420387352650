<template>
  <VDialog @input="closeDialog()" :value="true" max-width="360">
    <VCard outlined raised elevation="4">
      <VCardTitle class="justify-space-between">
        {{ $t(editSentenceCategory ? 'edit_title' : 'add_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <smile-error-message :errors="errors"></smile-error-message>
        <VContainer>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="name" :label="$t('name')" color="cyan darken-1" type="text" />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VSelect :items="displayTypeOptions" hide-details v-model="displayType" :label="$t('display_type')"
                color="cyan darken-1" item-color="cyan" type="text">
              </VSelect>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="order" :label="$t('order')" color="cyan darken-1" type="text" />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VBtn color="red" small text type="button" v-if="editSentenceCategory" @click="deleteButtonClicked()"
          :loading="deleteLoading">
          {{ $t('delete_btn') }}
        </VBtn>
        <VSpacer />
        <VBtn color="cyan" :loading="loading" type="submit" @click="submitForm()">
          {{ $t('submit') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import SentenceCategoryApi from "../../../../serivce/api/sentence-category-api";

export default {
  props: ["editSentenceCategory"],
  data() {
    return {
      errors: false,
      displayType: this.editSentenceCategory ? this.editSentenceCategory.display_type : "patientTreatment",
      name: this.editSentenceCategory ? this.editSentenceCategory.name : "",
      order: this.editSentenceCategory ? this.editSentenceCategory.order : "",
      loading: false,
      deleteLoading: false,
    };
  },
  computed: {
    displayTypeOptions() {
      return [
        {
          value: "patientTreatment",
          text: this.$t("display_type_patient_treatment"),
        },
        {
          value: "patientLetter",
          text: this.$t("display_type_patient_letter"),
        },
        {
          value: "appointment",
          text: this.$t("display_type_appointment"),
        },
        {
          value: "reminderNote",
          text: this.$t("display_type_reminder_note"),
        },
        {
          value: "calendarNote",
          text: this.$t("display_type_calendar_note"),
        },
      ];
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitForm() {
      if (this.loading) return;

      this.loading = true;
      this.errors = false;


      const attempt = this.editSentenceCategory
        ? SentenceCategoryApi.update(this.editSentenceCategory.id, this.name, this.order, this.displayType)
        : SentenceCategoryApi.create(this.name, this.order, this.displayType);

      attempt
        .then(() => {
          this.loading = false;

          this.$toastr.s(this.editSentenceCategory ? this.$t("type_updated") : this.$t("type_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
    },
    async deleteButtonClicked() {

      const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });
      if (!res) {
        return;
      }

      this.deleteLoading = true;
      try {
        await SentenceCategoryApi.delete(this.editSentenceCategory.id);
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("reload");
        this.closeDialog();
      } catch (err) {
        this.deleteLoading = false;
        this.$toastr.e(this.$t("delete_error"));
        this.$emit("reload");
      }
      //   deleteSentenceCategory(sentenceCategory) {
      //   this.deleteErrors = null;
      //   this.$confirm(this.$t("delete_confirm_message"), {
      //     buttonTrueColor: "red",
      //     buttonTrueText: this.$t("delete_ok_btn"),
      //     buttonFalseText: this.$t("delete_cancel_btn")
      //   })
      //     .then(res => {
      //       if (res) {
      //         this.loading = true;
      //         SentenceCategoryApi.delete(sentenceCategory.id)
      //           .then(() => {
      //             this.$toastr.s(this.$t("deleted_toastr"));
      //             this.reload();
      //             this.$emit("categoriesChanged");
      //           })
      //           .catch(err => {
      //             if (err.response && err.response.data && err.response.data.errors) {
      //               this.deleteErrors = err.response.data.errors;
      //             } else {
      //               this.deleteErrors = this.$t("delete_error");
      //             }

      //             this.reload();
      //           });
      //       }
      //     });

      //   this.$vuetify.rtl = this.$store.getters["auth/isRtl"];
      // }

    }
  }
};
</script>

<i18n>
{
  "he": {
    "name": "קטגוריות משפטים",
    "order": "סדר",
    "display_type": "אזור תצוגה",
    "delete_btn": "מחיקה",
    "delete_confirm_message": "האם למחוק את הקטגוריה? פעולה זו תמחק את כל המשפטים בקטגוריה זו",
    "delete_cancel_btn": "ביטול",
    "delete_ok_btn": "מחיקה",
    "deleted_toastr": "קטגוריה נמחקה בהצלחה",
    "delete_error": "שגיאה במחיקה. יש לנסות שוב",
    "edit_title": "עריכת קטגוריה",
    "add_title": "הוספת קטגוריה",
    "display_type_patient_treatment": "טופס תיעוד טיפול",
    "display_type_appointment": "הערות לפגישה עם מטופל",
    "display_type_reminder_note": "הערות לתזכורת למטופל",
    "display_type_patient_letter": "הפקת מכתב",
    "display_type_calendar_note": "הערות ביומן",
    "submit": "שמירה",
    "type_created": "קטגוריה נוצרה בהצלחה",
    "type_updated": "קטגוריה עודכנה בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "name": "Sentence Categories",
    "order": "Order",
    "display_type": "Display Area",
    "delete_btn": "Delete",
    "delete_confirm_message": "Delete Category? This will delete all sentences in this category",
    "delete_cancel_btn": "Cancel",
    "delete_ok_btn": "Delete",
    "deleted_toastr": "Job deleted successfully",
    "delete_error": "Error deleting data, try again",
    "edit_title": "Edit Sentence Category",
    "add_title": "Add Category",
    "display_type_patient_treatment": "Treatment Document Form",
    "display_type_appointment": "Appointment Notes",
    "display_type_reminder_note": "Patient Reminder Notes",
    "display_type_patient_letter": "Create Letter",
    "display_type_calendar_note": "Calendar Notes",
    "submit": "Submit",
    "type_created": "Sentence category created successfully",
    "type_updated": "Sentence category updated successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
