import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  DrugApiRouteParameters,
  DrugApiRoutes,
} from "@/modules/settings/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { Drug } from "../types/drug.type";

class DrugApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<Drug[]> {
    try {
      const response: ApiResponse<Drug[]> = await this.httpClient.get(
        DrugApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    quantityPerTaking: string,
    quantityPerDay: string,
    daysDuration: string,
    totalQuantity: string,
    internalNote: string,
    notes: string
  ): Promise<Drug> {
    try {
      const response: ApiResponse<Drug> = await this.httpClient.put(
        DrugApiRoutes.update.replace(DrugApiRouteParameters.id, id.toString()),
        {
          name,
          quantityPerTaking,
          quantityPerDay,
          daysDuration,
          totalQuantity,
          internalNote,
          notes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    name: string,
    quantityPerTaking: string,
    quantityPerDay: string,
    daysDuration: string,
    totalQuantity: string,
    internalNote: string,
    notes: string
  ): Promise<Drug> {
    try {
      const response: ApiResponse<Drug> = await this.httpClient.post(
        DrugApiRoutes.create,
        {
          name,
          quantityPerTaking,
          quantityPerDay,
          daysDuration,
          totalQuantity,
          internalNote,
          notes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        DrugApiRoutes.delete.replace(DrugApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new DrugApi();
