import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { Plan } from "@/modules/treatment/types/plan.type";
import { ApiResponse } from "@/core/http-client/http-client.types";
import {
  PlanApiRouteParameters,
  PlanApiRoutes,
} from "@/modules/treatment/constants/api-routes";
import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";

class PlanApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async create(patientId: number, attributes: any): Promise<Plan> {
    try {
      const response: ApiResponse<Plan> = await this.httpClient.post(
        PlanApiRoutes.create,
        {
          patient_id: patientId,
          attributes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async getUrl(planId: number): Promise<string> {
    const { data }: ApiResponse<string> = await this.httpClient.post(
      PlanApiRoutes.share,
      {
        plan_id: planId,
      }
    );

    return data;
  }

  async markAllTreatmentsDone(planId: number): Promise<PatientTreatment[]> {
    try {
      const response: ApiResponse<PatientTreatment[]> = await this.httpClient.post(
        PlanApiRoutes.markAllTreatmentsDone,
        {
          plan_id: planId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async getOne(id: number): Promise<Plan> {
    try {
      const response = await this.httpClient.get(
        PlanApiRoutes.getOne.replace(PlanApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    attributes: any,
    is_completed: boolean,
    notes: string,
    userId: number,
    createdAt: string
  ): Promise<Plan> {
    try {
      const response: ApiResponse<Plan> = await this.httpClient.put(
        PlanApiRoutes.update.replace(PlanApiRouteParameters.id, id.toString()),
        {
          name,
          attributes,
          is_completed,
          notes,
          user_id: userId,
          created_at: createdAt,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PlanApiRoutes.delete.replace(PlanApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async sendEmailFile(sendEmailTo: string, patientId: number, planId: number) {
    try {
      const response = await this.httpClient.post(PlanApiRoutes.sendEmail, {
        sendEmailTo,
        patientId,
        planId,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PlanApi();
