import { render, staticRenderFns } from "./ReportDocumentNumberDialog.vue?vue&type=template&id=3881b6b4&"
import script from "./ReportDocumentNumberDialog.vue?vue&type=script&lang=ts&"
export * from "./ReportDocumentNumberDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ReportDocumentNumberDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/report-document-number-dialog.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Freports%2Fcomponents%2FReportDocumentNumberDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports