
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";
import ShowPatientFileDialog from "@/modules/patient-file/components/patient-file/ShowPatientFileDialog.vue";
import PatientFileListItem from "@/modules/patient-file/components/patient-file/PatientFileListItem.vue";
import { Patient } from "@/modules/patient/types/patient.type";
import PatientFileApi from "../../api/patient-file.api";
import DiagnocatApi from "@/modules/diagnocat/api/diagnocat.api";
import { Getter } from "vuex-class";

@Component({
  components: {
    PatientFileListItem,
    ShowPatientFileDialog,
  }
})
export default class DisplayPatientFiles extends Vue {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isImagingScanoraEnabled") isImagingScanoraEnabled: boolean;
  @Getter("auth/isImagingRomexisEnabled") isImagingRomexisEnabled: boolean;
  @Getter("auth/isImagingDimaxisEnabled") isImagingDimaxisEnabled: boolean;
  @Getter("auth/isImagingIrysEnabled") isImagingIrysEnabled: boolean;
  @Getter("auth/isImagingArchimedEnabled") isImagingArchimedEnabled: boolean;
  @Getter("auth/isImagingVistaSoftEnabled") isImagingVistaSoftEnabled: boolean;
  @Getter("auth/isImagingMediadentEnabled") isImagingMediadentEnabled: boolean;
  @Getter("auth/isDiagnocatEnabled") isDiagnocatEnabled: boolean;

  $refs!: {
    uploadInput: HTMLFormElement;
  }

  public showPatientFile: PatientFile = null;
  public navigateImages: PatientFile[] = [];
  public showingFilesCount = 6;
  public uploadLoading = false;
  public isDragOver = false;
  public isScanoraLoading = false;
  public isRomexisLoading = false;
  public isDimaxisLoading = false;
  public isIrysLoading = false;
  public isArchimedLoading = false;
  public isVistaSoftLoading = false;
  public isMediadentLoading = false;
  public isDiagnocatLoading = false;

  get patientFiles(): PatientFile[] {
    return this.patient.files;
  }

  public fileDeleted() {
    this.$emit("refreshPatientFiles");
  }

  public onFileSelected(file: PatientFile) {
    this.showPatientFile = file;
  }

  public handleLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isDragOver = false;
  }
  public handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      this.uploadFiles(files);
    }
    this.isDragOver = false;
  }

  public addDragDropListeners() {
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      this.isDragOver = true;
    });
    document.addEventListener("dragleave", this.handleLeave);
    document.addEventListener("drop", this.handleDrop);
  }

  public removeDragDropListeners() {
    document.removeEventListener("dragover", (event) => {
      event.preventDefault();
      this.isDragOver = true;
    });
    document.removeEventListener("dragleave", this.handleLeave);
    document.removeEventListener("drop", this.handleDrop);
  }
  mounted() {
    this.addDragDropListeners();
  }

  beforeDestroy() {
    this.removeDragDropListeners();
  }

  public async onOpenIrysClicked() {
    try {
      if (!this.patient.id_number) {
        const res = await this.$confirm(this.$t("missing_id_number_confirm_message").toString(), {
          buttonTrueColor: "pink",
          buttonTrueText: this.$t("missing_id_number_ok_btn").toString(),
          buttonFalseText: this.$t("missing_id_number_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }
      }

      const externalId = this.patient.id_number;
      this.isIrysLoading = true;
      const newLocation = new URL(`smile-app://irys/open/${externalId ? externalId : ""}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isIrysLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenRomexisClicked() {
    try {
      if ((!this.patient.id_number || this.patient.id_number.includes(" ")) && !this.$store.getters["auth/isImagingRomexisExternalIdPatientId"]) {
        const res = await this.$confirm(this.$t("missing_id_number_confirm_message").toString(), {
          buttonTrueColor: "pink",
          buttonTrueText: this.$t("missing_id_number_ok_btn").toString(),
          buttonFalseText: this.$t("missing_id_number_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }
      }

      const externalId = this.$store.getters["auth/isImagingRomexisExternalIdPatientId"]
        ? this.patient.id
        : this.patient.id_number;

      const firstName = this.patient.first_name.replace(/[(|)|"]/g, "");
      const lastName = this.patient.last_name.replace(/[(|)|"]/g, "");
      this.isRomexisLoading = true;
      const newLocation = new URL(`smile-app://romexis/open/${externalId ? externalId : ""}/${firstName}/${lastName}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isRomexisLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenDimaxisClicked() {
    try {
      if ((!this.patient.id_number || this.patient.id_number.includes(" ")) && !this.$store.getters["auth/isImagingDimaxisExternalIdPatientId"]) {
        const res = await this.$confirm(this.$t("missing_id_number_confirm_message").toString(), {
          buttonTrueColor: "pink",
          buttonTrueText: this.$t("missing_id_number_ok_btn").toString(),
          buttonFalseText: this.$t("missing_id_number_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }
      }

      const externalId = this.$store.getters["auth/isImagingDimaxisExternalIdPatientId"]
        ? this.patient.id
        : this.patient.id_number;

      this.isDimaxisLoading = true;
      const newLocation = new URL(`smile-app://romexis/open/${externalId ? externalId : ""}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isDimaxisLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenVistaSoftClicked() {

    try {
      if (!this.patient.id_number) {
        this.$toastr.e(this.$t("diagnocat_missing_id_number"));
        return;
      }

      this.isVistaSoftLoading = true;
      const newLocation = new URL(`smile-app://vistasoft/open/${this.patient.id_number}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isVistaSoftLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenScanoraClicked() {

    try {
      if (!this.patient.id_number) {
        this.$toastr.e(this.$t("diagnocat_missing_id_number"));
        return;
      }

      this.isScanoraLoading = true;
      const newLocation = new URL(`smile-app://scanora/open/${this.patient.id_number}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isScanoraLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenArchimedClicked() {
    try {
      if (!this.patient.id_number) {
        const res = await this.$confirm(this.$t("missing_id_number_confirm_message").toString(), {
          buttonTrueColor: "pink",
          buttonTrueText: this.$t("missing_id_number_ok_btn").toString(),
          buttonFalseText: this.$t("missing_id_number_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }
      }

      this.isArchimedLoading = true;
      const newLocation = new URL(`smile-app://archimed/open/${this.patient.id_number}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isArchimedLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onOpenDiagnocatClicked() {
    if (!this.patient.id_number) {
      this.$toastr.e(this.$t("diagnocat_missing_id_number"));
      return;
    }
    if (!this.patient.birth_date) {
      this.$toastr.e(this.$t("diagnocat_missing_birth_date"));
      return;
    }

    try {
      this.isDiagnocatLoading = true;
      const diagnocatUrl = await DiagnocatApi.getPatientUrl(this.patient.id);
      window.open(diagnocatUrl, "_tab");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    } finally {
      this.isDiagnocatLoading = false;
    }
  }

  public async onOpenMediadentClicked() {
    try {
      if (!this.patient.id_number) {
        const res = await this.$confirm(this.$t("missing_id_number_confirm_message").toString(), {
          buttonTrueColor: "pink",
          buttonTrueText: this.$t("missing_id_number_ok_btn").toString(),
          buttonFalseText: this.$t("missing_id_number_cancel_btn").toString(),
        });

        if (!res) {
          return;
        }
      }

      this.isMediadentLoading = true;
      const newLocation = new URL(`smile-app://mediadent/open/${this.patient.id_number}/${this.patient.first_name}/${this.patient.last_name}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        this.isMediadentLoading = false;
      }, 1500);

    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public fileNameUpdated(id: number, newName: string) {
    const file = this.patient.files.find(file => (file.id === id));
    if (file) {
      file.name = newName;
    }
  }

  public openFile(file: PatientFile) {
    this.showPatientFile = file;
    if (file.type === "photo") {
      this.navigateImages = this.patientFiles.filter(({ type }) => (type === "photo"));
    }
  }

  public closeShowPatientFileDialog() {
    this.showPatientFile = null;
    this.navigateImages = [];
  }

  public uploadFileClicked() {
    this.$refs.uploadInput.click();
  }

  public async uploadFiles(fileList) {
    if (!fileList.length) return;

    this.uploadLoading = true;
    const promises = [];
    for (let i = 0; i < fileList.length; i++) {
      const formData = new FormData();
      formData.append("file", fileList[i], fileList[i].name);
      formData.append("patient_id", this.patient.id.toString());

      const uploadPromise = PatientFileApi.create(formData)
        .then(() => {
          this.$toastr.s(this.$t("uploaded_successfully"));
        });
      promises.push(uploadPromise);
    }

    Promise.allSettled(promises).then(() => {
      this.uploadLoading = false;
      this.$emit("refreshPatientFiles");
      if (this.$refs && this.$refs.uploadInput && this.$refs.uploadInput.value) {
        this.$refs.uploadInput.value = "";
      }
    });
  }

  public onOpenPatientFileExplorerClicked() {
    this.$emit("openPatientFileExplorer");
  }
}
