import { User } from "@/modules/users/types/user.type";
import { AuthState } from "../types/auth.type";

const authGetters = {
  isEditEnabled: (state: AuthState) => {
    if (state.settings && state.settings.syncFromRapidEnabled) {
      const allowed = ["support@smile-app.co.il"];

      return state.user ? allowed.includes(state.user.email) : null;
    }

    return true;
  },

  isAccountant: (state: AuthState) => {
    return state.user && state.user.type === "accountant";
  },

  shouldDisplaySentEmailsReport: (state: AuthState) => {
    return state.user && state.user.type !== "accountant";
  },

  isSmileSupport: (state: AuthState) => {
    const allowed = ["support@smile-app.co.il"];

    return state.user ? allowed.includes(state.user.email) : null;
  },

  userColor: (state: AuthState) => {
    return state.user && state.user.color ? state.user.color : "cyan";
  },

  shouldDisplayUnreadWhatsapp: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.isWhatsappBotEnabled &&
      state.user &&
      (state.user.type === "assistant" || state.user.user_id_for_wa_messages)
    );
  },

  maamRate: (state: AuthState) => {
    return state.settings && state.settings.maamRate;
  },

  shouldDisplayTodoTasks: (state: AuthState) => {
    return state.user && state.user.is_tasks_enabled;
  },

  whatsappWebShareLinkTemplate: (state: AuthState) => {
    return state.settings && state.settings.whatsappWebShareLinkTemplate;
  },

  isShowingPaymentPinkasName: (state: AuthState) => {
    return state.settings && state.settings.isShowingPaymentPinkasName;
  },

  letterFromPatientTreatmentContentTemplate: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.createLetterFromPatientTreatmentContentTemplate
    );
  },

  shouldDisplayFinanceReports: (state: AuthState) => {
    return state.user && state.user.is_finance_reports_enabled;
  },

  shouldDisplayKupaReport: (state: AuthState) => {
    return state.user && state.user.is_kupa_report_enabled;
  },

  isTimeClockEditEnabled: (state: AuthState) => {
    return state.user && state.user.is_time_clock_edit_enabled;
  },

  shouldDisplayManageShifts: (state: AuthState) => {
    if (!state.user) {
      return false;
    }
    return state.user.is_admin || state.user.is_manage_shifts_enabled;
  },

  isTimeEntryRequired: (state: AuthState) => {
    return state.user && state.user.is_time_entry_required;
  },

  isAllowedSettingsPage: (state: AuthState) => {
    if (!state.user) {
      return false;
    }
    return state.user.is_admin || state.user.is_manage_shifts_enabled;
  },

  token: (state: AuthState) => {
    return state.token;
  },

  isAdmin: (state: AuthState) => {
    return state.user ? state.user.is_admin : false;
  },

  fullName: (state: AuthState) => {
    return state.user ? state.user.name : null;
  },

  clinicName: (state: AuthState) => {
    return state.settings && state.settings.clinicName;
  },

  clinicLogoUrl: (state: AuthState) => {
    return state.settings && state.settings.clinicLogoUrl;
  },

  isAlwaysDisplayAllCalendars: (state: AuthState) => {
    return state.settings && state.settings.isAlwaysDisplayAllCalendars;
  },

  isIdNumberInputNumeric: (state: AuthState) => {
    return state.settings && state.settings.isIdNumberInputNumeric;
  },

  waAllowFreeText: (state: AuthState) => {
    return state.settings && state.settings.waAllowFreeText;
  },

  creditCardDriver: (state: AuthState) => {
    return state.settings && state.settings.creditCardDriver;
  },

  isExpensesEnabled: (state: AuthState) => {
    return state.settings && state.settings.isExpensesEnabled;
  },

  isDiagnocatEnabled: (state: AuthState) => {
    return state.settings && state.settings.isDiagnocatEnabled;
  },

  isSmartMirrorEnabled: (state: AuthState) => {
    return state.settings && state.settings.isSmartMirrorEnabled;
  },

  smartMirrorRooms: (state: AuthState) => {
    return state.settings && state.settings.smartMirrorRooms;
  },

  isWhatsappBotEnabled: (state: AuthState) => {
    return state.settings && state.settings.isWhatsappBotEnabled;
  },

  isTimeClockFeatureEnabled: (state: AuthState) => {
    return state.settings && state.settings.isTimeClockFeatureEnabled;
  },

  isKupaReportFeatureEnabled: (state: AuthState) => {
    return state.settings && state.settings.isKupaReportFeatureEnabled;
  },

  isLabWorksEnabled: (state: AuthState) => {
    return state.settings && state.settings.isLabWorksEnabled;
  },

  isShowingPaymentRefundButton: (state: AuthState) => {
    return state.settings && state.settings.isShowingPaymentRefundButton;
  },

  isShowingExportPdfInvoicesButton: (state: AuthState) => {
    return state.settings && state.settings.isShowingExportPdfInvoicesButton;
  },

  isShowingFamilyButtonInPatientPage: (state: AuthState) => {
    return state.settings && state.settings.isShowingFamilyButtonInPatientPage;
  },

  isShowingPatientTreatmentPrice: (state: AuthState) => {
    return state.settings && state.settings.isShowingPatientTreatmentPrice;
  },

  isShowingAppointmentTypeSelector: (state: AuthState) => {
    return state.settings && state.settings.isShowingAppointmentTypeSelector;
  },

  isShowingXrayReference: (state: AuthState) => {
    return state.settings && state.settings.isShowingXrayReference;
  },

  isShowingParentsFields: (state: AuthState) => {
    return state.settings && state.settings.isShowingParentsFields;
  },

  showPatientExtraReferredByPatient: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.showExtraPatientFields &&
      state.settings.showExtraPatientFields
        .split(",")
        .includes("referred_by_patient")
    );
  },

  showPatientExtraInsuranceCompany: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.showExtraPatientFields &&
      state.settings.showExtraPatientFields
        .split(",")
        .includes("insurance_company")
    );
  },

  showPatientExtraReferredByContactId: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.showExtraPatientFields &&
      state.settings.showExtraPatientFields
        .split(",")
        .includes("referred_by_contact_ids")
    );
  },

  calendarIntervalMinutes: (state: AuthState) => {
    return state.settings && state.settings.calendarIntervalMinutes;
  },

  calendarStartHour: (state: AuthState) => {
    return state.settings && state.settings.calendarStartHour;
  },

  calendarEndHour: (state: AuthState) => {
    return state.settings && state.settings.calendarEndHour;
  },

  calendarHideNoShows: (state: AuthState) => {
    return state.settings && state.settings.calendarHideNoShows;
  },

  isPatientHiddenNoteEnabled: (state: AuthState) => {
    return state.settings && state.settings.isPatientHiddenNoteEnabled;
  },

  isImagingScanoraEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingScanoraEnabled;
  },

  isImagingRomexisEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingRomexisEnabled;
  },

  isImagingDimaxisEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingDimaxisEnabled;
  },

  isImagingIrysEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingIrysEnabled;
  },

  isImagingRomexisExternalIdPatientId: (state: AuthState) => {
    return state.settings && state.settings.isImagingRomexisExternalIdPatientId;
  },

  isImagingDimaxisExternalIdPatientId: (state: AuthState) => {
    return state.settings && state.settings.isImagingDimaxisExternalIdPatientId;
  },

  isImagingVistaSoftEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingVistaSoftEnabled;
  },

  isImagingArchimedEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingArchimedEnabled;
  },

  isImagingMediadentEnabled: (state: AuthState) => {
    return state.settings && state.settings.isImagingMediadentEnabled;
  },

  isUsingFirstPinkasDefault: (state: AuthState) => {
    return state.settings && state.settings.isUsingFirstPinkasDefault;
  },

  isShowingTreatmentCode: (state: AuthState) => {
    return state.settings && state.settings.isShowingTreatmentCode;
  },

  isShowingContactsReference: (state: AuthState) => {
    return state.settings && state.settings.isShowingContactsReference;
  },

  isShowingTreatmentFiles: (state: AuthState) => {
    return state.settings && state.settings.isShowingTreatmentFiles;
  },

  isShowingPaymentTreatments: (state: AuthState) => {
    return state.settings && state.settings.isShowingPaymentTreatments;
  },

  trackChangesAppointment: (state: AuthState) => {
    return state.settings && state.settings.trackChangesAppointment;
  },

  trackChangesPatientTreatment: (state: AuthState) => {
    return state.settings && state.settings.trackChangesPatientTreatment;
  },

  isPlansEnabled: (state: AuthState) => {
    return state.settings && state.settings.isPlansEnabled;
  },

  isPlanTreatmentStatusWorkingOnIt: (state: AuthState) => {
    return state.settings && state.settings.isPlanTreatmentStatusWorkingOnIt;
  },

  planFormId: (state: AuthState) => {
    return parseInt(state.settings && state.settings.planFormId);
  },

  quickActions: (state: AuthState) => {
    return state.settings && state.settings.quickActions;
  },

  calendarViewColorType: (state: AuthState) => {
    if (state.user && state.user.calendar_view_color_type) {
      return state.user.calendar_view_color_type;
    }
    return state.settings && state.settings.calendarViewColorType;
  },

  defaultPaymentDescription: (state: AuthState) => {
    return state.settings && state.settings.defaultPaymentDescription;
  },

  whatsappWebFutureAppointmentsTemplate: (state: AuthState) => {
    return (
      state.settings && state.settings.whatsappWebFutureAppointmentsTemplate
    );
  },

  whatsappWebFollowUpTemplate: (state: AuthState) => {
    return state.settings && state.settings.whatsappWebFollowUpTemplate;
  },

  whatsappWebSingleReminderTemplate: (state: AuthState) => {
    return state.settings && state.settings.whatsappWebSingleReminderTemplate;
  },

  whatsappWebMultipleRemindersTemplate: (state: AuthState) => {
    return (
      state.settings && state.settings.whatsappWebMultipleRemindersTemplate
    );
  },

  drugPrescriptionDefaultNote: (state: AuthState) => {
    return state.settings && state.settings.drugPrescriptionDefaultNote;
  },

  xrayReferenceDefaultNote: (state: AuthState) => {
    return state.settings && state.settings.xrayReferenceDefaultNote;
  },

  isPatientProfilePictureEnabled: (state: AuthState) => {
    return state.settings && state.settings.isPatientProfilePictureEnabled;
  },

  isEmailEnabled: (state: AuthState) => {
    return state.settings && state.settings.isEmailEnabled;
  },

  treatmentAttributesType: (state: AuthState) => {
    return state.settings && state.settings.treatmentAttributesType;
  },

  treatmentShowQuantity: (state: AuthState) => {
    return state.settings && state.settings.treatmentShowQuantity;
  },

  calendarStartTimeIntervals: (state: AuthState) => {
    return state.settings && state.settings.calendarStartTimeIntervals;
  },

  calendarAppointmentDurations: (state: AuthState) => {
    return state.settings && state.settings.calendarAppointmentDurations;
  },

  isRtl: (state: AuthState) => {
    const locale = state.user ? state.user.locale : "he";
    return locale === "he";
  },

  isWaLinkDirect: (state: AuthState) => {
    return state.user ? state.user.is_wa_link_direct : null;
  },

  defaultLetterHeader: (state: AuthState) => {
    return state.user ? state.user.default_letter_header : null;
  },

  defaultLetterSignature: (state: AuthState) => {
    if (!state.user) {
      return null;
    }

    if (state.user.default_letter_signature) {
      return state.user.default_letter_signature;
    }

    return "בברכה,\n" + state.user.name;
  },

  locale: (state: AuthState) => {
    return state.user ? state.user.locale : "he";
  },

  user: (state: AuthState) => {
    return state.user;
  },

  userId: (state: AuthState) => {
    return state.user ? state.user.id : null;
  },

  assignedCalendarUserId: (state: AuthState) => {
    if (state.user && state.user.use_calendar_user_id) {
      return state.user.use_calendar_user_id;
    } else {
      return state.user ? state.user.id : null;
    }
  },

  userEmail: (state: AuthState) => {
    return state.user ? state.user.email : null;
  },

  isLogged: (state: AuthState) => {
    return state.token && state.user && state.user.email;
  },

  pusherConfig: (state: AuthState) => {
    return state.settings && state.settings.pusher;
  },

  contacts: (state: AuthState) => {
    return (state.settings && state.settings.contacts) || [];
  },

  imagingAgents: (state: AuthState) => {
    return (state.settings && state.settings.imagingAgents) || [];
  },

  timeEntryTypes: (state: AuthState) => {
    return (
      state.settings &&
      state.settings.timeEntryTypes.map((timeEntryType) => ({
        text: timeEntryType.name,
        value: timeEntryType.id,
      }))
    );
  },

  sentences: (state: AuthState) => {
    return (state.settings && state.settings.sentences) || [];
  },

  users: (state: AuthState) => {
    return (state.settings && state.settings.users) || [];
  },

  supplierPaymentCategories: (state: AuthState) => {
    return (state.settings && state.settings.supplierPaymentCategories) || [];
  },

  taskUsers: (state: AuthState) => {
    if (!state.settings || !state.settings.users) {
      return [];
    }

    return (state.settings.users as User[]).filter(
      (user) =>
        user.is_tasks_enabled &&
        !user.deleted_at &&
        user.email !== "support@smile-app.co.il"
    );
  },

  therapistUsers: (state: AuthState) => {
    if (!state.settings || !state.settings.users) {
      return [];
    }

    return (state.settings.users as User[]).filter(
      (user) => user.type === "caregiver" && !user.deleted_at
    );
  },

  documentTreatmentsUsers: (state: AuthState) => {
    if (!state.settings || !state.settings.users) {
      return [];
    }

    return (state.settings.users as User[]).filter(
      (user) =>
        user.type === "caregiver" &&
        !user.deleted_at &&
        user.is_document_treatments_enabled
    );
  },

  usersWithCalendar: (state: AuthState) => {
    if (!state.settings || !state.settings.users) {
      return [];
    }

    return (state.settings.users as User[]).filter(
      (user) =>
        user.type === "caregiver" &&
        !user.deleted_at &&
        !user.use_calendar_user_id
    );
  },

  isTherapist: (state: AuthState) => {
    return state.user && state.user.type === "caregiver";
  },

  isCalendarDayCategory: (state: AuthState) => {
    return state.settings && state.settings.isCalendarDayCategory;
  },

  isConvertFutureHeshbonitMasEnabled: (state: AuthState) => {
    return state.settings && state.settings.isConvertFutureHeshbonitMasEnabled;
  },

  appointmentTypes: (state: AuthState) => {
    return (state.settings && state.settings.appointmentTypes) || [];
  },

  patientStatuses: (state: AuthState) => {
    return (state.settings && state.settings.patientStatuses) || [];
  },

  appointmentStatuses: (state: AuthState) => {
    return (state.settings && state.settings.appointmentStatuses) || [];
  },

  treatments: (state: AuthState) => {
    return (state.settings && state.settings.treatments) || [];
  },

  pinkasList: (state: AuthState) => {
    return (state.settings && state.settings.pinkas) || [];
  },

  suppliers: (state: AuthState) => {
    return (state.settings && state.settings.suppliers) || [];
  },

  isTranzilaEnabled: (state: AuthState) => {
    if (!state.settings || !state.settings.priceLists) {
      return false;
    }

    return !!state.settings.pinkas.find(
      ({ is_tranzila_enabled }) => is_tranzila_enabled
    );
  },

  priceLists: (state: AuthState) => {
    return (state.settings && state.settings.priceLists) || [];
  },

  tags: (state: AuthState) => {
    return (state.settings && state.settings.tags) || [];
  },

  drugs: (state: AuthState) => {
    return (state.settings && state.settings.drugs) || [];
  },

  forms: (state: AuthState) => {
    return (state.settings && state.settings.forms) || [];
  },

  activeForms: (state: AuthState) => {
    return ((state.settings && state.settings.forms) || []).filter(
      ({ deleted_at }) => !deleted_at
    );
  },
};

export default authGetters;
