import TimeClockPage from "../pages/TimeClockPage.vue";
import ManageTimeClockPage from "../pages/ManageTimeClockPage.vue";
import {RouteConfig} from "vue-router";
import {
    MANAGE_TIME_CLOCK_ROUTE_NAME,
    MANAGE_TIME_CLOCK_ROUTE_PATH,
    TIME_CLOCK_ROUTE_NAME,
    TIME_CLOCK_ROUTE_PATH
} from "@/modules/time-clock/constants/route-config";

const timeClockRoutes: RouteConfig[] = [
    {
        name: TIME_CLOCK_ROUTE_NAME,
        path: TIME_CLOCK_ROUTE_PATH,
        component: TimeClockPage,
    },
    {
        name: MANAGE_TIME_CLOCK_ROUTE_NAME,
        path: MANAGE_TIME_CLOCK_ROUTE_PATH,
        component: ManageTimeClockPage,
    }
];

export default timeClockRoutes;
