























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PatientFolder } from "../../types/patient-folder.type";

@Component({
})
export default class FolderFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public patientFolder: PatientFolder;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public name = "";
  public showErrors = false;

  public mounted() {
    this.name = this.patientFolder ? this.patientFolder.name : "";
  }

  public closeDialog() {
    this.$emit("close");
  }


  public async onSubmitClicked() {
    this.showErrors = true;
    this.name = this.name.trim();
    if (!this.name) {
      return;
    }

    if (this.patientFolder) {
      this.$emit("updateFolder", this.patientFolder.id, this.name, this.patientFolder.parent_folder_id);
    } else {
      this.$emit("createFolder", this.name);
    }

    this.closeDialog();
  }

}
