













































































import ShiftApi from "@/modules/shift/api/shift.api";
import ShiftForm from "./ShiftForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Shift } from "@/modules/shift/types/shift.type";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { User } from "@/modules/users/types/user.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { date, time } from "@/filters/date.filter";

@Component({

  filters: {
    date,
    time
  },
  components: {
    ErrorLogo,
    ShiftForm
  }
})
export default class ManageShifts extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  public displayUserIds: number[] = [];
  public fromDateFilter = moment().startOf("month").format("YYYY-MM-DD");
  public toDateFilter = moment().endOf("month").format("YYYY-MM-DD");
  public fromDateMenuOpen = false;
  public toDateMenuOpen = false;
  public loadErrors: any = false;
  public showShiftForm = false;
  public editShift: Shift = null;
  public loading = false;
  public shifts: Shift[] = [];
  public headers = [
    { text: this.$t("headers.user_id"), value: "user_id", sortable: false },
    { text: this.$t("headers.date"), value: "date", sortable: false },
    { text: this.$t("headers.start_time"), value: "start_time", sortable: false },
    { text: this.$t("headers.end_time"), value: "end_time", sortable: false },
    { text: this.$t("headers.notes"), value: "notes", sortable: false },
    { text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];


  public openShiftFormDialog(shift: Shift) {
    this.editShift = shift;
    this.showShiftForm = true;
  }

  public closeShiftForm() {
    this.editShift = null;
    this.showShiftForm = false;
  }


  get formattedFromDateFilter() {
    if (!this.fromDateFilter) {
      return "";
    }
    return moment(this.fromDateFilter).format("DD/MM/YYYY");
  }

  get formattedToDateFilter() {
    if (!this.toDateFilter) {
      return "";
    }
    return moment(this.toDateFilter).format("DD/MM/YYYY");
  }

  @Watch("displayUserIds")
  @Watch("fromDateFilter")
  @Watch("toDateFilter")
  public reload() {
    this.loadShifts();
  }

  public async loadShifts() {
    this.loadErrors = null;
    this.loading = true;
    this.shifts = [];

    try {
      this.shifts = await ShiftApi.getAll(this.displayUserIds, this.fromDateFilter, this.toDateFilter);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }
    }
  }

  public updated() {
    this.enableAdjacentMonths();
  }


  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public mounted() {
    this.loadShifts();
  }
}
