





























































import UserApi from "../api/user.api";
import UserForm from "../components/UserForm.vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";

@Component({
  components: {
    ErrorMessage,
    ErrorLogo,
    UserForm,
  }
})
export default class UsersPage extends mixins(SmileMixin) {
  public loadErrors = null;
  public showUserForm = false;
  public processedUser = null;
  public loading = false;
  public users: User[] = [];
  public headers = [
    { width: "20%", text: this.$t("headers.name"), value: "name", sortable: false },
    { width: "20%", text: this.$t("headers.role"), value: "role", sortable: false },
    { width: "20%", text: this.$t("headers.email"), value: "email", sortable: false },
    { width: "20%", text: this.$t("headers.phone"), value: "phone", sortable: false },
    { width: "20%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];
  @Getter("auth/isRtl") public isRtl: boolean;

  created() {
    this.loadUsers();
  }

  public openUserFormDialog(user) {
    this.processedUser = user;
    this.showUserForm = true;
  }

  public closeUserForm() {
    this.processedUser = null;
    this.showUserForm = false;
  }

  public addUserRecord(userRecord: User) {
    this.users.push(userRecord);
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public updateUserRecord(userRecord: User) {
    const updatedUserIndex = this.users.findIndex((user) => userRecord.id === user.id);

    if (updatedUserIndex !== -1) {
      this.$set(this.users, updatedUserIndex, userRecord);
    }
    this.loadUsers();
  }

  public deleteUserRecord(userId: number) {
    const toBeRemovedUserIndex = this.users.findIndex((user) => userId === user.id);

    if (toBeRemovedUserIndex !== -1) {
      this.users.splice(toBeRemovedUserIndex, 1);
    }
    this.loadUsers();
  }

  public async loadUsers() {
    this.loadErrors = null;
    this.loading = true;

    try {
      const users = await UserApi.getAll();
      this.users = users.filter(user => (user.email !== "support@smile-app.co.il"));
    } catch (e) {
      console.log(e.response);
    }

    this.loading = false;
  }
}
