import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"report-sent-emails-page"},[_c(VContainer,{staticStyle:{"padding":"12px 22px"},attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c('RouterLink',{attrs:{"to":{ name: _vm.reportsPageRouteName }}},[_vm._v(_vm._s(_vm.$t('title')))]),_c(VIcon,[_vm._v(_vm._s(_vm.isRtl ? 'mdi-menu-left' : 'mdi-menu-right'))]),_vm._v("\n          "+_vm._s(_vm.$t('reports.sent-emails-report.title'))+"\n        ")],1)])],1),_c(VRow,{staticClass:"pb-5",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c(VCol,{staticClass:"pb-0 custom-5-cols",attrs:{"cols":"6","md":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":_vm.$t('fromDate'),"readonly":"","color":"cyan","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.fromDate = null}}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.displayFromDatePicker),callback:function ($$v) {_vm.displayFromDatePicker=$$v},expression:"displayFromDatePicker"}},[(_vm.displayFromDatePicker)?_c(VDatePicker,{staticClass:"fix-date-picker-adjacent-months",attrs:{"no-title":"","color":"cyan darken-1","show-adjacent-months":""},on:{"input":function($event){_vm.displayFromDatePicker = false},"update:active-picker":_vm.enableAdjacentMonths,"update:picker-date":_vm.enableAdjacentMonths},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}):_vm._e()],1)],1),_c(VCol,{staticClass:"pb-0 custom-5-cols",attrs:{"cols":"6","md":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":_vm.$t('toDate'),"readonly":"","color":"cyan","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.toDate = null}}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.displayToDatePicker),callback:function ($$v) {_vm.displayToDatePicker=$$v},expression:"displayToDatePicker"}},[(_vm.displayToDatePicker)?_c(VDatePicker,{staticClass:"fix-date-picker-adjacent-months",attrs:{"no-title":"","color":"cyan darken-1","show-adjacent-months":""},on:{"input":function($event){_vm.displayToDatePicker = false},"update:active-picker":_vm.enableAdjacentMonths,"update:picker-date":_vm.enableAdjacentMonths},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}):_vm._e()],1)],1),_c(VCol,{staticClass:"pb-0 custom-5-cols",attrs:{"cols":"6","md":"4"}},[_c(VAutocomplete,{attrs:{"color":"cyan darken-1","item-color":"cyan darken-1","items":_vm.users,"multiple":"","label":_vm.$t('headers.sent_by'),"hide-details":""},model:{value:(_vm.usersIds),callback:function ($$v) {_vm.usersIds=$$v},expression:"usersIds"}})],1),_c(VCol,{staticClass:"pb-0 custom-5-cols",attrs:{"cols":"6","md":"4"}},[_c('EmailContactSearch',{attrs:{"inputLabel":_vm.$t('headers.sent_to'),"isClearable":true,"isFullWidth":true},model:{value:(_vm.sentTo),callback:function ($$v) {_vm.sentTo=$$v},expression:"sentTo"}})],1)],1),(_vm.reportResults || _vm.isLoading)?_c(VRow,[_c(VCol,{staticClass:"pb-12",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.reportResults ? _vm.reportResults : [],"loading":_vm.isLoading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm.userName(item.user_id))+"\n            ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm._f("date")(item.created_at))+"\n            ")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm._f("time")(item.created_at))+"\n            ")])]}},{key:"item.patient_id",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
              name: _vm.patientRouteName,
              params: {
                id: item.patient.id
              }
            }}},[_vm._v("\n              "+_vm._s(item.patient.first_name)+"\n              "+_vm._s(item.patient.last_name))])]}},{key:"item.to",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(item.to)+"\n            ")])]}},{key:"item.patient_file_name",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.patient_file_name)+"\n          ")]}}],null,false,803322667)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-file-document-edit-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_treatments')))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }