import {AppModule} from "@/types/app-module";
import timeClockRoutes from "@/modules/time-clock/routes/routes";
import timeClockStore from "@/modules/time-clock/store";
import timeClockPolicy from "@/modules/time-clock/policy";

const timeClockModule: AppModule = {
    name: "timeClock",
    routes: timeClockRoutes,
    store: timeClockStore,
    policy: timeClockPolicy,
};

export default timeClockModule;
