import { TaskStoreState } from "../types/task.type";

const taskMutations = {
  setTasks(state: TaskStoreState, data: TaskStoreState) {
    state.myFutureTasks = data.myFutureTasks;
    state.createdFutureTasks = data.createdFutureTasks;
    state.myTaskRecurrings = data.myTaskRecurrings;
    state.createdTaskRecurrings = data.createdTaskRecurrings;

    state.myTodoTasks = data.myTodoTasks
      .sort((a, b) => {
        if (a.urgency < b.urgency) { return -1; }
        if (a.urgency > b.urgency) { return 1; }
        if (a.task_date > b.task_date) { return -1; }
        if (a.task_date < b.task_date) { return 1; }
        return 0;
      });
    state.createdTodoTasks = data.createdTodoTasks
      .sort((a, b) => {
        if (a.urgency < b.urgency) { return -1; }
        if (a.urgency > b.urgency) { return 1; }
        if (a.task_date > b.task_date) { return -1; }
        if (a.task_date < b.task_date) { return 1; }
        return 0;
      });
    state.myDoneTasks = data.myDoneTasks
      .sort((a, b) => {
        if (a.done_at > b.done_at) { return -1; }
        if (a.done_at < b.done_at) { return 1; }
        return 0;
      });
    state.createdDoneTasks = data.createdDoneTasks
      .sort((a, b) => {
        if (a.done_at > b.done_at) { return -1; }
        if (a.done_at < b.done_at) { return 1; }
        return 0;
      });
  },
};

export default taskMutations;
