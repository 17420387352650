import WhatsappApi from "../api/whatsapp.api";

const whatsappActions = {
  async loadContacts({ commit }) {
    const contacts = await WhatsappApi.getLastContacts();
    commit("setContacts", contacts);
  },
  async markAsRead({ commit }, phone) {
    commit("setMarkAsRead", phone);
  },
  async markAsUnread({ commit }, phone) {
    commit("setMarkAsUnread", phone);
  }
};

export default whatsappActions;
