





































import SentenceApi from "@/serivce/api/sentence-api";
import SentenceForm from "../sentences/SentenceForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { Sentence } from "../../types/sentence.type";

@Component({
  components: {
    ErrorLogo,
    SentenceForm
  },
})

export default class ManageSentences extends Vue {


  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public editSentence: Sentence = null;
  public showSentenceForm = false;
  public loading = false
  public sentences: Sentence[] = []
  public headers = [
    { width: "35%", text: this.$t("sentences_headers.contents"), value: "contents" },
    { width: "25%", text: this.$t("sentences_headers.sentenceCategoryId"), value: "sentence_category.name" },
    { width: "20%", text: this.$t("sentences_headers.order"), value: "order" },
    { width: "20%", text: this.$t("sentences_headers.actions"), value: "actions", sortable: false }
  ]


  public openSentenceFormDialog(sentence: Sentence) {
    this.editSentence = sentence;
    this.showSentenceForm = true;
  }
  public closeSentenceForm() {
    this.editSentence = null;
    this.showSentenceForm = false;
  }
  public reload() {
    this.loadSentences();
  }
  public async loadSentences() {
    this.loadErrors = null;
    this.loading = true;
    this.sentences = [];
    try {
      this.loading = false;
      const results = await SentenceApi.get();
      this.sentences = results.data.sentences;
      console.log(this.sentences);

    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }
    }
  }

  mounted() {
    this.loadSentences();
  }
}
