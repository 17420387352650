export enum PlanApiRoutes {
  getAll = "/plan",
  create = "/plan",
  getOne = "/plan/{id}",
  update = "/plan/{id}",
  delete = "/plan/{id}",
  share = "/plan/share",
  markAllTreatmentsDone = "/plan/markAllTreatmentsDone",
  sendEmail = "/plan/send-email",
}

export enum PlanApiRouteParameters {
  id = "{id}",
}

export enum PlanTreatmentApiRoutes {
  getAll = "/plan-treatment",
  create = "/plan-treatment",
  update = "/plan-treatment/{id}",
  delete = "/plan-treatment/{id}",
}

export enum PlanTreatmentApiRouteParameters {
  id = "{id}",
}

export enum TreatmentApiRoutes {
  getAll = "/treatment",
  create = "/treatment",
  update = "/treatment/{id}",
  delete = "/treatment/{id}",
}

export enum TreatmentApiRouteParameters {
  id = "{id}",
}

export enum PriceListApiRoutes {
  getAll = "/price-list",
  create = "/price-list",
  update = "/price-list/{id}",
  delete = "/price-list/{id}",
}

export enum PriceListApiRouteParameters {
  id = "{id}",
}
