










































































































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import PaymentVerifoneApi from "../api/payment-verifone.api";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { PaymentVerifoneReportResults, PaymentVerifoneReportTransaction } from "../types/payment-verifone.type";
import { time } from "@/filters/date.filter";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";

@Component({
  filters: {
    time,
  }
})
export default class VerifoneReportPage extends mixins(SmileMixin) {
  public date: string = null;
  public displayDatePicker = false;
  public isLoading = false;
  public reportResults: PaymentVerifoneReportResults = null;
  public loadingVoucherTransactionId: string = null;
  private activeRequest: CancelTokenSource;

  public mounted() {
    this.applyFromQueryStringFilters();
    if (!this.date) {
      this.date = moment().format("YYYY-MM-DD");
    }
    this.reload();
  }

  @Watch("date")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.created_at"), value: "created_at", sortable: false },
      { text: this.$t("headers.transaction_id"), value: "transaction_id", sortable: false },
      { text: this.$t("headers.transaction_type_weaver"), value: "transaction_type_weaver", sortable: false },
      { text: this.$t("headers.card_number"), value: "card_number", sortable: false },
      { text: this.$t("headers.issuer_name"), value: "issuer_name", sortable: false },
      { text: this.$t("headers.brand"), value: "brand", sortable: false },
      { text: this.$t("headers.original_amount"), value: "original_amount", sortable: false },
      { text: this.$t("headers.credit_terms"), value: "credit_terms", sortable: false },
      { text: this.$t("headers.transaction_status"), value: "transaction_status", sortable: false },
    ];
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get dateFormatted() {
    return moment(this.date).format("DD/MM/YYYY");
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      this.reportResults = await PaymentVerifoneApi.getTransactions(this.date, this.date, this.activeRequest);

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async cancelTranscationClicked(transaction: PaymentVerifoneReportTransaction) {
    const res = await this.$confirm(
      this.$t("cancel_transaction_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("cancel_transaction_ok_btn").toString(),
        buttonFalseText: this.$t("cancel_transaction_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    // TODO open popup 
    console.log({ transaction });
  }

  public async getVoucherUrl(transaction: PaymentVerifoneReportTransaction) {
    this.loadingVoucherTransactionId = transaction.transaction_id;

    try {
      const url = await PaymentVerifoneApi.getVoucherUrl(this.date, this.date, transaction.transaction_id);
      this.loadingVoucherTransactionId = null;

      const res = await this.$confirm(
        this.$t("download_voucher_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("download_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, "voucher.jpg");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.loadingVoucherTransactionId = null;
    }
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();

    if (queryDate) {
      this.date = queryDate;
    }
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.date,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }
}
