










































































import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Prop } from "vue-property-decorator";
import { date, time, age } from "@/filters/date.filter";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";

@Component({
    components: {
    },
    filters: {
        date,
        time,
        age,
    }
})

export default class DetailedTreatmentsReport extends mixins(SmileMixin) {

    @Getter("auth/isRtl") isRtl: boolean;
    @Prop({ required: true }) displayUserIds: number[];
    @Prop({ required: true }) page: number;
    @Prop({ required: true }) dataTableOptions: any;
    @Prop({ required: true }) totalItemsCount: number;
    @Prop({ required: true }) itemsPerPage: number;
    @Prop({ required: true }) patientTreatments: PatientTreatment[]
    @Prop({ required: true }) isLoading: boolean;
    @Prop({ required: true }) isExportLoading: boolean;

    get headers() {
        const headers = [];
        if (this.displayUserIds.length !== 1) {
            headers.push({ text: this.$t("headers.user"), value: "user", sortable: false });
        }
        headers.push(
            { text: this.$t("headers.date"), value: "date", sortable: false },
            { text: this.$t("headers.created_at"), value: "created_at", sortable: false },
            { text: this.$t("headers.patient"), value: "patient", sortable: false },
            { text: this.$t("headers.treatment"), value: "treatment", sortable: false },
            { text: this.$t("headers.price"), value: "price", sortable: false },
        );

        return headers;
    }

    public updatePage(page: number) {
        this.$emit("changePage", page);
    }

    get isMobile() {
        return this.$vuetify.breakpoint.xsOnly;
    }

    get patientRouteName() {
        return PATIENT_ROUTE_NAME;
    }

    public onEditTreatmentClicked(treatment: PatientTreatment) {
        this.$emit("editTreatmentClicked", treatment);
    }

    public exportReport() {
        this.$emit("onExport");
    }
}
