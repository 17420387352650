import {AppModule} from "@/types/app-module";
import patientFileRoutes from "@/modules/patient-file/routes/routes";
import patientFileStore from "@/modules/patient-file/store";
import patientFilePolicy from "@/modules/patient-file/policy";

const patientFileModule: AppModule = {
    name: "patientFile",
    routes: patientFileRoutes,
    store: patientFileStore,
    policy: patientFilePolicy,
};

export default patientFileModule;
