import {RouteConfig} from "vue-router";
import LoginPage from "../pages/LoginPage.vue";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/ResetPasswordPage.vue";
import store from "@/store";
import ProfilePage from "../pages/ProfilePage.vue";
import { PROFILE_ROUTE_NAME, PROFILE_ROUTE_PATH } from "../constants/route-config";

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters["auth/isLogged"]) {
        return next();
    }

    return next({name: "home"});
};

const authRoutes: RouteConfig[] = [
    {
        path: "/login",
        name: "login",
        component: LoginPage,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPasswordPage,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: "/reset-password/:token",
        component: ResetPasswordPage,
        beforeEnter: ifNotAuthenticated
    },
];

export const guardedAuthRoutes: RouteConfig[] = [
    {
        name: PROFILE_ROUTE_NAME,
        path: PROFILE_ROUTE_PATH,
        component: ProfilePage
    },
];

export default authRoutes;
