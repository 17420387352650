import { RouteConfig } from "vue-router";
import VerifoneReportPage from "../pages/VerifoneReportPage.vue";
import {
  VERIFONE_REPORT_NAME,
  VERIFONE_REPORT_PATH,
} from "@/modules/payment-verifone/constants/route-config";

const VerifoneRoutes: RouteConfig[] = [
  {
    name: VERIFONE_REPORT_NAME,
    path: VERIFONE_REPORT_PATH,
    component: VerifoneReportPage,
  },
];

export default VerifoneRoutes;
