




























































































































import { Component } from "vue-property-decorator";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import ContactForm from "@/modules/contacts/components/ContactForm.vue";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Contact } from "../types/contact.type";
import { Getter } from "vuex-class";
import PatientWhatsappChatDialog from "@/modules/whatsapp/components/PatientWhatsappChatDialog.vue";

@Component({
  components: {
    ContactForm,
    PatientWhatsappChatDialog
  }
})
export default class ContactsPage extends mixins(SmileMixin) {
  public contactBookmarks: ContactBookmark[] = [];
  public activeBookmarkId: number = null;
  public freeTextSearch = "";
  public displayWhatsappChatDialog = false;
  public contactPhone = "";
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;


  mounted() {
    this.contactBookmarks = JSON.parse(JSON.stringify(this.$store.getters["auth/contacts"]));
  }

  public closeWhatsappChatDialog() {
    this.displayWhatsappChatDialog = false;
  }
  public openWhatsappChatDialog(contact: Contact) {
    this.contactPhone = contact.phone && this.isValidPhone(contact.phone) ? contact.phone : contact.phone2;
    this.displayWhatsappChatDialog = true;
  }

  get bookmarksSelectOptions() {
    return this.contactBookmarks
      .map(bookmark => ({
        "text": bookmark.name,
        "value": bookmark.id
      }));
  }

  get activeContactBookmark() {
    return this.contactBookmarks.find(({ id }) => (id === this.activeBookmarkId));
  }

  public editContact: Contact;
  public showContactForm = false;

  public closeContactFormDialog() {
    this.editContact = null;
    this.showContactForm = false;
  }


  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public openContactForm(contact) {
    this.editContact = contact;
    this.showContactForm = true;
  }

  public contactDeleted(deletedContactId: number) {
    this.contactBookmarks.forEach(bookmark => {
      const toBeRemovedContactIndex = bookmark.contacts.findIndex((contact) => deletedContactId === contact.id);

      if (toBeRemovedContactIndex !== -1) {
        bookmark.contacts.splice(toBeRemovedContactIndex, 1);
      }
    });
  }

  public contactCreated(contact: Contact) {
    this.contactBookmarks.forEach(bookmark => {
      if (bookmark.id !== contact.bookmark_id) {
        return;
      }

      bookmark.contacts.unshift(contact);
    });
  }

  public contactUpdated(updatedContact: Contact) {
    this.contactDeleted(updatedContact.id);
    this.contactCreated(updatedContact);
  }

  public validPhone(phone: string) {
    if (!phone) {
      return false;
    }

    const reformatedPhone = phone.replace("-", "");

    return reformatedPhone.length >= 9 && reformatedPhone.length <= 10;
  }

  get displayContacts() {
    if (this.activeContactBookmark) {
      return this.activeContactBookmark.contacts.filter(({ deleted_at }) => (!deleted_at));
    }

    const contacts: Contact[] = [];
    this.contactBookmarks.forEach(bookmark => {
      contacts.push(...bookmark.contacts.filter(({ deleted_at }) => (!deleted_at)));
    });

    return contacts;
  }

  get filteredContacts() {
    if (!this.freeTextSearch) {
      return this.displayContacts;
    }

    return this.displayContacts.filter((contact => {
      return `${contact.name} ${contact.email} ${contact.notes} ${contact.phone}`.includes(this.freeTextSearch.trim());
    }));
  }

  public getBookmarkName(bookmarkId: number) {
    return this.contactBookmarks.find(({ id }) => (id === bookmarkId)).name;
  }
}
