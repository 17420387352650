export const AppConfig = new class {
  get apiBaseUrl() {
    return process.env.VUE_APP_API_BASE_URL;
  }

  get logApiCall() {
    return process.env.VUE_APP_LOG_API_CALLS;
  }

  get inProductionMode() {
    return process.env.NODE_ENV === "production";
  }

  get customPatientInfoComponent() {
    // TODO make it customable
    return "PatientInfo";
  }

  get customPatientFormComponent() {
    // TODO make it customable
    return "PatientForm";
  }
}();
