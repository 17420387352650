














































































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import { date } from "@/filters/date.filter";
import SupplierApi from "../api/supplier.api";
import axios from "axios";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import { Supplier } from "@/modules/supplier/types/supplier.type";
import SupplierFormDialog from "../components/SupplierFormDialog.vue";
import { EXPENSES_ROUTE_NAME, SUPPLIERS_ROUTE_NAME } from "@/modules/supplier/constants/route-config";

@Component({
  components: {
    SupplierFormDialog,
  },
  filters: {
    date,
  }
})
export default class SuppliersPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  public isDisplaySupplierFormDialog = false;
  public editingSupplier: Supplier = null;
  public querySupplierSearch = "";
  public pinkasId: number = null;
  public isLoading = false;
  public suppliers: Supplier[] = [];
  public headers = [
    { text: this.$t("headers.name"), value: "name", sortable: true, width: 100 },
    { text: this.$t("headers.phone"), value: "phone", sortable: false, width: 100 },
    { text: this.$t("headers.balance"), value: "balance", sortable: true, width: 100 },
    { text: "", value: "actions", sortable: false, width: 100 }
  ];

  @Watch("querySupplierSearch")
  public updateQueryStringFilters() {
    const query = {
      supplier: this.querySupplierSearch ? this.querySupplierSearch : undefined,
      business: this.$route.query.business
    };
    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  get filteredSuppliers() {
    const filteredSuppliers = this.suppliers.filter(supplier => {
      const isSupplierIncludesStrings = this.querySupplierSearch.split(" ").map(query => supplier.name.includes(query));
      return !isSupplierIncludesStrings.includes(false);
    });
    return filteredSuppliers;
  }

  public async mounted() {
    this.pinkasId = parseInt(this.$route.query.business.toString());
    await this.reload();
    if (this.$route.query.supplier) {
      this.querySupplierSearch = this.$route.query.supplier.toString();
    }
  }

  public async reload() {
    try {
      this.isLoading = true;
      this.suppliers = await SupplierApi.getAll();
      this.isLoading = false;

    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public openSupplierFormDialog(supplier: Supplier) {
    this.isDisplaySupplierFormDialog = true;
    this.editingSupplier = supplier;
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  get expensesRouteName() {
    return EXPENSES_ROUTE_NAME;
  }

  get suppliersRouteName() {
    return SUPPLIERS_ROUTE_NAME;
  }
}
