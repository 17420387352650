













































import SentenceApi from "@/serivce/api/sentence-api";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { Sentence } from "../types/sentence.type";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import SentenceForm from "../components/sentences/SentenceForm.vue";
import sentenceCategoryApi from "@/serivce/api/sentence-category-api";
import { SentenceCategory } from "../types/sentence-category.type";

@Component({
  components: {
    ErrorLogo,
    SentenceForm
  },
})

export default class ManageSentencesPage extends Vue {

  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public editSentence: Sentence = null;
  public showSentenceForm = false;
  public loading = false
  public sentences: Sentence[] = []
  public sentenceCategories: SentenceCategory[] = [];
  public sentenceCategoryId: number = null;
  public headers = [
    { width: "35%", text: this.$t("sentences_headers.contents"), value: "contents" },
    { width: "25%", text: this.$t("sentences_headers.sentenceCategoryId"), value: "sentence_category.name" },
    { width: "20%", text: this.$t("sentences_headers.order"), value: "order" },
    { width: "20%", text: this.$t("sentences_headers.actions"), value: "actions", sortable: false }
  ]

  get displayTemplateSentences() {
    if (!this.sentenceCategoryId) {
      return this.sentences;
    }
    return this.sentences.filter(({ sentence_category_id }) => (sentence_category_id === this.sentenceCategoryId));
  }

  public openSentenceFormDialog(sentence: Sentence) {
    this.editSentence = sentence;
    this.showSentenceForm = true;
  }
  public closeSentenceForm() {
    this.editSentence = null;
    this.showSentenceForm = false;
  }
  public reload() {
    this.loadSentences();
  }
  public async loadSentences() {
    this.loadErrors = null;
    this.loading = true;
    this.sentences = [];
    try {
      const results = await SentenceApi.get();
      this.sentences = results.data.sentences;
      const sentenceCategoriesResults = await sentenceCategoryApi.get();
      this.sentenceCategories = sentenceCategoriesResults.data.sentence_categories;
      this.loading = false;

    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }
    }
  }

  mounted() {
    this.sentenceCategoryId = +this.$route.params.categoryId;
    this.loadSentences();
  }
}
