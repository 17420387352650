import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  SupplierApiRouteParameters,
  SupplierApiRoutes,
} from "@/modules/supplier/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { Supplier, SupplierMandatory } from "../types/supplier.type";

class SupplierApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<Supplier[]> {
    try {
      const response: ApiResponse<Supplier[]> = await this.httpClient.get(
        SupplierApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, payload: SupplierMandatory): Promise<Supplier> {
    try {
      const response: ApiResponse<Supplier> = await this.httpClient.put(
        SupplierApiRoutes.updateSupplier.replace(
          SupplierApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: SupplierMandatory): Promise<Supplier> {
    try {
      const response: ApiResponse<Supplier> = await this.httpClient.post(
        SupplierApiRoutes.createSupplier,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        SupplierApiRoutes.deleteSupplier.replace(
          SupplierApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new SupplierApi();
