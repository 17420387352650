import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { LabWork, LabWorkMandatory } from "../types/lab-work.type";
import {
  LabWorkApiRouteParameters,
  LabWorkApiRoutes,
} from "../constants/api-routes";

class LabWorkApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async get(): Promise<LabWork[]> {
    try {
      const response = await this.httpClient.get(LabWorkApiRoutes.get);

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: LabWorkMandatory): Promise<LabWork> {
    try {
      const response = await this.httpClient.post(
        LabWorkApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, payload: LabWorkMandatory): Promise<LabWork> {
    try {
      const response = await this.httpClient.put(
        LabWorkApiRoutes.update.replace(
          LabWorkApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        LabWorkApiRoutes.delete.replace(
          LabWorkApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new LabWorkApi();
