

























import { Component } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import YearlyIncomesChart from "../components/charts/YearlyIncomesChart.vue";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import YearlyAppointmentsChart from "../components/charts/YearlyAppointmentsChart.vue";

@Component({
  components: {
    YearlyIncomesChart,
    YearlyAppointmentsChart,
  },
})
export default class ReportOverviewPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;


  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }
}
