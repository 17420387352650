import { render, staticRenderFns } from "./WhatsappPage.vue?vue&type=template&id=962c23f4&scoped=true&"
import script from "./WhatsappPage.vue?vue&type=script&lang=ts&"
export * from "./WhatsappPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962c23f4",
  null
  
)

/* custom blocks */
import block0 from "../locales/whatsapp-page.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fwhatsapp%2Fpages%2FWhatsappPage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports