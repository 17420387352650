import { RouteConfig } from "vue-router";
import TranzilaReportPage from "../pages/TranzilaReportPage.vue";
import {
  TRANZILA_REPORT_NAME,
  TRANZILA_REPORT_PATH,
} from "@/modules/payment-tranzila/constants/route-config";

const TranzilaRoutes: RouteConfig[] = [
  {
    name: TRANZILA_REPORT_NAME,
    path: TRANZILA_REPORT_PATH,
    component: TranzilaReportPage,
  },
];

export default TranzilaRoutes;
