


























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Payment } from "@/modules/patient/types/payment.type";

@Component({
})
export default class CancelPaymentConfirmDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public payment: Payment;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public cancelReason = "";
  public showErrors = false;

  public closeDialog() {
    this.$emit("close");
  }

  public async onPaymentCancelClicked() {
    if (this.cancelReason.trim().length <= 1) {
      this.showErrors = true;
      return;
    }
    this.$emit("paymentCancelConfirmed", this.payment, this.cancelReason);
    this.closeDialog();
  }

  get explanationVersion() {
    if (this.payment.heshbonit_mas.length || this.payment.heshbonit_mas_kabala.length) {
      return "explanation_kabala_and_zikoi";
    }

    return "explanation_kabala";
  }
}
