






































































































































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import { date } from "@/filters/date.filter";
import SupplierPaymentApi from "../api/supplier-payment.api";
import axios from "axios";
import { Pinkas } from "@/modules/pinkas/types/pinkas.type";
import { SupplierPayment } from "@/modules/supplier/types/supplier-payment.type";
import SupplierPaymentFormDialog from "../components/SupplierPaymentFormDialog.vue";
import { EXPENSES_ROUTE_NAME, SUPPLIERS_ROUTE_NAME } from "@/modules/supplier/constants/route-config";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";

@Component({
  components: {
    SupplierPaymentFormDialog,
  },
  filters: {
    date,
  }
})
export default class SuppliersPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];

  public pinkasId: number = null;
  public isDisplaySupplierPaymentFormDialog = false;
  public editingSupplierPayment: SupplierPayment = null;
  public isLoading = false;
  public supplierIds: number[] = [];
  public fromDate: string = null;
  public toDate: string = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;
  public supplierPayments: SupplierPayment[] = [];


  get headers() {
    const headers = [
      { text: this.$t("headers.created_at"), value: "created_at", sortable: false, width: 100 },
      { text: this.$t("headers.document_type"), value: "document_type", sortable: false, width: 100 },
      { text: this.$t("headers.supplier"), value: "supplier", sortable: false, width: 100 },
      { text: this.$t("headers.category"), value: "category", sortable: false, width: 100 },
      { text: this.$t("headers.document_number"), value: "document_number", sortable: false, width: 100 },
      { text: this.$t("headers.notes"), value: "notes", sortable: false, width: 100 },
      { text: this.$t("headers.amount_without_maam"), value: "amount_without_maam", sortable: false, width: 100 },
      { text: this.$t("headers.maam_amount"), value: "maam_amount", sortable: false, width: 100 },
      { text: this.$t("headers.amount"), value: "amount", sortable: false, width: 100 },
    ];

    if (this.supplierIds.length === 1) {
      headers.push({ text: this.$t("headers.balance"), value: "balance", sortable: false, width: 100 });
    }

    headers.push({ text: "", value: "actions", sortable: false, width: 100 });

    return headers;
  }

  public shouldShowMaam(documentType) {
    return documentType !== "kabala";
  }

  public async mounted() {
    this.applyFromQueryStringFilters();

    if (!this.pinkasId) {
      this.pinkasId = this.pinkasList[0].id;
    }
    if (!this.fromDate) {
      this.fromDate = moment().startOf("month").subtract(3, "months").format("YYYY-MM-DD");
    }
    if (!this.toDate) {
      this.toDate = moment().format("YYYY-MM-DD");
    }
    await this.reload();
  }

  public applyFromQueryStringFilters() {
    const queryBusiness = parseInt(this.$route.query.business && this.$route.query.business.toString());
    const queryFrom = this.$route.query.from && this.$route.query.from.toString();
    const queryTo = this.$route.query.to && this.$route.query.to.toString();
    const querySuppliers = this.$route.query.supplier && this.$route.query.supplier.toString();

    if (queryBusiness) {
      this.pinkasId = queryBusiness;
    }
    if (queryFrom) {
      this.fromDate = queryFrom;
    }
    if (queryTo) {
      this.toDate = queryTo;
    }
    if (querySuppliers) {
      this.supplierIds = querySuppliers.split(",").map(id => (parseInt(id)));
    }
  }

  public updateQueryStringFilters() {
    const query = {
      from: this.fromDate ? this.fromDate : undefined,
      to: this.toDate ? this.toDate : undefined,
      business: this.pinkasId ? this.pinkasId.toString() : undefined,
      supplier: this.supplierIds.length ? this.supplierIds.join(",") : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  @Watch("fromDate")
  @Watch("toDate")
  @Watch("pinkasId")
  @Watch("supplierIds")
  onFiltersChanged() {
    this.reload();
  }

  get displaySupplierPayments() {
    return this.supplierPayments.filter(({ created_at }) => (!this.fromDate || moment(created_at).isAfter(moment(this.fromDate))));
  }

  public async reload() {
    this.updateQueryStringFilters();
    try {
      this.isLoading = true;
      this.supplierPayments = await SupplierPaymentApi.getAll(this.pinkasId, this.supplierIds, this.supplierIds.length === 1 ? null : this.fromDate, this.toDate);

      if (this.supplierIds.length === 1) {
        let balance = 0;
        for (let i = this.supplierPayments.length - 1; i >= 0; i--) {
          balance -= this.supplierPayments[i].amount * (this.supplierPayments[i].document_type === "heshbonit_mas" ? -1 : 1);
          (this.supplierPayments[i] as any).balance = balance;
        }
      }

      this.isLoading = false;

    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return moment(this.fromDate).format("DD/MM/YYYY");
  }

  get toDateFormatted() {
    return moment(this.toDate).format("DD/MM/YYYY");
  }

  public openSupplierFormDialog(supplierPayment: SupplierPayment) {
    this.isDisplaySupplierPaymentFormDialog = true;
    this.editingSupplierPayment = supplierPayment;
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  get expensesRouteName() {
    return EXPENSES_ROUTE_NAME;
  }

  get suppliersRouteName() {
    return SUPPLIERS_ROUTE_NAME;
  }

  get suppliersList() {
    return this.$store.getters["auth/suppliers"].filter(({ deleted_at }) => (!deleted_at));
  }
}
