import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"600px"},on:{"input":_vm.closeDialog}},[_c(VCard,{staticClass:"track-changes-patient-treatment-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between align-start"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('title'))+"\n      ")]),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[(_vm.patientTreatment.changes_log)?_c(VSimpleTable,{attrs:{"dense":"","mobile-breakpoint":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("headers.user")))]),_c('th',[_vm._v(_vm._s(_vm.$t("headers.date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("headers.change")))])])]),_c('tbody',_vm._l((_vm.changesLogItems),function(item,index){return _c('tr',{key:'changeLog' + index},[_c('td',[(item.changed_by_user_id)?_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm.userName(item.changed_by_user_id))+"\n                ")]):_vm._e()]),_c('td',[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                  "+_vm._s(_vm._f("date")(item.updated_at))+"\n                  "+_vm._s(_vm._f("time")(item.updated_at))+"\n                ")])]),_c('td',[(item.created_at)?_c('div',[_vm._v(_vm._s(_vm.$t('created_at'))+": "+_vm._s(_vm._f("date")(item.created_at)))]):_vm._e(),(item.user_id)?_c('div',[_vm._v(_vm._s(_vm.$t('user_id'))+": "+_vm._s(_vm.userName(item.user_id)))]):_vm._e(),(item.treatment_id)?_c('div',[_vm._v(_vm._s(_vm.$t('treatment_id'))+": "+_vm._s(_vm.treatmentName(item.treatment_id)))]):_vm._e(),(_vm.getAttribute(item.attributes, 'teeth'))?_c('div',[_vm._v(_vm._s(_vm.$t('teeth'))+": "+_vm._s(_vm.getAttribute(item.attributes,
  'teeth'))+"\n                ")]):_vm._e(),(_vm.getAttribute(item.attributes, 'surface'))?_c('div',[_vm._v(_vm._s(_vm.$t('surface'))+": "+_vm._s(_vm.getAttribute(item.attributes, 'surface')))]):_vm._e(),(item.price)?_c('div',[_vm._v(_vm._s(_vm.$t('price'))+": "+_vm._s(item.price.toLocaleString())+"₪")]):_vm._e(),(item.discount)?_c('div',[_vm._v(_vm._s(_vm.$t('discount'))+": "+_vm._s(item.discount.toLocaleString())+"₪")]):_vm._e(),(item.notes)?_c('div',[_vm._v(_vm._s(_vm.$t('notes'))+": "+_vm._s(item.notes))]):_vm._e(),(item.color)?_c('div',[_vm._v(_vm._s(_vm.$t('color'))+": "+_vm._s(item.color))]):_vm._e()])])}),0)]},proxy:true}],null,false,1647215064)}):_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('no_changes'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }