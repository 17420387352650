import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{staticClass:"last-contacts-list",attrs:{"three-line":""}},[_c(VListItemGroup,{attrs:{"color":"cyan darken-3"}},_vm._l((_vm.contacts),function(contact,index){return _c(VListItem,{key:'li' + index,attrs:{"to":{
  name: _vm.whatsappRouteName,
  params: {
    phone: contact.phone
  }
}}},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#dfe5e7","size":"40"}},[_c(VIcon,{attrs:{"color":"#ffffff","large":""}},[_vm._v("mdi-account")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(contact.displayName || _vm.$t('no-display-name'))}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(contact.phone)}})],1),_c(VListItemAction,[_c('small',[_vm._v(_vm._s(_vm.relativeDate(contact.lastSent)))]),(contact.isUnread)?_c(VBtn,{attrs:{"icon":"","small":"","color":"pink"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMarkAsRead(contact.phone)}}},[_c(VIcon,[_vm._v("mdi-checkbox-blank-circle")])],1):_c(VBtn,{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMarkAsUnread(contact.phone)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-email-open-outline")])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }