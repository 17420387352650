import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  WhatsappApiRouteParameters,
  WhatsappApiRoutes,
} from "@/modules/whatsapp/constants/api-routes";
import {
  WhatsappChatHistoryApiResponse,
  WhatsappContact,
  WhatsappDate,
  WhatsappReceivedMessage,
  WhatsappSentMessage,
} from "../types/whatsapp.type";
import { date } from "@/filters/date.filter";
import {
  WHATSAPP_TEMPLATE_FAST_INSTRUCTIONS,
  WHATSAPP_TEMPLATE_FAST_INSTRUCTIONS_ST,
  WHATSAPP_TEMPLATE_QUESTIONNAIRE_ADULT,
  WHATSAPP_TEMPLATE_QUESTIONNAIRE_CHILD,
} from "../constants/whatsapp-templates";

class WhatsappNoteApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getChatHistory(phone: string) {
    try {
      const response = await this.httpClient.get(
        WhatsappApiRoutes.getChatHistory.replace(
          WhatsappApiRouteParameters.phone,
          phone
        )
      );

      return this.reformatChatHistoryForDisplay(
        response.data as WhatsappChatHistoryApiResponse
      );
    } catch (e) {
      throw e;
    }
  }

  async getMessages(phone: string) {
    try {
      const response = await this.httpClient.get(
        WhatsappApiRoutes.getChatHistory.replace(
          WhatsappApiRouteParameters.phone,
          phone
        )
      );

      return response.data as WhatsappChatHistoryApiResponse;
    } catch (e) {
      throw e;
    }
  }

  async getLastContacts() {
    try {
      const response = await this.httpClient.get(
        WhatsappApiRoutes.getLastContacts
      );

      return response.data as WhatsappContact[];
    } catch (e) {
      throw e;
    }
  }

  async markAsRead(phone: string, markAsRead: boolean) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.markContactRead,
        {
          markAsRead,
          phone,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw e.response.data.errors[""][0];
      }

      throw e;
    }
  }

  async sendAppointmentReminderMessage(phone: string, date: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sentAppointmentReminder,
        {
          phone,
          date,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw e.response.data.errors[""][0];
      }

      throw e;
    }
  }

  async sendSinglePatientFutureReminders(patientId: number, phone?: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappSinglePatientFutureAppintments,
        {
          patientId,
          phone,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendFutureReminders(phone: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappFutureAppintments,
        {
          phone,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendPdfDocument(phone: string, documentUrl: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplatePdf,
        {
          phone,
          documentUrl,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendImage(phone: string, imageUrl: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplateImage,
        {
          phone,
          imageUrl,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendFastInstructionsKido(phone: string, firstName: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplateImage,
        {
          template: WHATSAPP_TEMPLATE_FAST_INSTRUCTIONS,
          phone,
          params: [firstName],
          imageUrl: "https://kido.smile-app.co.il/whatsapp/fast-kido.jpeg",
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendFastInstructionsEsti(phone: string, firstName: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplateImage,
        {
          template: WHATSAPP_TEMPLATE_FAST_INSTRUCTIONS_ST,
          phone,
          params: [firstName],
          imageUrl: "https://kido.smile-app.co.il/whatsapp/fast-esti.png",
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw e.response.data.errors[""][0];
      }

      throw e;
    }
  }

  async sendTemplate(
    phone: string,
    templateName: string,
    params: string[],
    buttonUrl?: string
  ) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplate,
        {
          template: templateName,
          phone,
          params,
          buttonUrl,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendQuestionnaireChildMessage(phone: string, firstName: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplate,
        {
          template: WHATSAPP_TEMPLATE_QUESTIONNAIRE_CHILD,
          phone,
          params: [firstName],
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendFreeText(phone: string, message: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendFreeText,
        {
          phone,
          message,
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  async sendQuestionnaireAdultMessage(phone: string, firstName: string) {
    try {
      const response = await this.httpClient.post(
        WhatsappApiRoutes.sendWhatsappTemplate,
        {
          template: WHATSAPP_TEMPLATE_QUESTIONNAIRE_ADULT,
          phone,
          params: [firstName],
        }
      );

      return response.data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[""][0]
      ) {
        throw { smileMessage: e.response.data.errors[""][0] };
      }

      throw e;
    }
  }

  private reformatChatHistoryForDisplay(
    chatHistory: WhatsappChatHistoryApiResponse
  ): Array<WhatsappDate | WhatsappSentMessage | WhatsappReceivedMessage> {
    const chatMessages: Array<WhatsappSentMessage | WhatsappReceivedMessage> = [
      ...chatHistory.receivedMessages,
      ...chatHistory.sentMessages,
    ].sort((a, b) => a.created_at.localeCompare(b.created_at));

    const displayChatHistory = [];
    for (let i = 0; i < chatMessages.length; i++) {
      if (
        i === 0 ||
        date(chatMessages[i].created_at) !==
          date(chatMessages[i - 1].created_at)
      ) {
        displayChatHistory.push({ date: chatMessages[i].created_at });
      }

      displayChatHistory.push(chatMessages[i]);
    }

    return displayChatHistory;
  }
}

export default new WhatsappNoteApi();
