





























































































































































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { date, time } from "@/filters/date.filter";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import { PaymentIncome } from "@/modules/patient/types/payment.type";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import moment from "moment";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import PatientApi from "@/modules/patient/api/patient.api";
import { Pinkas } from "../../pinkas/types/pinkas.type";
import PaymentProviderVerifoneAction from "@/modules/payment-verifone/components/PaymentProviderVerifoneAction.vue";
import PaymentProviderTranzilaAction from "@/modules/payment-tranzila/components/PaymentProviderTranzilaAction.vue";

interface MorePatient {
  balance: number;
  patient: Patient;
  receivingAmount: number;
}
@Component({
  components: {
    SearchPatient,
    PaymentProviderVerifoneAction,
    PaymentProviderTranzilaAction,
  },
  filters: {
    date,
    time
  }
})
export default class ReceivePaymentDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: true }) public patientBalance: number;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  @Getter("auth/defaultPaymentDescription") defaultPaymentDescription: string;
  @Getter("auth/creditCardDriver") creditCardDriver: string;

  public pinkasId: number = null;
  public paymentIncomes: PaymentIncome[] = [];
  public morePatients: MorePatient[] = [];
  public showSearchPatientInput = false;
  public isLoadingPatient = false;
  public isHeshbonitDeal = false;
  public totalDealAmount = "0";
  public displayIncomeDatePicker: { [key: number]: boolean } = {};
  public description = "";
  public notes = "";
  public showSplitPaymentSection = false;
  public patientReceivingAmount: number = null;
  public showErrors = false;
  public isSubmitLoading = false;
  public displayCreditCardDriverDialog = false;
  public creditCardReceivedWarn = false;

  public onTotalAmountDealBlured() {
    this.totalDealAmount = parseFloat(this.totalDealAmount).toFixed(2).replace(".00", "");
  }

  public mounted() {
    if (this.$store.getters["auth/isUsingFirstPinkasDefault"]) {
      this.pinkasId = this.pinkasList[0].id;
    }

    this.description = this.defaultPaymentDescription;

    this.addPaymentIncome();

    this.patient.family_balance.forEach(({ balance, patient }) => {
      this.morePatients.push({
        balance: balance * -1,
        patient,
        receivingAmount: 0,
      });
    });

    document.addEventListener("wheel", function () {
      if ((document.activeElement as any).type === "number") {
        (document.activeElement as any).blur();
      }
    });

  }

  public dateFormatted(date: string) {
    return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  }

  public removeMorePatient(morePatientIndex: number) {
    this.morePatients = this.morePatients.filter((morePatient, index) => (index !== morePatientIndex));
    this.updateSplitPayment();
  }

  public async onSelectedPatient(selectedPatient: Patient) {
    this.isLoadingPatient = true;
    this.showSearchPatientInput = false;
    const patient = await PatientApi.get(selectedPatient.id);

    let balance = 0;
    patient.treatments.forEach(treatment => {
      balance += treatment.price - treatment.discount;
    });
    patient.payments.forEach(payment => {
      if (!payment.deleted_at) {
        balance -= payment.amount;
      }
    });


    if (patient.id !== this.patient.id && !this.morePatients.map(({ patient }) => (patient.id)).includes(patient.id)) {
      this.morePatients.push({
        balance,
        patient,
        receivingAmount: null
      });
    }

    this.updateSplitPayment();
    this.isLoadingPatient = false;
  }

  public updateSplitPayment() {
    let payingAmount = this.totalAmount;

    if (this.morePatients.length === 0) {
      this.patientReceivingAmount = payingAmount;
      return;
    }

    if (this.patientBalance > payingAmount) {
      this.patientReceivingAmount = Number(payingAmount.toFixed(2));
      this.morePatients.forEach((morePatient) => {
        morePatient.receivingAmount = 0;
      });

      if (!this.morePatients.find(({ balance }) => (balance < 0))) {
        return;
      }
    }

    this.patientReceivingAmount = Math.min(payingAmount, this.patientBalance);
    payingAmount -= parseFloat((this.patientReceivingAmount || 0).toString());
    [...this.morePatients].sort((a, b) => {
      if (a.balance < b.balance) return -1;
      if (a.balance > b.balance) return 1;
      return 0;
    }).forEach((morePatient) => {
      morePatient.receivingAmount = Math.min(payingAmount, morePatient.balance) === 0 ? 0 : Math.min(payingAmount, morePatient.balance); // trick to avoid -0
      morePatient.receivingAmount = Number(Number(morePatient.receivingAmount.toString()).toFixed(2));
      payingAmount -= morePatient.receivingAmount;
    });

    this.patientReceivingAmount += payingAmount;
    this.patientReceivingAmount = Number(this.patientReceivingAmount.toFixed(2));
  }

  get totalReceiving() {
    const totalReceiving = parseFloat((this.patientReceivingAmount || 0).toString()) + this.morePatients.map(({ receivingAmount }) => (parseFloat((receivingAmount || 0).toString())))
      .reduce((a, b) => (a + b), 0);

    return parseFloat(totalReceiving.toFixed(2));
  }

  public async submit() {
    this.showErrors = true;

    if (this.description.trim().length <= 1) {
      return;
    }

    for (let i = 0; i < this.paymentIncomes.length; i++) {
      if (!this.paymentIncomes[0].type) {
        return;
      }
      if (!this.paymentIncomes[i].amount || !(parseFloat(this.paymentIncomes[i].amount.toString()) >= 1)) {
        return;
      }

      if (this.paymentIncomes[i].type === "check" && (!this.paymentIncomes[i].check_info.account.trim() || !this.paymentIncomes[i].check_info.branch.trim() || !this.paymentIncomes[i].check_info.bank.trim() || !this.paymentIncomes[i].check_info.check_number.trim())) {
        return;
      }

      if (this.paymentIncomes[i].type === "credit_card") {
        if (this.isFirstAmountError(this.paymentIncomes[i])) {
          return;
        }
        if (this.isNextAmountError(this.paymentIncomes[i])) {
          return;
        }
      }
    }

    try {
      this.creditCardReceivedWarn = false;
      this.isSubmitLoading = true;

      const paymentSplit: { [patientId: number]: number } = { [this.patient.id]: this.patientReceivingAmount };
      this.morePatients.forEach(morePatient => {
        paymentSplit[morePatient.patient.id] = morePatient.receivingAmount;
      });

      await PatientApi.receivePayment(this.patient.id, {
        notes: this.notes,
        description: this.description,
        paymentSplit,
        incomes: this.paymentIncomes,
        pinkasId: this.pinkasId,
      });

      this.isSubmitLoading = false;
      this.$toastr.s(this.$t("payment_received_toastr"));
      this.$emit("paymentReceived");
      await this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isSubmitLoading = false;
    }
  }

  public async submitDeal() {
    this.showErrors = true;

    if (this.description.trim().length <= 1) {
      return;
    }


    if (parseFloat(this.totalDealAmount) <= 0) {
      return;
    }

    try {
      this.creditCardReceivedWarn = false;
      this.isSubmitLoading = true;

      await PatientApi.createHeshbonitDeal(this.patient.id, {
        notes: this.notes,
        description: this.description,
        pinkasId: this.pinkasId,
        totalDealAmount: parseFloat(this.totalDealAmount),
      });

      this.isSubmitLoading = false;
      this.$toastr.s(this.$t("heshbonit_deal_created_toastr"));
      this.$emit("paymentReceived");
      await this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isSubmitLoading = false;
    }
  }

  public creditCardPaymentClicked() {
    this.displayCreditCardDriverDialog = true;
  }

  public addPaymentIncome() {
    this.paymentIncomes.push({
      type: "",
      amount: null,
      check_info: {
        date: moment().format("YYYY-MM-DD"),
        bank: "",
        account: "",
        branch: "",
        check_number: "",
      },
      bank_transfer_info: {
        date: moment().format("YYYY-MM-DD"),
        bank: "",
        account: "",
        branch: "",
      },
      credit_card_info: {
        date: moment().format("YYYY-MM-DD"),
        company: "",
        card_number: "",
        expiry_date: "",
        type: "רגיל",
        num_payments: 2,
        first_payment_amount: null,
        next_payment_amount: null,
      },
    });
  }

  public onChangeToHeshbonitDealClicked() {
    this.isHeshbonitDeal = true;
  }

  public async closeDialog() {
    if (this.creditCardReceivedWarn) {
      const res = await this.$confirm(
        this.$t("credit_card_received_warn_message").toString(),
        {
          buttonTrueColor: "red",
          buttonTrueText: this.$t("credit_card_received_warn_ignore_btn").toString(),
          buttonFalseText: this.$t("credit_card_received_warn_cancel_btn").toString(),
        });

      if (!res) {
        return;
      }
    }

    this.$emit("close");
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  public removeIncome(removeIndex: number) {
    this.paymentIncomes = this.paymentIncomes.filter((income, index) => (removeIndex !== index));
    this.updateSplitPayment();
  }

  public duplicateIncome(income: PaymentIncome) {
    const newIncome: PaymentIncome = JSON.parse(JSON.stringify(income));
    this.paymentIncomes.push(newIncome);
    this.updateSplitPayment();
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get totalPatientsAmount() {
    return this.morePatients
      .map(({ balance }) => (balance))
      .reduce((a, b) => (a + b))
      + this.patientBalance;
  }

  get totalAmount() {
    if (this.paymentIncomes.length === 0) {
      return 0;
    }

    const totalAmount = this.paymentIncomes
      .map(treatment => (parseFloat(treatment.amount ? treatment.amount.toString() : "0")))
      .reduce((a, b) => (a + b));

    return parseFloat(totalAmount.toFixed(2));
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get creditCardTypes() {
    return [
      { text: this.$t("regular"), value: "רגיל" },
      { text: this.$t("payments"), value: "תשלומים" },
      { text: this.$t("credit"), value: "קרדיט" },
    ];
  }

  get creditCardCompanies() {
    return [
      { text: this.$t("unknown"), value: "לא ידוע" },
      { text: this.$t("isracard"), value: "ישראכרט" },
      { text: this.$t("visa"), value: "ויזה" },
      { text: this.$t("mastercard"), value: "מאסטרקארד" },
      { text: this.$t("american_express"), value: "אמריקן אקספרס" },
      { text: this.$t("max"), value: "מקס" },
      { text: this.$t("diners"), value: "דיינרס" },
    ];
  }

  get paymentIncomeTypes() {
    return [
      { text: this.$t("credit_card"), value: "credit_card" },
      { text: this.$t("cash"), value: "cash" },
      { text: this.$t("check"), value: "check" },
      { text: this.$t("bank_transfer"), value: "bank_transfer" },
    ];
  }

  public onCreditTypeChanged(income: PaymentIncome) {
    this.onAmountChanged(income);
  }

  public onPaymentsNumPaymentsChanged(income: PaymentIncome) {
    this.onAmountChanged(income);
  }

  public onAmountChanged(income: PaymentIncome) {
    if (income.amount) {
      income.amount = Number(Number(income.amount.toString()).toFixed(2));
    }

    if (income.type !== "credit_card" || income.credit_card_info.type !== "תשלומים" || !income.amount || income.credit_card_info.num_payments <= 1) {
      return;
    }

    income.credit_card_info.next_payment_amount = Number(Math.floor(parseFloat(income.amount.toString()) / income.credit_card_info.num_payments).toString());
    income.credit_card_info.first_payment_amount = Number((parseFloat(income.amount.toString()) - ((income.credit_card_info.num_payments - 1) * parseFloat(income.credit_card_info.next_payment_amount.toString()))).toFixed(2));
  }

  public onFirstAmountChanged(income: PaymentIncome) {
    if (!parseFloat((income.amount || 0).toString())) {
      return;
    }
    if (parseFloat(income.credit_card_info.first_payment_amount.toString()) >= parseFloat(income.amount.toString()) || !parseFloat(income.amount.toString()) || !parseFloat(income.credit_card_info.first_payment_amount.toString())) {
      return;
    }

    income.credit_card_info.next_payment_amount = Number(((parseFloat(income.amount.toString()) - parseFloat(income.credit_card_info.first_payment_amount.toString())) / (income.credit_card_info.num_payments - 1)).toFixed(2));
  }

  public onNextAmountChanged(income: PaymentIncome) {
    if (!parseFloat((income.amount || 0).toString())) {
      return;
    }
    if (parseFloat(income.credit_card_info.next_payment_amount.toString()) >= parseFloat(income.amount.toString()) || !parseFloat(income.amount.toString()) || !parseFloat(income.credit_card_info.next_payment_amount.toString())) {
      return;
    }

    income.credit_card_info.first_payment_amount = Number((parseFloat(income.amount.toString()) - ((income.credit_card_info.num_payments - 1) * parseFloat(income.credit_card_info.next_payment_amount.toString()))).toFixed(2));
  }

  get paymentsNumPaymentsOptions() {
    const options = [];
    for (let i = 2; i <= 18; i++) {
      options.push({ text: i.toString(), value: i });
    }
    return options;
  }

  public roundPatientReceivingAmount() {
    if (this.patientReceivingAmount) {
      this.patientReceivingAmount = Number(Number(this.patientReceivingAmount.toString()).toFixed(2));
    }
  }

  public roundMorePatientReceivingAmount(morePatient: MorePatient) {
    if (morePatient.receivingAmount) {
      morePatient.receivingAmount = Number(Number(morePatient.receivingAmount.toString()).toFixed(2));
    }
  }


  public isFirstAmountError(income: PaymentIncome) {
    if (income.credit_card_info.type !== "תשלומים") {
      return false;
    }
    const paymentsFirstAmount = parseFloat(income.credit_card_info.first_payment_amount.toString());
    if (!paymentsFirstAmount) {
      return true;
    }
    if (paymentsFirstAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsFirstAmount.toFixed(2)) < paymentsFirstAmount) {
      return true;
    }

    return false;
  }

  public isNextAmountError(income: PaymentIncome) {
    if (income.credit_card_info.type !== "תשלומים") {
      return false;
    }
    const paymentsNextAmount = parseFloat(income.credit_card_info.next_payment_amount.toString());
    if (!paymentsNextAmount) {
      return true;
    }
    if (paymentsNextAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsNextAmount.toFixed(2)) < paymentsNextAmount) {
      return true;
    }

    if (paymentsNextAmount * (income.credit_card_info.num_payments - 1) + parseFloat(income.credit_card_info.first_payment_amount.toString()) !== parseFloat(income.amount.toString())) {
      return true;
    }

    return false;
  }

  public cardPaymentReceived(company: string, expiryDate: string, cardNumber: string, amount: number, paymentType: string, numPayments: number, firstPaymentAmount: number, nextPaymentAmount: number) {
    this.displayCreditCardDriverDialog = false;
    this.creditCardReceivedWarn = true;
    if (this.paymentIncomes.length === 1 && this.paymentIncomes[0].type === "" && this.paymentIncomes[0].amount === null) {
      this.paymentIncomes.splice(0, 1);
    }

    this.paymentIncomes.push({
      type: "credit_card",
      amount: amount,
      check_info: {
        date: moment().format("YYYY-MM-DD"),
        bank: "",
        account: "",
        branch: "",
        check_number: "",
      },
      bank_transfer_info: {
        date: moment().format("YYYY-MM-DD"),
        bank: "",
        account: "",
        branch: "",
      },
      credit_card_info: {
        date: moment().format("YYYY-MM-DD"),
        company,
        card_number: cardNumber,
        expiry_date: expiryDate,
        type: paymentType,
        num_payments: numPayments,
        first_payment_amount: firstPaymentAmount,
        next_payment_amount: nextPaymentAmount,
      },
    });

    this.updateSplitPayment();
  }

  get isTranzilaEmvEnabled() {
    return !!this.pinkasList.find(
      ({ id, is_tranzila_enabled, tranzila_public_attributes }) =>
        (id === this.pinkasId && is_tranzila_enabled && tranzila_public_attributes.is_emv_terminal_enabled)
    );
  }

}
