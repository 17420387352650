import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "./plugins/i18n";
import VueToastr from "vue-toastr";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import VuetifyConfirm from "vuetify-confirm";
import "@/registerServiceWorker";
import axios from "axios";
import ModuleRegistrar from "@/core/module-registrar/module-registrar";
import timeClockModule from "@/modules/time-clock";
import usersModule from "@/modules/users";
import patientFileModule from "@/modules/patient-file";
import patientModule from "@/modules/patient";
import settingsModule from "@/modules/settings";
import authModule from "@/modules/auth";
import AppRequest from "./serivce/request";
import { AppStoreState } from "@/store/store.types";
import { Store } from "vuex";
import contactsModule from "@/modules/contacts";
import notificationsModule from "@/modules/notifications";
import { AppConfig } from "@/core/config/config";
import { initFullStory } from "./core/fullstory/fullstory";
import { identifyUser, initSentry } from "./core/sentry/sentry";
import reportsModule from "./modules/reports";
import { VueMaskDirective } from "v-mask";
import supplierModule from "./modules/supplier";

Vue.directive("mask", VueMaskDirective);
Vue.component("smile-error-message", ErrorMessage);

axios.defaults.baseURL = AppConfig.apiBaseUrl;
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueToastr, {
    defaultPosition: "toast-bottom-center"
});

initSentry(router);

Vue.config.productionTip = false;

AppRequest.bind(store, router);

const moduleRegistrar = new ModuleRegistrar(store as Store<AppStoreState>, router);
moduleRegistrar.register(authModule);
moduleRegistrar.register(timeClockModule);
moduleRegistrar.register(usersModule);
moduleRegistrar.register(patientFileModule);
moduleRegistrar.register(patientModule);
moduleRegistrar.register(settingsModule);
moduleRegistrar.register(contactsModule);
moduleRegistrar.register(notificationsModule);
moduleRegistrar.register(reportsModule);
moduleRegistrar.register(supplierModule);

new Vue({
    router,
    store,
    vuetify,
    i18n: i18n(store.getters["auth/locale"]),
    render: h => h(App),
    created() {
        this.$vuetify.lang.current = this.$store.getters["auth/locale"];

        if (store.getters["auth/isLogged"]) {
            initFullStory(this.$store.getters["auth/userEmail"]);
            identifyUser(this.$store.getters["auth/userEmail"]);
        }
    }
}).$mount("#app");
