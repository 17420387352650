



































































































import { Component, Prop, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import TaskApi from "../api/task.api";
import { Task, TaskRecurring } from "../types/task.type";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import { Patient } from "@/modules/patient/types/patient.type";
import axios, { CancelTokenSource } from "axios";
import PatientApi from "@/modules/patient/api/patient.api";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import moment from "moment";

@Component({
  components: {
  },
})
export default class TaskFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public editTask: Task | TaskRecurring;
  @Prop({ required: false }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/taskUsers") taskUsers: User[];
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public isDirty = false;
  private activePatientSearchRequest: CancelTokenSource = null;
  public isRecurring = false;
  public recurringInterval = 1;
  public recurringEvery = "weeks";
  public daysOfWeek: number[] = [];
  public hideNoData = true;
  public isFormLoading = false;
  public displayTaskDatePicker = false;
  public taskDate = moment().utc().format("YYYY-MM-DD");
  public isLoadingPatient = false;
  public showErrors = false;
  public assigneeUserId: number = null;
  public patientSearch = "";
  public urgency = 2;
  public isLoadingDelete = false;
  public notes = "";
  public taskPatient: Patient = null;
  public disablePatientSearch = false;
  public patientSearchEntries: Patient[] = [];
  public showRecurring = false;

  public markDirty() {
    this.isDirty = true;
  }

  @Watch("patientSearch")
  public async onPatientSearchChanged(query: string) {
    if (this.disablePatientSearch) {
      return;
    }
    this.hideNoData = true;
    this.isLoadingPatient = true;
    this.patientSearchEntries = [];

    // Lazily load input items
    if (!query || query.length === 0) {
      this.isLoadingPatient = false;
      return;
    }

    if (this.activePatientSearchRequest) {
      this.activePatientSearchRequest.cancel();
    }
    this.activePatientSearchRequest = axios.CancelToken.source();

    try {
      const response = await PatientApi.quickSearch(query, false, this.activePatientSearchRequest);
      this.hideNoData = response.data.data.length !== 0;
      this.patientSearchEntries = response.data.data;
      this.isLoadingPatient = false;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingPatient = false;
    }
  }

  @Watch("patient")
  onPatientChanged(selectedPatientId: number) {
    const patient = this.patientSearchEntries.find(({ id }) => (id === selectedPatientId));
    if (!patient) {
      // TODO log to sentry / coralogix
      return;
    }

    this.markDirty();
    this.disablePatientSearch = true;
    setTimeout(() => {
      this.disablePatientSearch = false;
    }, 500);

    this.taskPatient = patient;
    this.hideNoData = true;
  }

  get taskDateFormatted() {
    return moment(this.taskDate).format("DD/MM/YYYY");
  }

  public mounted() {

    if (this.editTask) {
      this.assigneeUserId = this.editTask.assignee_user_id;
      this.urgency = this.editTask.urgency;
      this.notes = this.editTask.notes;
      if (this.editTask.patient) {
        this.taskPatient = this.editTask.patient;
        this.patientSearch = this.fullName(this.taskPatient);
      }

      if ("recurring_interval" in this.editTask) {
        this.showRecurring = true;
        this.isRecurring = true;
        this.recurringInterval = this.editTask.recurring_interval;
        this.recurringEvery = this.editTask.recurring_every;
        this.taskDate = this.editTask.start_date;
        if (this.editTask.recurring_every === "days") {
          this.daysOfWeek = this.editTask.attributes.daysOfWeek;
        }
      } else {
        this.taskDate = this.editTask.task_date;
      }

    } else if (this.patient) {
      this.taskPatient = this.patient;
      this.patientSearch = this.fullName(this.taskPatient);
      this.showRecurring = true;
    } else {
      this.showRecurring = true;
    }

    if (!this.assigneeUserId && !this.editTask && this.taskUsers.length === 1) {
      this.assigneeUserId = this.taskUsers[0].id;
    }
  }

  get urgencyOptions() {
    return [3, 2, 1]
      .map(urgency => ({
        "text": this.$t("urgency." + urgency),
        "value": urgency
      }));
  }

  get daysOfWeekOptions() {
    return [1, 2, 3, 4, 5, 6, 7]
      .map(dayOfWeek => ({
        "text": this.$t("day_of_week." + dayOfWeek),
        "value": dayOfWeek
      }));
  }

  get recurringEveryOptions() {
    return ["days", "weeks", "months", "years"]
      .map(every => ({
        "text": this.$t("recurring_every." + (this.recurringInterval === 1 ? every.slice(0, -1) : every)),
        "value": every
      }));
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onDeleteClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    this.isLoadingDelete = true;
    try {
      if ("recurring_interval" in this.editTask) {
        await TaskApi.deleteRecurring(this.editTask.id);
      } else {
        await TaskApi.delete(this.editTask.id);
      }
      this.$emit("taskDeleted", this.editTask.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.isLoadingDelete = false;
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingDelete = false;
    }
  }

  public fullName(patient: Patient) {
    return `${patient.first_name} ${patient.last_name}`;
  }

  get datePickerMinDate() {
    return moment().utc().format("YYYY-MM-DD");
  }

  public async submitForm() {
    this.showErrors = true;

    if (!this.assigneeUserId || !this.notes.trim()) {
      return;
    }

    if (this.isRecurring && this.recurringEvery === "days" && this.daysOfWeek.length === 0) {
      return;
    }

    this.isFormLoading = true;

    try {
      if (this.isRecurring) {
        const payload = {
          creator_user_id: this.editTask ? this.editTask.creator_user_id : this.$store.getters["auth/userId"],
          assignee_user_id: this.assigneeUserId,
          patient_id: this.taskPatient ? this.taskPatient.id : null,
          urgency: this.urgency,
          notes: this.notes,
          recurring_interval: this.recurringInterval,
          recurring_every: this.recurringEvery,
          start_date: this.taskDate,
          attributes: {
            daysOfWeek: this.daysOfWeek,
          },
        };

        const taskRecurring = this.editTask
          ? await TaskApi.updateRecurring(this.editTask.id, payload)
          : await TaskApi.createRecurring(payload);

        this.$emit(this.editTask ? "taskUpdated" : "taskCreated", taskRecurring);

      } else {
        const payload = {
          creator_user_id: this.editTask ? this.editTask.creator_user_id : this.$store.getters["auth/userId"],
          assignee_user_id: this.assigneeUserId,
          patient_id: this.taskPatient ? this.taskPatient.id : null,
          urgency: this.urgency,
          notes: this.notes,
          task_date: this.taskDate,
        };

        const task = this.editTask
          ? await TaskApi.update(this.editTask.id, payload)
          : await TaskApi.create(payload);

        this.$emit(this.editTask ? "taskUpdated" : "taskCreated", task);
      }

      this.$toastr.s(this.$t(this.editTask ? "task_updated" : "task_created"));
      this.isFormLoading = false;
      this.closeDialog();
    } catch (err) {
      this.isFormLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }
}
