export enum ContactApiRoutes {
    getAll = "/contact",
    create = "/contact",
    update = "/contact/{id}",
    delete = "/contact/{id}",
}

export enum ContactApiRouteParameters {
    id = "{id}",
}

export enum ContactBookmarkApiRouteParameters {
    id = "{id}",
}

export enum ContactBookmarkApiRoutes {
    getAll = "/contact-bookmark",
    create = "/contact-bookmark",
    update = "/contact-bookmark/{id}",
    delete = "/contact-bookmark/{id}",
}
