import { render, staticRenderFns } from "./ManageSupplierPaymentCategoriesPage.vue?vue&type=template&id=79656ef3&"
import script from "./ManageSupplierPaymentCategoriesPage.vue?vue&type=script&lang=ts&"
export * from "./ManageSupplierPaymentCategoriesPage.vue?vue&type=script&lang=ts&"
import style0 from "./ManageSupplierPaymentCategoriesPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/modules/settings/locales/settings-supplier-payment-categories.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fsettings%2Fpages%2FManageSupplierPaymentCategoriesPage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports