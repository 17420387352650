export enum WhatsappApiRoutes {
  getLastContacts = "/whatsapp",
  getChatHistory = "/whatsapp/{phone}",
  sentAppointmentReminder = "/whatsapp",
  markContactRead = "/whatsapp/mark-read",
  sendWhatsappTemplate = "/whatsapp/template",
  sendWhatsappTemplateImage = "/whatsapp/template-image",
  sendWhatsappTemplatePdf = "/whatsapp/template-pdf",
  sendWhatsappFutureAppintments = "/whatsapp/future-appointments",
  sendWhatsappSinglePatientFutureAppintments = "/whatsapp/single-patient-future-appointments",
  sendFreeText = "/whatsapp/send-free-text",
}

export enum WhatsappApiRouteParameters {
  phone = "{phone}",
}
