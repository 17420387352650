import {AppModule} from "@/types/app-module";
import patientRoutes from "@/modules/patient/routes/routes";
import patientStore from "@/modules/patient/store";
import patientPolicy from "@/modules/patient/policy";

const patientModule: AppModule = {
    name: "patient",
    routes: patientRoutes,
    store: patientStore,
    policy: patientPolicy,
};

export default patientModule;
