import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"400","persistent":""},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"patient-file-send-email-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,[_vm._v("\n      "+_vm._s(_vm.$t('share_contacts_title'))+"\n      "),_c(VSpacer),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0"},[_vm._v("\n      "+_vm._s(_vm.$t('share_contacts_sub_title'))+"\n    ")]),_c(VCardText,[_c(VAutocomplete,{attrs:{"multiple":"","items":_vm.contacts,"item-text":"name","item-value":"id","color":"cyan darken-1","item-color":"cyan darken-1","filter":_vm.onAdvancedContactSearch},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
var attrs = ref.attrs;
return [_c(VSimpleCheckbox,_vm._g({attrs:{"value":attrs.inputValue,"ripple":false}},on)),_c(VListItemContent,{staticStyle:{"display":"inline"}},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.name)}}),(item.category)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.category)}}):_vm._e()],1)]}}]),model:{value:(_vm.contactsIds),callback:function ($$v) {_vm.contactsIds=$$v},expression:"contactsIds"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"cyan darken-1","text":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.openPatientCreateLetterDialog()}}},[_vm._v("\n        "+_vm._s(_vm.$t('create_letter'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }