























import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";

@Component({
})
export default class ReportItem extends mixins(SmileMixin) {
  @Prop({ required: true }) public title: string;
  @Prop({ required: true }) public subTitle: string;

}
