import Vue from "vue";
import Vuetify from "vuetify/lib";
import he from "vuetify/es5/locale/he";
import en from "vuetify/es5/locale/en";
import store from "./../store";

Vue.use(Vuetify);

export default new Vuetify({
    rtl: store.getters["auth/isRtl"],
    lang: {
        locales: {he, en},
        current: store.getters["auth/locale"]
    }
});
