import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { Tag } from "@/modules/patient/types/tag.type";
import { TagApiRouteParameters, TagApiRoutes } from "@/modules/settings/constants/api-routes";

class TagApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }


  async getAll(): Promise<Tag[]> {
    try {
      const response: ApiResponse<Tag[]> = await this.httpClient.get(TagApiRoutes.getAll);

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        TagApiRoutes.delete.replace(TagApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, name: string, color: string, order: number): Promise<Tag> {
    try {
      const response: ApiResponse<Tag> = await this.httpClient.put(
        TagApiRoutes.update.replace(TagApiRouteParameters.id, id.toString()),
        {
          name,
          color,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(name: string, color: string): Promise<Tag> {
    try {
      const response: ApiResponse<Tag> = await this.httpClient.post(
        TagApiRoutes.create,
        {
          name,
          color,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

}

export default new TagApi();
