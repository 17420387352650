import {RouteConfig} from "vue-router";
import UsersPage from "../pages/UsersPage.vue";
import {USERS_ROUTE_NAME, USERS_ROUTE_PATH} from "../constants/route-config";

const usersRoutes: RouteConfig[] = [
    {
        name: USERS_ROUTE_NAME,
        path: USERS_ROUTE_PATH,
        component: UsersPage,
    }
];

export default usersRoutes;
