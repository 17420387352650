





































import { Tag } from "@/modules/patient/types/tag.type";
import TagApi from "../../../../serivce/api/tag-api";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    ErrorLogo,
  },
})
export default class TagForm extends Vue {
  @Prop({ required: false }) editTag: Tag;

  public errors: any = false;
  public name = "";
  public color = "#ffd7f1";
  public order: number = null;
  public loading = false;
  public deleteLoading = false;

  public mounted() {
    if (this.editTag) {
      this.name = this.editTag.name;
      this.order = this.editTag.order;
      this.color = this.editTag.color;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    if (this.loading) return;

    this.loading = true;
    this.errors = false;

    try {

      const tag = this.editTag
        ? await TagApi.update(this.editTag.id, this.name, this.color, this.order)
        : await TagApi.create(this.name, this.color);

      this.loading = false;
      this.$store.commit("auth/setTag", tag);
      this.$toastr.s(this.$t(this.editTag ? "tag_updated" : "tag_created"));
      this.closeDialog();
      this.$emit("reload", tag);

    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    }
  }

  public async deleteButtonClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message", { "name": this.editTag.name }).toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });
    if (!res) {
      return;
    }

    this.deleteLoading = true;
    try {
      await TagApi.delete(this.editTag.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.$emit("reload");
      this.deleteLoading = false;
    }
  }
}
