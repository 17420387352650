import { RouteConfig } from "vue-router";
import SuppliersPage from "../pages/SuppliersPage.vue";
import ExpensesPage from "../pages/ExpensesPage.vue";
import {
  SUPPLIERS_ROUTE_NAME,
  SUPPLIERS_ROUTE_PATH,
  EXPENSES_ROUTE_NAME,
  EXPENSES_ROUTE_PATH,
} from "@/modules/supplier/constants/route-config";

const taskRoutes: RouteConfig[] = [
  {
    name: SUPPLIERS_ROUTE_NAME,
    path: SUPPLIERS_ROUTE_PATH,
    component: SuppliersPage,
  },
  {
    name: EXPENSES_ROUTE_NAME,
    path: EXPENSES_ROUTE_PATH,
    component: ExpensesPage,
  },
];

export default taskRoutes;
