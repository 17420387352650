
























































































import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import EchoService from "@/serivce/echo-service";
import PaymentVerifoneApi from "../api/payment-verifone.api";
import moment from "moment";
import { PaymentVerifoneReportTransaction } from "../types/payment-verifone.type";

@Component({
})
export default class PaymentProviderVerifoneAction extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  public paymentType = "card";
  public creditTerm = "regular";
  public amount = "";
  public creditNumPayments = 3;
  public paymentsNumPayments = 2;
  public paymentsFirstAmount = "";
  public paymentsNextAmount = "";
  public smileUuid = "";
  public showErrors = false;
  public smileStatus: number = null;
  public timeExpiredForStatus = false;
  public verifoneUid = "";

  public closeDialog() {
    this.$emit("close");
  }

  private generateUID() {
    return ("000" + ((Math.random() * 46656) | 0).toString(36)).slice(-3) +
      ("000" + ((Math.random() * 46656) | 0).toString(36)).slice(-3);
  }

  get isAmountError() {
    const amount = parseFloat(this.amount);
    if (!amount) {
      return true;
    }
    if (amount < 0) {
      return true;
    }

    if (parseFloat(amount.toFixed(2)) < amount) {
      return true;
    }

    return false;
  }

  get isFirstAmountError() {
    if (this.creditTerm !== "payments") {
      return false;
    }
    const paymentsFirstAmount = parseFloat(this.paymentsFirstAmount);
    if (!paymentsFirstAmount) {
      return true;
    }
    if (paymentsFirstAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsFirstAmount.toFixed(2)) < paymentsFirstAmount) {
      return true;
    }

    return false;
  }

  get isNextAmountError() {
    if (this.creditTerm !== "payments") {
      return false;
    }
    const paymentsNextAmount = parseFloat(this.paymentsNextAmount);
    if (!paymentsNextAmount) {
      return true;
    }
    if (paymentsNextAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsNextAmount.toFixed(2)) < paymentsNextAmount) {
      return true;
    }

    if (paymentsNextAmount * (this.paymentsNumPayments - 1) + parseFloat(this.paymentsFirstAmount) !== parseFloat(this.amount)) {
      return true;
    }

    return false;
  }

  public onCreditTypeChanged() {
    this.onAmountChanged();
  }

  public onPaymentsNumPaymentsChanged() {
    this.onAmountChanged();
  }

  public onAmountChanged() {
    if (this.creditTerm !== "payments" || !this.amount) {
      return;
    }

    this.paymentsNextAmount = Math.floor(parseFloat(this.amount) / this.paymentsNumPayments).toString();
    this.paymentsFirstAmount = (parseFloat(this.amount) - ((this.paymentsNumPayments - 1) * parseFloat(this.paymentsNextAmount))).toString();
  }

  public onFirstAmountChanged() {
    if (parseFloat(this.paymentsFirstAmount) >= parseFloat(this.amount) || !parseFloat(this.amount) || !parseFloat(this.paymentsFirstAmount)) {
      return;
    }

    this.paymentsNextAmount = ((parseFloat(this.amount) - parseFloat(this.paymentsFirstAmount)) / (this.paymentsNumPayments - 1)).toFixed(2);
  }

  public onNextAmountChanged() {
    if (parseFloat(this.paymentsNextAmount) >= parseFloat(this.amount) || !parseFloat(this.amount) || !parseFloat(this.paymentsNextAmount)) {
      return;
    }

    this.paymentsFirstAmount = (parseFloat(this.amount) - ((this.paymentsNumPayments - 1) * parseFloat(this.paymentsNextAmount))).toFixed(2);
  }

  public onRetryClicked() {
    this.timeExpiredForStatus = false;
    this.onStartEmvClicked();
  }

  public async onStartEmvClicked() {
    this.showErrors = true;

    if (this.isAmountError || this.isFirstAmountError || this.isNextAmountError) {
      return;
    }

    try {
      if (!this.smileUuid) {
        this.smileUuid = this.generateUID();
      }
      const amount = Number(Number(this.amount.toString()).toFixed(2)) * 100;
      const type = this.creditTerm;
      const isCardPresent = this.paymentType === "card" ? "1" : "0";
      const numPayments = this.creditTerm === "payments" ? this.paymentsNumPayments : (this.creditTerm === "credit" ? this.creditNumPayments : 1);
      const firstPaymentAmount = Number(Number(this.paymentsFirstAmount.toString()).toFixed(2)) * 100;
      const nextPaymentAmount = Number(Number(this.paymentsNextAmount.toString()).toFixed(2)) * 100;

      const newLocation = new URL(`smile-verifone://${this.smileUuid}/${amount}/${type}/${isCardPresent}/${numPayments}/${firstPaymentAmount}/${nextPaymentAmount}`);
      (window.location as any) = newLocation;

      setTimeout(() => {
        if (!this.smileStatus) {
          this.timeExpiredForStatus = true;
        }
      }, 8000);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  get paymentTypeOptions() {
    return [
      { text: this.$t("payment_type_card"), value: "card" },
      { text: this.$t("payment_type_manual"), value: "manual" },
    ];
  }

  destroyed() {
    EchoService.stopListenPaymentProviderVerifoneEventPush();
  }

  public mounted() {
    this.listenVerifoneStatusEvents();
  }

  private listenVerifoneStatusEvents() {
    EchoService.listenPaymentProviderVerifoneEventPush(async (eventData: {
      smileUuid: string;
      status: string;
      verifoneUid: string;
    }) => {
      if (this.smileUuid !== eventData.smileUuid) {
        return;
      }

      this.smileStatus = parseInt(eventData.status);
      this.verifoneUid = eventData.verifoneUid;
      if (this.smileStatus === 9) {

        try {
          const transaction = await PaymentVerifoneApi.getTransaction(moment().utc().format("YYYY-MM-DD"), moment().utc().format("YYYY-MM-DD"), this.verifoneUid);
          this.$toastr.s(this.$t("payment_received_toastr"));
          this.emitTransaction(transaction);
        } catch (err) {
          try {
            const transaction = await PaymentVerifoneApi.getTransaction(moment().utc().format("YYYY-MM-DD"), moment().utc().format("YYYY-MM-DD"), this.verifoneUid);
            this.$toastr.s(this.$t("payment_received_toastr"));
            this.emitTransaction(transaction);
          } catch (err) {
            this.$toastr.e(this.$t("loading_transaction_error"));
            this.closeDialog();
          }
        }
      }
    });
  }

  private emitTransaction(transaction: PaymentVerifoneReportTransaction) {
    const paymentType = transaction.credit_terms === "Credit" ? "קרדיט" : (transaction.credit_terms === "Installments" ? "תשלומים" : "רגיל");
    const company = ""; // TODO
    const expiryDate = ""; // TODO
    this.$emit("cardPaymentReceived", company, expiryDate, transaction.card_number, transaction.original_amount.replace(".00", ""), paymentType, parseInt(transaction.installment_count.replace(" Months", "")), transaction.payment_first_amount.replace(".00", ""), transaction.payment_not_first_amount.replace(".00", ""));
  }

  get creditTermOptions() {
    return [
      { text: this.$t("credit_terms_regular"), value: "regular" },
      { text: this.$t("credit_terms_payments"), value: "payments" },
      { text: this.$t("credit_terms_credit"), value: "credit" },
    ];
  }

  get creditNumPaymentsOptions() {
    const options = [];
    for (let i = 3; i <= 18; i++) {
      options.push({ text: i.toString(), value: i });
    }
    return options;
  }

  get paymentsNumPaymentsOptions() {
    const options = [];
    for (let i = 2; i <= 18; i++) {
      options.push({ text: i.toString(), value: i });
    }
    return options;
  }
}
