























































import { User } from "@/modules/users/types/user.type";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
})
export default class CalendarsPicker extends Vue {
  @Prop({ required: true }) displayUserIds: number[];
  @Prop({ required: true }) isDisplayCanceled: boolean;
  @Getter("auth/usersWithCalendar") usersWithCalendar: User[];
  @Getter("auth/userId") userId: number;
  @Getter("auth/assignedCalendarUserId") assignedCalendarUserId: number;
  @Getter("auth/isTherapist") isTherapist: boolean;
  @Getter("auth/userColor") currentUserColor: string;

  get moreCalendarsList() {
    return this.usersWithCalendar.filter(user => (user.id !== this.assignedCalendarUserId));
  }

  public isUserSelected(userId: number) {
    return this.displayUserIds.includes(userId);
  }

  get currentUserCalendarName() {
    const assignedCalendarUser = this.usersWithCalendar.find(user => user.id === this.assignedCalendarUserId);
    return assignedCalendarUser && assignedCalendarUser.name;
  }

  public showOnlyUser(userId: number) {
    this.$emit("updateDisplayUserIds", [userId]);
  }

  public onChangedDisplayUser(userId: number) {
    if (this.displayUserIds.includes(userId)) {
      this.$emit("updateDisplayUserIds", this.displayUserIds.filter(fUserId => (fUserId !== userId)));
    } else {
      this.$emit("updateDisplayUserIds", [...this.displayUserIds, userId]);
    }
  }

  public onChangedDisplayCanceled() {
    this.$emit("updateDisplayCanceled", !this.isDisplayCanceled);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
