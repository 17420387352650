import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c(VIcon,[_vm._v("mdi-timer-outline")]),_vm._v("\n          "+_vm._s(_vm.$t('time_entries'))+"\n\n          "),(_vm.isUserAdmin || _vm.isTimeClockEditEnabled)?_c(VBtn,{class:{ 'float-right ml-2': !_vm.isRtl, 'float-left mr-2': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){_vm.isAddingFormOpen = true}}},[_vm._v("\n            "+_vm._s(_vm.$t('add_btn'))+"\n            "),_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e(),_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){_vm.isLoggingFormOpen = true}}},[_vm._v("\n            "+_vm._s(_vm.$t('enter_out_btn'))+"\n            "),_c(VIcon,{staticStyle:{"margin-right":"3px"}},[_vm._v("mdi-progress-clock")])],1)],1)])],1),_c(VRow,{staticStyle:{"border-bottom":"1px solid #ccc"}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"6","md":"3","lg":"3","xl":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.$t('date_filter'),"readonly":"","color":"cyan darken-1"},model:{value:(_vm.formattedDateFilter),callback:function ($$v) {_vm.formattedDateFilter=$$v},expression:"formattedDateFilter"}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.dateMenuOpen),callback:function ($$v) {_vm.dateMenuOpen=$$v},expression:"dateMenuOpen"}},[(_vm.dateMenuOpen)?_c(VDatePicker,{attrs:{"color":"cyan darken-1","type":"month","no-title":""},on:{"input":function($event){_vm.dateMenuOpen = false}},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}):_vm._e()],1)],1),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"6","md":"3","lg":"3","xl":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.userOptions,"clearable":"","label":_vm.$t('user_label'),"color":"cyan darken-1","menu-props":{ maxHeight: 500 },"item-color":"cyan darken-1"},model:{value:(_vm.dispalyUserId),callback:function ($$v) {_vm.dispalyUserId=$$v},expression:"dispalyUserId"}})],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":1,"dense":"","headers":_vm.headers,"items":_vm.filteredEntries,"loading":_vm.isFetchingEntries || _vm.isEditingEntry,"loading-text":_vm.$t('loading'),"item-class":_vm.rowClass,"no-data-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.errors)?{key:"no-data",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.loadEntries()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c(VImg,{staticStyle:{"margin-bottom":"30px"},attrs:{"src":require('../../../assets/smile-logo-error.png'),"height":"80","contain":""}})]},proxy:true}:null,{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("date")(item.start)))])]}},{key:"item.day",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.formattedDay(item.start)))])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.userName(item.user_id)))])]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.type.name))]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [(item.start !== item.end || !item.notes)?_c('span',[_vm._v(_vm._s(_vm._f("time")(item.start)))]):_vm._e()]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [(item.end !== item.start)?_c('span',[_vm._v(_vm._s(_vm._f("time")(item.end)))]):_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.getDuration(item.start,
            item.end)))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4","disabled":!_vm.isUserAdmin && !_vm.isTimeClockEditEnabled},on:{"click":function($event){_vm.processedEntry = item}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n              "+_vm._s(_vm.$t('edit_btn'))+"\n            ")],1)]}}],null,true)})],1)],1)],1),(_vm.processedEntry)?_c('TimeClockManageForm',{attrs:{"time-clock":_vm.processedEntry},on:{"close":function($event){_vm.processedEntry = null},"updatedEntry":_vm.updateEntry,"deleted":_vm.removeEntry}}):_vm._e(),(_vm.isAddingFormOpen)?_c('TimeClockManageForm',{on:{"close":function($event){_vm.isAddingFormOpen = false},"createdEntry":_vm.createdEntry}}):_vm._e(),(_vm.isLoggingFormOpen)?_c('TimeClockLoggingDialog',{on:{"close":function($event){_vm.isLoggingFormOpen = false},"reload":function($event){return _vm.loadEntries()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }