






































import PriceListApi from "@/modules/treatment/api/price-list.api";
import PriceListForm from "../components/treatment/PriceListForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PriceList } from "@/modules/treatment/types/treatment.type";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    PriceListForm,
    SettingsHeader
  }
})
export default class ManagePriceListsPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public loadErrors: any = false;
  public showPriceListForm = false;
  public editPriceList: PriceList = null;
  public loading = false;
  public priceLists: PriceList[] = [];
  public headers = [
    { width: "30%", text: this.$t("headers.name"), value: "name" },
    { width: "30%", text: this.$t("headers.order"), value: "order" },
    { width: "20%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];


  public openPriceListFormDialog(priceList: PriceList) {
    this.editPriceList = priceList;
    this.showPriceListForm = true;
  }

  public closePriceListForm() {
    this.editPriceList = null;
    this.showPriceListForm = false;
  }


  public reload() {
    this.loadPriceLists();
  }

  public loadPriceLists() {
    this.loadErrors = null;
    this.loading = true;
    this.priceLists = [];

    PriceListApi.getAll()
      .then(priceLists => {
        this.loading = false;
        this.priceLists = priceLists;
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.loadErrors = err.response.data.errors;
        } else {
          this.loadErrors = this.$t("temporary_error");
        }
      });
  }

  public mounted() {
    this.loadPriceLists();
  }
}
