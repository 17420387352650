import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"smile-search-patient"},[_c(VAutocomplete,{key:_vm.key,attrs:{"flat":"","dense":"","solo":"","hide-details":"","items":_vm.entries,"loading":_vm.loading,"search-input":_vm.search,"color":"cyan darken-2","hide-no-data":_vm.hideNoData,"no-filter":"","item-text":_vm.fullName,"item-value":"id","item-color":"cyan darken-1","outlined":_vm.outlinedDesign,"label":_vm.$t('input_label'),"append-icon":"mdi-magnify","no-data-text":_vm.$t('no_data')},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.onPatientChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,{staticStyle:{"max-width":"220px"}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.fullName(item))+" "),_c('span',{class:[_vm.isRtl ? 'float-left' : 'float-right']},[_vm._v(_vm._s(item.id_number))])]),_c(VListItemSubtitle,[(item.phone)?_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-phone")]):_vm._e(),_vm._v("\n          "+_vm._s(item.phone)+"\n        ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }