






























































import { Component, Prop } from "vue-property-decorator";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { date, time } from "@/filters/date.filter";
import { Getter } from "vuex-class";

@Component({
  filters: {
    date,
    time,
  }
})
export default class TrackChangesAppointmentDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) appointment: Appointment;
  @Getter("auth/isRtl") isRtl: boolean;

  public closeDialog() {
    this.$emit("close");
  }

  get changesLogItems() {
    return this.appointment.changes_log.reverse();
  }
}
