

































import AuthApi from "../api/auth.api";
import SmileLogo from "@/components/Logo/SmileLogo.vue";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SmileLogo,
    ErrorMessage
  }
})
export default class ForgotPasswordPage extends Vue {
  public email = "";
  public loading = false;
  public success = false;
  public errors: any = false;

  public handleForgot() {
    this.loading = true;
    this.success = false;
    this.errors = false;

    AuthApi.forgotPassword(this.email)
      .then(() => {
        this.loading = false;
        this.success = true;
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }
}
