import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"time-entry-table",attrs:{"outlined":""}},[_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","elevation":"4"},on:{"click":function($event){return _vm.goPreviousMonth()}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1),_c(VSpacer),_vm._v("\n    "+_vm._s(_vm.$t('time_entries'))+" - "+_vm._s(_vm.fullName)+" - "+_vm._s(_vm.activeMonthName)+" "+_vm._s(_vm.activeYear)+"\n    "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","elevation":"4"},on:{"click":function($event){return _vm.goNextMonth()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":1,"dense":"","headers":_vm.headers,"items":_vm.entries,"loading":_vm.loadingEntries || _vm.isEditingEntry,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.errors)?{key:"no-data",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return this.$emit('changedMonth')}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c(VImg,{staticStyle:{"margin-bottom":"30px"},attrs:{"src":require('../../../../assets/smile-logo-error.png'),"height":"80","contain":""}})]},proxy:true}:null,{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.start)))]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.type.name))]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("time")(item.start)))]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("time")(item.end)))]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getDuration(item.start, item.end)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"hover",attrs:{"icon":""},on:{"click":function($event){_vm.processedEntry = item}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{staticClass:"hover",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEntry(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),(_vm.processedEntry)?_c('EditTimeClockEntry',{attrs:{"time-clock":_vm.processedEntry},on:{"close":function($event){_vm.processedEntry = null},"updatedEntry":_vm.$listeners['updatedEntry']}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }