










































































































































































import PatientApi from "../api/patient.api";
import { AppConfig } from "@/core/config/config";
import { Component, Watch } from "vue-property-decorator";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { age, date } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import moment from "moment/moment";
import axios from "axios";
import { Patient } from "@/modules/patient/types/patient.type";
import { PATIENT_ROUTE_NAME } from "../constants/route-config";
import { Getter } from "vuex-class";
import { PatientStatus } from "../types/patient-status.type";

const PatientForm = () => import("@/modules/patient/components/PatientForm/" + AppConfig.customPatientFormComponent + ".vue");

@Component({
  components: {
    PatientForm,
    ErrorLogo
  },
  filters: {
    age,
    date
  }
})

export default class PatientSearchTable extends mixins(SmileMixin) {
  @Getter("auth/patientStatuses") statuses: PatientStatus[];
  @Getter("auth/isRtl") isRtl: boolean;

  private activeRequest;
  public query = "";
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public loading = false;
  public totalItemsCount = 0;
  public patients: Patient[] = [];
  public selectedTags: number[] = null;
  public selectedExcludedTags: number[] = null;
  public selectedStatuses: number[] = [];
  public showArchived = false;
  public showWithoutFutureAppointments = false;
  public lastAppointmentFrom = "";
  public formattedDateStringFrom = "";
  public formattedDateStringTo = "";
  public lastAppointmentTo = "";
  public showAdvancedFilters = true;
  public dataTableOptions: any = {
    page: 1,
    sortBy: ["last_name", "first_name"],
    sortDesc: [true],
  };
  private validDateRegex = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/

  public applyDateSearchFrom() {
    if (!this.lastAppointmentFrom) {
      this.formattedDateStringFrom = "";
      this.resetPage();
    }
    const valid = this.validDateRegex.test(this.lastAppointmentFrom);
    if (!valid) {
      this.lastAppointmentFrom = "";
      return;
    }
    const convertedDate = this.lastAppointmentFrom.split("/").reverse().join("-");
    this.formattedDateStringFrom = convertedDate;
    this.resetPage();
  }

  public applyDateSearchTo() {
    if (!this.lastAppointmentTo) {
      this.formattedDateStringTo = "";
      this.resetPage();
    }
    const valid = this.validDateRegex.test(this.lastAppointmentTo);
    if (!valid) {
      this.lastAppointmentTo = "";
      return;
    }
    const convertedDate = this.lastAppointmentTo.split("/").reverse().join("-");
    this.formattedDateStringTo = convertedDate;
    this.resetPage();
  }

  get headers() {
    const headers = [
      { text: this.$t("headers.name"), value: "name", sortable: false },
      { text: this.$t("headers.age"), value: "birth_date", sortable: false },
      { text: this.$t("headers.phone"), value: "phone", sortable: false },
      { text: this.$t("headers.id_number"), value: "id_number", sortable: false },
      { text: this.$t("headers.last_appointment"), value: "last_appointment", sortable: false },
      { text: this.$t("headers.next_appointment"), value: "next_appointment", sortable: false },
      { text: this.$t("headers.tags"), value: "tags", sortable: false }
    ];

    if (this.statuses.length > 0) {
      headers.push({ text: this.$t("headers.status"), value: "status", sortable: false });
    }

    return headers;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  get tags() {
    return this.$store.getters["auth/tags"]
      .map(tag => ({
        "text": tag.name,
        "value": tag.id,
      }));
  }

  get isEmptySearch() {
    return !this.query && (!this.selectedTags || this.selectedTags.length === 0) && (!this.selectedStatuses || this.selectedStatuses.length === 0) && (!this.formattedDateStringFrom) && (!this.formattedDateStringTo) && (!this.selectedExcludedTags || this.selectedExcludedTags.length === 0) && (!this.showWithoutFutureAppointments);
  }

  public reload() {
    this.updateQueryStringFilters();
    const { page, itemsPerPage, sortBy, sortDesc } = this.dataTableOptions;
    this.loading = true;
    this.patients = [];

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    if (this.isEmptySearch) {
      this.loadErrors = false;
      this.patients = [];
      this.totalItemsCount = 0;
      this.loading = false;
      return;
    }

    PatientApi
      .find({
        query: this.query,
        page,
        itemsPerPage,
        sortBy: sortBy && sortBy.length ? sortBy[0] : undefined,
        sortDesc: sortDesc && sortDesc.length ? sortDesc[0] : undefined,
        tags: this.selectedTags,
        patientStatuses: this.selectedStatuses,
        showArchived: this.showArchived,
        showWithoutFutureAppointments: this.showWithoutFutureAppointments,
        excludedTags: this.selectedExcludedTags,
        lastAppointmentFrom: this.formattedDateStringFrom,
        lastAppointmentTo: this.formattedDateStringTo
      }, this.activeRequest)
      .then(response => {
        this.loadErrors = false;
        this.patients = response.data;
        this.totalItemsCount = response.total;

        this.loading = false;
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return;
        }

        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.loadErrors = err.response.data.errors;
        } else {
          this.loadErrors = this.$t("temporary_error").toString();
        }
      });
  }

  public goToPatient(patient) {
    this.$router.push({
      name: "patient",
      params: {
        id: patient.id
      }
    });
  }

  public lastAppointment(appointments: Appointment[]) {
    const today = moment().startOf("day");

    const pastAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") > 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentB.start_date.localeCompare(appointmentA.start_date)));

    if (!pastAppointments.length) {
      return "-";
    }

    return this.relativeDate(pastAppointments[0].start_date) + " " + this.userName(pastAppointments[0].user_id);
  }

  public nextAppointments(appointments: Appointment[]): Appointment[] {
    const today = moment().startOf("day");

    const futureAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") <= 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentA.start_date.localeCompare(appointmentB.start_date)));

    if (!futureAppointments.length) {
      return [];
    }

    return futureAppointments;
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    this.showAdvancedFilters = !!(this.selectedExcludedTags || this.lastAppointmentFrom || this.lastAppointmentTo || this.showWithoutFutureAppointments);

  }

  public applyFromQueryStringFilters() {
    const queryQuery = this.$route.query.query && this.$route.query.query.toString();
    const queryTag = this.$route.query.tag && this.$route.query.tag.toString();
    const queryExcludedTag = this.$route.query.excludedTag && this.$route.query.excludedTag.toString();
    const queryStatus = this.$route.query.status && this.$route.query.status.toString();
    const queryArchive = this.$route.query.archive && this.$route.query.archive.toString();
    const queryPage = this.$route.query.page && this.$route.query.page.toString();
    const queryNoFutureAppointments = this.$route.query.noFutureAppointments && this.$route.query.noFutureAppointments.toString();
    const queryLastAppointmentTo = this.$route.query.lastAppointmentTo && this.$route.query.lastAppointmentTo.toString();
    const queryLastAppointmentFrom = this.$route.query.lastAppointmentFrom && this.$route.query.lastAppointmentFrom.toString();

    if (queryQuery) {
      this.query = queryQuery;
    }
    if (queryTag) {
      this.selectedTags = queryTag.split(",").map(id => (parseInt(id)));
    }
    if (queryExcludedTag) {
      this.selectedExcludedTags = queryExcludedTag.split(",").map(id => (parseInt(id)));
    }
    if (queryStatus) {
      this.selectedStatuses = queryStatus.split(",").map(id => (parseInt(id)));
    }
    if (queryArchive === "1") {
      this.showArchived = true;
    } else {
      this.showArchived = false;
    }
    if (queryNoFutureAppointments === "1") {
      this.showWithoutFutureAppointments = true;
    } else {
      this.showWithoutFutureAppointments = false;
    }
    if (queryLastAppointmentTo) {
      this.lastAppointmentTo = queryLastAppointmentTo.replaceAll("-", "/");
      this.formattedDateStringTo = queryLastAppointmentTo.split("-").reverse().join("-");

    }
    if (queryLastAppointmentFrom) {
      this.lastAppointmentFrom = queryLastAppointmentFrom.replaceAll("-", "/");
      this.formattedDateStringFrom = queryLastAppointmentFrom.split("-").reverse().join("-");
    }
    if (parseInt(queryPage) > 1) {
      this.dataTableOptions.page = parseInt(queryPage);
    }
  }

  public updateQueryStringFilters() {
    const query = {
      query: this.query ? this.query : undefined,
      tag: this.selectedTags && this.selectedTags.length ? this.selectedTags.join(",") : undefined,
      status: this.selectedStatuses && this.selectedStatuses.length ? this.selectedStatuses.join(",") : undefined,
      archive: this.showArchived ? "1" : undefined,
      page: this.dataTableOptions.page > 1 ? this.dataTableOptions.page.toString() : undefined,
      noFutureAppointments: this.showWithoutFutureAppointments ? "1" : undefined,
      excludedTag: this.selectedExcludedTags && this.selectedExcludedTags.length ? this.selectedExcludedTags.join(",") : undefined,
      lastAppointmentFrom: this.lastAppointmentFrom ? this.lastAppointmentFrom.replaceAll("/", "-") : undefined,
      lastAppointmentTo: this.lastAppointmentTo ? this.lastAppointmentTo.replaceAll("/", "-") : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  @Watch("query")
  @Watch("selectedTags")
  @Watch("selectedStatuses")
  @Watch("showArchived")
  @Watch("showWithoutFutureAppointments")
  @Watch("selectedExcludedTags")
  @Watch("formattedDateStringTo")
  @Watch("formattedDateStringFrom")
  onQueryChanged() {
    this.reload();
  }

  public resetPage() {
    this.dataTableOptions.page = 1;
  }
}
