import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { SmartMirrorApiRoutes } from "../constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";

class SmartMirrorApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async selectPatient(patientId: number, roomId: string) {
    try {
      const response: ApiResponse<string> = await this.httpClient.post(
        SmartMirrorApiRoutes.selectPatient,
        {
          patientId,
          roomId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new SmartMirrorApi();
