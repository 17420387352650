







































import Vue from "vue";
import TimeEntryTypeApi from "../../../serivce/api/time-entry-type-api";
import TimeEntryTypeForm from "../../time-clock/components/time-entry-type/TimeEntryTypeForm.vue";
import ErrorLogo from "../../../components/Logo/ErrorLogo.vue";
import Component from "vue-class-component";
import SettingsHeader from "../components/SettingsHeader.vue";
import { TimeClockEntryType } from "@/modules/time-clock/types/time-clock.type";
import { Getter } from "vuex-class";

@Component({
  components: {
    ErrorLogo,
    TimeEntryTypeForm,
    SettingsHeader
  }
})
export default class ManageTimeEntryTypesPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors = false;
  public loadErrors: any = false;
  public showTimeEntryTypeForm = false;
  public editTimeEntryType: TimeClockEntryType = null;
  public loading = false;
  public types: any = [];
  public headers = [
    { width: "50%", text: this.$t("headers.name"), value: "name" },
    { width: "50%", text: this.$t("headers.actions"), value: "actions", sortable: false }];

  public openTimeEntryTypeFormDialog(type: TimeClockEntryType) {
    this.editTimeEntryType = type;
    this.showTimeEntryTypeForm = true;
  }

  public closeTimeEntryTypeForm() {
    this.editTimeEntryType = null;
    this.showTimeEntryTypeForm = false;
  }

  public reload() {
    this.loadTimeEntryTypes();
  }

  public async loadTimeEntryTypes() {
    this.loadErrors = null;
    this.loading = true;
    this.types = [];
    try {
      const res = await TimeEntryTypeApi.get();
      this.types = res.data.data;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }
  public mounted() {
    this.loadTimeEntryTypes();
  }

}
