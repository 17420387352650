














































import SmileLogo from "@/components/Logo/SmileLogo.vue";
import { CALENDAR_ROUTE_NAME } from "@/modules/appointment/constants/route-config";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SmileLogo
  }
})
export default class LoginPage extends Vue {
  public email = "";
  public password = "";
  public loading = false;
  public isResendingCode = false;
  public errorMessage = null;
  public twoFactorToken = "";
  public code = "";

  public handleLogin() {
    this.loading = true;
    this.errorMessage = null;

    const credentials = {
      email: this.email,
      password: this.password,
      twoFactorToken: this.twoFactorToken,
      code: this.code,
    };
    this.$store.dispatch(this.twoFactorToken ? "auth/twoFactor" : "auth/login", credentials)
      .then((data) => {
        this.loading = false;
        this.isResendingCode = false;

        if (data && data.two_factor_token) {
          this.twoFactorToken = data.two_factor_token;
        } else {
          if (!this.$store.getters["auth/isRtl"]) {
            location.reload();
          }
          this.$router.push({ name: CALENDAR_ROUTE_NAME });
        }
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
        this.isResendingCode = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errorMessage = this.$t("error.invalid_credentials");
        } else {
          this.errorMessage = this.$t("error.temporary_error");
        }
      });
  }

  public resendLogin() {
    this.twoFactorToken = "";
    this.code = "";
    this.isResendingCode = true;
    this.handleLogin();
  }
}
