import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"manage-recurring-shifts",attrs:{"fluid":""}},[_c(VRow,{staticStyle:{"border-bottom":"1px solid #ccc"},attrs:{"align":"start","justify":"start"}},[_c(VRow,{staticClass:"filter-shift"},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"6","md":"3"}},[_c(VSelect,{attrs:{"multiple":"","item-text":"name","items":_vm.therapistUsers,"item-value":"id","clearable":"","label":_vm.$t('therapist_label'),"color":"cyan darken-1","menu-props":{ maxHeight: 500 },"item-color":"cyan darken-1"},model:{value:(_vm.displayUserIds),callback:function ($$v) {_vm.displayUserIds=$$v},expression:"displayUserIds"}})],1),_c(VCol,[_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){return _vm.openRecurringShiftFormDialog(null)}}},[_vm._v("\n          "+_vm._s(_vm.$t('add_recurring_shift'))+"\n          "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1),_c(VCol,{staticClass:"pr-0 pl-0",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"hide-default-footer":"","mobile-breakpoint":1,"disable-pagination":"","headers":_vm.headers,"items":_vm.recurringShifts,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.loadErrors)?{key:"no-data",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.reload()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c('ErrorLogo')]},proxy:true}:null,{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.userName(item.user_id))+"\n        ")]}},{key:"item.day_in_week",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$t(("day-in-week." + (item.day_in_week))))+"\n        ")]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("time")(item.shift_start_time))+"\n        ")]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("time")(item.shift_end_time))+"\n        ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openRecurringShiftFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n            "+_vm._s(_vm.$t('edit_btn'))+"\n          ")],1)]}}],null,true)})],1)],1),(_vm.showRecurringShiftForm)?_c('RecurringShiftForm',{attrs:{"edit-recurring-shift":_vm.editRecurringShift},on:{"close":function($event){return _vm.closeRecurringShiftForm()},"reload":function($event){return _vm.reload()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }