import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import {
  AppointmentApiRouteParameters,
  AppointmentApiRoutes,
} from "../constants/api-routes";
import {
  Appointment,
  RichAppointment,
} from "@/modules/appointment/types/appointment.type";
import moment from "moment";
import { Shift } from "@/modules/shift/types/shift.type";

class AppointmentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getOne(id: number): Promise<Appointment> {
    try {
      const response = await this.httpClient.get(
        AppointmentApiRoutes.getOne.replace(
          AppointmentApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload): Promise<Appointment> {
    try {
      const response = await this.httpClient.post(
        AppointmentApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, params): Promise<Appointment> {
    return this.httpClient
      .put(
        AppointmentApiRoutes.update.replace(
          AppointmentApiRouteParameters.id,
          id.toString()
        ),
        params
      )
      .then((response) => {
        return response.data;
      });
  }

  async find(
    fromDate: string,
    toDate: string,
    statusIds: number[],
    activeRequest: CancelTokenSource
  ): Promise<{ appointments: RichAppointment[]; shifts: Shift[] }> {
    try {
      const { data } = await this.httpClient.get(
        AppointmentApiRoutes.findCalendar,
        {
          params: {
            from_date: fromDate,
            to_date: toDate,
            status_ids: statusIds,
          },
          cancelToken: activeRequest.token,
        }
      );

      const appointments = data.appointments.map((appointment) => {
        return {
          ...appointment,
          sameDayAppointments: data.appointments.filter(
            (app) =>
              app.patient_id &&
              app.patient_id === appointment.patient_id &&
              app.user_id !== appointment.user_id &&
              app.id !== appointment.id &&
              app.status_id !== 8 && // Canceled appointment
              moment(app.start_date).format("YYYY-MM-DD") ===
                moment(appointment.start_date).format("YYYY-MM-DD")
          ),
        };
      });

      return {
        appointments,
        shifts: data.shifts,
      };
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        AppointmentApiRoutes.delete.replace(
          AppointmentApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async findNotificationsCenter(
    date: string,
    userId: number,
    statusIds: number[],
    activeRequest: CancelTokenSource
  ): Promise<Appointment[]> {
    try {
      const response = await this.httpClient.post(
        AppointmentApiRoutes.findNotificationsCenter,
        {
          date,
          userId,
          statusIds,
        },
        { cancelToken: activeRequest.token }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new AppointmentApi();
