export function advancedContactSearch(
  item: any,
  queryText: string,
  itemText: string
) {
  const nameQueries = queryText.split(" ").map((subText) => {
    return itemText.includes(subText);
  });
  let categoryQueries;
  if (item.category) {
    categoryQueries = queryText.split(" ").map((subText) => {
      return item.category.includes(subText);
    });
  }

  return !nameQueries.includes(false) || !categoryQueries.includes(false);
}
