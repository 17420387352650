import {AppModule} from "@/types/app-module";
import reportsRoutes from "@/modules/reports/routes/routes";

const reportsModule: AppModule = {
    name: "reports",
    routes: reportsRoutes,
    store: {}
};

export default reportsModule;
