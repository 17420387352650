import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { TimeClockApiRouteParameters, TimeClockApiRoutes } from "@/modules/time-clock/constants/api-routes";
import {
  GetAllTimeClockEntriesResponse,
  TimeClockCreationOrUpdateResponse,
  TimeClockEntries,
  TimeClockEntryRemovalResponse
} from "@/modules/time-clock/types/api-response.type";
import { MandatoryTimeClockEntryData, TimeClockEntry } from "@/modules/time-clock/types/time-clock.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class TimeClockApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(params = {}): Promise<TimeClockEntries> {
    try {
      const response: GetAllTimeClockEntriesResponse = await this.httpClient.get(
        TimeClockApiRoutes.getAll,
        { params },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: MandatoryTimeClockEntryData): Promise<TimeClockEntry> {
    try {
      const response: TimeClockCreationOrUpdateResponse = await this.httpClient.post(
        TimeClockApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id, payload: MandatoryTimeClockEntryData): Promise<TimeClockEntry> {
    try {
      const response: TimeClockCreationOrUpdateResponse = await this.httpClient.put(
        TimeClockApiRoutes.update.replace(TimeClockApiRouteParameters.id, id),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async downloadMonthlyReport(yearMonth: string, userId: number) {
    const { data }: ApiResponse<string> = await this.httpClient.post(
      TimeClockApiRoutes.downloadReport,
      {
        year_month: yearMonth,
        user_id: userId,
      }
    );

    return data;
  }

  shareMonthlyReport(email: string, yearMonth: string) {
    return this.httpClient.post(
      TimeClockApiRoutes.shareReport,
      {
        email,
        year_month: yearMonth
      }
    );
  }

  async delete(id): Promise<boolean> {
    try {
      const response: TimeClockEntryRemovalResponse = await this.httpClient.delete(
        TimeClockApiRoutes.delete.replace(TimeClockApiRouteParameters.id, id)
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new TimeClockApi();
