















































import Vue from "vue";
import SentenceCategoryApi from "../../../serivce/api/sentence-category-api";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { SentenceCategory } from "../types/sentence-category.type";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import SentenceCategoryForm from "../components/sentences/SentenceCategoryForm.vue";
import { SENTENCES_TEMPLATES_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {
    ErrorLogo,
    SentenceCategoryForm
  },
})

export default class ManageSentenceCategoriesPage extends Vue {

  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors = false;
  public loadErrors: any = false;
  public showSentenceCategoryForm = false;
  public editSentenceCategory: SentenceCategory = null;
  public loading = false;
  public sentenceCategories: SentenceCategory[] = [];
  public headers = [
    { width: "25%", text: this.$t("catagories_headers.name"), value: "name", sortable: false },
    { width: "25%", text: this.$t("catagories_headers.display_type"), value: "display_type", sortable: false },
    { width: "25%", text: this.$t("catagories_headers.order"), value: "order", sortable: false },
    { width: "25%", text: this.$t("catagories_headers.actions"), value: "actions", sortable: false }
  ];

  public openSentenceCategoryFormDialog(sentenceCategory: SentenceCategory) {
    this.editSentenceCategory = sentenceCategory;
    this.showSentenceCategoryForm = true;
  }
  public closeSentenceCategoryForm() {
    this.editSentenceCategory = null;
    this.showSentenceCategoryForm = false;
  }
  public reload() {
    this.loadSentenceCategories();
  }
  get sentencesTemplatesPageName() {
    return SENTENCES_TEMPLATES_PAGE_ROUTE_NAME;
  }

  public async loadSentenceCategories() {
    this.loadErrors = null;
    this.loading = true;
    this.sentenceCategories = [];
    try {
      const results = await SentenceCategoryApi.get();
      this.sentenceCategories = results.data.sentence_categories;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }
  mounted() {
    this.loadSentenceCategories();
  }
}
