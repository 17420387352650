import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  ContactApiRouteParameters,
  ContactApiRoutes,
} from "../constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import {
  Contact,
  ContactFormPayload,
} from "@/modules/contacts/types/contact.type";

class ContactApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async update(id: number, payload: ContactFormPayload): Promise<Contact> {
    try {
      const response: ApiResponse<Contact> = await this.httpClient.put(
        ContactApiRoutes.update.replace(
          ContactApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  create(payload: ContactFormPayload): Promise<Contact> {
    return this.httpClient
      .post(ContactApiRoutes.create, payload)
      .then((response) => {
        return response.data;
      });
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        ContactApiRoutes.delete.replace(
          ContactApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new ContactApi();
