import {AppModule} from "@/types/app-module";
import timeClockPolicy from "@/modules/time-clock/policy";
import usersRoutes from "@/modules/users/routes/routes";
import usersStore from "@/modules/users/store";

const usersModule: AppModule = {
    name: "users",
    routes: usersRoutes,
    store: usersStore,
    policy: timeClockPolicy,
};

export default usersModule;
