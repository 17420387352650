import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  SupplierPaymentApiRouteParameters,
  SupplierPaymentApiRoutes,
} from "@/modules/supplier/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import {
  SupplierPayment,
  SupplierPaymentMandatory,
} from "../types/supplier-payment.type";
import moment from "moment";

class SupplierPaymentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(
    pinkasId: number,
    supplierIds: number[],
    fromDate: string | null,
    toDate: string | null
  ): Promise<SupplierPayment[]> {
    try {
      const response: ApiResponse<SupplierPayment[]> = await this.httpClient.get(
        SupplierPaymentApiRoutes.getAll,
        {
          params: {
            supplierIds,
            pinkasId,
            fromDate: fromDate
              ? moment(fromDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            toDate: toDate
              ? moment(toDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    payload: SupplierPaymentMandatory
  ): Promise<SupplierPayment> {
    try {
      const response: ApiResponse<SupplierPayment> = await this.httpClient.put(
        SupplierPaymentApiRoutes.updateSupplierPayment.replace(
          SupplierPaymentApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: SupplierPaymentMandatory): Promise<SupplierPayment> {
    try {
      const response: ApiResponse<SupplierPayment> = await this.httpClient.post(
        SupplierPaymentApiRoutes.createSupplierPayment,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        SupplierPaymentApiRoutes.deleteSupplierPayment.replace(
          SupplierPaymentApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new SupplierPaymentApi();
