











































import ContactBookmarkApi from "@/modules/contacts/api/contact-bookmark.api";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import BookmarkForm from "@/modules/contacts/components/BookmarkForm.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    BookmarkForm,
    SettingsHeader
  }
})
export default class ManageContactBookmarksPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public loadErrors: any = false;
  public showContactBookmarkForm = false;
  public editContactBookmark: ContactBookmark = null;
  public loading = false;
  public contactbookmarks: ContactBookmark[] = [];
  public headers = [
    { width: "30%", text: this.$t("headers.name"), value: "name" },
    { width: "30%", text: this.$t("headers.order"), value: "order" },
    { width: "30%", text: this.$t("headers.display_type"), value: "display_type" },
    { width: "20%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];


  public openContactBookmarkFormDialog(contactbookmark: ContactBookmark) {
    this.editContactBookmark = contactbookmark;
    this.showContactBookmarkForm = true;
  }

  public closeContactBookmarkForm() {
    this.editContactBookmark = null;
    this.showContactBookmarkForm = false;
  }


  public reload() {
    this.loadContactBookmarks();
  }

  public loadContactBookmarks() {
    this.loadErrors = null;
    this.loading = true;
    this.contactbookmarks = [];

    ContactBookmarkApi.getAll()
      .then(contactbookmarks => {
        this.loading = false;
        this.contactbookmarks = contactbookmarks;
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.loadErrors = err.response.data.errors;
        } else {
          this.loadErrors = this.$t("temporary_error");
        }
      });
  }

  public mounted() {
    this.loadContactBookmarks();
  }
}
