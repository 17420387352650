import { Module } from "vuex";
import taskState from "@/modules/task/store/task.state";
import taskGetters from "@/modules/task/store/task.getters";
import taskActions from "@/modules/task/store/task.actions";
import taskMutations from "@/modules/task/store/task.mutations";

const taskStore: Module<any, any> = {
  namespaced: true,
  state: taskState,
  getters: taskGetters,
  actions: taskActions,
  mutations: taskMutations,
};

export default taskStore;
