






























import { Component, Prop } from "vue-property-decorator";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import { age, date } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import PatientApi from "../../api/patient.api";
import { Patient } from "../../types/patient.type";

@Component({
  components: {
    SentenceMenu,
  },
  filters: {
    age,
    date,
  },
})
export default class PatientHiddenNoteFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public isSubmitLoading = false;
  public hiddenNoteModel: string = null;
  public isDirty = false;

  mounted() {
    this.hiddenNoteModel = this.patient.hidden_note;
  }

  public closeDialog() {
    this.$emit("close");
  }

  public markDirty() {
    this.isDirty = true;
  }

  public async submitForm() {
    this.isSubmitLoading = true;

    try {
      const patient = await PatientApi.update(this.patient.id, {
        hidden_note: this.hiddenNoteModel,
      });

      this.isSubmitLoading = false;
      this.$emit("hiddenNoteUpdated", patient.hidden_note);
      this.closeDialog();
    } catch (err) {
      this.isSubmitLoading = false;
      this.$toastr.e(this.$t("toastr_saving_error"));
    }
  }

}
