import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { FormApiRoutes } from "@/modules/form/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";

class FormApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async sendEmail(
    patientId: number,
    formId: number,
    email: string,
    url: string
  ) {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.post(
        FormApiRoutes.sendEmail,
        {
          patientId,
          formId,
          email,
          url,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async generateUrl(
    patientId: number,
    formId: number,
    initData?: any,
    planId?: number
  ) {
    try {
      const { data }: ApiResponse<string> = await this.httpClient.post(
        FormApiRoutes.generateUrl,
        {
          patientId,
          formId,
          initData,
          planId,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
}

export default new FormApi();
