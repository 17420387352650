



























































































































import moment from "moment";
import { Component, Prop } from "vue-property-decorator";
import PatientLetterApi from "../../api/patient-letter.api";
import { Patient } from "../../types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import { Drug } from "@/modules/settings/types/drug.type";

interface FormDrug extends Drug {
  showFreeDrugName: boolean;
}

@Component
export default class PatientDrugPrescriptionFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/documentTreatmentsUsers") documentTreatmentsUsers: User[];
  @Getter("auth/drugs") drugList: Drug[];
  @Getter("auth/isRtl") isRtl: boolean;

  public notes = "";
  public date = "";
  public showErrors = false;
  public errors: any = false;
  public isSendLoading = false;
  public userId: number = null;
  public prescriptionDrugs: FormDrug[] = [];

  public mounted() {
    this.notes = this.$store.getters["auth/drugPrescriptionDefaultNote"];
    this.date = moment().format("DD-MM-YYYY");
    this.prescriptionDrugs = [
      {
        name: "",
        quantity_per_taking: " ",
        quantity_per_day: " ",
        days_duration: " ",
        total_quantity: " ",
        internal_note: "",
        notes: "",
        showFreeDrugName: !this.drugList.length,
      },
    ];

    if (this.$store.getters["auth/isTherapist"]) {
      this.userId = this.$store.getters["auth/userId"];
    } else if (this.documentTreatmentsUsers.length === 1) {
      this.userId = this.documentTreatmentsUsers[0].id;
    }
  }

  public drugsFilter(item: Drug, queryText: string) {
    return `${item.name} ${item.internal_note}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  public onDrugSelected(prescriptionDrugIndex: number, drugId: number) {
    const selectedDrug = this.drugList.find(({ id }) => (id === drugId));
    if (selectedDrug) {
      this.prescriptionDrugs[prescriptionDrugIndex].name = selectedDrug.name;
      this.prescriptionDrugs[prescriptionDrugIndex].quantity_per_taking = selectedDrug.quantity_per_taking;
      this.prescriptionDrugs[prescriptionDrugIndex].quantity_per_day = selectedDrug.quantity_per_day;
      this.prescriptionDrugs[prescriptionDrugIndex].days_duration = selectedDrug.days_duration;
      this.prescriptionDrugs[prescriptionDrugIndex].total_quantity = selectedDrug.total_quantity;
      this.prescriptionDrugs[prescriptionDrugIndex].notes = selectedDrug.notes;
    }
  }

  public onAddDrugClicked() {
    this.prescriptionDrugs.push({
      name: "",
      quantity_per_taking: "",
      quantity_per_day: "",
      days_duration: "",
      total_quantity: "",
      internal_note: "",
      notes: "",
      showFreeDrugName: !this.drugList.length,
    });
  }

  public onDeleteDrugClicked(drugIndex: number) {
    this.prescriptionDrugs.splice(drugIndex, 1);
  }

  public async closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    this.errors = false;
    this.showErrors = true;

    const isMissingDrug = this.prescriptionDrugs.find(({ name }) => (!name));
    if (isMissingDrug) {
      return;
    }

    try {
      this.isSendLoading = true;
      const patientFile = await PatientLetterApi.createDrugPrescription(this.date, this.patient.id, this.userId, this.prescriptionDrugs, this.notes);
      this.$toastr.s(this.$t("drug_prescription_created"));
      this.$emit("drugPrescriptionCreated", patientFile);
      this.closeDialog();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
      this.$emit("refreshPatientFiles");
    }
  }

}
