















































import { Component, Prop } from "vue-property-decorator";
import { date, time } from "@/filters/date.filter";
import { Plan } from "@/modules/treatment/types/plan.type";
import PlanDialog from "@/modules/treatment/components/PlanDialog.vue";
import PlanApi from "@/modules/treatment/api/plan.api";
import { Getter } from "vuex-class";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Patient } from "../types/patient.type";


@Component({
  components: {
    PlanDialog
  },
  filters: {
    date,
    time
  }
})
export default class PatientPlans extends mixins(SmileMixin) {
  @Prop({ required: true }) public plans: Plan[];
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;

  public loadingCreation = false;
  public planDisplayed: Plan = null;
  public displayPlanDialog = false;

  public closePlanDialog() {
    this.displayPlanDialog = false;
    this.planDisplayed = null;
  }

  public open(plan: Plan) {
    this.displayPlanDialog = true;
    this.planDisplayed = plan;
  }

  public async createPlan() {
    this.loadingCreation = true;
    try {
      const plan = await PlanApi.create(this.patient.id, { denture: "permanent" });
      this.$emit("planCreated", plan);
      this.open(plan);
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingCreation = false;
    }
  }

  public totalPrice(plan: Plan) {
    if (plan.plan_treatments.length === 0)
      return 0;

    return plan.plan_treatments
      .map(treatment => (treatment.price - treatment.discount))
      .reduce((a, b) => (a + b));
  }

  public planDeleted(planId: number) {
    this.$emit("planDeleted", planId);
  }

}
