import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"560"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"cancel-payment-confirm-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,[_vm._v("\n      "+_vm._s(_vm.$t('title'))+"\n      "),_c(VSpacer),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VAlert,{attrs:{"color":"blue lighten-5","icon":"mdi-information-outline"}},[_vm._v(_vm._s(_vm.$t(_vm.explanationVersion))),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t('explanation_line2')))]),_c(VTextarea,{attrs:{"label":_vm.$t('cancel_reason_label'),"color":"cyan darken-1","auto-grow":"","rows":"2","outlined":"","error":_vm.showErrors},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.onPaymentCancelClicked()}}},[_vm._v("\n        "+_vm._s(_vm.$t('cancel_btn'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }