












































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Tag } from "@/modules/patient/types/tag.type";
import PatientTagApi from "@/serivce/api/patient-tag-api";
import TagForm from "@/modules/settings/components/tag/TagForm.vue";

@Component({
  components: {
    TagForm,
  }
})
export default class PatientTags extends Vue {
  @Prop({ required: true }) public patientId: number;
  @Prop({ required: true }) public patientTags: Tag[];
  @Getter("auth/tags") public tags: Tag[];

  public showTagFormDialog = false;
  public loading = false;
  public menuOpened = false;
  public selectedTagsIds: number[] = [];

  mounted() {
    this.patientTags.forEach(patientTag => this.selectedTagsIds.push(this.tags.find(tag => tag.id === patientTag.id).id));
  }

  update(newPatientTags: Tag[]) {
    this.loading = true;

    const newPatientTagIds = newPatientTags.map(tag => (tag.id));
    PatientTagApi.update(this.patientId, newPatientTagIds)
      .then(() => {
        this.$emit("tagsUpdated", newPatientTags);
        this.loading = false;
      });
  }

  @Watch("menuOpened")
  onMenuOpenedChanged(newStatus: boolean) {
    if (!newStatus) {
      const newPatientTags = [];
      this.selectedTagsIds.forEach(tagId => newPatientTags.push(this.tags.find(tag => tag.id === tagId)));

      // check if patient tags changed
      const isEqual = (JSON.stringify(newPatientTags.map(tag => tag.id).sort()) === JSON.stringify(this.patientTags.map(tag => tag.id).sort()));
      if (!isEqual)
        this.update(newPatientTags);
    }
  }

  public tagCreated(tag: Tag) {
    this.selectedTagsIds.push(tag.id);
    this.onMenuOpenedChanged(false);
  }

  public showTagForm() {
    this.showTagFormDialog = true;
  }
}
