












import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TEETH_SIMPLE_SURFACES } from "../../constants/denture-map";

@Component
export default class DentalDirectSurface extends Vue {
    @Prop({ required: true }) public surface: string[];
    public simpleSurfaceOptions: string[] = TEETH_SIMPLE_SURFACES;
    public surfaceIndexs: number[] = [];
    public updatingSurfaces(selectedSurfacesIndex: number[]) {
        const treatmentSurface = selectedSurfacesIndex.map((index: number) => this.simpleSurfaceOptions[index]);
        this.$emit("update-surfaces", treatmentSurface);
    }

    mounted() {
        this.resetSurfaceIndexes();
    }

    @Watch("surface")
    public resetSurfaceIndexes() {
        this.surfaceIndexs = this.surface ? this.surface.map((value) => this.simpleSurfaceOptions.findIndex((option) => option === value)) : [];
    }
}

