import Request from "../request";

class TimeEntryTypeApi {
  constructor () {
    this.request = new Request();
  }

  get () {
    return this.request.get("/time-entry-type");
  }

  delete (timeEntryTypeId) {
    return this.request.delete(`/time-entry-type/${timeEntryTypeId}`);
  }

  update (timeEntryTypeId, payload = {}) {
    return this.request.put(`/time-entry-type/${timeEntryTypeId}`, payload);
  }

  create (payload = {}) {
    return this.request.post("/time-entry-type", payload);
  }

}

export default new TimeEntryTypeApi();
