



























import PatientSearchTable from "../components/PatientSearchTable.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { AppConfig } from "@/core/config/config";
import { Patient } from "../types/patient.type";
import { PATIENT_ROUTE_NAME } from "../constants/route-config";

const PatientForm = () => import("@/modules/patient/components/PatientForm/" + AppConfig.customPatientFormComponent + ".vue");

@Component({
  components: {
    PatientSearchTable,
    PatientForm,
  },
})
export default class PatientsPage extends Vue {
  public showPatientForm = false;
  public editPatient = null;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public openPatientFormDialog(patient) {
    this.editPatient = patient;
    this.showPatientForm = true;
  }

  public closePatientForm() {
    this.editPatient = null;
    this.showPatientForm = false;
  }

  public onPatientCreated(patient: Patient) {
    this.$router.push({
      name: PATIENT_ROUTE_NAME,
      params: {
        id: patient.id.toString()
      }
    });
  }
}
