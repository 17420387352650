






import { Component, Vue } from "vue-property-decorator";

@Component({
})
export default class TranzilaIframeResultsPage extends Vue {
  public mounted() {
    const queryPamras = this.$route.query.results;
    if (!queryPamras) {
      return;
    }
    const tranzilaParams = Object.fromEntries(new URLSearchParams(queryPamras.toString()));

    (window.parent as any).iframeResponse(tranzilaParams);
  }
}
