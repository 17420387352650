export enum SupplierPaymentCategoryApiRoutes {
  getAll = "/supplier-payment-category",
  createSupplierPaymentCategory = "/supplier-payment-category",
  updateSupplierPaymentCategory = "/supplier-payment-category/{id}",
  deleteSupplierPaymentCategory = "/supplier-payment-category/{id}",
}

export enum SupplierPaymentCategoryApiRouteParameters {
  id = "{id}",
}

export enum SupplierPaymentApiRoutes {
  getAll = "/supplier-payment",
  createSupplierPayment = "/supplier-payment",
  updateSupplierPayment = "/supplier-payment/{id}",
  deleteSupplierPayment = "/supplier-payment/{id}",
}

export enum SupplierPaymentApiRouteParameters {
  id = "{id}",
}

export enum SupplierApiRoutes {
  getAll = "/supplier",
  createSupplier = "/supplier",
  updateSupplier = "/supplier/{id}",
  deleteSupplier = "/supplier/{id}",
}

export enum SupplierApiRouteParameters {
  id = "{id}",
}

SupplierPaymentApiRouteParameters;
SupplierPaymentApiRoutes;
