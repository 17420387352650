import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"patient-notes-component mx-auto",attrs:{"elevation":"3"}},[_c(VCardTitle,[_vm._v("\n    "+_vm._s(_vm.$t('notes'))+"\n    "),_c(VSpacer),(_vm.isPatientHiddenNoteEnabled)?_c(VBtn,{class:_vm.isRtl ? 'ml-2' : 'mr-2',attrs:{"elevation":"4","icon":"","color":_vm.patientHiddenNote ? 'cyan darken - 2' : '',"small":""},on:{"click":function($event){return _vm.hiddenNoteClicked()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e(),(_vm.isEditEnabled)?_c(VBtn,{attrs:{"elevation":"4","icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.onAddNoteClicked()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VCardText,{staticClass:"pt-4"},[(_vm.notes.length)?_c(VRow,_vm._l((_vm.notes),function(note,index){return _c(VCol,{key:index,staticClass:"pt-0 ",attrs:{"cols":"12"}},[_c('PatientNote',{ref:"note",refInFor:true,attrs:{"note":note,"patient-id":_vm.patientId},on:{"editNote":function($event){return _vm.onEditNoteClicked($event)}}})],1)}),1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-comment")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_notes')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }