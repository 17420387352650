import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { AuthApiRoutes } from "@/modules/auth/constants/api-routes";
import { LoginData } from "@/modules/auth/types/api-response.type";

class AuthApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  login(email: string, password: string): Promise<LoginData> {
    return this.httpClient.post(AuthApiRoutes.login, { email, password });
  }

  twoFactor(
    email: string,
    password: string,
    twoFactorToken: string,
    code: string
  ): Promise<LoginData> {
    return this.httpClient.post(AuthApiRoutes.twoFactor, {
      email,
      password,
      two_factor_token: twoFactorToken,
      code,
    });
  }

  forgotPassword(email: string) {
    return this.httpClient.post(AuthApiRoutes.forgotPassword, { email });
  }

  logout() {
    return this.httpClient.get(AuthApiRoutes.logout);
  }

  resetPassword(token: string, password: string) {
    return this.httpClient.post(AuthApiRoutes.resetPassword, {
      token,
      password,
    });
  }

  loadInitData(): Promise<any> {
    return this.httpClient.get(AuthApiRoutes.initData);
  }
}

export default new AuthApi();
