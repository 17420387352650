




import moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
import { CalendarViewTypes } from "../../../types/calendar.type";

@Component({
})
export default class CalendarTitle extends Vue {
  @Prop({ required: true }) calendarStartDate: string;
  @Prop({ required: true }) calendarEndDate: string;
  @Prop({ required: true }) calendarView: CalendarViewTypes;

  get displayedDatesTitle() {
    if (!this.calendarStartDate) {
      return "";
    }
    if (this.calendarView === "day") {
      if (this.isMobile) {
        return this.$t(`months.${parseInt(moment(this.calendarStartDate).format("M")) - 1}`) + " " + moment(this.calendarStartDate).format("Y");
      }
      return moment(this.calendarStartDate).format("D") + " " + this.$t("date_in") + this.$t(`months.${parseInt(moment(this.calendarStartDate).format("M")) - 1}`) + " " + moment(this.calendarStartDate).format("Y");
    }

    if (this.calendarView === "week") {
      // Different month
      if (moment(this.calendarStartDate).format("M") !== moment(this.calendarEndDate).format("M")) {
        // Different year
        if (moment(this.calendarStartDate).format("Y") !== moment(this.calendarEndDate).format("Y")) {
          return this.$t(`months.${parseInt(moment(this.calendarStartDate).format("M")) - 1}`) + " " + moment(this.calendarStartDate).format("Y") + " - " + this.$t(`months.${parseInt(moment(this.calendarEndDate).format("M")) - 1}`) + " " + moment(this.calendarEndDate).format("Y");
        }

        return this.$t(`months.${parseInt(moment(this.calendarStartDate).format("M")) - 1}`) + " - " + this.$t(`months.${parseInt(moment(this.calendarEndDate).format("M")) - 1}`) + " " + moment(this.calendarStartDate).format("Y");
      }
    }

    if (this.calendarView === "week" || this.calendarView === "month") {
      return this.$t(`months.${parseInt(moment(this.calendarStartDate).format("M")) - 1}`) + " " + moment(this.calendarStartDate).format("Y");
    }
    return "";
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
