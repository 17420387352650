import {AppHttpClient} from "@/core/http-client/http-client";
import {AxiosInstance} from "axios";
import {TimeClockTypeApiRoutes} from "@/modules/time-clock/constants/api-routes";

class TimeEntryTypeApi {
    private httpClient: AxiosInstance;

    constructor() {
        this.httpClient = AppHttpClient.instance;
    }

    getAll(params = {}) {
        return this.httpClient.get(TimeClockTypeApiRoutes.getAll, params);
    }

    create(params = {}) {
        return this.httpClient.post(TimeClockTypeApiRoutes.create, params);
    }

    update(id, params = {}) {
        return this.httpClient.put(TimeClockTypeApiRoutes.update.replace("{id}", id), params);
    }

    delete(id) {
        return this.httpClient.delete(TimeClockTypeApiRoutes.update.replace("{id}", id));
    }

    getLastUsedType(): Promise<number> {
        return this.httpClient.post(
            TimeClockTypeApiRoutes.getLastUsedType
        )
            .then(response => {
                return response.data;
            });
    }

}

export default new TimeEntryTypeApi();

