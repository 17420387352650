import ReportsPage from "../pages/ReportsPage.vue";
import ReportTreatmentsMonthlyPage from "../pages/ReportTreatmentsMonthlyPage.vue";
import ReportAppointmentsMonthlyPage from "../pages/ReportAppointmentsMonthlyPage.vue";
import ReportAllReceiptsPage from "../pages/ReportAllReceiptsPage.vue";
import ReportOverviewPage from "../pages/ReportOverviewPage.vue";
import ReportDoctorCalendarPage from "../pages/ReportDoctorCalendarPage.vue";
import ReportClinicKupaPage from "../pages/ReportClinicKupaPage.vue";
import ReportAllIncomesPage from "../pages/ReportAllIncomesPage.vue";
import ReportPatientsDebtsPage from "../pages/ReportPatientsDebtsPage.vue";
import { RouteConfig } from "vue-router";
import {
  REPORTS_ROUTE_NAME,
  REPORTS_ROUTE_PATH,
  REPORT_ALL_INCOMES_ROUTE_NAME,
  REPORT_ALL_INCOMES_ROUTE_PATH,
  REPORT_OVERVIEW_ROUTE_NAME,
  REPORT_OVERVIEW_ROUTE_PATH,
  REPORT_ALL_RECEIPTS_ROUTE_NAME,
  REPORT_ALL_RECEIPTS_ROUTE_PATH,
  REPORT_APPOINTMENTS_MONTHLY_ROUTE_NAME,
  REPORT_APPOINTMENTS_MONTHLY_ROUTE_PATH,
  REPORT_PATIENTS_DEBTS_ROUTE_NAME,
  REPORT_PATIENTS_DEBTS_ROUTE_PATH,
  REPORT_TREATMENTS_MONTHLY_ROUTE_NAME,
  REPORT_TREATMENTS_MONTHLY_ROUTE_PATH,
  REPORT_DOCTOR_CALENDAR_ROUTE_NAME,
  REPORT_DOCTOR_CALENDAR_ROUTE_PATH,
  REPORT_KUPA_ROUTE_NAME,
  REPORT_KUPA_ROUTE_PATH,
  REPORT_SENT_EMAILS_ROUTE_NAME,
  REPORT_SENT_EMAILS_ROUTE_PATH,
  REPORT_LAB_WORKS_ROUTE_NAME,
  REPORT_LAB_WORKS_ROUTE_PATH,
} from "@/modules/reports/constants/route-config";
import store from "@/store";
import { CALENDAR_ROUTE_NAME } from "@/modules/appointment/constants/route-config";
import ReportSentEmailsPage from "../pages/ReportSentEmailsPage.vue";
import ReportLabWorksPage from "@/modules/lab-work/pages/ReportLabWorksPage.vue";

const ifFinanceReportsEnabled = (to, from, next) => {
  if (store.getters["auth/shouldDisplayFinanceReports"]) {
    return next();
  }

  return next({ name: CALENDAR_ROUTE_NAME });
};

const isSentEmailsReportAllowed = (to, from, next) => {
  if (
    !store.getters["auth/isAccountant"] &&
    store.getters["auth/isEmailEnabled"]
  ) {
    return next();
  }

  return next({ name: CALENDAR_ROUTE_NAME });
};

const ifKupaReportEnabled = (to, from, next) => {
  if (
    store.getters["auth/shouldDisplayKupaReport"] ||
    store.getters["auth/isAccountant"]
  ) {
    return next();
  }

  return next({ name: CALENDAR_ROUTE_NAME });
};
const isLabWorksEnabled = (to, from, next) => {
  if (
    store.getters["auth/isLabWorksEnabled"] ||
    !store.getters["auth/isAccountant"]
  ) {
    return next();
  }

  return next({ name: CALENDAR_ROUTE_NAME });
};

const reportsRoutes: RouteConfig[] = [
  {
    name: REPORTS_ROUTE_NAME,
    path: REPORTS_ROUTE_PATH,
    component: ReportsPage,
  },
  {
    name: REPORT_PATIENTS_DEBTS_ROUTE_NAME,
    path: REPORT_PATIENTS_DEBTS_ROUTE_PATH,
    component: ReportPatientsDebtsPage,
    beforeEnter: ifFinanceReportsEnabled,
  },
  {
    name: REPORT_TREATMENTS_MONTHLY_ROUTE_NAME,
    path: REPORT_TREATMENTS_MONTHLY_ROUTE_PATH,
    component: ReportTreatmentsMonthlyPage,
    beforeEnter: ifFinanceReportsEnabled,
  },
  {
    name: REPORT_APPOINTMENTS_MONTHLY_ROUTE_NAME,
    path: REPORT_APPOINTMENTS_MONTHLY_ROUTE_PATH,
    component: ReportAppointmentsMonthlyPage,
  },
  {
    name: REPORT_ALL_RECEIPTS_ROUTE_NAME,
    path: REPORT_ALL_RECEIPTS_ROUTE_PATH,
    component: ReportAllReceiptsPage,
    beforeEnter: ifFinanceReportsEnabled,
  },
  {
    name: REPORT_OVERVIEW_ROUTE_NAME,
    path: REPORT_OVERVIEW_ROUTE_PATH,
    component: ReportOverviewPage,
    beforeEnter: ifFinanceReportsEnabled,
  },
  {
    name: REPORT_DOCTOR_CALENDAR_ROUTE_NAME,
    path: REPORT_DOCTOR_CALENDAR_ROUTE_PATH,
    beforeEnter: ifFinanceReportsEnabled,
    component: ReportDoctorCalendarPage,
  },
  {
    name: REPORT_KUPA_ROUTE_NAME,
    path: REPORT_KUPA_ROUTE_PATH,
    component: ReportClinicKupaPage,
    beforeEnter: ifKupaReportEnabled,
  },
  {
    name: REPORT_ALL_INCOMES_ROUTE_NAME,
    path: REPORT_ALL_INCOMES_ROUTE_PATH,
    component: ReportAllIncomesPage,
    beforeEnter: ifFinanceReportsEnabled,
  },
  {
    name: REPORT_SENT_EMAILS_ROUTE_NAME,
    path: REPORT_SENT_EMAILS_ROUTE_PATH,
    component: ReportSentEmailsPage,
    beforeEnter: isSentEmailsReportAllowed,
  },
  {
    name: REPORT_LAB_WORKS_ROUTE_NAME,
    path: REPORT_LAB_WORKS_ROUTE_PATH,
    component: ReportLabWorksPage,
    beforeEnter: isLabWorksEnabled,
  },
];

export default reportsRoutes;
