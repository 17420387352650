





































import DrugApi from "@/modules/settings/api/drug.api";
import DrugForm from "../components/drug/DrugForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Drug } from "@/modules/settings/types/drug.type";
import PatientDrugPrescriptionFormDialog from "@/modules/patient/components/PatientActionDialog/PatientDrugPrescriptionFormDialog.vue";
import SettingsHeader from "../components/SettingsHeader.vue";


@Component({
    components: {
        ErrorLogo,
        PatientDrugPrescriptionFormDialog,
        SettingsHeader,
        DrugForm,
    }
})

export default class ManageDrugsPage extends Vue {
    @Getter("auth/isRtl") isRtl: boolean;
    public loadErrors: any = false;
    public showDrugForm = false;
    public editDrug: Drug = null;
    public loading = false;
    public drugs: Drug[] = [];
    public headers = [
        { width: "30%", text: this.$t("headers.name"), value: "name" },
        { width: "30%", text: this.$t("headers.internal_note"), value: "internal_note" },
        { width: "20%", text: this.$t("headers.actions"), value: "actions", sortable: false }
    ];

    public openDrugFormDialog(drug: Drug) {
        this.editDrug = drug;
        this.showDrugForm = true;
    }

    public closeDrugForm() {
        this.editDrug = null;
        this.showDrugForm = false;
    }


    public async reload() {
        await this.loadDrugs();
    }

    public async loadDrugs() {
        this.loadErrors = null;
        this.loading = true;
        this.drugs = [];

        try {
            this.drugs = await DrugApi.getAll();
            this.loading = false;
        } catch (err) {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
                this.loadErrors = err.response.data.errors;
            } else {
                this.loadErrors = this.$t("temporary_error");
            }
        }
    }

    public async mounted() {
        await this.loadDrugs();
    }
}
