


















































































































import axios, { CancelTokenSource } from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import ReportApi from "../api/report.api";
import { ReportPatientsDebtsResults } from "../types/report.type";
import moment from "moment";
import { Appointment } from "@/modules/appointment/types/appointment.type";

@Component({
  components: {
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportPatientsDebtsPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest: CancelTokenSource = null;
  public reportResults: ReportPatientsDebtsResults = null;
  public minDebtValue: number = null;
  public isLoading = false;
  public isExportLoading = false;

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  @Watch("minDebtValue")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    return [
      { text: this.$t("headers.payment_balance"), value: "payment_balance", sortable: true },
      { text: this.$t("headers.last_appointment"), value: "last_appointment", sortable: true },
      { text: this.$t("headers.next_appointments"), value: "next_appointments", sortable: false },
      { text: this.$t("headers.patient"), value: "patient", sortable: false },
      { text: this.$t("headers.phone"), value: "phone", sortable: false },
      { text: this.$t("headers.notes"), value: "notes", sortable: false },
    ];
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.reportResults = null;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      const minDebts = parseInt(this.minDebtValue.toString()) || 10;
      const results = await ReportApi
        .getPatientsDebtsReport(minDebts, this.activeRequest);

      const patients = results.patients.map(patient => ({
        last_appointment: this.lastAppointment(patient.appointments, true),
        ...patient
      }));

      this.reportResults = {
        ...results,
        patients,
      };

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    if (!this.minDebtValue) {
      this.minDebtValue = 10;
    }
    this.reload();
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public applyFromQueryStringFilters() {
    const queryDebt = parseInt(this.$route.query.debt && this.$route.query.debt.toString());

    if (queryDebt) {
      this.minDebtValue = queryDebt;
    }
  }

  public updateQueryStringFilters() {
    const query = {
      debt: this.minDebtValue.toString(),
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }



  public lastAppointment(appointments: Appointment[], returnStartDate = false) {
    const today = moment().startOf("day");

    const pastAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") > 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentB.start_date.localeCompare(appointmentA.start_date)));

    if (!pastAppointments.length) {
      return "-";
    }

    if (returnStartDate) {
      return pastAppointments[0].start_date;
    }
    return this.relativeDate(pastAppointments[0].start_date) + " " + this.userName(pastAppointments[0].user_id);
  }

  public nextAppointments(appointments: Appointment[]): Appointment[] {
    const today = moment().startOf("day");

    const futureAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") <= 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentA.start_date.localeCompare(appointmentB.start_date)));

    if (!futureAppointments.length) {
      return [];
    }

    return futureAppointments;
  }

  public async onExportClicked() {
    this.isExportLoading = true;

    try {
      const url = await ReportApi.getPatientsDebtsExportUrl(this.minDebtValue);
      this.isExportLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, "debts.pdf");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportLoading = false;
    }
  }


}
