import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({ height: _vm.isMobile ? '65px' : '170px' })}),_c(VCard,{staticClass:"patient-card ml-2 mr-2",attrs:{"elevation":"12","outlined":"","shaped":""}},[_c(VCardTitle,{staticClass:"pb-3 pt-3"},[_c('RouterLink',{attrs:{"to":{
        name: _vm.patientRouteName,
        params: {
          id: _vm.focusPatient.id
        }
      }}},[_vm._v("\n        "+_vm._s(_vm.focusPatient.first_name)+"\n        "+_vm._s(_vm.focusPatient.last_name))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.onRemoveFocusPatientClicked()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),(!_vm.isMobile)?_c(VCardText,[_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.focusPatient.phone)?_c('a',{on:{"click":function($event){return _vm.sendWhatsapp(_vm.focusPatient.phone)}}},[_vm._v("\n          "+_vm._s(_vm.focusPatient.phone)+"\n        ")]):_vm._e(),_vm._v("\n        "+_vm._s(_vm.focusPatient ? _vm.focusPatient.phone_note : '')+"\n      ")]),(_vm.focusPatient.id_number)?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('id_number'))+": "+_vm._s(_vm.focusPatient.id_number)+"\n      ")]):_vm._e(),(_vm.patientGeneralNotes.length)?_c('div',{staticClass:"mt-1"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('patient_notes'))+": ")]),_c('ul',{staticClass:" pl-0 pr-0",staticStyle:{"list-style-type":"none"}},_vm._l((_vm.patientGeneralNotes),function(note,index){return _c('li',{key:'note' + index},[_c(VIcon,{staticClass:"ml-1",attrs:{"color":note.pinned ? 'orange lighten-4' : 'cyan lighten-4',"size":"12"}},[_vm._v("\n              mdi-square\n            ")]),_c('span',{staticStyle:{"white-space":"break-spaces"}},[_vm._v(_vm._s(note.text))])],1)}),0)]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }