


























import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import PatientNotes from "./PatientNotes.vue";
import { Patient } from "../types/patient.type";
import { date } from "@/filters/date.filter";

@Component({
  components: {
    PatientNotes
  },
  filters: {
    date
  }
})
export default class PatientNotesPopupDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;

  public closeDialog() {
    this.$emit("close");
  }

  get popupNotes() {
    return this.patient.notes.filter(note => note.is_popup);
  }

}
