














































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Patient } from "@/modules/patient/types/patient.type";
import AppointmentApi from "@/modules/appointment/api/appointment.api";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import moment from "moment";
import { AppointmentType } from "@/modules/appointment/types/appointment-type.type";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { AppointmentStatus } from "../../types/appointment-status.type";
import axios, { CancelTokenSource } from "axios";
import PatientApi from "@/modules/patient/api/patient.api";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import { Tag } from "@/modules/patient/types/tag.type";

@Component({
  components: {
    SentenceMenu,
    SearchPatient,
    ErrorMessage,
  }
})
export default class NewAppointmentFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) defaultUserId: number;
  @Prop({ required: false }) defaultStartDate: string;
  @Prop({ required: false }) patient: Patient;
  @Prop({ required: false }) showPatientTabOnly: boolean;
  @Getter("auth/usersWithCalendar") usersWithCalendar: User[];
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/calendarViewColorType") calendarViewColorType: "patient-status" | "appointment-status" | "appointment-type";
  @Getter("auth/isIdNumberInputNumeric") isIdNumberInputNumeric: boolean;
  @Getter("auth/isShowingAppointmentTypeSelector") isShowingAppointmentTypeSelector: boolean;
  private activeRequest: CancelTokenSource = null;
  public selectedPatient: Patient = null;
  public patientId: number = null;
  public userId: number = null;
  public typeId: number = null;
  public startHour = "";
  public endHour = "";
  public date = moment().utc().format("YYYY-MM-DD");
  public treatmentDuration = 0;
  public showErrors = false;
  public sendFutureAppointmentsReminder = false;
  public activeTab = null;
  public errors: any = false;
  public savingLoading = false;
  public deleteLoading = false;
  public showReminderNotes = false;
  public notes = "";
  public reminderNotes = "";
  public displayTreatmentDatePicker = false;
  public displayNoteDatePicker = false;
  public isDirty = false;
  public showNewPatientForm = false;
  public statusId = 1;// TODO
  public newPatient = {
    first_name: "",
    last_name: "",
    id_number: "",
    phone: "",
    phone_note: "",
    address: "",
    city: "",
    birth_date: "",
    tag_ids: []
  }
  public isNewPatientIdNumberAlreadyExists = false;
  public isNewPatientIdNumberArchivedExists = false;
  public isLoadingNewPatientIdNumber = false;
  public isLoadingNewPatientName = false;
  public isNewPatientNameAlreadyExists = false;
  public isNewPatientNameArchivedExists = false;

  public appendNotesSentence(sentence: string) {
    if (this.notes) {
      this.notes += " " + sentence;
    } else {
      this.notes = sentence;
    }
  }

  get tags() {
    return this.$store.getters["auth/tags"]
      .map((tag: Tag) => ({
        "text": tag.name,
        "value": tag.id,
      }));
  }

  public appendReminderNotesSentence(sentence: string) {
    if (this.reminderNotes) {
      this.reminderNotes += " " + sentence.replace(/\n/g, " ");
    } else {
      this.reminderNotes = sentence.replace(/\n/g, " ");
    }
  }

  @Watch("reminderNotes")
  public onReminderNotesChanged() {
    if (!this.reminderNotes) {
      return;
    }

    this.reminderNotes = this.reminderNotes.replace(/\n/g, " ");
  }

  mounted() {
    if (this.defaultUserId) {
      this.userId = this.defaultUserId;
    }

    if (!this.userId && this.usersWithCalendar.length === 1) {
      this.userId = this.usersWithCalendar[0].id;
    }

    if (!this.userId && this.$store.getters["auth/isTherapist"]) {
      this.userId = this.$store.getters["auth/userId"];
    }

    if (this.patient) {
      this.patientId = this.patient.id;
    }

    if (this.patient) {
      this.patientId = this.patient.id;
      this.selectedPatient = this.patient;
    }

    const user = (this.$store.getters["auth/users"] as User[]).find(({ id }) => (id === this.userId));
    if (user && user.default_appointment_type_id) {
      this.typeId = user.default_appointment_type_id;
    } else {
      this.typeId = (this.$store.getters["auth/appointmentTypes"] as AppointmentType[])
        .filter(({ deleted_at }) => (!deleted_at))[0].id;
    }

    if (this.defaultStartDate) {
      this.date = this.defaultStartDate.substring(0, 10);
      if (this.defaultStartDate.length > 10) {
        this.onStartHourChanged(moment(this.defaultStartDate).format("HH:mm"));
      }
    }
  }

  get patientGeneralNotes() {
    return this.selectedPatient.notes.filter(({ pinned }) => (!pinned));
  }

  onStartHourChanged(newStartHour) {
    if (!newStartHour) {
      return;
    }

    let treatmentDuration = (this.endHour ? moment(`${this.date} ${this.endHour}:00`).diff(moment(`${this.date} ${this.startHour}:00`), "minutes") : 0);
    if (!treatmentDuration) {
      const appointmentTypes = this.$store.getters["auth/appointmentTypes"] as AppointmentType[];
      const appointmentType = appointmentTypes.find(({ id }) => (this.typeId === id));
      treatmentDuration = appointmentType ? appointmentType.duration_minutes : appointmentTypes[0].duration_minutes;
    }

    this.startHour = this.reformatTimeInput(newStartHour);

    if (!this.startHour) {
      this.startHour = "";
      return;
    }

    if (!this.endHour) {
      this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(treatmentDuration, "minutes").format("HH:mm");
      return;
    }

    this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(treatmentDuration, "minutes").format("HH:mm");
  }

  public markDirty() {
    this.isDirty = true;
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    if (hour === 0 && min === 0) {
      return "";
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  get appointmentStatuses() {
    return this.$store.getters["auth/appointmentStatuses"]
      .filter((appointmentStatus: AppointmentStatus) => (appointmentStatus.is_enabled));
  }

  public typeChanged() {
    this.markDirty();
    const selectedType = (this.$store.getters["auth/appointmentTypes"] as AppointmentType[])
      .find(appointment => (appointment.id === this.typeId));

    this.treatmentDuration = selectedType.duration_minutes;
    this.endHour = moment.utc("1970-01-01 " + this.startHour + "Z").add(this.treatmentDuration, "minutes").format("HH:mm");
  }

  public displayAppointmentTypeDuration(appointmentType: AppointmentType) {
    if (appointmentType.id === this.typeId) {
      const start = `${this.date} ${this.startHour}`;
      const end = `${this.date} ${this.endHour}`;

      return this.durationText(moment.duration(moment(end).diff(start)).asMinutes());
    }

    return this.durationText(appointmentType.duration_minutes);
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onNewPaitentIdNumberChanged() {
    this.isLoadingNewPatientIdNumber = true;
    this.isNewPatientIdNumberAlreadyExists = false;
    this.isNewPatientIdNumberArchivedExists = false;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    if (!this.newPatient.id_number) {
      this.isLoadingNewPatientIdNumber = false;
      return;
    }

    try {
      const patientsByIdNumber = await PatientApi.quickSearch(this.newPatient.id_number, true, this.activeRequest);
      this.isLoadingNewPatientIdNumber = false;
      if (patientsByIdNumber.data.data.find(({ id_number, deleted_at }) => (id_number === this.newPatient.id_number && !deleted_at))) {
        this.isNewPatientIdNumberAlreadyExists = true;
      } else if (patientsByIdNumber.data.data.find(({ id_number }) => (id_number === this.newPatient.id_number))) {
        this.isNewPatientIdNumberArchivedExists = true;
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      this.isLoadingNewPatientIdNumber = false;
    }
  }

  public async onNewPaitentNameChanged() {
    this.isLoadingNewPatientName = true;
    this.isNewPatientNameAlreadyExists = false;
    this.isNewPatientNameArchivedExists = false;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    if (!this.newPatient.first_name || !this.newPatient.last_name) {
      this.isLoadingNewPatientName = false;
      return;
    }

    try {
      const patientsByName = await PatientApi.quickSearch(this.newPatient.first_name + " " + this.newPatient.last_name, true, this.activeRequest);
      this.isLoadingNewPatientName = false;
      if (patientsByName.data.data.find(({ first_name, last_name, deleted_at }) => (first_name === this.newPatient.first_name && last_name === this.newPatient.last_name && !deleted_at))) {
        this.isNewPatientNameAlreadyExists = true;
      } else if (patientsByName.data.data.find(({ first_name, last_name }) => (first_name === this.newPatient.first_name && last_name === this.newPatient.last_name))) {
        this.isNewPatientNameArchivedExists = true;
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      this.isLoadingNewPatientName = false;
    }
  }

  get appointmentDateFormatted() {
    const momentDate = moment(this.date);
    return this.$t(`day-in-week.${momentDate.format("d")}`) + ", " +
      momentDate.format("D") + " " + this.$t("date_in") + this.$t(`months.${parseInt(momentDate.format("M")) - 1}`) + " " + momentDate.format("Y");
  }

  get isValidBirthDate() {
    return moment(this.newPatient.birth_date, "DD/MM/YYYY").isValid();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get appointmentTypeOptions() {
    return (this.$store.getters["auth/appointmentTypes"] as AppointmentType[]).filter((appointmentType) => {
      return !appointmentType.deleted_at || appointmentType.id === this.typeId;
    });
  }

  public submitForm() {
    this.errors = false;
    this.showErrors = true;

    if (!this.typeId || !this.userId || (this.activeTab === 1 && !this.notes.length) || !this.startHour || !this.endHour) {
      this.errors = this.$t("missing_required");
      return;
    }

    if (this.activeTab === 0 && !this.showNewPatientForm && !this.patientId) {
      this.errors = this.$t("missing_patient");
      return;
    }

    if (this.activeTab === 0 && this.showNewPatientForm && (!this.newPatient.first_name.length || !this.newPatient.last_name.length)) {
      this.errors = this.$t("missing_patient_name");
      return;
    }

    if (this.activeTab === 0 && this.showNewPatientForm && this.newPatient.birth_date.length && !this.isValidBirthDate) {
      this.errors = this.$t("invalid_birth_date");
      return;
    }

    this.savingLoading = true;

    const startDate = moment(`${this.date} ${this.startHour}`);
    let endDate = moment(`${this.date} ${this.endHour}`);

    if (this.isMultiDate) {
      endDate = endDate.add(1, "day");
    }

    const newPatient = {
      ...this.newPatient,
      birth_date: this.newPatient.birth_date ? moment(this.newPatient.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD") : undefined,
    };

    const payload = {
      patient_id: this.patientId,
      user_id: this.userId,
      type_id: this.typeId,
      status_id: this.activeTab === 1 ? 1 : this.statusId, // TODO typeId = 1?
      start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
      notes: this.notes,
      reminder_notes: this.reminderNotes,
      newPatient: this.showNewPatientForm && this.activeTab === 0 ? newPatient : undefined,
    };

    const attempt = AppointmentApi.create(payload);

    attempt
      .then(appointment => {
        this.savingLoading = false;
        this.$toastr.s(this.$t("appointment_created"));
        this.$emit("created", appointment);
        if (this.activeTab === 0 && this.sendFutureAppointmentsReminder && this.isWhatsappBotEnabled && (this.patientId || this.newPatient.phone)) {
          WhatsappApi.sendSinglePatientFutureReminders(appointment.patient_id)
            .then(() => {
              this.$toastr.s(this.$t("send_reminders_success_toastr"));
            })
            .catch((err) => {
              this.$toastr.e(err && err.smileMessage ? err.smileMessage : this.$t("send_reminders_error_toastr"));
            });
        }
        this.closeDialog();
      })
      .catch(err => {
        this.savingLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public onSelectedPatient(patient?: Patient) {
    this.selectedPatient = patient;
    this.patientId = patient ? patient.id : null;
  }

  public setEndHour(time: string) {
    this.endHour = time;
    setTimeout(() => {
      // workaround
      this.endHour = time;
    }, 50);
  }

  get isMultiDate() {
    if (this.startHour.length !== 5 || !this.endHour || this.endHour.length !== 5) {
      return false;
    }

    const start = `${this.date} ${this.startHour}`;
    const end = `${this.date} ${this.endHour}`;

    return moment.duration(moment(end).diff(start)).asMinutes() < 0;
  }

  get nextDay() {
    return moment(this.date).add(1, "day").format("DD-MM-YYYY");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get startTimeSuggestionList() {
    if (!this.startHour || !moment(`${this.date} ${this.startHour}:00`).isValid()) {
      return [];
    }
    const intervals = this.$store.getters["auth/calendarStartTimeIntervals"];

    return intervals.map(intervalMinutes => ({
      time: moment(`${this.date} ${this.startHour}:00`).add(intervalMinutes, "minutes").format("HH:mm"),
    }));
  }

  get endTimeSuggestionList() {
    if (!this.startHour || !moment(`${this.date} ${this.startHour}:00`).isValid()) {
      return [];
    }
    let durations = this.$store.getters["auth/calendarAppointmentDurations"];

    const treatmentDuration = moment(`${this.date} ${this.endHour}:00`).diff(moment(`${this.date} ${this.startHour}:00`), "minutes");
    if (!durations.includes(treatmentDuration) && parseInt(treatmentDuration.toString()) > 0) {
      durations = [...durations, parseInt(treatmentDuration.toString())].sort(function (a, b) {
        return a - b;
      });
    }

    return durations.map(durationMinutes => ({
      time: moment(`${this.date} ${this.startHour}:00`).add(durationMinutes, "minutes").format("HH:mm"),
      duration: durationMinutes,
    }));
  }

  public durationText(durationMinutes: number) {
    if (durationMinutes < 60) {
      return `${durationMinutes} ${this.$t("minutes")}`;
    }

    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    if (hours === 1) {
      if (!minutes) {
        return `${this.$t("hour")}`;
      }

      return `${this.$t("hour")} ${this.$t("and")}${minutes} ${this.$t("minutes")}`;
    }

    if (!minutes) {
      return `${hours} ${this.$t("hours")}`;
    }

    return `${hours} ${this.$t("hours")} ${this.$t("and")}${minutes} ${this.$t("minutes")}`;
  }

}
