import {Module} from "vuex";
import patientFileState from "./patient-file.state";
import patientFileGetters from "./patient-file.getters";
import patientFileActions from "./patient-file.actions";
import patientFileMutations from "./patient-file.mutations";

const patientFileStore: Module<any, any> = {
    namespaced: true,
    state: patientFileState,
    getters: patientFileGetters,
    actions: patientFileActions,
    mutations: patientFileMutations,
};

export default patientFileStore;
