
























































import ShiftApi from "@/modules/shift/api/shift.api";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Shift } from "@/modules/shift/types/shift.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import moment from "moment";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";

@Component({
  components: {
    ErrorMessage,
  }
})
export default class ShiftForm extends Vue {
  @Prop({ required: false }) public editShift: Shift;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  public errors: any = false;
  public userId: number = null;
  public date = "";
  public startTime = "";
  public endTime = "";
  public notes = "";
  public dateMenuOpen = false;
  public isLoading = false;
  public deleteLoading = false;

  mounted() {
    if (this.editShift) {
      this.userId = this.editShift.user_id;
      this.notes = this.editShift.notes;
      this.date = moment(this.editShift.start_date).format("YYYY-MM-DD");
      this.startTime = moment(this.editShift.start_date).format("HH:mm");
      this.endTime = moment(this.editShift.end_date).format("HH:mm");
    } else {
      if (this.therapistUsers.length === 1) {
        this.userId = this.therapistUsers[0].id;
      }
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  get computedDateFormatted() {
    if (!this.date) {
      return null;
    }
    const [year, month, day] = this.date.split("-");

    return `${day}/${month}/${year}`;
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    if (hour === 0 && min === 0) {
      return "";
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  public async submitForm() {
    if (this.isLoading) {
      return;
    }

    this.errors = false;

    if (!this.startTime || !this.endTime) {
      this.errors = true;
      return;
    }

    this.isLoading = true;
    const startDate = this.date + " " + this.startTime;
    const endDate = this.date + " " + this.endTime;
    const attempt = this.editShift
      ? ShiftApi.update(this.editShift.id, this.userId, startDate, endDate, this.notes)
      : ShiftApi.create(this.userId, startDate, endDate, this.notes);

    attempt
      .then(() => {
        this.isLoading = false;

        this.$toastr.s(this.editShift ? this.$t("shift_updated") : this.$t("shift_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public async deleteShift() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await ShiftApi.delete(this.editShift.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

}
