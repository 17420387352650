import { WhatsappContact } from "../types/whatsapp.type";

interface State {
    contacts: WhatsappContact[];
}

const whatsappState: State = {
    contacts: []
};

export default whatsappState;
