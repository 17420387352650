import {Module} from "vuex";
import authState from "@/modules/auth/store/auth.state";
import authGetters from "@/modules/auth/store/auth.getters";
import authActions from "@/modules/auth/store/auth.actions";
import authMutations from "@/modules/auth/store/auth.mutations";

const authStore: Module<any, any> = {
    namespaced: true,
    state: authState,
    getters: authGetters,
    actions: authActions,
    mutations: authMutations,
};

export default authStore;
