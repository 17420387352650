






































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { Pinkas } from "../types/pinkas.type";
import PinkasApi from "../api/pinkas.api";

@Component
export default class PinkasForm extends mixins(SmileMixin) {
  @Prop({ required: false }) public editPinkas: Pinkas;
  @Getter("auth/isRtl") isRtl: boolean;

  public showErrors = false;
  public errors: any = false;
  public isSendLoading = false;
  public deleteLoading = false;
  public isInvalidPhone = false;
  public isInvalidEmail = false;
  public initData = {
    businessName: null,
    businessId: null,
    address: null,
    email: null,
    homeNumber: null,
    city: null,
    zipCode: null,
    phone: null,
    logo: null
  }
  public formFields = [
    { label: "business_id", variable: "businessId" },
    { label: "business_name", variable: "businessName" },
    { label: "email", variable: "email" },
    { label: "phone", variable: "phone" },
    { label: "address", variable: "address" },
    { label: "home_number", variable: "homeNumber" },
    { label: "city", variable: "city" },
    { label: "zip_code", variable: "zipCode" },
    { label: "logo", variable: "logo" },
  ]

  async public deletePinkas() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    this.deleteLoading = true;
    try {
      await PinkasApi.delete(this.editPinkas.id);
      this.$toastr.s(this.$t("pinkas_deleted"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("saving_error"));
    } finally {
      this.deleteLoading = false;
    }

  }

  async public mounted() {
    if (this.editPinkas) {
      this.initData.businessId = this.editPinkas.business_id;
      this.initData.businessName = this.editPinkas.business_name;
      this.initData.address = this.editPinkas.address;
      this.initData.email = this.editPinkas.email;
      this.initData.zipCode = this.editPinkas.zip_code;
      this.initData.phone = this.editPinkas.phone;
      this.initData.homeNumber = this.editPinkas.home_number;
      this.initData.city = this.editPinkas.city;
      this.initData.logo = this.editPinkas.logo;
    }
  }

  public async closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    this.errors = false;
    this.showErrors = true;
    this.isInvalidPhone = false;
    this.isInvalidEmail = false;
    if (!this.initData.phone || this.initData.phone.length < 9) {
      this.isInvalidPhone = true;
      return;
    } else if (!this.isValidEmail(this.initData.email)) {
      this.isInvalidEmail = true;
      return;
    }

    const payload = {
      business_id: this.initData.businessId,
      business_name: this.initData.businessName,
      email: this.initData.email,
      address: this.initData.address,
      home_number: this.initData.homeNumber,
      city: this.initData.city,
      zip_code: this.initData.zipCode,
      phone: this.initData.phone,
      logo: this.initData.logo
    };
    try {
      this.isSendLoading = true;
      this.editPinkas ? await PinkasApi.update(this.editPinkas.id, payload) : await PinkasApi.create(payload);
      this.editPinkas ? this.$toastr.s(this.$t("pinkas_updated")) : this.$toastr.s(this.$t("pinkas_created"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
    }
  }

}
