import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { FamilyApiRoutes } from "@/modules/patient/constants/api-routes";
import { Patient } from "../types/patient.type";

class FamilyApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async markAsFamily(
    patientAId: number,
    patientBId: number,
    forceMerge: boolean
  ): Promise<true | Patient[]> {
    try {
      const response = await this.httpClient.post(
        FamilyApiRoutes.markAsFamily,
        {
          patientAId,
          patientBId,
          forceMerge,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async removeFamily(patientId: number): Promise<void> {
    try {
      const response = await this.httpClient.post(
        FamilyApiRoutes.removeFamily,
        {
          patientId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new FamilyApi();
