import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEmpty)?_c('div'):(_vm.isSingleBookmark)?_c(VMenu,{attrs:{"offset-x":"","top":"","right":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'VIcon',attrs,false),on),[_vm._v("mdi-notebook-outline")])]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.contacts[0].contacts),function(contact,i){return _c(VListItem,{key:i,staticStyle:{"max-width":"350px"},on:{"click":function($event){return _vm.contactSelected(contact.email)}}},[_vm._v("\n        "+_vm._s(contact.name)+"\n      ")])}),1)],1):_c(VMenu,{attrs:{"right":"","top":"","offset-y":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'VIcon',attrs,false),on),[_vm._v("mdi-notebook-outline")])]}}]),model:{value:(_vm.contactsOpen),callback:function ($$v) {_vm.contactsOpen=$$v},expression:"contactsOpen"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.contacts),function(category,index){return _c(VMenu,{key:index,attrs:{"offset-x":"","top":"","right":"","max-height":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({},'VListItem',attrs,false),on),[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])]}}],null,true)},[_c(VList,{attrs:{"dense":""}},_vm._l((category.contacts),function(contact,i){return _c(VListItem,{key:i,staticStyle:{"max-width":"350px"},on:{"click":function($event){return _vm.contactSelected(contact.email)}}},[_vm._v("\n            "+_vm._s(contact.name)+"\n          ")])}),1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }