import Request from "../request";

class SentenceCategoryApi {
  constructor() {
    this.request = new Request();
  }

  get() {
    return this.request.get("/sentence_category");
  }

  delete(id) {
    return this.request.delete(`/sentence_category/${id}`);
  }

  create(name, order, displayType) {
    return this.request.post("/sentence_category", { name, order, display_type: displayType });
  }

  update(id, name, order, displayType) {
    return this.request.put(`/sentence_category/${id}`, { name, order, display_type: displayType });
  }

}

export default new SentenceCategoryApi();
