






import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class App extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;

  public routeName = "";

  mounted() {
    // Cache images
    (new Image()).src = require("./assets/smile-logo-error.png");
    (new Image()).src = require("./assets/whatsapp-background.png");

    // Disable scroll behavior for input[type=number] elements
    document.addEventListener("wheel", function () {
      if (document.activeElement instanceof HTMLInputElement && document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }

  @Watch("$route")
  routeChanged() {
    this.routeName = this.$route.name;
  }
}
