

























































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import PatientTreatmentFormDialog from "./PatientTreatmentFormDialog.vue";
import ShowPaymentDocumentFileDialog from "./PatientPayment/ShowPaymentDocumentFileDialog.vue";
import { PatientTreatment } from "../types/patient-treatment.type";
import { Treatment } from "@/modules/treatment/types/treatment.type";
import { FutureHeshbonitMas, HeshbonitMas, HeshbonitMasKabala, HeshbonitZikoi, Kabala, Payment } from "../types/payment.type";
import CancelPaymentConfirmDialog from "@/modules/payment/components/CancelPaymentConfirmDialog.vue";
import RefundPaymentConfirmDialog from "@/modules/payment/components/RefundPaymentConfirmDialog.vue";
import PatientApi from "@/modules/patient/api/patient.api";
import RebalancePaymentDialog from "@/modules/payment/components/RebalancePaymentDialog.vue";
import { PATIENT_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {
    ShowPaymentDocumentFileDialog,
    PatientTreatmentFormDialog,
    CancelPaymentConfirmDialog,
    RebalancePaymentDialog,
    RefundPaymentConfirmDialog,
  },
  filters: {
    age,
    date,
    time
  }
})
export default class PatientPayments extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: true }) public isOpenReceivePaymentDialogLoading: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isShowingPaymentRefundButton") isShowingPaymentRefundButton: boolean;
  @Getter("auth/isShowingPaymentPinkasName") isShowingPaymentPinkasName: boolean;
  @Getter("auth/isConvertFutureHeshbonitMasEnabled") isConvertFutureHeshbonitMasEnabled: boolean;

  public displayTreatmentFormDialog = false;
  public isDisplayRebalancePaymentDialog = false;
  public editingTreatment: PatientTreatment = null;
  public showMoreIds: number[] = [];
  public displayDocument: Kabala | HeshbonitMas | HeshbonitZikoi | HeshbonitMasKabala = null;
  public patientBalance: number = null;
  public paymentToCancel: Payment = null;
  public showPaymentRefundDialog = false;
  public isRefundLoading = false;
  public paymentIdLoadingCancel: number = null;
  public isLoadingCreateFutureInvoice = false;

  public onOpenFamilyBalanceModal() {
    this.$emit("openFamilyBalanceModal");
  }

  get familyBalance() {
    let familyBalance = 0;

    this.patient.family_balance.forEach(({ balance }) => {
      familyBalance += balance;
    });

    return familyBalance + this.patientBalance;
  }

  public async onConvertFutureHeshbonitMas(document: FutureHeshbonitMas) {
    const res = await this.$confirm(
      this.$t("create_invoice_confirm_message").toString(),
      {
        buttonTrueColor: "green",
        buttonTrueText: this.$t("confirm_btn").toString(),
        buttonFalseText: this.$t("cancel_btn").toString(),
      });

    if (!res) {
      return;
    }
    try {
      this.isLoadingCreateFutureInvoice = true;
      await PatientApi.convertFutureHeshbonitMas(document.id);
      this.$toastr.s(this.$t("future_heshbonit_created_toastr"));
      this.isLoadingCreateFutureInvoice = false;
      this.$emit("paymentUpdated");
    } catch (err) {
      this.$toastr.e(this.$t("future_heshbonit_temporary_error"));
      this.isLoadingCreateFutureInvoice = false;
    }
  }

  public onEditTreatmentClicked(treatment: PatientTreatment) {
    this.displayTreatmentFormDialog = true;
    this.editingTreatment = treatment;
  }

  public mounted() {
    this.calculateBalance();
  }

  public onPaymentRebalanced() {
    this.$emit("paymentRebalanced");
  }

  @Watch("patient")
  @Watch("patient.treatments")
  public calculateBalance() {
    const treatmentsAndPayments = [
      ...this.patient.payments,
      ...this.patient.treatments,
    ].sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });

    let balance = 0;
    for (let i = treatmentsAndPayments.length - 1; i >= 0; i--) {
      if ("treatment_id" in treatmentsAndPayments[i]) {
        balance -= (treatmentsAndPayments[i] as PatientTreatment).price - (treatmentsAndPayments[i] as PatientTreatment).discount;
      } else {
        if (!(treatmentsAndPayments[i] as Payment).deleted_at) {
          balance += parseFloat((treatmentsAndPayments[i] as Payment).amount.toString());
        }
      }

      (treatmentsAndPayments[i] as any).balance = balance;
    }

    this.patientBalance = balance;

    return treatmentsAndPayments;
  }

  public isCancelEnabled(payment: Payment) {
    if (payment.amount < 0) {
      return false;
    }
    return payment.kabala.length > 0 || payment.heshbonit_mas_kabala.length > 0;
  }

  public onCancelButtonClicked(payment: Payment) {
    this.paymentToCancel = payment;
  }

  public onRefundButtonClicked() {
    this.showPaymentRefundDialog = true;
  }

  public async onPaymentRefundConfirmed(pinkasId: number, amount: number, paymentType: "credit_card" | "cash" | "check" | "bank_transfer", description: string) {
    try {
      this.isRefundLoading = true;
      await PatientApi.refundPayment(pinkasId, this.patient.id, amount, paymentType, description);
      this.isRefundLoading = false;
      this.$toastr.s(this.$t("payment_refunded_toastr"));
      this.$emit("paymentUpdated");
    } catch (err) {
      this.isRefundLoading = false;
      this.$toastr.e(this.$t("refund_temporary_error"));
    }
  }

  public async onPaymentCancelConfirmed(payment: Payment, cancelReason: string) {
    try {
      this.paymentIdLoadingCancel = payment.id;

      await PatientApi.cancelPayment(this.patient.id, payment.id, cancelReason);

      this.paymentIdLoadingCancel = null;
      this.$toastr.s(this.$t("payment_canceled_toastr"));
      this.$emit("paymentUpdated");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.paymentIdLoadingCancel = null;
    }
  }

  get patientTreatmentsAndPayments() {
    const treatmentsAndPayments = [
      ...this.patient.payments,
      ...this.patient.treatments,
    ];

    return treatmentsAndPayments.sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });
  }

  public async onTreatmentUpdated() {
    this.$toastr.s(this.$t("treatment_updated_toastr"));
    this.$emit("paymentRebalanced");
  }

  public async onTreatmentDeleted(appointmentTreatmentId: number) {
    this.displayTreatmentFormDialog = false;
    this.editingTreatment = null;

    const index = this.patient.treatments.findIndex(payments => payments.id === appointmentTreatmentId);
    this.patient.treatments.splice(index, 1);

    this.$toastr.s(this.$t("treatment_deleted_toastr"));
    this.$emit("paymentRebalanced");
  }

  public headers = [
    { text: this.$t("headers.date"), value: "date", sortable: false },
    { text: this.$t("headers.start_time"), value: "start_time", sortable: false },
    { text: this.$t("headers.description"), value: "description", sortable: false },
    { text: this.$t("headers.price"), value: "price", sortable: false },
    { text: this.$t("headers.balance"), value: "balance", sortable: false },
  ];

  public rowClass(row: Payment | PatientTreatment) {
    const itemIndex = this.patientTreatmentsAndPayments.findIndex((paymentRow: Payment | PatientTreatment) => (paymentRow === row));

    const classList: string[] = [];
    if (!("treatment_id" in row)) {
      classList.push("gren lighten-5");
    }

    if (itemIndex > 0) {
      const previusRow = this.patientTreatmentsAndPayments[itemIndex - 1];
      if (date(previusRow.created_at) !== date(row.created_at)) {
        classList.push("topBorder");
      }
    }

    return classList.join(" ");
  }

  public onOpenPaymentReceiveClicked() {
    this.$emit("openPaymentReceiveDialog");
  }

  public getPaymentSummary(payment: Payment) {
    let summary = "";
    if (payment.attributes.cash) {
      summary += this.$t("cash") + ": " + payment.attributes.cash.toLocaleString() + "₪ ";
    }
    if (payment.attributes.check) {
      summary += this.$t("check") + ": " + payment.attributes.check.toLocaleString() + "₪ ";
    }
    if (payment.attributes.bank_transfer) {
      summary += this.$t("bank_transfer") + ": " + payment.attributes.bank_transfer.toLocaleString() + "₪ ";
    }
    if (payment.attributes.credit_card) {
      summary += this.$t("credit_card") + ": " + payment.attributes.credit_card.toLocaleString() + "₪ ";
    }
    return summary;
  }

  public getPaymentDocuments(payment: Payment) {
    return [
      ...payment.heshbonit_mas,
      ...payment.heshbonit_mas_kabala,
      ...payment.heshbonit_zikoi,
      ...payment.kabala,
      ...payment.future_heshbonit_mas,
      ...payment.heshbonit_deal,
    ].sort((a, b) => {
      if ((a as FutureHeshbonitMas).value_date_at < (b as FutureHeshbonitMas).value_date_at) return -1;
      if ((a as FutureHeshbonitMas).value_date_at > (b as FutureHeshbonitMas).value_date_at) return 1;
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });
  }

  public treatmentCleanup(notes: string) {
    return notes.replace(/^(תשלום:\s)/, "").replace(/^(תשלום -\s)/, "");
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get treatmentPaymentId() {
    let paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    if (!paymentTreatment) {
      paymentTreatment = this.$store.getters["auth/treatments"]
        .find((treatment: Treatment) => (treatment.name === "תשלום"));
    }

    return paymentTreatment.id;
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }
}
