import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { TreatmentApiRouteParameters, TreatmentApiRoutes } from "@/modules/treatment/constants/api-routes";
import { Treatment, TreatmentAttributes } from "@/modules/treatment/types/treatment.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class TreatmentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<Treatment[]> {
    try {
      const response: ApiResponse<Treatment[]> = await this.httpClient.get(TreatmentApiRoutes.getAll);

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, name: string, code: string, price: number, order: number, defaultNotes: string, attributes: TreatmentAttributes, color: string): Promise<Treatment> {
    try {
      const response: ApiResponse<Treatment> = await this.httpClient.put(
        TreatmentApiRoutes.update.replace(TreatmentApiRouteParameters.id, id.toString()),
        {
          name,
          code,
          price,
          order,
          default_notes: defaultNotes,
          attributes,
          color,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(name: string, code: string, price: number, order: number, defaultNotes: string, attributes: TreatmentAttributes, color: string): Promise<Treatment> {
    try {
      const response: ApiResponse<Treatment> = await this.httpClient.post(
        TreatmentApiRoutes.create,
        {
          name,
          code,
          price,
          order,
          default_notes: defaultNotes,
          attributes,
          color,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        TreatmentApiRoutes.delete.replace(TreatmentApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new TreatmentApi();
