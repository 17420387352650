





















import PatientApi from "@/modules/patient/api/patient.api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { Patient } from "@/modules/patient/types/patient.type";
import { Getter } from "vuex-class";

@Component
export default class SearchPatient extends Vue {
  @Prop({ required: false, default: false }) outlinedDesign: boolean;
  @Prop({ required: false, default: null }) defaultRelatedPatient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest;
  public hideNoData = true;
  public entries = [];
  public loading = false;
  public search = "";
  public key = 0;

  public fullName(patient: Patient) {
    if (patient.deleted_at) {
      return `${patient.first_name} ${patient.last_name} (${this.$t("archived")})`;
    }
    return `${patient.first_name} ${patient.last_name}`;
  }

  public async mounted() {
    if (this.defaultRelatedPatient) {
      await this.onSearchChanged(this.defaultRelatedPatient.phone);
    }
  }

  @Watch("patient")
  onPatientChanged(selectedPatientId: number) {
    const patient = this.entries.find(({ id }) => (id === selectedPatientId));
    if (!patient) {
      // TODO log to sentry / coralogix
      return;
    }

    this.$emit("selectedPatient", patient);
    this.hideNoData = true;
    this.entries = [];
    this.search = "";
    this.key++;
  }

  @Watch("search")
  public async onSearchChanged(query) {
    this.hideNoData = true;
    this.loading = true;
    this.entries = [];

    // Lazily load input items
    if (!query || query.length === 0) {
      this.loading = false;
      return;
    }

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      const response = await PatientApi.quickSearch(query, false, this.activeRequest);
      const entries = this.defaultRelatedPatient
        ? response.data.data.filter((patient: Patient) => (patient.id !== this.defaultRelatedPatient.id))
        : response.data.data;

      this.loading = false;
      if (this.defaultRelatedPatient && entries.length === 0) {
        return;
      }
      this.hideNoData = entries.length !== 0;
      this.entries = entries;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.$toastr.e(this.$t("temporary_error"));
      this.loading = false;
    }
  }
}
