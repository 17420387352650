export function enableDatePickerAdjacentMonths() {
  const disabledButtons = document.querySelectorAll(
    ".fix-date-picker-adjacent-months button:disabled, .fix-date-picker-adjacent-months button.v-btn--disabled"
  );
  disabledButtons.forEach((button) => {
    (button as HTMLButtonElement).removeAttribute("disabled");
    (button as HTMLButtonElement).classList.remove("v-btn--disabled");
    (button as HTMLButtonElement).classList.add("disabled-btn-color");
  });
}
