import { date, time } from "@/filters/date.filter";
import { AppointmentType } from "@/modules/appointment/types/appointment-type.type";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import { Contact } from "@/modules/contacts/types/contact.type";
import { SupplierPaymentCategory } from "@/modules/supplier/types/supplier-payment-category.type";
import { Supplier } from "@/modules/supplier/types/supplier.type";
import { User } from "@/modules/users/types/user.type";
import moment from "moment";
import { Vue } from "vue-property-decorator";

export default class SmileMixin extends Vue {
  public tagColor(id: number) {
    const selectedTag = this.$store.getters["auth/tags"].find(
      (tag) => tag.id === id
    );

    if (!selectedTag) {
      return null;
    }

    return selectedTag.color;
  }

  public tagName(id: number) {
    const selectedTag = this.$store.getters["auth/tags"].find(
      (tag) => tag.id === id
    );

    return selectedTag.name;
  }

  public supplierName(supplierId: number) {
    const supplier = (this.$store.getters["auth/suppliers"] as Supplier[]).find(
      ({ id }) => supplierId === id
    );

    return supplier.name;
  }

  public supplierPaymentCategoryName(supplierPaymentCategoryId: number) {
    const supplier = (this.$store.getters[
      "auth/supplierPaymentCategories"
    ] as SupplierPaymentCategory[]).find(
      ({ id }) => supplierPaymentCategoryId === id
    );

    return supplier.name;
  }

  public treatmentCode(id: number) {
    if (!id) {
      return "";
    }
    const selectedTreatment = this.$store.getters["auth/treatments"].find(
      (treatment) => treatment.id === id
    );

    return selectedTreatment.code;
  }

  public treatmentName(id: number) {
    if (!id) {
      return "--";
    }
    const selectedTreatment = this.$store.getters["auth/treatments"].find(
      (treatment) => treatment.id === id
    );

    return selectedTreatment.name;
  }

  public userName(id: number): string {
    const user = this.$store.getters["auth/users"].find(
      (user) => user.id === id
    );

    if (!user) {
      // TODO log to sentry / coralogix
      console.warn(`unable to find user for id: ${id}`);
      return id.toString();
    }

    return user.name;
  }

  public userNameToId(name: string): number {
    const user = (this.$store.getters["auth/users"] as User[]).find(
      (user) => user.name === name
    );

    return user.id;
  }

  public agentName(id: number): string {
    const agent = this.$store.getters["auth/imagingAgents"].find(
      (agent) => agent.id === id
    );

    if (!agent) {
      // TODO log to sentry / coralogix
      console.warn(`unable to find agent for id: ${id}`);
      return id.toString();
    }

    return agent.name;
  }

  public contactById(contactId: number): Contact {
    if (!this.$store.getters["auth/contacts"]) {
      return null;
    }

    const contactBookmark = (this.$store.getters[
      "auth/contacts"
    ] as ContactBookmark[]).find(({ contacts }) =>
      contacts.find(({ id }) => id === contactId)
    );

    if (!contactBookmark) {
      return null;
    }

    return contactBookmark.contacts.find(({ id }) => id === contactId);
  }

  public contactNameByEmail(contactEmail: string) {
    const contacts = this.$store.getters["auth/contacts"];
    if (!contacts || !contactEmail) {
      return null;
    }

    let contact: Contact;
    for (let i = 0; i < contacts.length; i++) {
      contact = contacts[i].contacts.find(
        (contact: Contact) =>
          contact.email === contactEmail || contact.email2 === contactEmail
      );
      if (contact) {
        break;
      }
    }

    return contact ? contact.name : null;
  }

  public getColorByUser(id: number): string {
    const user = (this.$store.getters["auth/users"] as User[]).find(
      (user) => user.id === id
    );

    if (!user) {
      // TODO log to sentry / coralogix
      console.warn(`unable to find user for id: ${id}`);
      return id.toString();
    }

    return user.color;
  }

  public getAppointmentTypeName(id: number) {
    const appointmentType = (this.$store.getters[
      "auth/appointmentTypes"
    ] as AppointmentType[]).find(
      (appointmentType) => appointmentType.id === id
    );

    return appointmentType.name;
  }

  public getAppointmentTypeColor(id: number) {
    const appointmentType = (this.$store.getters[
      "auth/appointmentTypes"
    ] as AppointmentType[]).find(
      (appointmentType) => appointmentType.id === id
    );

    return appointmentType.color;
  }

  public getAppointmentStatusColor(id: number) {
    const appointmentStatus = this.$store.getters[
      "auth/appointmentStatuses"
    ].find((appointmentStatus) => appointmentStatus.id === id);

    if (!appointmentStatus) {
      return null;
    }

    return appointmentStatus.color;
  }

  public getAppointmentStatusText(id: number) {
    const appointmentStatus = this.$store.getters[
      "auth/appointmentStatuses"
    ].find((appointmentStatus) => appointmentStatus.id === id);

    if (!appointmentStatus) {
      return null;
    }

    return appointmentStatus.name;
  }

  public getPatientStatusColor(id: number) {
    const patientStatus = this.$store.getters["auth/patientStatuses"].find(
      ({ id: statusId }) => statusId === id
    );

    if (!patientStatus) {
      return null;
    }

    return patientStatus.color;
  }

  public getPatientStatusText(id: number) {
    const patientStatus = this.$store.getters["auth/patientStatuses"].find(
      ({ id: statusId }) => statusId === id
    );

    if (!patientStatus) {
      return null;
    }

    return patientStatus.name;
  }

  public downloadFile(url: string, name: string) {
    const save = document.createElement("a");
    save.href = url;
    save.download = name;
    save.target = "_blank";
    save.click();
  }

  private reformatPhoneForWhatsapp(phone: string) {
    if (!phone) {
      return phone;
    }
    const reformatedPhone = phone.replace("-", "");
    if (phone.startsWith("+") && !phone.startsWith("+972")) {
      return encodeURIComponent(reformatedPhone);
    }

    if (reformatedPhone[0] === "0") {
      return "972" + reformatedPhone.substring(1);
    }

    return reformatedPhone;
  }

  public shareWhatsapp(phone: string, msg: string) {
    const msgWithoutEmojiesEncoded = encodeURIComponent(msg.replace("🌻", ""));
    const msgEncoded = encodeURIComponent(msg);
    const reformatedPhone = this.reformatPhoneForWhatsapp(phone);
    const waUrlWithTab = `https://wa.me/${reformatedPhone}?text=${msgWithoutEmojiesEncoded}`;
    const waUrlDirect = `whatsapp://send?text=${msgEncoded}&phone=${reformatedPhone}`;
    if (this.$store.getters["auth/isWaLinkDirect"]) {
      document.location.href = waUrlDirect;
    } else {
      window.open(waUrlWithTab, "_blank");
    }
  }

  public shareWatsappWebMessage(patientPhone: string, fileUrl: string) {
    const whatsappTemplateUrl = this.$store.getters[
      "auth/whatsappWebShareLinkTemplate"
    ];
    const clinicName = this.$store.getters["auth/clinicName"];
    const composedWhatsappMessageToShare = whatsappTemplateUrl
      .replace("{clinicName}", clinicName)
      .replace("{linkUrl}", fileUrl);
    this.shareWhatsapp(patientPhone, composedWhatsappMessageToShare);
  }

  public daysDiffToBirthday(date: string) {
    if (!date) {
      return false;
    }

    const thisYear = new Date().getFullYear();
    const closestBirthdayDaysDiff = Math.min(
      ...[
        Math.abs(moment().diff(moment(date).set("year", thisYear - 1), "days")),
        Math.abs(moment().diff(moment(date).set("year", thisYear), "days")),
        Math.abs(moment().diff(moment(date).set("year", thisYear + 1), "days")),
      ]
    );

    return closestBirthdayDaysDiff;
  }

  public isValidEmail(email: string) {
    if (!email) {
      return false;
    }
    /* eslint-disable */
    return !!email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  public isValidPhone(phone: string) {
    if (!phone) {
      return false;
    }

    if (phone[0].toString() !== "0") {
      return false;
    }

    if (phone.length < 9 || phone.length > 10) {
      return false;
    }

    return true;
  }

  public relativeDate(d: string) {
    if (!d) {
      return "";
    }
    if (this._isToday(d)) {
      return this.$t("today") + " " + time(d);
    }
    if (this.isYesterday(d)) {
      return this.$t("yesterday") + " " + time(d);
    }
    return date(d);
  }

  private _isToday(date: string) {
    const today = moment().startOf("day");
    const d = moment(date).startOf("day");
    return today.diff(d, "day") === 0;
  }

  private isYesterday(date: string) {
    const today = moment().startOf("day");
    const d = moment(date).startOf("day");
    return today.diff(d, "day") === 1;
  }
}
