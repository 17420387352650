import { SmileCalendarEvent } from "../../types/calendar.type";

export const holidaysSmileCalendarEvent: SmileCalendarEvent[] = [
  {
    appointment: null,
    name: "eve_of_rosh_hashanah",
    start: "2023-09-15",
    end: "2023-09-15",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "rosh_hashanah",
    start: "2023-09-16",
    end: "2023-09-17",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_kippur",
    start: "2023-09-24",
    end: "2023-09-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_kippur",
    start: "2023-09-25",
    end: "2023-09-25",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_sukkot",
    start: "2023-09-29",
    end: "2023-09-29",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "sukkot",
    start: "2023-09-30",
    end: "2023-10-06",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "simchat_torah",
    start: "2023-10-07",
    end: "2023-10-07",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_hanukkah",
    start: "2023-12-07",
    end: "2023-12-07",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "hanukkah",
    start: "2023-12-08",
    end: "2023-12-15",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_purim",
    start: "2024-03-23",
    end: "2024-03-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "purim",
    start: "2024-03-24",
    end: "2024-03-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_passover",
    start: "2024-04-22",
    end: "2024-04-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "passover",
    start: "2024-04-23",
    end: "2024-04-29",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hashoah",
    start: "2024-05-05",
    end: "2024-05-05",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hashoah",
    start: "2024-05-06",
    end: "2024-05-06",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hazikaron",
    start: "2024-05-12",
    end: "2024-05-12",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hazikaron",
    start: "2024-05-13",
    end: "2024-05-13",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "lag_bomer",
    start: "2024-05-26",
    end: "2024-05-26",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_haatzmaut",
    start: "2024-05-14",
    end: "2024-05-14",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_shavuot",
    start: "2024-06-11",
    end: "2024-06-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "shavuot",
    start: "2024-06-12",
    end: "2024-06-12",
    timed: true,
    allDay: true,
  },

  {
    appointment: null,
    name: "seventeen_tamuz",
    start: "2024-07-23",
    end: "2024-07-23",
    timed: true,
    allDay: true,
  },

  {
    appointment: null,
    name: "eve_of_rosh_hashanah",
    start: "2024-10-02",
    end: "2024-10-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "rosh_hashanah",
    start: "2024-10-03",
    end: "2024-10-04",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_kippur",
    start: "2024-10-11",
    end: "2024-10-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_kippur",
    start: "2024-10-12",
    end: "2024-10-12",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_sukkot",
    start: "2024-10-16",
    end: "2024-10-16",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "sukkot",
    start: "2024-10-17",
    end: "2024-10-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "simchat_torah",
    start: "2024-10-24",
    end: "2024-10-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_hanukkah",
    start: "2024-12-25",
    end: "2024-12-25",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "hanukkah",
    start: "2024-12-26",
    end: "2025-01-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_purim",
    start: "2025-03-13",
    end: "2025-03-13",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "purim",
    start: "2025-03-14",
    end: "2025-03-14",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_passover",
    start: "2025-04-12",
    end: "2025-04-12",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "passover",
    start: "2025-04-13",
    end: "2025-04-19",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hashoah",
    start: "2025-04-23",
    end: "2025-04-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hashoah",
    start: "2025-04-24",
    end: "2025-04-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hazikaron",
    start: "2025-04-29",
    end: "2025-04-29",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hazikaron",
    start: "2025-04-30",
    end: "2025-04-30",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_haatzmaut",
    start: "2025-05-01",
    end: "2025-05-01",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_lag_bomer",
    start: "2025-05-15",
    end: "2025-05-15",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "lag_bomer",
    start: "2025-05-16",
    end: "2025-05-16",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_shavuot",
    start: "2025-06-01",
    end: "2025-06-01",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "shavuot",
    start: "2025-06-02",
    end: "2025-06-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_rosh_hashanah",
    start: "2025-09-22",
    end: "2025-09-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "rosh_hashanah",
    start: "2025-09-23",
    end: "2025-09-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_kippur",
    start: "2025-10-01",
    end: "2025-10-01",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_kippur",
    start: "2025-10-02",
    end: "2025-10-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_sukkot",
    start: "2025-10-06",
    end: "2025-10-06",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "sukkot",
    start: "2025-10-07",
    end: "2025-10-07",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "simchat_torah",
    start: "2025-10-14",
    end: "2025-10-14",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_hanukkah",
    start: "2025-12-14",
    end: "2025-12-14",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "hanukkah",
    start: "2025-12-15",
    end: "2025-12-15",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_purim",
    start: "2026-03-02",
    end: "2026-03-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "purim",
    start: "2026-03-03",
    end: "2026-03-03",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_passover",
    start: "2026-04-01",
    end: "2026-04-01",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "passover",
    start: "2026-04-02",
    end: "2026-04-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hashoah",
    start: "2026-04-13",
    end: "2026-04-13",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hashoah",
    start: "2026-04-14",
    end: "2026-04-14",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hazikaron",
    start: "2026-04-20",
    end: "2026-04-20",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hazikaron",
    start: "2026-04-21",
    end: "2026-04-21",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_haatzmaut",
    start: "2026-04-22",
    end: "2026-04-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_lag_bomer",
    start: "2026-05-04",
    end: "2026-05-04",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "lag_bomer",
    start: "2026-05-05",
    end: "2026-05-05",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_shavuot",
    start: "2026-05-21",
    end: "2026-05-21",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "shavuot",
    start: "2026-05-22",
    end: "2026-05-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_rosh_hashanah",
    start: "2026-09-11",
    end: "2026-09-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "rosh_hashanah",
    start: "2026-09-12",
    end: "2026-09-12",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_kippur",
    start: "2026-09-20",
    end: "2026-09-20",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_kippur",
    start: "2026-09-21",
    end: "2026-09-21",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_sukkot",
    start: "2026-09-25",
    end: "2026-09-25",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "sukkot",
    start: "2026-09-26",
    end: "2026-09-26",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "simchat_torah",
    start: "2026-10-03",
    end: "2026-10-03",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_hanukkah",
    start: "2026-12-04",
    end: "2026-12-04",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "hanukkah",
    start: "2026-12-05",
    end: "2026-12-05",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_purim",
    start: "2027-03-22",
    end: "2027-03-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "purim",
    start: "2027-03-23",
    end: "2027-03-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_passover",
    start: "2027-04-21",
    end: "2027-04-21",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "passover",
    start: "2027-04-22",
    end: "2027-04-22",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hashoah",
    start: "2027-05-03",
    end: "2027-05-03",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hashoah",
    start: "2027-05-04",
    end: "2027-05-04",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_hazikaron",
    start: "2027-05-10",
    end: "2027-05-10",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_hazikaron",
    start: "2027-05-11",
    end: "2027-05-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_haatzmaut",
    start: "2027-05-12",
    end: "2027-05-12",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_lag_bomer",
    start: "2027-05-24",
    end: "2027-05-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "lag_bomer",
    start: "2027-05-25",
    end: "2027-05-25",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_shavuot",
    start: "2027-06-10",
    end: "2027-06-10",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "shavuot",
    start: "2027-06-11",
    end: "2027-06-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_rosh_hashanah",
    start: "2027-10-01",
    end: "2027-10-01",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "rosh_hashanah",
    start: "2027-10-02",
    end: "2027-10-02",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_yom_kippur",
    start: "2027-10-10",
    end: "2027-10-10",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "yom_kippur",
    start: "2027-10-11",
    end: "2027-10-11",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_sukkot",
    start: "2027-10-15",
    end: "2027-10-15",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "sukkot",
    start: "2027-10-16",
    end: "2027-10-16",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "simchat_torah",
    start: "2027-10-23",
    end: "2027-10-23",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "eve_of_hanukkah",
    start: "2027-12-24",
    end: "2027-12-24",
    timed: true,
    allDay: true,
  },
  {
    appointment: null,
    name: "hanukkah",
    start: "2027-12-25",
    end: "2027-12-25",
    timed: true,
    allDay: true,
  },
];
