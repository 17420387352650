import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',{staticClass:"mb-2"},[_c(VIcon,[_vm._v("mdi-forum")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n        ")],1),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('feature-description'))+"\n          "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mb-2 ml-2 mr-2",attrs:{"icon":"","color":"cyan lighten-2"},on:{"click":function($event){return _vm.reloadContacts()}}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('reload')))])])],1)])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{class:{
            'hidden-xs-only': _vm.activeWaContact
          },attrs:{"cols":"12","sm":"5"}},[_c(VCard,[_c('WhatsappContactsList',{ref:"WhatsappContactsList"})],1)],1),_c(VCol,{class:{
            'hidden-xs-only': !_vm.activeWaContact
          },attrs:{"cols":"12","sm":"7"}},[(_vm.activeWaContact)?_c(VCard,[_c(VBtn,{staticClass:"hidden-sm-and-up",class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },staticStyle:{"position":"absolute","top":"4px"},style:({ left: !_vm.isRtl ? 'auto' : '4px', right: _vm.isRtl ? 'auto' : '4px' }),attrs:{"icon":"","color":"pink lighten-2","router-link":"","exact":"","to":{ name: _vm.whatsappPageRouteName }}},[_c(VIcon,[_vm._v(_vm._s(_vm.isRtl ? 'mdi-chevron-left' : 'mdi-chevron-right'))])],1),_c('WhatsappContactInfo',{attrs:{"whatsappContact":_vm.activeWaContact}}),_c('SingleWhatsappChat',{ref:"SingleWhatsappChat",attrs:{"phone":_vm.activeWaContact.phone,"markRead":true}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }