

























import { Component, Prop } from "vue-property-decorator";
import { Plan } from "@/modules/treatment/types/plan.type";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";

@Component({
})
export default class DiscountPlanDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public plan: Plan;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public discountPercent = "";

  public closeDialog() {
    this.$emit("close");
  }

  get hasDoneTreatments() {
    return this.plan.plan_treatments.find(({ done_at, started_at }) => (done_at || started_at));
  }

  get isInvalidDiscount() {
    if (!this.discountPercent) {
      return false;
    }

    if (parseFloat(this.discountPercent) > 100) {
      return true;
    }

    if (parseFloat(this.discountPercent) < 0) {
      return true;
    }

    if (!this.discountPercent.match(/^[0-9]+$/)) {
      return true;
    }

    return false;
  }

  public async onPlanUpdateDiscountClicked() {
    this.$emit("planUpdateDiscount", parseFloat(this.discountPercent));
    this.closeDialog();
  }

}
