
























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PatientFolder } from "../../types/patient-folder.type";
import { Patient } from "@/modules/patient/types/patient.type";

@Component({
})
export default class MovePatientFileDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: true }) public folders: PatientFolder[];
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public folderId: number = null;

  public closeDialog() {
    this.$emit("close");
  }

  public async onMoveFileClicked() {
    this.$emit("patientFilesMoved", this.folderId);
    this.closeDialog();
  }

  public getSubFolders(rootName?: string, folderId?: number) {
    const folder = folderId ? this.folders.find(({ id }) => (id === folderId)) : null;
    const subFolders = this.folders.filter(({ parent_folder_id }) => (parent_folder_id === (folderId ? folderId : null)));
    const folderName = folder ? rootName + "/" + folder.name : this.patient.first_name + " " + this.patient.last_name;

    return [
      {
        id: folderId ? folderId : null,
        name: folderName,
      },
      ...subFolders.map(folder => ([
        ...this.getSubFolders(folderName, folder.id)
      ])),
    ].flat(Infinity);
  }

  get foldersList() {
    return this.getSubFolders();
  }
}
