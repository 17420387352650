























import { Component, Vue } from "vue-property-decorator";
import NotificationsCenter from "../components/notifications-center/NotificationsCenter.vue";

@Component({
  components: {
    NotificationsCenter,
  }
})
export default class NotificationsCenterPage extends Vue {
  public onPrintClicked() {
    window.print();
  }

  get showPrintButton() {
    return this.$store.getters["auth/clinicName"] === "מרפאת רומנו"
      || this.$store.getters["auth/clinicName"] === "Modiin Dental"
      || this.$store.getters["auth/clinicName"] === "מרפאת שיניים פלא"
      || this.$store.getters["auth/clinicName"] === "מרכז רפואי אחוזה"
      || this.$store.getters["auth/clinicName"] === "מ.דינבר א.דינבר א.עיני מרפאת שיניים"
      || this.$store.getters["auth/clinicName"] === "ד״ר יעקב הורביץ";
  }
}
