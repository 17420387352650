


















































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import { date, time } from "@/filters/date.filter";

@Component({
  filters: {
    date,
    time,
  }
})
export default class CalendarAppointmentCard extends mixins(SmileMixin) {
  @Prop({ required: true }) appointment: Appointment;
  @Prop({ required: true }) actionType: "move" | "copy";

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onCloseClicked() {
    this.$emit("onCloseClicked");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get patientGeneralNotes() {
    return this.appointment.patient.notes.filter(({ pinned }) => (!pinned));
  }
}
