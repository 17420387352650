import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"verifone-report-page"},[_c(VContainer,{staticStyle:{"padding":"12px 22px"},attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c(VIcon,[_vm._v("mdi-credit-card-outline")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n        ")],1)])],1),_c(VRow,{staticStyle:{"border-bottom":"1px solid #ccc"}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"4","md":"3","lg":"3","xl":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.dateFormatted,"label":_vm.$t('date_filter'),"readonly":"","color":!_vm.date ? 'red' : 'cyan'}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.displayDatePicker),callback:function ($$v) {_vm.displayDatePicker=$$v},expression:"displayDatePicker"}},[(_vm.displayDatePicker)?_c(VDatePicker,{staticClass:"fix-date-picker-adjacent-months",attrs:{"no-title":"","color":"cyan darken-1","show-adjacent-months":""},on:{"input":function($event){_vm.displayDatePicker = false},"update:active-picker":_vm.enableAdjacentMonths,"update:picker-date":_vm.enableAdjacentMonths},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e()],1)],1)],1),(_vm.reportResults || _vm.isLoading)?_c(VRow,[_c(VCol,{staticClass:"pb-12",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","mobile-breakpoint":1,"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.reportResults ? _vm.reportResults.transactions : [],"loading":_vm.isLoading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([(!_vm.isLoading)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-8 text-center"},[_c('img',{staticClass:"mr-1 ml-1",staticStyle:{"vertical-align":"middle","height":"70px"},attrs:{"src":require('@/assets/credit-cards.png')}}),_c('br'),_vm._v("\n              "+_vm._s(_vm.$t('no_data'))+"\n            ")])]},proxy:true}:null,{key:"item.original_amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.original_amount.toLocaleString().replace('.00', ''))+"₪\n          ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("time")(item.created_at))+"\n          ")]}},{key:"item.transaction_id",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.transaction_id.substr(-8))+"\n          ")]}},{key:"item.transaction_status",fn:function(ref){
var item = ref.item;
return [(item.transaction_status === 'CAPTURED' || item.transaction_status === 'VOIDED')?_c('span',{staticStyle:{"white-space":"nowrap"}},[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("\n                mdi-check-circle")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"loading":_vm.loadingVoucherTransactionId === item.transaction_id,"x-small":"","icon":"","elevation":"1"},on:{"click":function($event){return _vm.getVoucherUrl(item)}}},'VBtn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-printer-pos-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('voucher')))])]),(item.transaction_type_weaver === 'Sale' && item.transaction_status === 'CAPTURED' && item.status === 'Include in batch')?_c(VBtn,{staticClass:"mr-3",attrs:{"color":"deep-orange lighten-4","x-small":""},on:{"click":function($event){return _vm.cancelTranscationClicked(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-credit-card-remove-outline")]),_vm._v("\n                "+_vm._s(_vm.$t('cancel_button'))+"\n              ")],1):_vm._e()],1):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"red lighten-2"}},'VIcon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.transaction_status))])])]}},{key:"item.credit_terms",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.$t("credit_terms." + item.credit_terms))+"\n            "),(item.credit_terms === 'Credit' || item.credit_terms === 'Installments')?_c('span',[_vm._v("("+_vm._s(item.installment_count.replace(' Months', ''))+")")]):_vm._e()]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.$t("brand." + item.brand))+"\n          ")]}},{key:"item.transaction_type_weaver",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.$t("transaction_type_weaver." + item.transaction_type_weaver))+"\n            "),(item.transaction_status === 'VOIDED')?_c('span',[_vm._v("("+_vm._s(_vm.$t('voided'))+")")]):_vm._e()]}}],null,true)})],1)],1):_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"min-height":"140px","padding-top":"35px"}},[_c(VIcon,{attrs:{"x-large":"","color":"#eee"}},[_vm._v("mdi-credit-card-outline")]),_c('br'),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(_vm.$t('empty_transactions')))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }