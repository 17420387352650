









import { Component, Vue } from "vue-property-decorator";
import SideMenu from "./layout/SideMenu.vue";
import TopNav from "./layout/TopNav.vue";
import EchoService from "@/serivce/echo-service";
import { Getter } from "vuex-class";

@Component({
  components: {
    SideMenu,
    TopNav
  }
})
export default class Layout extends Vue {
  @Getter("auth/isLogged") isLogged: boolean;
  @Getter("auth/shouldDisplayUnreadWhatsapp") shouldDisplayUnreadWhatsapp: boolean;
  @Getter("auth/shouldDisplayTodoTasks") shouldDisplayTodoTasks: boolean;

  mounted() {
    EchoService.listenFrontendDataChangedPush();
    EchoService.listenFrontendRefreshPush();
    this.reloadInitData();
    if (this.shouldDisplayUnreadWhatsapp) {
      this.reloadWhatsappContacts();
      EchoService.listenFrontendWhatsappReloadContactsPush();
    }

    if (this.shouldDisplayTodoTasks) {
      this.reloadTodoTasks();
    }

    window["smileIsRtl"] = this.$store.getters["auth/isRtl"];
    this.$vuetify.rtl = this.$store.getters["auth/isRtl"];
    this.$i18n.locale = this.$store.getters["auth/locale"];
    this.$root.$i18n.locale = this.$store.getters["auth/locale"];
    this.$vuetify.lang.current = this.$store.getters["auth/locale"];
  }

  public toggleDrawer() {
    (this.$refs.sideMenu as SideMenu).toggleDrawer();
  }

  public reloadInitData() {
    this.$store.dispatch("auth/loadInitData");
  }

  public async reloadTodoTasks() {
    try {
      await this.$store.dispatch("task/loadTasks", this.$store.getters["auth/userId"]);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => this.reloadTodoTasks(), 3 * 60 * 1000); // 3 mins
    }
  }

  public async reloadWhatsappContacts() {
    try {
      await this.$store.dispatch("whatsapp/loadContacts");
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => this.reloadWhatsappContacts(), 2 * 60 * 1000); // 2 mins
    }
  }
}
