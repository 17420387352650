import {AppModule} from "@/types/app-module";
import authRoutes from "@/modules/auth/routes/routes";
import authStore from "@/modules/auth/store";
import authPolicy from "@/modules/auth/policy";

const authModule: AppModule = {
    name: "auth",
    routes: authRoutes,
    store: authStore,
    policy: authPolicy,
};

export default authModule;
