import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smile-notifications"},[_c(VMenu,{attrs:{"offset-y":"","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.markRead()}}},'VBtn',attrs,false),on),[_c(VBadge,{attrs:{"color":_vm.isUnread ? 'pink' : 'transperent',"dot":"","overlap":""}},[_c(VIcon,{class:{ 'bell-animation': _vm.isUnread }},[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[_c(VCard,{attrs:{"color":"white","width":"220"}},[_c(VCardTitle,{staticClass:"justify-center text-center"},[_vm._v("עדכונים חדשים\n        "),_c('img',{staticClass:"pr-2",staticStyle:{"vertical-align":"middle","height":"28px"},attrs:{"src":require('../../assets/party-popper.png')}})]),_c(VCardText,[_c('p',[_vm._v("\n          לתיאום הדרכה על סמייל, צרי קשר בוואטסטפ"),_c('br'),_c('a',{attrs:{"href":"https://wa.me/972552724499","target":"_blank"}},[_vm._v("055-272-4499")]),_c('br'),_c('br'),_vm._v("\n          כדי לשפר את תהליך הכניסה, ניתן לעדכן את הסיסמה בקלות דרך \n          "),_c('RouterLink',{attrs:{"to":{ name: 'profile' }}},[_vm._v("עמוד הפרופיל שלך")]),_vm._v(".\n        ")],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }