import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"manage-shifts-and-recurrings",attrs:{"fluid":""}},[_c('SettingsHeader',{attrs:{"title":_vm.$t('shifts'),"icon":'mdi-cog'}}),_c(VTabs,{staticClass:"shifts-nav",attrs:{"color":"cyan darken-4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(_vm._s(_vm.$t('shifts')))]),_c(VTab,[_vm._v(_vm._s(_vm.$t('recurring_shifts')))])],1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{staticClass:"item"},[_c('ManageShifts')],1),_c(VTabItem,[_c('ManageRecurringShifts')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }