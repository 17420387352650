














import { Component, Prop } from "vue-property-decorator";
import { Note } from "@/modules/patient/types/note.type";
import { date } from "@/filters/date.filter";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";

@Component({
  filters: {
    date
  }
})
export default class PatientNote extends mixins(SmileMixin) {
  @Prop({ required: true }) public note: Note;
  @Prop({ required: true }) public patientId: number;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public onEditNoteClicked(note: Note) {
    this.$emit("editNote", note);
  }

}
