import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { PriceListApiRouteParameters, PriceListApiRoutes } from "@/modules/treatment/constants/api-routes";
import { PriceList } from "@/modules/treatment/types/treatment.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class PriceListApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<PriceList[]> {
    try {
      const response: ApiResponse<PriceList[]> = await this.httpClient.get(PriceListApiRoutes.getAll);

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(id: number, name: string, order: number): Promise<PriceList> {
    try {
      const response: ApiResponse<PriceList> = await this.httpClient.put(
        PriceListApiRoutes.update.replace(PriceListApiRouteParameters.id, id.toString()),
        {
          name,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(name: string, order: number): Promise<PriceList> {
    try {
      const response: ApiResponse<PriceList> = await this.httpClient.post(
        PriceListApiRoutes.create,
        {
          name,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PriceListApiRoutes.delete.replace(PriceListApiRouteParameters.id, id.toString())
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PriceListApi();
