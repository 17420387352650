<template>
  <VDialog @input="closeDialog()" :value="true" max-width="360">
    <VCard outlined raised elevation="4">
      <VCardTitle class="justify-space-between">
        {{ this.editAgent ? $t('edit_title') : $t('add_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <VForm accept-charset="UTF-8" @submit.stop.prevent="submitForm()">
          <smile-error-message :errors="errors"></smile-error-message>
          <VContainer>
            <VRow>
              <VCol cols="12">
                <VTextField v-model="name" :label="$t('name')" color="cyan darken-1" type="text" />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VTextField v-model="folderName" :label="$t('folder_name')" color="cyan darken-1" type="text" />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <pre style="direction: ltr;" v-if="editAgent">{{ editAgent.attributes }}</pre>
              </VCol>
              <VCol>
                <VBtn block color="cyan" :loading="loading" type="submit">
                  {{ $t('submit') }}
                </VBtn>
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import AgentApi from "../../../../serivce/api/imaging-agent-api";

export default {
  props: ["editAgent"],
  data() {
    return {
      errors: false,
      name: this.editAgent ? this.editAgent.name : "",
      folderName: this.editAgent ? this.editAgent.attributes.folder_name : "",
      loading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.errors = false;

      const payload = {
        name: this.name,
        attributes: {
          folder_name: this.folderName
        }
      };

      const attempt = this.editAgent ? AgentApi.update(this.editAgent.id, payload) : AgentApi.create(payload);
      attempt
        .then(response => {
          this.loading = false;

          if (this.editAgent && this.editAgent.id === this.$store.getters["auth/agentId"]) {
            this.$store.commit("auth/setAgent", response.data.results);
          }

          this.$toastr.s(this.$t(this.editAgent ? "agent_updated" : "agent_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
    }
  }
};
</script>

<i18n>
{
  "he": {
    "name": "שם קישורית דימות",
    "folder_name": "שם תיקייה",
    "add_title": "הוספת קישורית דימות",
    "edit_title": "עריכת קישורית דימות",
    "submit": "שמירה",
    "agent_updated": "קישורית עודכנה בהצלחה",
    "agent_created": "קישורית נוצרה בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "name": "Imaging Associate Name",
    "folder_name": "Folder Name",
    "add_title": "Add Imaging Associate",
    "edit_title": "Edit Imaging Associate",
    "submit": "Submit",
    "agent_updated": "Associate updated successfully",
    "agent_created": "Associate created successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
