export enum TaskApiRoutes {
  getAll = "/task",
  create = "/task",
  update = "/task/{id}",
  delete = "/task/{id}",
  markDone = "/task/markDone",
}

export enum TaskRecurringApiRoutes {
  create = "/task-recurring",
  update = "/task-recurring/{id}",
  delete = "/task-recurring/{id}",
}

export enum TaskApiRouteParameters {
  id = "{id}",
}

export enum TaskRecurringApiRouteParameters {
  id = "{id}",
}
