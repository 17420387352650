




















import { Component, Vue } from "vue-property-decorator";
import ManageShifts from "../components/shift/ManageShifts.vue";
import ManageRecurringShifts from "../components/shift/ManageRecurringShifts.vue";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ManageShifts,
    ManageRecurringShifts,
    SettingsHeader
  }
})
export default class ManageShiftsAndRecurringsPage extends Vue {
  public tab = 0;
}
