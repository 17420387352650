<template>
  <VDialog @input="closeDialog()" :value="true" max-width="660">
    <VCard outlined raised elevation="4" class="sentence-form">
      <VCardTitle class="justify-space-between">
        {{ $t(editTemplateLetter ? 'edit_title' : 'add_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText>
        <smile-error-message :errors="errors"></smile-error-message>
        <VContainer>
          <VRow>
            <VCol cols="12">
              <VSelect color="cyan" item-color="cyan darken-1" v-model="quickActionCategoryId"
                :items="quickActionCategories" :label="$t('quickActionCategoryId')" required hide-details outlined
                dense />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="name" :label="$t('name')" color="cyan darken-1" type="text" outlined
                dense />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="to" :label="$t('to')" color="cyan darken-1" type="text" outlined
                dense />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="subject" :label="$t('subject')" color="cyan darken-1" type="text"
                outlined dense />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextField hide-details v-model="order" :label="$t('order')" color="cyan darken-1" type="text" outlined
                dense />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VTextarea v-model="content" auto-grow rows="2" :label="$t('content')" color="cyan darken-1" outlined
                hide-details />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <VCheckbox hide-details v-model="displayLineBellow" color="cyan" :label="$t('displayLineBellow')" />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VBtn color="red" small text type="button" v-if="editTemplateLetter" @click="deleteButtonClicked()"
          :loading="deleteLoading">
          {{ $t('delete_btn') }}
        </VBtn>
        <VSpacer />
        <VBtn color="cyan" :loading="loading" @click="submitForm()">
          {{ $t('submit') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import TemplateLetterApi from "../../api/template-letter-api";
import QuickActionCategoryApi from "../../api/quick-action-category-api";

export default {
  props: ["editTemplateLetter", "selectedQuickActionCategoryId"],
  data() {
    return {
      errors: false,
      quickActionCategories: [],
      name: this.editTemplateLetter ? this.editTemplateLetter.name : "",
      subject: this.editTemplateLetter ? this.editTemplateLetter.subject : "",
      to: this.editTemplateLetter ? this.editTemplateLetter.to : "",
      content: this.editTemplateLetter ? this.editTemplateLetter.content : "",
      quickActionCategoryId: this.editTemplateLetter ? this.editTemplateLetter.quick_action_category_id : this.selectedQuickActionCategoryId,
      order: this.editTemplateLetter ? this.editTemplateLetter.order : "",
      displayLineBellow: this.editTemplateLetter ? this.editTemplateLetter.display_line_bellow : false,
      loading: false,
      deleteLoading: false,
    };
  },
  mounted() {
    QuickActionCategoryApi.getAll()
      .then(results => {
        this.quickActionCategories = results
          .map(category => ({
            value: category.id,
            text: category.name
          }));
      });
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitForm() {
      if (this.loading)
        return;
      this.loading = true;
      this.errors = false;
      const attempt = this.editTemplateLetter
        ? TemplateLetterApi.update(this.editTemplateLetter.id,
          this.name,
          this.subject,
          this.to,
          this.content,
          this.quickActionCategoryId,
          this.order,
          this.displayLineBellow
        )
        : TemplateLetterApi.create(
          this.name,
          this.subject,
          this.to,
          this.content,
          this.quickActionCategoryId,
          this.order,
          this.displayLineBellow);
      attempt
        .then(() => {
          this.loading = false;
          this.$toastr.s(this.editTemplateLetter ? this.$t("template_letter_updated") : this.$t("template_letter_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }
          else {
            this.errors = this.$t("temporary_error");
          }
        });
    },
    async deleteButtonClicked() {
      const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });
      if (!res) {
        return;
      }

      this.deleteLoading = true;
      try {
        await TemplateLetterApi.delete(this.editTemplateLetter.id);
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("reload");
        this.closeDialog();
      } catch (err) {
        this.deleteLoading = false;
        this.$toastr.e(this.$t("delete_error"));
        this.$emit("reload");
      }
    }
  },
};
</script>

<style lang="scss">
.sentence-form {
  .v-text-field__details {
    display: none;
  }
}
</style>
<i18n>
{
  "he": {
    "delete_btn": "מחיקה",
    "delete_confirm_message": "למחוק מכתב?",
    "delete_cancel_btn": "ביטול",
    "delete_ok_btn": "מחיקה",
    "deleted_toastr": "מכתב נמחק בהצלחה",
    "delete_error": "שגיאה במחיקה. יש לנסות שוב",
    "name": "שם המכתב",
    "subject": "נושא המכתב",
    "to": "לכבוד",
    "content": "תוכן המכתב",
    "content": "תוכן המכתב",
    "content": "תוכן המכתב",
    "quickActionCategoryId": "קטגוריה",
    "order": "סדר",
    "add_title": "הוספת מכתב",
    "edit_title": "עריכת מכתב",
    "submit": "שמירה",
    "displayLineBellow": "הצגת קו תחתון",
    "template_letter_created": "מכתב נוצר בהצלחה",
    "template_letter_updated": "מכתב עודכן בהצלחה",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "delete_btn": "Delete",
    "delete_confirm_message": "Delete letter?",
    "delete_cancel_btn": "Cancel",
    "delete_ok_btn": "Delete",
    "deleted_toastr": "Job deleted successfully",
    "delete_error": "Error deleting data, try again",
    "content": "Contents",
    "quickActionCategoryId": "Category",
    "order": "Order",
    "add_title": "Add letter",
    "edit_title": "Edit letter",
    "submit": "Submit",
    "displayLineBellow": "Display underline",
    "template_letter_created": "letter created successfully",
    "template_letter_updated": "letter updated successfully",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
