import { RouteConfig } from "vue-router";
import {
  WAITING_LIST_ROUTE_NAME,
  WAITING_LIST_ROUTE_PATH,
  CALENDAR_ROUTE_NAME,
  CALENDAR_ROUTE_PATH,
} from "../constants/route-config";
import CalendarPage from "../pages/CalendarPage.vue";
import WaitingListPage from "@/modules/appointment/pages/WaitingListPage.vue";

const appointmentRoutes: RouteConfig[] = [
  {
    name: CALENDAR_ROUTE_NAME,
    path: CALENDAR_ROUTE_PATH,
    component: CalendarPage,
  },
  {
    name: WAITING_LIST_ROUTE_NAME,
    path: WAITING_LIST_ROUTE_PATH,
    component: WaitingListPage,
  },
];

export default appointmentRoutes;
