export enum PinkasApiRoutes {
  getAll = "/pinkas",
  create = "/pinkas",
  update = "/pinkas/{id}",
  delete = "/pinkas/{id}",
}

export enum PinkasApiRouteParameters {
  id = "{id}",
}
