import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"manage-agents",attrs:{"fluid":""}},[_c('SettingsHeader',{attrs:{"title":_vm.$t('title'),"icon":'mdi-cog'}}),_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,{staticClass:"pr-0 pl-0 pb-0",attrs:{"cols":"12"}},[_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){return _vm.openAgentFormDialog(null)}}},[_vm._v("\n          "+_vm._s(_vm.$t('add_agent'))+" "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VCol,{staticClass:"pr-0 pl-0",attrs:{"cols":"12"}},[_c('smile-error-message',{attrs:{"errors":_vm.deleteErrors}}),_c(VDataTable,{attrs:{"mobile-breakpoint":1,"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.agents,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.loadErrors)?{key:"no-data",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.reload()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c('ErrorLogo')]},proxy:true}:null,{key:"item.last_reported_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.last_reported_at)+"\n          ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"hover",attrs:{"icon":""},on:{"click":function($event){return _vm.openAgentFormDialog(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{staticClass:"hover",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAgent(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1),(_vm.showAgentForm)?_c('AgentForm',{attrs:{"edit-agent":_vm.editAgent},on:{"close":function($event){return _vm.closeAgentForm()},"reload":function($event){return _vm.reload()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }