




















































import { Component, Prop, Vue } from "vue-property-decorator";
import ContactApi from "@/modules/contacts/api/contact.api";
import { AxiosError } from "axios";
import get from "lodash/get";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { Contact } from "@/modules/contacts/types/contact.type";
import { Getter } from "vuex-class";
import { ContactBookmark } from "../types/contact-bookmark.type";

@Component({
  components: {
    ErrorMessage
  }
})
export default class ContactForm extends Vue {
  @Prop({ required: true }) public contact: Contact;
  @Prop({ required: false }) public bookmarkId: number;
  @Getter("auth/contacts") contactBookmarks: ContactBookmark[];
  public selectedBookmarkId: number = null;
  public errors: any = false;
  public saveLoading = false;
  public deleteLoading = false;
  public name = "";
  public phone = "";
  public phone2 = "";
  public email = "";
  public email2 = "";
  public notes = "";
  public address = "";

  get bookmarksSelectOptions() {
    return this.contactBookmarks
      .map(bookmark => ({
        "text": bookmark.name,
        "value": bookmark.id
      }));
  }

  get editMode(): boolean {
    return Boolean(this.contact);
  }

  public mounted() {
    if (this.contact) {
      this.name = this.contact.name;
      this.phone = this.contact.phone;
      this.phone2 = this.contact.phone2;
      this.email = this.contact.email;
      this.email2 = this.contact.email2;
      this.address = this.contact.address;
      this.notes = this.contact.notes;
      this.selectedBookmarkId = this.contact.bookmark_id;
    } else {
      this.selectedBookmarkId = this.bookmarkId;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async deleteClicked() {

    this.errors = false;

    const res = await this.$confirm(
      this.$t("delete_confirm_message", { "name": this.contact.name }).toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.deleteLoading = true;
    ContactApi
      .delete(this.contact.id)
      .then(() => {
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("contactDeleted", this.contact.id);
        this.closeDialog();
      })
      .catch((error: AxiosError) => {
        const errorMessage = get(error, "response.data.errors", null);
        this.errors = errorMessage || this.$t("delete_error");
      })
      .finally(() => this.deleteLoading = false);
  }

  public save() {
    this.errors = false;
    this.saveLoading = true;

    const payload = {
      bookmark_id: this.selectedBookmarkId,
      name: this.name,
      phone: this.phone,
      phone2: this.phone2,
      email: this.email,
      email2: this.email2,
      address: this.address,
      notes: this.notes,
    };

    const savingAttempt = this.editMode
      ? ContactApi
        .update(this.contact.id, payload)
      : ContactApi
        .create(payload);

    savingAttempt
      .then((contact: Contact) => {
        this.$emit(this.editMode ? "contactUpdated" : "contactCreated", contact);
        this.saveLoading = false;
        this.closeDialog();
      })
      .catch(err => {
        this.saveLoading = false;
        this.errors = err.response.data.errors;
      });
  }

}
