<template>
  <v-alert type="error" text dense v-if="errors">
    <div v-if="typeof errors !== 'string'">
      <ul v-for="(fieldErrors, fieldName) in errors" :key="fieldName" style="list-style-type: none">
        <li v-for="(errorMsg, index) in fieldErrors" :key="index">{{ errorMsg }}</li>
      </ul>
    </div>
    <ul  v-if="typeof errors === 'string'" style="list-style-type: none">
      <li>{{ errors }}</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: [ "errors" ]
};
</script>
