















































































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Patient } from "@/modules/patient/types/patient.type";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import PatientTreatmentApi from "@/modules/patient/api/patient-treatment.api";
import { Treatment } from "@/modules/treatment/types/treatment.type";

@Component({
  components: {
    SearchPatient,
  }
})
export default class RebalancePaymentDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/userId") currentUserId: number;

  public changeReason = "טעות הקלדה";
  public selectedPatient: Patient = null;
  public changeAmount = null;
  public showErrors = false;
  public isLoading = false;
  public rebalanceType = "move_to_another_patient";

  public closeDialog() {
    this.$emit("close");
  }

  get patientBalance() {
    let balance = 0;

    this.patient.treatments.forEach(treatment => {
      balance -= treatment.price - treatment.discount;
    });
    this.patient.payments.forEach(payment => {
      if (!payment.deleted_at) {
        balance += parseFloat(payment.amount.toString());
      }
    });

    return balance;
  }

  public async onSubmitClicked() {
    if (this.rebalanceType === "change_patient_balance" && this.changeReason.trim().length <= 1) {
      this.showErrors = true;
      return;
    }
    if (!parseFloat(this.changeAmount ? this.changeAmount.toString() : "0")) {
      this.showErrors = true;
      return;
    }
    if (this.rebalanceType === "move_to_another_patient" && !this.selectedPatient) {
      this.showErrors = true;
      return;
    }

    try {
      this.isLoading = true;
      if (this.rebalanceType === "move_to_another_patient") {
        const notesFrom = "העברת יתרה ל" + this.selectedPatient.first_name + " " + this.selectedPatient.last_name;
        const notesTo = "העברת יתרה מ" + this.patient.first_name + " " + this.patient.last_name;
        await PatientTreatmentApi.create(this.patient.id, this.currentUserId, null, this.treatmentPaymentId, { transfer_patient_id: this.selectedPatient.id }, this.changeAmount, 0, notesFrom);
        await PatientTreatmentApi.create(this.selectedPatient.id, this.currentUserId, null, this.treatmentPaymentId, { transfer_patient_id: this.patient.id }, this.changeAmount * -1, 0, notesTo);
      } else {
        const notes = this.$t("change_reason_label") + ": " + this.changeReason;
        await PatientTreatmentApi.create(this.patient.id, this.currentUserId, null, this.treatmentPaymentId, {}, this.changeAmount * -1, 0, notes);
      }
      this.$emit("paymentRebalanced");
      this.$toastr.s(this.$t("toastr_rebalanced"));
      this.closeDialog();
      this.isLoading = false;
    } catch (err) {
      this.$emit("paymentRebalanced");
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  get treatmentPaymentId() {
    let paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    if (!paymentTreatment) {
      paymentTreatment = this.$store.getters["auth/treatments"]
        .find((treatment: Treatment) => (treatment.name === "תשלום"));
    }

    return paymentTreatment.id;
  }


  get rebalanceOptions() {
    return [
      {
        "text": this.$t("move_to_another_patient"),
        "value": "move_to_another_patient",
      },
      {
        "text": this.$t("change_patient_balance"),
        "value": "change_patient_balance",
      },
    ];
  }

  public async onSelectedPatient(selectedPatient: Patient) {
    this.selectedPatient = selectedPatient;
  }

  public mounted() {
    document.addEventListener("wheel", function () {
      if ((document.activeElement as any).type === "number") {
        (document.activeElement as any).blur();
      }
    });

  }
}
