import {RouteConfig} from "vue-router";
import PatientsPage from "../pages/PatientsPage.vue";
import PatientPage from "../pages/PatientPage.vue";
import {
    PATIENT_ROUTE_NAME,
    PATIENT_ROUTE_PATH,
    PATIENTS_ROUTE_NAME,
    PATIENTS_ROUTE_PATH
} from "@/modules/patient/constants/route-config";

const patientRoutes: RouteConfig[] = [
    {
        name: PATIENT_ROUTE_NAME,
        path: PATIENT_ROUTE_PATH,
        component: PatientPage,
    },
    {
        name: PATIENTS_ROUTE_NAME,
        path: PATIENTS_ROUTE_PATH,
        component: PatientsPage,
    }
];

export default patientRoutes;
