import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"notifications-center-page"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VSheet,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h2',[_c(VIcon,[_vm._v("mdi-calendar-check-outline")]),_vm._v("\n            "+_vm._s(_vm.$t('title'))+"\n            "),(_vm.showPrintButton)?_c(VBtn,{staticClass:"print-button",attrs:{"icon":""},on:{"click":function($event){return _vm.onPrintClicked()}}},[_c(VIcon,[_vm._v("mdi-printer")])],1):_vm._e()],1)]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('NotificationsCenter')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }