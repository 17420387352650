
























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import PatientApi from "@/modules/patient/api/patient.api";
import { Patient } from "@/modules/patient/types/patient.type";
import { HeshbonitMas, HeshbonitMasKabala, HeshbonitZikoi, Kabala } from "../../types/payment.type";

@Component({
})
export default class SharePatientPaymentEmailDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public document: HeshbonitMas | HeshbonitMasKabala | HeshbonitZikoi | Kabala;
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  public emailTo = "";
  public isLoading = false;
  public isInvalidEmail = false;

  public mounted() {
    if (this.patient.email) {
      this.emailTo = this.patient.email;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onShareClicked() {
    if (!this.isValidEmail(this.emailTo)) {
      this.isInvalidEmail = true;
      return;
    } else {
      this.isInvalidEmail = false;
    }

    try {
      this.isLoading = true;
      await PatientApi.sendEmailPaymentDocument(this.emailTo, this.patient.id, this.document.document_type, this.document.id);
      this.$toastr.s(this.$t("sent_success_toastr"));
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

}
