import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"350"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"patient-note-popup",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"pb-0"},[_vm._v("\n      "+_vm._s(_vm.$t('notes'))+"\n      "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._l((_vm.popupNotes),function(note){return _c(VCard,{key:note.id,staticClass:"patient-note",staticStyle:{"position":"relative"},attrs:{"color":note.pinned ? 'orange lighten-4' : 'cyan lighten-4'}},[_c(VCardText,{staticStyle:{"padding-bottom":"0"}},[_c('p',{staticStyle:{"margin-bottom":"0","white-space":"break-spaces"}},[_vm._v(_vm._s(note.text))])]),_c(VCardActions,[(note.user_id)?_c('small',[_vm._v(_vm._s(_vm.userName(note.user_id)))]):_vm._e(),_c(VSpacer),_c('small',[_vm._v(_vm._s(_vm._f("date")(note.updated_at)))])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }