import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  PatientFolderApiRouteParameters,
  PatientFolderApiRoutes,
} from "@/modules/patient-file/constants/api-routes";
import { PatientFolder } from "@/modules/patient-file/types/patient-folder.type";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { AppConfig } from "@/core/config/config";

class PatientFolderApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async create(patientId: number, name: string, parentFolderId: number) {
    try {
      const response: ApiResponse<PatientFolder> = await this.httpClient.post(
        PatientFolderApiRoutes.create,
        {
          patient_id: patientId,
          name,
          parent_folder_id: parentFolderId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  getExportUrl(patientId: number, folderId: number) {
    return `${AppConfig.apiBaseUrl}${
      PatientFolderApiRoutes.export
    }?patientId=${patientId}&folderId=${
      folderId ? folderId : 0
    }&_token=${localStorage.getItem("token")}`;
  }

  async update(id: number, name: string, parentFolderId: number) {
    try {
      const response: ApiResponse<PatientFolder> = await this.httpClient.put(
        PatientFolderApiRoutes.update.replace(
          PatientFolderApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          parent_folder_id: parentFolderId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number) {
    try {
      const response: ApiResponse<boolean> = await this.httpClient.delete(
        PatientFolderApiRoutes.delete.replace(
          PatientFolderApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientFolderApi();
