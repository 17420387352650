export enum TagApiRoutes {
  getAll = "/tag",
  create = "/tag",
  update = "/tag/{id}",
  delete = "/tag/{id}",
}

export enum TagApiRouteParameters {
  id = "{id}",
}
export enum PatientStatusApiRoutes {
  getAll = "/patient-status",
  create = "/patient-status",
  update = "/patient-status/{id}",
  delete = "/patient-status/{id}",
}

export enum PatientStatusApiRouteParameters {
  id = "{id}",
}

export enum DrugApiRoutes {
  getAll = "/drug",
  create = "/drug",
  update = "/drug/{id}",
  delete = "/drug/{id}",
}

export enum DrugApiRouteParameters {
  id = "{id}",
}

export enum QuickActionCategoryApiRoutes {
  getAll = "/quick-action-category",
  create = "/quick-action-category",
  update = "/quick-action-category/{id}",
  delete = "/quick-action-category/{id}",
}

export enum QuickActionCategoryApiRouteParameters {
  id = "{id}",
}

export enum TemplateLetterApiRoutes {
  getAll = "/template-letter",
  create = "/template-letter",
  update = "/template-letter/{id}",
  delete = "/template-letter/{id}",
}

export enum TemplateLetterApiRouteParameters {
  id = "{id}",
}

