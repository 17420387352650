export enum AppointmentApiRoutes {
  getOne = "/appointment/{id}",
  find = "/appointment",
  findCalendar = "/appointment-calendar",
  create = "/appointment",
  update = "/appointment/{id}",
  delete = "/appointment/{id}",
  updateStatus = "/appointment/update-status",
  findNotificationsCenter = "/appointment/notifications-center",
}

export enum AppointmentApiRouteParameters {
  id = "{id}",
}

export enum WaitingAppointmentApiRoutes {
  find = "/waiting-appointment",
  create = "/waiting-appointment",
  update = "/waiting-appointment/{id}",
  delete = "/waiting-appointment/{id}",
}

export enum WaitingAppointmentApiRouteParameters {
  id = "{id}",
}
