import { render, staticRenderFns } from "./GoogleContactsSyncConnect.vue?vue&type=template&id=1fc7a2f2&"
import script from "./GoogleContactsSyncConnect.vue?vue&type=script&lang=ts&"
export * from "./GoogleContactsSyncConnect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/google-contacts-sync-connect.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fgoogle-contacts-sync%2Fcomponents%2FGoogleContactsSyncConnect.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports