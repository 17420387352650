import Request from "../request";

class AppointmentTypeApi {
  constructor() {
    this.request = new Request();
  }

  get() {
    return this.request.get("/appointment_type");
  }

  delete(typeId) {
    return this.request.delete(`/appointment_type/${typeId}`);
  }

  create(name, durationMinutes, color, order) {
    return this.request.post("/appointment_type", {
      name,
      duration_minutes: durationMinutes,
      color,
      order,
    });
  }

  update(typeId, name, durationMinutes, color, order) {
    return this.request.put(`/appointment_type/${typeId}`, {
      name,
      duration_minutes: durationMinutes,
      color,
      order,
    });
  }
}

export default new AppointmentTypeApi();
