const whatsappGetters = {
    contacts: state => {
        return state.contacts;
    },
    unreadCount: state => {
        return state.contacts.filter(({ isUnread }) => (isUnread)).length;
    },
};

export default whatsappGetters;
