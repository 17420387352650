










































import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import { CalendarViewTypes } from "../../../types/calendar.type";
import { Getter } from "vuex-class";

@Component({
})
export default class CalendarNavigateButtons extends Vue {
  @Prop({ required: true }) focusDate: string;
  @Prop({ required: true }) calendarView: CalendarViewTypes;
  @Getter("auth/isRtl") isRtl: boolean;

  public displayMenuJumpToDate = false;
  public jumpCount: number = null;
  public jumpInterval: "days" | "weeks" | "months" = "weeks";

  public onCalendarSizeOpenClicked() {
    this.$emit("calendarSizeOpen");
  }

  public onTodayClicked() {
    const focusDate = moment().format("YYYY-MM-DD");
    this.$emit("focusDateChanged", focusDate);
    this.$emit("calendarViewChanged", "day");
  }

  public onPrevDateClicked() {
    const focusDate = moment(this.focusDate).subtract(1, this.calendarView).format("YYYY-MM-DD");
    this.$emit("focusDateChanged", focusDate);
  }

  public onNextDateClicked() {
    const focusDate = moment(this.focusDate).add(1, this.calendarView).format("YYYY-MM-DD");
    this.$emit("focusDateChanged", focusDate);
  }
  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onJumpToDateClicked() {
    if (this.jumpCount) {
      const focusDate = moment(this.focusDate).add(this.jumpCount, this.jumpInterval).format("YYYY-MM-DD");
      this.$emit("focusDateChanged", focusDate);
    }
    this.displayMenuJumpToDate = false;
    this.jumpCount = null;
    this.jumpInterval = "weeks";
  }

  get jumpOptions() {
    return [
      {
        text: this.$t("jump_to_date.days"),
        value: "days"
      },
      {
        text: this.$t("jump_to_date.weeks"),
        value: "weeks"
      },
      {
        text: this.$t("jump_to_date.months"),
        value: "months"
      }
    ];
  }

}
