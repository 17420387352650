import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pb-5"},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%","height":"12px","background-image":"linear-gradient( 95.2deg, rgba(173,252,234,1) 26.8%, rgba(192,229,246,1) 64% )"}}),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-2",staticStyle:{"white-space":"normal","font-size":"20px !important","line-height":"20px"}},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_c(VListItemSubtitle,{staticStyle:{"white-space":"normal","-webkit-line-clamp":"unset","margin-top":"10px","height":"60px"}},[_vm._v("\n        "+_vm._s(_vm.subTitle)+"\n      ")])],1)],1),_c(VCardActions,[_c(VSpacer),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }