


















































import SmileMixin from "@/mixins/smile.mixin";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SingleWhatsappChat from "../components/SingleWhatsappChat.vue";
import WhatsappContactsList from "../components/WhatsappContactsList.vue";
import { WhatsappContact } from "../types/whatsapp.type";
import mixins from "vue-class-component";
import WhatsappContactInfo from "../components/WhatsappContactInfo.vue";
import { WHATSAPP_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {
    SingleWhatsappChat,
    WhatsappContactsList,
    WhatsappContactInfo,
  },
})
export default class WhatsappPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/shouldDisplayUnreadWhatsapp") shouldDisplayUnreadWhatsapp: boolean;

  public reloadTimeout: number = null;
  public activeWaContact: WhatsappContact = null;

  public async mounted() {
    if (!this.shouldDisplayUnreadWhatsapp) {
      await this.autoReload(true);
    }
    this.routePhoneChanged();
  }

  public async autoReload(isFirst: boolean) {
    try {
      await this.$store.dispatch("whatsapp/loadContacts");
    } finally {
      if ((isFirst || this.reloadTimeout) && this.$route.name === WHATSAPP_ROUTE_NAME) {
        this.reloadTimeout = setTimeout(async () => {
          if (this.reloadTimeout) {
            await this.autoReload(false);
          }
        }, 2 * 60 * 1000); // 2 mins
      }
    }
  }

  @Watch("$route")
  routePhoneChanged() {
    if (this.$route.name !== WHATSAPP_ROUTE_NAME) {
      return;
    }
    if (!this.$route.params.phone) {
      const contact = this.$store.getters["whatsapp/contacts"][0];
      if (contact && contact.phone && this.$vuetify.breakpoint.smAndUp) {
        this.$router.replace({
          name: WHATSAPP_ROUTE_NAME,
          params: {
            phone: contact.phone
          }

        });
        return;
      }
    }

    const contact = this.$store.getters["whatsapp/contacts"].find(contact => (contact.phone === this.$route.params.phone));
    this.activeWaContact = contact;
  }

  public async reloadContacts() {
    await this.$store.dispatch("whatsapp/loadContacts");
    if (this.$refs.SingleWhatsappChat) {
      await (this.$refs.SingleWhatsappChat as SingleWhatsappChat).fetchChatHistory(true);
    }
  }

  get whatsappPageRouteName() {
    return WHATSAPP_ROUTE_NAME;
  }

  beforeDestroy() {
    if (this.reloadTimeout) {
      this.reloadTimeout = null;
      clearTimeout(this.reloadTimeout);
    }
  }

}
