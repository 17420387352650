































































import moment from "moment";
import EditTimeClockEntry from "./EditTimeClockEntry.vue";
import TimeClockApi from "../../api/time-clock.api";
import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {date, time} from "@/filters/date.filter";
import {TimeClockEntry} from "@/modules/time-clock/types/time-clock.type";
import {DataTableHeader} from "vuetify";

@Component({
  components: {
    EditTimeClockEntry,
  },
  filters: {
    date,
    time,
  }
})
export default class MonthTimeEntries extends Vue {
  @Prop({required: true}) public activeMonth: string;
  @Prop({required: true}) public activeYear: string;
  @Prop({required: true}) public entries: TimeClockEntry[];
  @Prop({required: true}) public loadingEntries: boolean;
  public errors = false;
  public isEditingEntry = false;
  public processedEntry: TimeClockEntry = null;
  public headers: DataTableHeader[] = [
    {width: "17%", text: this.$t("headers.date").toString(), value: "date"},
    {width: "17%", text: this.$t("headers.type").toString(), value: "type_id"},
    {width: "17%", text: this.$t("headers.start_time").toString(), value: "start_time"},
    {width: "17%", text: this.$t("headers.end_time").toString(), value: "end_time"},
    {width: "17%", text: this.$t("headers.duration").toString(), value: "duration"},
    {width: "15%", text: this.$t("headers.actions").toString(), value: "actions"}
  ];
  @Getter("auth/fullName") public fullName: string;

  get activeMonthName() {
    const month = parseInt(this.activeMonth);
    const translatedMonthKey = `months.${(month - 1).toString()}`;

    return this.$t(translatedMonthKey);
  }

  public goNextMonth() {
    const activeDate = moment(`${this.activeMonth}-${this.activeYear}`, "MM-YYYY");
    const nextMonth = parseInt(this.activeMonth) === 12 ?
        activeDate.add(1, "year").startOf("year") :
        activeDate.add(1, "month");

    this.$emit("update:activeYear", nextMonth.format("YYYY"));
    this.$emit("update:activeMonth", nextMonth.format("MM"));

    this.$emit("changedMonth");
  }

  public goPreviousMonth() {
    const activeDate = moment(`${this.activeMonth}-${this.activeYear}`, "MM-YYYY");
    const previousMonth = parseInt(this.activeMonth) === 1 ?
        activeDate.subtract(1, "year").endOf("year") :
        activeDate.subtract(1, "month");

    this.$emit("update:activeYear", previousMonth.format("YYYY"));
    this.$emit("update:activeMonth", previousMonth.format("MM"));

    this.$emit("changedMonth");
  }

  public getDuration(start, end) {
    const duration = moment.duration(moment(end).diff(start));
    let humanDuration = "";

    if (duration.hours()) {
      humanDuration += `${duration.hours()} ${this.$t("duration_hours")}`;

      if (duration.minutes()) {
        humanDuration += ` ${this.$t("duration_concat")} `;
      }
    }

    if (duration.minutes()) {
      humanDuration += `${duration.minutes()} ${this.$t("duration_minutes")} `;
    }

    return humanDuration;
  }

  public deleteEntry(timeEntry: TimeClockEntry) {
    this.$confirm(
        this.$t("delete_confirm_message").toString(), {
          buttonTrueColor: "red",
          buttonTrueText: this.$t("delete_ok_btn").toString(),
          buttonFalseText: this.$t("delete_cancel_btn").toString(),
        })
        .then(confirmed => {
          if (confirmed) {
            this.isEditingEntry = true;

            TimeClockApi.delete(timeEntry.id)
                .then(() => {
                  this.$toastr.s(this.$t("deleted_toastr"));
                  this.$emit("removedEntry", timeEntry.id);
                })
                .catch(() => {
                  this.$toastr.e(this.$t("delete_error"));
                })
                .finally(() => this.isEditingEntry = false);
          }
        });
  }
}
