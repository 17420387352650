import Request from "../request";

class PatientTagApi {

  getRequest () {
    if (this.request) {
      return this.request;
    }

    this.request = new Request();
    return this.request;
  }

  update (patientId, tagIds) {
    return this.getRequest()
        .put(`/patient-tag/${patientId}`,
            {tags: tagIds});
  }

}

export default new PatientTagApi();
