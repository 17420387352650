













































import {Component, Vue} from "vue-property-decorator";
import SmileLogo from "@/components/Logo/SmileLogo.vue";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import AuthApi from "../api/auth.api";

@Component({
  components: {
    SmileLogo,
    ErrorMessage
  }
})
export default class ResetPasswordPage extends Vue {
  public show = false;
  public password = "";
  public loading = false;
  public errors: any = false;

  handleLogin() {
    this.loading = true;
    this.errors = false;

    AuthApi.resetPassword(this.$route.params.token, this.password)
        .then(() => {
          this.loading = false;
          this.$toastr.s(this.$t("success_toastr"));
          this.$router.push({name: "login"});
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
  }
}
