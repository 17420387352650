import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  TemplateLetterApiRouteParameters,
  TemplateLetterApiRoutes,
} from "@/modules/settings/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { TemplateLetter } from "../types/template-letter.type";

class TemplateLetterApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<TemplateLetter[]> {
    try {
      const response: ApiResponse<TemplateLetter[]> = await this.httpClient.get(
        TemplateLetterApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    subject: string,
    to: string,
    content: string,
    quick_action_category_id: number,
    order: number,
    display_line_bellow: boolean
  ): Promise<TemplateLetter> {
    try {
      const response: ApiResponse<TemplateLetter> = await this.httpClient.put(
        TemplateLetterApiRoutes.update.replace(
          TemplateLetterApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          subject,
          to,
          content,
          quick_action_category_id,
          order,
          display_line_bellow,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    name: string,
    subject: string,
    to: string,
    content: string,
    quick_action_category_id: number,
    order: number,
    display_line_bellow: boolean
  ): Promise<TemplateLetter> {
    try {
      const response: ApiResponse<TemplateLetter> = await this.httpClient.post(
        TemplateLetterApiRoutes.create,
        {
          name,
          subject,
          to,
          content,
          quick_action_category_id,
          order,
          display_line_bellow,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        TemplateLetterApiRoutes.delete.replace(
          TemplateLetterApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new TemplateLetterApi();
