




































import {Component, Vue} from "vue-property-decorator";
import moment from "moment";
import TimeClockApi from "../api/time-clock.api";
import {TimeClockEntry} from "../types/time-clock.type";
import AddTimeClockEntry from "../components/time-entry/AddTimeClockEntry.vue";
import MonthTimeEntries from "../components/time-entry/MonthTimeEntries.vue";
import {Getter} from "vuex-class";

@Component({
  components: {
    AddTimeClockEntry,
    MonthTimeEntries,
  },
})
export default class TimeClockPage extends Vue {
  public activeMonth: string = moment().format("MM");
  public activeYear: string = moment().format("YYYY");
  public entries: TimeClockEntry[] = [];
  public isFetchingEntries = false;
  @Getter("auth/isAdmin") isUserAdmin;

  created() {
    this.loadEntries();
  }

  public loadEntries() {
    this.isFetchingEntries = true;
    const yearMonth = `${this.activeYear}-${this.activeMonth}`;

    TimeClockApi
        .getAll({
          year_month: yearMonth
        })
        .then(data => {
          if (yearMonth === data.year_month) {
            this.entries = data.entries;
          }
        })
        .finally(() => this.isFetchingEntries = false);
  }

  public addEntry() {
    this.loadEntries();
  }

  public updateEntry(clockEntry: TimeClockEntry) {
    const updatedEntryId = this.entries.findIndex((entry) => entry.id === clockEntry.id);

    if (updatedEntryId !== -1) {
      this.$set(this.entries, updatedEntryId, clockEntry);
    }
  }

  public removeEntry(entryId: number) {
    const toBeRemovedEntryIndex = this.entries.findIndex((entry) => entryId === entry.id);

    if (toBeRemovedEntryIndex !== -1) {
      this.entries.splice(toBeRemovedEntryIndex, 1);
    }
  }
}
