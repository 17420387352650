













































import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { WhatsappReceivedMessage, WhatsappSentMessage } from "../types/whatsapp.type";

@Component({
})
export default class WhatsappDisplatImageDocumentDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public message: WhatsappSentMessage | WhatsappReceivedMessage;

  public closeDialog() {
    this.$emit("close");
  }

  public onDownloadFileClicked() {
    const url = this.isImage ? this.message.attributes.extra.image_download : this.message.attributes.extra.document_download;
    this.downloadFile(url, "whatsapp");
  }

  get isDocument() {
    return this.message && this.message.attributes && this.message.attributes.extra && this.message.attributes.extra.document;
  }

  get isPdf() {
    const fileUrl = new URL(this.message.attributes.extra.document);
    const splittedPathname = fileUrl.pathname.split(".");
    return splittedPathname[splittedPathname.length - 1] === "pdf";
  }

  get isImage() {
    return this.message && this.message.attributes && this.message.attributes.extra && this.message.attributes.extra.image;
  }
}
