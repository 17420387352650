import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance, CancelTokenSource } from "axios";
import { PaymentTranzilaApiRoutes } from "@/modules/payment-tranzila/constants/api-routes";
import {
  PaymentTranzilaEmvResponse,
  PaymentTranzilaReportResults,
} from "../types/payment-tranzila.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class PaymentTranzilaApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getTransactions(
    pinkasId: number,
    fromDate: string,
    toDate: string,
    activeRequest: CancelTokenSource
  ): Promise<PaymentTranzilaReportResults> {
    try {
      const { data } = await this.httpClient.get(
        PaymentTranzilaApiRoutes.getTransactions,
        {
          params: {
            pinkasId,
            fromDate,
            toDate,
          },
          cancelToken: activeRequest.token,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async cancelTransaction(
    pinkasId: number,
    transactionIndex: string,
    authorizationNumber: string
  ): Promise<PaymentTranzilaReportResults> {
    try {
      const { data } = await this.httpClient.post(
        PaymentTranzilaApiRoutes.cancelTransaction,
        {
          pinkasId,
          transactionIndex,
          authorizationNumber,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }

  async startEmv(
    pinkasId: number,
    sum: number,
    credType: number,
    npay: number,
    fpay: number,
    spay: number
  ) {
    try {
      const {
        data,
      }: ApiResponse<PaymentTranzilaEmvResponse> = await this.httpClient.post(
        PaymentTranzilaApiRoutes.startEmv,
        {
          pinkasId,
          sum,
          credType,
          npay,
          fpay,
          spay,
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PaymentTranzilaApi();
