






















































import SmileMixin from "@/mixins/smile.mixin";
import moment from "moment";
import mixins from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import EditAppointmentFormDialog from "../components/appointment/EditAppointmentFormDialog.vue";
import NewAppointmentFormDialog from "../components/appointment/NewAppointmentFormDialog.vue";
import AppointmentApi from "@/modules/appointment/api/appointment.api";
import SmileCalendar from "../components/calendar-page/SmileCalendar.vue";
import { Getter } from "vuex-class";
import { CalendarViewTypes, SmileCalendarEvent, VuetifyCalendarLocale } from "../types/calendar.type";
import CalendarNavigateButtons from "../components/calendar-page/calendar-header/CalendarNavigateButtons.vue";
import CalendarViewSelect from "../components/calendar-page/calendar-header/CalendarViewSelect.vue";
import CalendarTitle from "../components/calendar-page/calendar-header/CalendarTitle.vue";
import { Appointment } from "../types/appointment.type";
import axios, { CancelTokenSource } from "axios";
import CalendarSide from "../components/calendar-page/calendar-side/CalendarSide.vue";
import { User } from "@/modules/users/types/user.type";
import { Patient } from "@/modules/patient/types/patient.type";
import PatientApi from "@/modules/patient/api/patient.api";
import CalendarPatientCard from "../components/calendar-page/calendar/CalendarPatientCard.vue";
import CalendarAppointmentCard from "../components/calendar-page/calendar/CalendarAppointmentCard.vue";
import { Shift } from "@/modules/shift/types/shift.type";


@Component({
  components: {
    SmileCalendar,
    NewAppointmentFormDialog,
    EditAppointmentFormDialog,
    CalendarNavigateButtons,
    CalendarViewSelect,
    CalendarTitle,
    CalendarSide,
    CalendarPatientCard,
    CalendarAppointmentCard,
  }
})
export default class CalendarPage extends mixins(SmileMixin) {
  @Getter("auth/isTherapist") isTherapist: boolean;
  @Getter("auth/userId") currentUserId: number;
  @Getter("auth/assignedCalendarUserId") currentAssignedCalendarUserId: number;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/therapistUsers") therapistUsers: User[];

  public editAppointment: Appointment = null;
  private activeRequest: CancelTokenSource = null;
  private lastLoadedAt: string = null;
  public calendarSideWidth = 225;
  public displayEditAppointmentDialog = false;
  public displayNewAppointmentDialog = false;
  public calendarView: CalendarViewTypes = "week";
  public focusDate = moment().utc().format("YYYY-MM-DD");
  public calendarStartDate: string = null;
  public calendarEndDate: string = null;
  public isLoading = true;
  public defaultStartDate: string = null;
  public calendarSizeOpen = false;
  public today = moment().format("YYYY-MM-DD");
  public isDisplayCanceled = false;
  public focusPatientId: number = null;
  public focusPatient: Patient = null;
  public moveAppointmentId: number = null;
  public copyAppointmentId: number = null;
  public moveAppointment: Appointment = null;
  public copyAppointment: Appointment = null;
  public displayUserIds = [];
  public appointments: Appointment[] = [];
  public shifts: Shift[] = [];
  public defaultUserId: number = null;

  public beforeMount() {
    this.applyFromQueryStringFilters();

    if (this.isMobile) {
      this.calendarView = "day";
    }
  }

  get filteredAppointments() {
    return this.appointments.filter(({ user_id }) => (this.displayUserIds.includes(user_id)));
  }

  public async reload() {
    this.updateQueryStringFilters();

    this.isLoading = true;
    this.appointments = [];
    this.shifts = [];
    const displayStatusIds = [1, 2, 3, 4, 5, 6, 7, 8, 9]; // all

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      if (this.displayUserIds.length) {
        const calendarResponse = await AppointmentApi.find(this.calendarStartDate, this.calendarEndDate, displayStatusIds, this.activeRequest);
        this.appointments = calendarResponse.appointments;
        this.shifts = calendarResponse.shifts;
        this.lastLoadedAt = moment().format("YYYY-MM-DD HH:mm:ss");
        this.isLoading = false;
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.i(this.$t("calendar_loading_error"));
    }
  }

  @Watch("$route")
  watchRoute() {
    this.applyFromQueryStringFilters();
  }

  public onAppointmentDeleted() {
    this.moveAppointmentId = null;
    this.copyAppointmentId = null;
    this.moveAppointment = null;
    this.reload();
  }

  public onAppointmentUpdated(appointment: Appointment) {
    this.moveAppointmentId = null;
    this.copyAppointmentId = null;
    this.moveAppointment = null;
    const foundAppointmentIndex = this.appointments.findIndex(({ id }) => (id === appointment.id));
    if (foundAppointmentIndex || foundAppointmentIndex === 0) {
      this.appointments[foundAppointmentIndex].status_id = appointment.status_id;
      this.appointments[foundAppointmentIndex].type_id = appointment.type_id;
      this.appointments[foundAppointmentIndex].user_id = appointment.user_id;
      this.appointments[foundAppointmentIndex].patient_id = appointment.patient_id;
      this.appointments[foundAppointmentIndex].start_date = appointment.start_date;
      this.appointments[foundAppointmentIndex].end_date = appointment.end_date;
      this.appointments[foundAppointmentIndex].notes = appointment.notes;
      this.appointments[foundAppointmentIndex].reminder_notes = appointment.reminder_notes;
      this.appointments[foundAppointmentIndex].changes_log = appointment.changes_log;
    }
  }

  public onAppointmentCreated() {
    this.reload();
  }

  public onCalendarEventClicked(event: SmileCalendarEvent) {
    this.editAppointment = event.appointment;
    this.displayEditAppointmentDialog = true;
  }

  private fixCalendarHeaderWidth() {
    const wrapperEl = (this.$refs.smileCalendar as any).$el;
    if (wrapperEl) {

      wrapperEl.querySelector(".v-calendar-daily__head").setAttribute("style", `width:${wrapperEl.querySelector(".v-calendar-daily__body").clientWidth}px`);
      document.querySelector(".calendar-buttons").setAttribute("style", `width:${wrapperEl.querySelector(".v-calendar-daily__body").clientWidth + 1}px`);
    }
  }

  created() {
    window.addEventListener("resize", this.fixCalendarHeaderWidth);
  }

  updated() {
    this.fixCalendarHeaderWidth();
  }

  @Watch("focusPatientId")
  public async onFocusPatientIdChanged() {
    if (!this.focusPatientId) {
      return;
    }

    try {
      this.focusPatient = await PatientApi.get(this.focusPatientId);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  @Watch("copyAppointmentId")
  public async onCopyAppointmentIdChanged() {
    if (!this.copyAppointmentId) {
      return;
    }

    try {
      this.copyAppointment = await AppointmentApi.getOne(this.copyAppointmentId);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  @Watch("moveAppointmentId")
  public async onMoveAppointmentIdChanged() {
    if (!this.moveAppointmentId) {
      return;
    }

    try {
      this.moveAppointment = await AppointmentApi.getOne(this.moveAppointmentId);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public onRemoveFocusPatientClicked() {
    this.focusPatientId = null;
    this.focusPatient = null;
    this.updateQueryStringFilters();
  }

  get appointmentCard() {
    return this.copyAppointment ? this.copyAppointment : this.moveAppointment;
  }

  public onRemoveAppointmentCardClicked() {
    this.moveAppointmentId = null;
    this.moveAppointment = null;
    this.copyAppointmentId = null;
    this.copyAppointment = null;
    this.updateQueryStringFilters();
  }

  public onCopyAppointmentClicked(appointmentId: number) {
    this.copyAppointmentId = appointmentId;
    this.updateQueryStringFilters();
  }

  public onMoveAppointmentClicked(appointmentId: number) {
    this.moveAppointmentId = appointmentId;
    this.updateQueryStringFilters();
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();
    const queryCalendar = this.$route.query.calendar && this.$route.query.calendar.toString();
    const queryView = this.$route.query.view && this.$route.query.view.toString();
    const queryCanceled = this.$route.query.canceled && this.$route.query.canceled.toString();
    const queryPatientId = this.$route.query.patient && this.$route.query.patient.toString();
    const queryAppointmentId = this.$route.query.appointment && this.$route.query.appointment.toString();
    const queryCopyAppointmentId = this.$route.query.copy && this.$route.query.copy.toString();

    this.focusDate = queryDate ? queryDate : moment().format("YYYY-MM-DD");

    if (queryCalendar) {
      this.displayUserIds = queryCalendar.split(",").map(id => (parseInt(id)));
    } else {
      if (this.isTherapist && !this.$store.getters["auth/isAlwaysDisplayAllCalendars"]) {
        this.displayUserIds = this.currentAssignedCalendarUserId && [this.currentAssignedCalendarUserId];
      } else {
        this.displayUserIds = this.$store.getters["auth/usersWithCalendar"]
          .map((user: User) => (user.id));
      }
    }

    if (queryView && ["day", "month"].includes(queryView)) {
      this.calendarView = queryView as CalendarViewTypes;
    }

    if (queryCanceled === "1") {
      this.isDisplayCanceled = true;
    } else {
      this.isDisplayCanceled = false;
    }

    if (queryPatientId) {
      this.focusPatientId = parseInt(queryPatientId);
    } else {
      this.focusPatientId = null;
      this.focusPatient = null;
    }

    if (queryAppointmentId) {
      this.moveAppointmentId = parseInt(queryAppointmentId);
    } else {
      this.moveAppointmentId = null;
      this.moveAppointment = null;
    }

    if (queryCopyAppointmentId) {
      this.copyAppointmentId = parseInt(queryCopyAppointmentId);
    } else {
      this.copyAppointmentId = null;
      this.copyAppointment = null;
    }
  }

  public updateQueryStringFilters() {
    const today = moment().format("YYYY-MM-DD");
    const query = {
      date: this.focusDate !== today ? this.focusDate : undefined,
      calendar: this.displayUserIds.length === 1
        ? ((this.displayUserIds[0] === this.currentAssignedCalendarUserId) && !this.$store.getters["auth/isAlwaysDisplayAllCalendars"] ? undefined : this.displayUserIds[0])
        : (this.displayUserIds.length > 1 ? this.displayUserIds.join(",") : undefined),
      view: this.calendarView !== "week" ? this.calendarView : undefined,
      canceled: this.isDisplayCanceled ? "1" : undefined,
      patient: this.focusPatientId ? this.focusPatientId.toString() : undefined,
      appointment: this.moveAppointmentId ? this.moveAppointmentId.toString() : undefined,
      copy: this.copyAppointmentId ? this.copyAppointmentId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public onOpenCreateAppointmentDialogClicked() {
    if (!this.isEditEnabled) {
      return;
    }
    this.defaultStartDate = this.focusDate;
    this.defaultUserId = this.displayUserIds.length === 1 ? this.displayUserIds[0] : null;
    this.displayNewAppointmentDialog = true;
  }

  public closeEditAppointmentDialog() {
    this.displayEditAppointmentDialog = false;
    this.editAppointment = null;
  }

  public closeNewAppointmentDialog() {
    this.displayNewAppointmentDialog = false;
    this.defaultStartDate = null;
  }

  public onCalendarViewChanged(calendarView: CalendarViewTypes) {
    this.calendarView = calendarView;
  }

  public onDisplayUserIdsChanged(userIds: number[]) {
    const sortedIds: number[] = [];
    this.therapistUsers.forEach(user => {
      if (userIds.includes(user.id)) {
        sortedIds.push(user.id);
      }
    });
    this.displayUserIds = sortedIds;
    this.updateQueryStringFilters();
    this.handleBackgroundReload(20);
  }

  public onDisplayCanceledChange(newDisplayCanceledFilter: boolean) {
    this.isDisplayCanceled = newDisplayCanceledFilter;
    this.updateQueryStringFilters();
    this.handleBackgroundReload(30);
  }

  public onFocusDateChanged(date: string) {
    this.focusDate = date;
    this.handleBackgroundReload(10);
  }

  public onCalendarDatesChanged(calendarStartDate: VuetifyCalendarLocale, calendarEndDate: VuetifyCalendarLocale) {
    this.calendarStartDate = calendarStartDate.date;
    this.calendarEndDate = calendarEndDate.date;

    this.reload();
  }

  public async onCalendarTimeClicked(date: string, userId?: number) {
    if (this.moveAppointmentId) {
      if (!this.moveAppointment) {
        // Still loading
        return;
      }
      const res = await this.$confirm(
        this.$t("move_appointment_confirm_message_note", {
          user: this.userName(userId ? userId : this.moveAppointment.user_id),
          date: moment(date).format("DD-MM-YYYY"),
          time: moment(date).format("HH:mm"),
        }).toString(),
        {
          buttonTrueColor: "cyan darken-2",
          buttonTrueText: this.$t("move_appointment_ok_btn").toString(),
          buttonFalseText: this.$t("move_appointment_cancel_btn").toString(),
        });

      if (!res) {
        return;
      }

      const newStartDate = date;
      const durationMinutes = moment(this.moveAppointment.end_date).diff(this.moveAppointment.start_date, "minutes");
      const newEndDate = moment(newStartDate).add(durationMinutes, "minutes").format("YYYY-MM-DD HH:mm");
      let newStatus = this.moveAppointment.status_id;
      if (newStatus === 3 && moment(newStartDate).diff(moment(), "days") >= 1) {
        newStatus = 1;
      }

      await AppointmentApi.update(this.moveAppointmentId, {
        status_id: newStatus,
        type_id: this.moveAppointment.type_id,
        patient_id: this.moveAppointment.patient_id,
        user_id: userId ? userId : this.moveAppointment.user_id,
        start_date: newStartDate,
        end_date: newEndDate,
        notes: this.moveAppointment.notes,
      });

      this.moveAppointmentId = null;
      this.moveAppointment = null;
      this.reload();
    } else if (this.copyAppointmentId) {
      if (!this.copyAppointment) {
        // Still loading
        return;
      }
      const res = await this.$confirm(
        this.$t("copy_appointment_confirm_message_note", {
          user: this.userName(userId ? userId : this.copyAppointment.user_id),
          date: moment(date).format("DD-MM-YYYY"),
          time: moment(date).format("HH:mm"),
        }).toString(),
        {
          buttonTrueColor: "cyan darken-2",
          buttonTrueText: this.$t("copy_appointment_ok_btn").toString(),
          buttonFalseText: this.$t("copy_appointment_cancel_btn").toString(),
        });

      if (!res) {
        return;
      }

      const newStartDate = date;
      const durationMinutes = moment(this.copyAppointment.end_date).diff(this.copyAppointment.start_date, "minutes");
      const newEndDate = moment(newStartDate).add(durationMinutes, "minutes").format("YYYY-MM-DD HH:mm");
      let newStatus = this.copyAppointment.status_id;
      if (newStatus === 3 && moment(newStartDate).diff(moment(), "days") >= 1) {
        newStatus = 1;
      }

      await AppointmentApi.create({
        status_id: newStatus,
        type_id: this.copyAppointment.type_id,
        patient_id: this.copyAppointment.patient_id,
        user_id: userId ? userId : this.copyAppointment.user_id,
        start_date: newStartDate,
        end_date: newEndDate,
        notes: this.copyAppointment.notes,
      });

      this.copyAppointmentId = null;
      this.copyAppointment = null;
      this.reload();
    } else {
      this.defaultStartDate = date;
      this.defaultUserId = userId ? userId : (this.displayUserIds.length === 1 ? this.displayUserIds[0] : null);
      this.displayNewAppointmentDialog = true;
    }
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get isSmAndDown() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public mounted() {
    document.addEventListener("visibilitychange", this.handleVisivilityChange);
  }

  public beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisivilityChange);
    window.removeEventListener("resize", this.fixCalendarHeaderWidth);
  }

  public handleVisivilityChange() {
    if (document.hidden) {
      return;
    }

    this.handleBackgroundReload(100);
  }

  public handleBackgroundReload(secondForReload: number) {
    if (this.displayEditAppointmentDialog || this.displayNewAppointmentDialog) {
      return;
    }

    this.today = moment().format("YYYY-MM-DD");
    const secondsSinceLastLoad = moment().diff(this.lastLoadedAt, "second");
    if (!this.lastLoadedAt || secondsSinceLastLoad > secondForReload) {
      this.reload();
    }
  }
}
