import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height cyan lighten-3",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[_c('RouterLink',{attrs:{"to":{ name: 'login' }}},[_c('SmileLogo')],1),_c(VForm,{attrs:{"accept-charset":"UTF-8"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleForgot()}}},[_c(VCard,{staticClass:"elevation-12",attrs:{"color":"white"}},[_c(VCardText,[_c('ErrorMessage',{attrs:{"errors":_vm.errors}}),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(_vm.success),expression:"success"}],attrs:{"type":"success","text":"","dense":""}},[_vm._v("\n              "+_vm._s(_vm.$t('password_reset_sent'))+"\n            ")]),_c(VTextField,{attrs:{"color":"cyan","label":_vm.$t('email'),"name":"email","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","block":"","color":"cyan","loading":_vm.loading,"disabled":_vm.success}},[_vm._v("\n              "+_vm._s(_vm.$t('forgot_password'))+"\n            ")])],1)],1)],1),_c(VBtn,{staticStyle:{"margin-top":"15px"},attrs:{"text":"","to":"/login","small":""}},[_vm._v(_vm._s(_vm.$t('go_to_login'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }