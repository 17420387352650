import { PaymentDocumentApiRoutes } from "../constants/api-routes";
import { AppConfig } from "@/core/config/config";

class PaymentDocumentApi {
  getPreviewPdfUrl(documentType: string, id: number) {
    const authToken = localStorage.getItem("token");
    return `${AppConfig.apiBaseUrl}${PaymentDocumentApiRoutes.paymentDocumentPreview}?documentType=${documentType}&documentId=${id}&_token=${authToken}`;
  }
}

export default new PaymentDocumentApi();
