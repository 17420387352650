



















import Vue from "vue";
import Component from "vue-class-component";
import SettingsHeader from "../components/SettingsHeader.vue";
import { LETTER_PAGE_ROUTE_NAME, QUICK_ACTION_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {
    SettingsHeader
  }
})

export default class ManageQuickActionAndTemplateLetterPage extends Vue {

  public quickCategoryId: number = null;

  get manageTemplateLettersPageName() {
    return LETTER_PAGE_ROUTE_NAME;
  }
  get manageQuickActionCatagoriesName() {
    return QUICK_ACTION_PAGE_ROUTE_NAME;
  }

}

