import { TaskStoreState } from "../types/task.type";

const taskState: TaskStoreState = {
  myFutureTasks: [],
  createdFutureTasks: [],
  myTodoTasks: [],
  createdTodoTasks: [],
  myDoneTasks: [],
  createdDoneTasks: [],
  myTaskRecurrings: [],
  createdTaskRecurrings: [],
};

export default taskState;
