














































import Vue from "vue";
import QuickActionCategoryApi from "../api/quick-action-category-api";
import QuickActionCategoryForm from "../components/quick-actions/QuickActionCategoryForm.vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { QuickActionCategory } from "../types/quick-action-category.type";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { LETTER_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({
    components: {
        ErrorLogo,
        QuickActionCategoryForm,
    }
})

export default class ManageQuickActionCategoriesPage extends Vue {
    @Getter("auth/isRtl") isRtl: boolean;
    public deleteErrors = false;
    public loadErrors: any = false;
    public showQuickActionCategoryForm = false;
    public editQuickActionCategory: QuickActionCategory = null;
    public loading = false;
    public quickActionCategories: QuickActionCategory[] = [];
    get headers() {
        return [
            { width: "25%", text: this.$t("catagories_headers.name"), value: "name", sortable: false },
            { width: "25%", text: this.$t("catagories_headers.order"), value: "order", sortable: false },
            { width: "25%", text: this.$t("catagories_headers.actions"), value: "actions", sortable: false }
        ];
    }

    public openQuickActionCategoryFormDialog(QuickActionCategory: QuickActionCategory) {
        this.editQuickActionCategory = QuickActionCategory;
        this.showQuickActionCategoryForm = true;
    }
    public closeQuickActionCategoryForm() {
        this.editQuickActionCategory = null;
        this.showQuickActionCategoryForm = false;
        this.$emit("categoriesChanged");
    }
    public reload() {
        this.loadCategories();
    }

    public onCategoryClick(categoryId: number) {
        this.$emit("onSetQuickActionCategoryId", categoryId);

    }

    public async loadCategories() {
        this.loadErrors = null;
        this.loading = true;
        this.quickActionCategories = [];
        try {
            this.quickActionCategories = await QuickActionCategoryApi.getAll();
            this.loading = false;
        } catch (err) {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
                this.loadErrors = err.response.data.errors;
            } else {
                this.loadErrors = this.$t("error_loading_data");
            }
        }
    }

    get manageTemplateLettersPageName() {
        return LETTER_PAGE_ROUTE_NAME;
    }

    mounted() {
        this.loadCategories();
    }
}
