













import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SmartMirrorApi from "@/modules/smartmirror/api/smartmirror.api";

@Component({
})
export default class SmartMirrorButton extends Vue {
  @Prop({ required: true }) public patientId: number;
  @Getter("auth/smartMirrorRooms") smartMirrorRooms: string; // TODO support multi

  public isSmartMirrorLoading = false;


  public async onSelectPatientClicked() {
    try {
      this.isSmartMirrorLoading = true;
      await SmartMirrorApi.selectPatient(this.patientId, this.smartMirrorRooms);
      this.$toastr.s(this.$t("patient_selected_toastr"));
      this.isSmartMirrorLoading = false;
    } catch {
      this.$toastr.e(this.$t("temporary_error"));
      this.isSmartMirrorLoading = false;
    }
  }

}
