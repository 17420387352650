














import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import { Getter } from "vuex-class";
import SingleWhatsappChat from "./SingleWhatsappChat.vue";

@Component({
  components: {
    SingleWhatsappChat,
  },
  filters: {
    age,
    date,
    time
  }
})
export default class PatientWhatsappChatDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public phone: string;
  @Getter("auth/isRtl") isRtl: boolean;

  public closeDialog() {
    this.$emit("close");
  }
}
