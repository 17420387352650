










































import Vue from "vue";
import AgentApi from "../../../serivce/api/imaging-agent-api";
import AgentForm from "../components/imaging-agent/AgentForm.vue";
import Component from "vue-class-component";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Getter } from "vuex-class";
import SettingsHeader from "../components/SettingsHeader.vue";
import { ImagingAgent } from "@/modules/imaging-agent/types/imaging-agent.type";

@Component({
  components: {
    ErrorLogo,
    AgentForm,
    SettingsHeader
  },
})

export default class ManageImagingAgentsPage extends Vue {

  @Getter("auth/isRtl") isRtl: boolean;
  public deleteErrors: any = false;
  public loadErrors: any = false;
  public showAgentForm = false
  public editAgent: ImagingAgent = null
  public loading = false;
  public agents: ImagingAgent[] = [];
  public headers = [
    { width: "33%", text: this.$t("headers.name"), value: "name" },
    { width: "33%", text: this.$t("headers.last_reported_at"), value: "last_reported_at" },
    { width: "33%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ]

  public openAgentFormDialog(agent: ImagingAgent) {
    this.editAgent = agent;
    this.showAgentForm = true;
  }
  public closeAgentForm() {
    this.editAgent = null;
    this.showAgentForm = false;
  }
  public async deleteAgent(agent) {
    this.deleteErrors = null;
    try {
      const res = await this.$confirm(this.$t("delete_confirm_message", { "name": agent.name }), {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn"),
        buttonFalseText: this.$t("delete_cancel_btn")
      });
      if (res) {
        this.loading = true;
        try {
          await AgentApi.delete(agent.id);
          this.$toastr.s(this.$t("deleted_toastr"));
          this.reload();
        }
        catch (err) {
          if (err.response && err.response.data && err.response.data.errors) {
            this.deleteErrors = err.response.data.errors;
          } else {
            this.deleteErrors = this.$t("delete_error");
          }
          this.reload();
        }
      }
    } finally {
      this.$vuetify.rtl = this.$store.getters["auth/isRtl"];

    }
  }
  public reload() {
    this.loadAgents();
  }

  public async loadAgents() {
    this.loadErrors = null;
    this.loading = true;
    this.agents = [];
    try {
      this.loading = false;
      const results = await AgentApi.get();
      this.agents = results.data.data;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }
  mounted() {
    this.loadAgents();
  }
}
