import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  QuickActionCategoryApiRouteParameters,
  QuickActionCategoryApiRoutes,
} from "@/modules/settings/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { QuickActionCategory } from "../types/quick-action-category.type";

class QuickActionCategoryApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<QuickActionCategory[]> {
    try {
      const response: ApiResponse<QuickActionCategory[]> = await this.httpClient.get(
        QuickActionCategoryApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    order: number
  ): Promise<QuickActionCategory> {
    try {
      const response: ApiResponse<QuickActionCategory> = await this.httpClient.put(
        QuickActionCategoryApiRoutes.update.replace(
          QuickActionCategoryApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(name: string, order: number): Promise<QuickActionCategory> {
    try {
      const response: ApiResponse<QuickActionCategory> = await this.httpClient.post(
        QuickActionCategoryApiRoutes.create,
        {
          name,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        QuickActionCategoryApiRoutes.delete.replace(
          QuickActionCategoryApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new QuickActionCategoryApi();
