








































import { PatientStatus } from "@/modules/patient/types/patient-status.type";
import PatientStatusApi from "../../../serivce/api/patient-status-api";
import PatientStatusForm from "../components/patient-status/PatientStatusForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    PatientStatusForm,
    SettingsHeader
  },
})
export default class ManagePatientStatusPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;

  public loadErrors: any = false;
  public showPatientStatusForm = false;
  public editPatientStatus: PatientStatus = null;
  public loading = false;
  public patientstatus: PatientStatus[] = [];

  get headers() {
    return [
      { width: "25%", text: this.$t("headers.name"), value: "name", sortable: false },
      { width: "25%", text: this.$t("headers.color"), value: "color", sortable: false },
      { width: "25%", text: this.$t("headers.order"), value: "order", sortable: false },
      { width: "25%", text: this.$t("headers.actions"), value: "actions", sortable: false }
    ];
  }

  public mounted() {
    this.loadPatientStatus();
  }

  public isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public openPatientStatusFormDialog(patientstatus) {
    this.editPatientStatus = patientstatus;
    this.showPatientStatusForm = true;
  }

  public closePatientStatusForm() {
    this.editPatientStatus = null;
    this.showPatientStatusForm = false;
  }

  public reload() {
    this.loadPatientStatus();
  }

  public async loadPatientStatus() {
    this.loadErrors = null;
    this.loading = true;
    this.patientstatus = [];

    try {

      this.patientstatus = await PatientStatusApi.getAll();

      this.loading = false;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }

      this.loading = false;

    }
  }
}
