





































import AppointmentStatusApi from "../../../serivce/api/appointment-status-api";
import AppointmentStatusForm from "../components/appointment-status/AppointmentStatusForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import SettingsHeader from "../components/SettingsHeader.vue";
import { AppointmentStatus } from "@/modules/appointment/types/appointment-status.type";

@Component({
  components: {
    ErrorLogo,
    AppointmentStatusForm,
    SettingsHeader
  },
})
export default class ManageAppointmentStatusesPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public loadErrors: any = false;
  public deleteErrors = false
  public showAppointmentStatusForm = false;
  public editAppointmentStatus: AppointmentStatus = null;
  public loading = false;
  public appointmentStatuses: AppointmentStatus[] = [];
  public headers = [
    { width: "25%", text: this.$t("headers.name"), value: "name" },
    { width: "25%", text: this.$t("headers.color"), value: "color" },
    { width: "25%", text: this.$t("headers.is_enabled"), value: "is_enabled" },
    { width: "25%", text: this.$t("headers.actions"), value: "actions", sortable: false }
  ];

  public openAppointmentStatusFormDialog(appointmentStatus: AppointmentStatus) {
    this.editAppointmentStatus = appointmentStatus;
    this.showAppointmentStatusForm = true;
  }
  public closeAppointmentStatusForm() {
    this.editAppointmentStatus = null;
    this.showAppointmentStatusForm = false;
  }
  public reload() {
    this.loadAppointmentStatuses();
  }
  public async loadAppointmentStatuses() {
    this.loadErrors = null;
    this.loading = true;
    this.appointmentStatuses = [];

    try {
      this.loading = false;
      const results = await AppointmentStatusApi.get();
      this.appointmentStatuses = results.data.data;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("error_loading_data");
      }
    }
  }

  mounted() {
    this.loadAppointmentStatuses();
  }
}
