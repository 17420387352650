import TimeClockApi from "@/modules/time-clock/api/time-clock.api";
import { MANAGE_TIME_CLOCK_ROUTE_NAME } from "@/modules/time-clock/constants/route-config";
import {
  getTimeEntryCheckedAt,
  setTimeEntryCheckedAt,
} from "@/serivce/time-entry-checked-at.service";
import store from "@/store";
import moment from "moment";
import { Route } from "vue-router";

async function checkUserActiveEntry() {
  const storageTimeEntry = getTimeEntryCheckedAt();
  if (!storageTimeEntry) {
    return false;
  }

  const now = moment();
  const duration = moment.duration(now.diff(moment(storageTimeEntry)));
  const durationByMinutes = duration.asMinutes();

  if (durationByMinutes < 15) {
    return true;
  } else if (durationByMinutes >= 15) {
    try {
      const userId = store.getters["auth/userId"];
      const userMonthTimeEntries = await TimeClockApi.getAll({
        userId,
        year_month: moment().format("YYYY-MM"),
      });
      const activeTimeEntry = userMonthTimeEntries.entries.find(
        (timeEntry) =>
          moment(timeEntry.start).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD") &&
          moment(timeEntry.start).isBefore(moment()) &&
          timeEntry.start === timeEntry.end
      );
      if (activeTimeEntry) {
        setTimeEntryCheckedAt();
      }
      return !!activeTimeEntry;
    } catch (err) {
      return true;
    }
  }
}

export async function timeClockRequiredGuard(to: Route, from: Route, next) {
  if (store.getters["auth/isTimeEntryRequired"]) {
    const isUserHasActiveTimeEntry = await checkUserActiveEntry();
    if (to.name !== MANAGE_TIME_CLOCK_ROUTE_NAME && !isUserHasActiveTimeEntry) {
      return next({ name: MANAGE_TIME_CLOCK_ROUTE_NAME });
    }
  }
}
