import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"manage-users pb-8"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"start"}},[_c(VCol,[_c('h2',[_c(VIcon,[_vm._v("mdi-account-group")]),_vm._v("\n          "+_vm._s(_vm.$t('title'))+"\n          "),_c(VBtn,{class:{ 'float-right': !_vm.isRtl, 'float-left': _vm.isRtl },attrs:{"small":"","color":"cyan"},on:{"click":function($event){return _vm.openUserFormDialog(null)}}},[_vm._v("\n            "+_vm._s(_vm.$t('add_user'))+"\n            "),_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"mobile-breakpoint":1,"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no_data')},scopedSlots:_vm._u([(_vm.loadErrors)?{key:"no-data",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('error_loading_data'))+" "),_c('br'),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.loadUsers}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('br'),_c('ErrorLogo')]},proxy:true}:null,{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(_vm.$t(("types." + (item.type))))+"\n              "),(item.is_admin)?_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v("\n                "+_vm._s(_vm.$t('admin'))+"\n              ")]):_vm._e()])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('a',{staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.sendWhatsapp(item.phone)}}},[_vm._v("\n              "+_vm._s(item.phone)+"\n            ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":"","color":"cyan lighten-4"},on:{"click":function($event){return _vm.openUserFormDialog(item)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_vm._v("\n              "+_vm._s(_vm.$t('edit_btn'))+"\n            ")],1)]}}],null,true)})],1)],1)],1),(_vm.showUserForm)?_c('UserForm',{attrs:{"user":_vm.processedUser},on:{"close":function($event){return _vm.closeUserForm()},"userCreated":_vm.addUserRecord,"userUpdated":_vm.updateUserRecord,"userDeleted":_vm.deleteUserRecord}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }