export const DENTURES_MAP = [
    {
        name: "permanent",
        teeth: [
            11, 12, 13, 14, 15, 16, 17, 18,
            21, 22, 23, 24, 25, 26, 27, 28,
            31, 32, 33, 34, 35, 36, 37, 38,
            41, 42, 43, 44, 45, 46, 47, 48,
        ],
    },
    {
        name: "deciduous",
        teeth: [
            51, 52, 53, 54, 55,
            61, 62, 63, 64, 65,
            71, 72, 73, 74, 75,
            81, 82, 83, 84, 85,
        ],
    },
    {
        name: "age6-7",
        teeth: [
            51, 52, 53, 54, 55, 16,
            61, 62, 63, 64, 65, 26,
            31, 72, 73, 74, 75, 36,
            41, 82, 83, 84, 85, 46,
        ],
    },
    {
        name: "age7-8",
        teeth: [
            11, 52, 53, 54, 55, 16,
            21, 62, 63, 64, 65, 26,
            31, 32, 73, 74, 75, 36,
            41, 42, 83, 84, 85, 46,
        ],
    },
    {
        name: "age8-10",
        teeth: [
            11, 12, 53, 54, 55, 16,
            21, 22, 63, 64, 65, 26,
            31, 32, 33, 74, 75, 36,
            41, 42, 43, 84, 85, 46,
        ],
    },
    {
        name: "age10-13",
        teeth: [
            11, 12, 53, 54, 55, 16, 17,
            21, 22, 63, 64, 65, 26, 27,
            31, 32, 33, 74, 75, 36, 37,
            41, 42, 43, 84, 85, 46, 47,
        ],
    },
];

export const TEETH_SURFACES = ["O", "DO", "D", "M", "MO", "B", "P", "L", "LA", "OL",  "OP", "OM", "PO+O", "OB", "MOD"];

export const TEETH_SIMPLE_SURFACES = ["B", "D","I", "L", "M", "O", "P"];

export const ALL_TEETH = [
    11, 12, 13, 14, 15, 16, 17, 18,
    21, 22, 23, 24, 25, 26, 27, 28,
    31, 32, 33, 34, 35, 36, 37, 38,
    41, 42, 43, 44, 45, 46, 47, 48,
    51, 52, 53, 54, 55,
    61, 62, 63, 64, 65,
    71, 72, 73, 74, 75,
    81, 82, 83, 84, 85,
];