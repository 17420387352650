











































































import moment from "moment";
import { Component, Prop } from "vue-property-decorator";
import { Patient } from "../../patient/types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import LabWorkApi from "../api/lab-work.api";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { LabWork } from "../types/lab-work.type";

@Component
export default class LabWorkFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: false }) public editLabWork: LabWork;
  @Getter("auth/documentTreatmentsUsers") documentTreatmentsUsers: User[];
  @Getter("auth/isRtl") isRtl: boolean;

  public notes = "";
  public sentAtDate = "";
  public returnedAtDate = "";
  public showErrors = false;
  public errors: any = false;
  public isSendLoading = false;
  public deleteLoading = false;
  public patientId: number = null;
  public userId: number = null;
  public labId: number = null;
  public sentAtDateMenuOpen = false;
  public returnedAtDateMenuOpen = false;

  get patientFullName() {
    return `${this.patient.first_name} ${this.patient.last_name}`;
  }

  get labsContacts() {
    const contacts = this.$store.getters["auth/contacts"].filter(contact => contact.display_type && contact.display_type.includes("labs"));
    const flattenContacts = [];
    contacts.map(contactBookmark => contactBookmark.contacts.map(contact => flattenContacts.push(contact)));
    return flattenContacts;
  }

  get formattedSentAtDate() {
    if (!this.sentAtDate) {
      return "";
    }
    return moment(this.sentAtDate).format("DD/MM/YYYY");
  }

  get formattedReturnedAtDate() {
    if (!this.returnedAtDate) {
      return "";
    }
    return moment(this.returnedAtDate).format("DD/MM/YYYY");
  }

  async public deleteLabWork() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    this.deleteLoading = true;
    try {
      await LabWorkApi.delete(this.editLabWork.id);
      this.$toastr.s(this.$t("lab_work_deleted"));
      this.$emit("labWorkChanged");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("saving_error"));
    } finally {
      this.deleteLoading = false;
    }

  }

  async public mounted() {
    if (this.editLabWork) {
      this.notes = this.editLabWork.notes;
      this.sentAtDate = moment(this.editLabWork.created_at).format("YYYY-MM-DD");
      this.returnedAtDate = this.editLabWork.returned_at ? moment(this.editLabWork.returned_at).format("YYYY-MM-DD") : "";
      this.userId = this.editLabWork.user_id;
      this.labId = this.editLabWork.contact_id;
    } else {
      this.sentAtDate = moment().format("YYYY-MM-DD HH:mm");
    }
    this.patientId = this.patient.id;

    if (this.$store.getters["auth/isTherapist"]) {
      this.userId = this.$store.getters["auth/userId"];
    } else if (this.documentTreatmentsUsers.length === 1) {
      this.userId = this.documentTreatmentsUsers[0].id;
    }
  }

  public async closeDialog() {
    this.$emit("close");
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public async submitForm() {
    this.errors = false;
    this.showErrors = true;

    const payload = {
      patient_id: this.patient.id,
      user_id: this.userId,
      contact_id: this.labId,
      created_at: moment(this.sentAtDate).format("YYYY-MM-DD HH:mm"),
      returned_at: this.returnedAtDate,
      notes: this.notes
    };
    try {
      this.isSendLoading = true;
      this.editLabWork ? await LabWorkApi.update(this.editLabWork.id, payload) : await LabWorkApi.create(payload);
      this.editLabWork ? this.$toastr.s(this.$t("lab_work_updated")) : this.$toastr.s(this.$t("lab_work_created"));
      this.$emit("labWorkChanged");
      this.closeDialog();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
    }
  }

}
