




































import { TreatmentGroupByTreatmentName } from "@/modules/patient/types/patient-treatment.type";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
    },

})

export default class TreatmentSummaryReport extends mixins(SmileMixin) {

    @Getter("auth/isRtl") isRtl: boolean;
    @Prop({ required: true }) page: number;
    @Prop({ required: true }) dataTableOptions: any;
    @Prop({ required: true }) totalItemsCount: number;
    @Prop({ required: true }) itemsPerPage: number;
    @Prop({ required: true }) treatmentsSummary: TreatmentGroupByTreatmentName[];
    @Prop({ required: true }) isLoading: boolean;
    public headers = [{ text: this.$t("headers.treatment"), value: "treatment", sortable: false },
    { text: this.$t("headers.count"), value: "treatmentscount", sortable: false },
    { text: this.$t("headers.amount"), value: "totalprice", sortable: false },];

    public updatePage(page: number) {
        this.$emit("changePage", page);
    }

    get isMobile() {
        return this.$vuetify.breakpoint.xsOnly;
    }

}
