

























































import DrugApi from "@/modules/settings/api/drug.api";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Drug } from "@/modules/settings/types/drug.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";

@Component({
  components: {
    ErrorMessage,
  }
})
export default class DrugForm extends Vue {
  @Prop({ required: false }) public editDrug: Drug;
  public errors: any = false;
  public name = "";
  public quantityPerTaking = "";
  public quantityPerDay = "";
  public daysDuration = "";
  public totalQuantity = "";
  public internalNote = "";
  public notes = "";
  public isLoading = false;
  public deleteLoading = false;

  mounted() {
    if (this.editDrug) {
      this.name = this.editDrug.name;
      this.quantityPerTaking = this.editDrug.quantity_per_taking;
      this.quantityPerDay = this.editDrug.quantity_per_day;
      this.daysDuration = this.editDrug.days_duration;
      this.totalQuantity = this.editDrug.total_quantity;
      this.internalNote = this.editDrug.internal_note;
      this.notes = this.editDrug.notes;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    if (this.isLoading) { return; }

    this.isLoading = true;
    this.errors = false;

    const attempt = this.editDrug
      ? DrugApi.update(this.editDrug.id, this.name, this.quantityPerTaking, this.quantityPerDay, this.daysDuration, this.totalQuantity, this.internalNote, this.notes)
      : DrugApi.create(this.name, this.quantityPerTaking, this.quantityPerDay, this.daysDuration, this.totalQuantity, this.internalNote, this.notes);

    attempt
      .then(() => {
        this.isLoading = false;

        this.$toastr.s(this.editDrug ? this.$t("drug_updated") : this.$t("drug_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public async deleteDrug() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await DrugApi.delete(this.editDrug.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

}
