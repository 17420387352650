

























































































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import PaymentTranzilaApi from "../api/payment-tranzila.api";
import { PaymentTranzilaEmvResponse, PaymentTranzilaIframeResponse } from "../types/payment-tranzila.type";
import { Pinkas } from "../../pinkas/types/pinkas.type";
import { TRANZILA_IFRAME_NAME } from "@/modules/payment-tranzila/constants/route-config";

const mutagToCompany = {
  "0": "לא ידוע",
  "1": "מאסטרקארד",
  "American Express": "אמריקן אקספרס",
  "3": "דיינרס",
  "2": "ויזה",
  "5": "ישראכרט"
};

@Component({
})
export default class PaymentProviderTranzilaAction extends mixins(SmileMixin) {
  @Prop({ required: true }) pinkasId: number;
  @Getter("auth/isRtl") isRtl: boolean;

  $refs!: {
    tranzilaIframe: HTMLIFrameElement;
  }

  public paymentType = "card";
  public creditTerm = "regular";
  public amount = "";
  public creditNumPayments = 3;
  public paymentsNumPayments = 2;
  public paymentsFirstAmount = "";
  public paymentsNextAmount = "";
  public iframeUrl: string = null;
  public isLoading = false;
  public showErrors = false;
  public emvResponse: PaymentTranzilaEmvResponse = null;

  public onBackToPaymentButtonClicked() {
    this.emvResponse = null;
  }

  public closeDialog() {
    this.$emit("close");
  }

  get isAmountError() {
    const amount = parseFloat(this.amount);
    if (!amount) {
      return true;
    }
    if (amount < 0) {
      return true;
    }

    if (parseFloat(amount.toFixed(2)) < amount) {
      return true;
    }

    return false;
  }

  get isFirstAmountError() {
    if (this.creditTerm !== "payments") {
      return false;
    }
    const paymentsFirstAmount = parseFloat(this.paymentsFirstAmount);
    if (!paymentsFirstAmount) {
      return true;
    }
    if (paymentsFirstAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsFirstAmount.toFixed(2)) < paymentsFirstAmount) {
      return true;
    }

    return false;
  }

  get isNextAmountError() {
    if (this.creditTerm !== "payments") {
      return false;
    }
    const paymentsNextAmount = parseFloat(this.paymentsNextAmount);
    if (!paymentsNextAmount) {
      return true;
    }
    if (paymentsNextAmount < 0) {
      return true;
    }

    if (parseFloat(paymentsNextAmount.toFixed(2)) < paymentsNextAmount) {
      return true;
    }

    if (paymentsNextAmount * (this.paymentsNumPayments - 1) + parseFloat(this.paymentsFirstAmount) !== parseFloat(this.amount)) {
      return true;
    }

    return false;
  }

  public onCreditTypeChanged() {
    this.onAmountInput();
  }

  public onPaymentsNumPaymentsChanged() {
    this.onAmountInput();
  }


  public onAmountChanged() {
    if (this.amount) {
      this.amount = Number(Number(this.amount.toString()).toFixed(2)).toString();
    }
  }

  public onAmountInput() {
    if (this.creditTerm !== "payments" || !this.amount) {
      return;
    }

    this.paymentsNextAmount = Math.floor(parseFloat(this.amount) / this.paymentsNumPayments).toFixed(2).replace(".00", "");
    this.paymentsFirstAmount = (parseFloat(this.amount) - ((this.paymentsNumPayments - 1) * parseFloat(this.paymentsNextAmount))).toFixed(2).replace(".00", "");
  }

  public onFirstAmountChanged() {
    if (parseFloat(this.paymentsFirstAmount) > parseFloat(this.amount) || !parseFloat(this.amount) || !parseFloat(this.paymentsFirstAmount)) {
      return;
    }

    this.paymentsNextAmount = ((parseFloat(this.amount) - parseFloat(this.paymentsFirstAmount)) / (this.paymentsNumPayments - 1)).toFixed(2).replace(".00", "");
  }

  public onNextAmountChanged() {
    if (parseFloat(this.paymentsNextAmount) > parseFloat(this.amount) || !parseFloat(this.amount) || !parseFloat(this.paymentsNextAmount)) {
      return;
    }

    this.paymentsFirstAmount = (parseFloat(this.amount) - ((this.paymentsNumPayments - 1) * parseFloat(this.paymentsNextAmount))).toFixed(2).replace(".00", "");
  }

  public async onStartEmvClicked() {
    this.showErrors = true;

    if (this.isAmountError || this.isFirstAmountError || this.isNextAmountError) {
      return;
    }

    try {
      this.isLoading = true;
      const amount = Number(Number(this.amount.toString()).toFixed(2));
      const numPayments = this.creditTerm === "payments" ? this.paymentsNumPayments - 1 : (this.creditTerm === "credit" ? this.creditNumPayments - 1 : 0);
      let firstPaymentAmount = null;
      let nextPaymentAmount = null;

      if (this.creditTerm === "credit") {
        nextPaymentAmount = Number(Math.floor(amount / this.creditNumPayments).toFixed(2).replace(".00", ""));
        firstPaymentAmount = Number((amount - ((this.creditNumPayments - 1) * nextPaymentAmount)).toFixed(2).replace(".00", ""));
      } else if (this.creditTerm === "payments") {
        firstPaymentAmount = Number(Number(this.paymentsFirstAmount.toString()).toFixed(2));
        nextPaymentAmount = Number(Number(this.paymentsNextAmount.toString()).toFixed(2));
      }
      const credType = this.creditTerm === "payments" ? 8 : (this.creditTerm === "credit" ? 6 : 1);
      this.emvResponse = await PaymentTranzilaApi.startEmv(this.pinkasId, amount, credType, numPayments, firstPaymentAmount, nextPaymentAmount);
      if (this.emvResponse.error) {
        this.$toastr.e(this.emvResponse.error);
        this.emvResponse = null;
      } else if (this.emvResponse.transaction_result.statusCode === 0) {
        // success
        this.$toastr.s(this.emvResponse.transaction_result.statusMessage);
        this.emitTransaction();
      } else if (this.emvResponse.transaction_result.statusCode !== 0) {
        // failed
        this.$toastr.e(this.emvResponse.transaction_result.statusMessage);
      }

    } catch (err) {
      this.$toastr.e(this.$t("emv_request_error"));
    } finally {
      this.isLoading = false;
    }
  }

  public onShowIframeClicked() {
    this.showErrors = true;

    if (this.isAmountError || this.isFirstAmountError || this.isNextAmountError) {
      return;
    }

    const amount = Number(Number(this.amount.toString()).toFixed(2));
    const numPayments = this.creditTerm === "payments" ? this.paymentsNumPayments - 1 : (this.creditTerm === "credit" ? this.creditNumPayments - 1 : 0);
    let firstPaymentAmount = null;
    let nextPaymentAmount = null;

    if (this.creditTerm === "credit") {
      nextPaymentAmount = Number(Math.floor(amount / this.creditNumPayments).toFixed(2).replace(".00", ""));
      firstPaymentAmount = Number((amount - ((this.creditNumPayments - 1) * nextPaymentAmount)).toFixed(2).replace(".00", ""));
    } else if (this.creditTerm === "payments") {
      firstPaymentAmount = Number(Number(this.paymentsFirstAmount.toString()).toFixed(2));
      nextPaymentAmount = Number(Number(this.paymentsNextAmount.toString()).toFixed(2));
    }

    const pinkas = (this.$store.getters["auth/pinkasList"] as Pinkas[]).find(({ id }) => (id === this.pinkasId));
    const terminal = pinkas.tranzila_public_attributes.phone_terminal_name;
    const credType = this.creditTerm === "payments" ? 8 : (this.creditTerm === "credit" ? 6 : 1);

    const callbackUrl = window.location.origin + this.$router.resolve({
      name: TRANZILA_IFRAME_NAME,
    }).href + "?results=";

    (window as any).iframeResponse = this.iframeResponse;
    this.iframeUrl = `https://direct.tranzila.com/${terminal}/iframenew.php?sum=${amount}&nologo=1&trButtonColor=00bcd4&lang=il&trTextColor=000000&currency=1&cred_type=${credType}&fpay=${firstPaymentAmount}&spay=${nextPaymentAmount}&npay=${numPayments}&success_url_address=${callbackUrl}&fail_url_address=${callbackUrl}`;
  }

  public iframeResponse(tranzilaIframeResponse: PaymentTranzilaIframeResponse) {
    if (tranzilaIframeResponse.Response === "000") {
      const paymentType = tranzilaIframeResponse.cred_type === "6" ? "קרדיט" : (tranzilaIframeResponse.cred_type === "8" ? "תשלומים" : "רגיל");
      const company = mutagToCompany[tranzilaIframeResponse.cardtype] || "לא ידוע";
      const expiryDate = tranzilaIframeResponse.expmonth + "/" + tranzilaIframeResponse.expyear;
      const cardNumber = tranzilaIframeResponse.ccno;
      const amount = tranzilaIframeResponse.sum;
      const numPayments = tranzilaIframeResponse.npay === "0" ? null : parseInt(tranzilaIframeResponse.npay) + 1;
      const firstPaymentAmount = parseFloat(tranzilaIframeResponse.fpay) ? parseFloat(tranzilaIframeResponse.fpay).toFixed(2).replace(".00", "") : null;
      const nextPaymentAmount = parseFloat(tranzilaIframeResponse.spay) ? parseFloat(tranzilaIframeResponse.spay).toFixed(2).replace(".00", "") : null;
      this.$emit("cardPaymentReceived", company, expiryDate, cardNumber, amount, paymentType, numPayments, firstPaymentAmount, nextPaymentAmount);
      return;
    }

    this.$toastr.e(this.$t(`error_code.${tranzilaIframeResponse.Response}`));
    this.onShowIframeClicked();
    this.$refs.tranzilaIframe.src = this.iframeUrl;
  }

  get paymentTypeOptions() {
    const pinkas = (this.$store.getters["auth/pinkasList"] as Pinkas[]).find(({ id }) => (id === this.pinkasId));
    const options = [];
    if (pinkas.tranzila_public_attributes.is_emv_terminal_enabled) {
      options.push({ text: this.$t("payment_type_card"), value: "card" });
    }

    if (pinkas.tranzila_public_attributes.phone_terminal_name) {
      options.push({ text: this.$t("payment_type_manual"), value: "manual" });
    }

    return options;
  }

  private emitTransaction() {
    const paymentType = this.emvResponse.transaction_result.creditTerms === 6 ? "קרדיט" : (this.emvResponse.transaction_result.creditTerms === 8 ? "תשלומים" : "רגיל");
    const company = mutagToCompany[this.emvResponse.transaction_result.mutag] || "לא ידוע";
    const expiryDate = this.emvResponse.transaction_result.expDate.slice(-2) + "/" + this.emvResponse.transaction_result.expDate.slice(0, -2);
    const cardNumber = this.emvResponse.transaction_result.cardNumber.slice(-4);
    const amount = (this.emvResponse.transaction_result.amount / 100).toFixed(2).replace(".00", "");
    const numPayments = this.emvResponse.original_request.npay ? parseInt(this.emvResponse.original_request.npay) + 1 : null;
    const firstPaymentAmount = this.emvResponse.original_request.fpay ? parseInt(this.emvResponse.original_request.fpay).toFixed(2).replace(".00", "") : null;
    const nextPaymentAmount = this.emvResponse.original_request.spay ? parseInt(this.emvResponse.original_request.spay).toFixed(2).replace(".00", "") : null;
    this.$emit("cardPaymentReceived", company, expiryDate, cardNumber, amount, paymentType, numPayments, firstPaymentAmount, nextPaymentAmount);
  }

  get creditTermOptions() {
    return [
      { text: this.$t("credit_terms_regular"), value: "regular" },
      { text: this.$t("credit_terms_payments"), value: "payments" },
      { text: this.$t("credit_terms_credit"), value: "credit" },
    ];
  }

  get creditNumPaymentsOptions() {
    const options = [];
    for (let i = 3; i <= 12; i++) {
      options.push({ text: i.toString(), value: i });
    }
    return options;
  }

  get paymentsNumPaymentsOptions() {
    const options = [];
    for (let i = 2; i <= 12; i++) {
      options.push({ text: i.toString(), value: i });
    }
    return options;
  }
}
