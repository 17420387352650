























import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PatientFile } from "../../types/patient-file.type";

@Component({
})
export default class RenamePatientFileDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public file: PatientFile;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public updatedPatientFileName = "";

  public mounted() {
    this.updatedPatientFileName = this.file.name;
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onUpdateNameClicked() {
    this.$emit("patientFileRenamed", this.updatedPatientFileName, this.file.id, this.file.folder_id);
    this.closeDialog();
  }

}
