import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"360","persistent":_vm.dateMenuOpen},on:{"input":function($event){return _vm.closeDialog()}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_vm._v("\n      "+_vm._s(_vm.$t('title'))+"\n      "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{attrs:{"accept-charset":"UTF-8"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateEntry()}}},[_c('smile-error-message',{attrs:{"errors":_vm.errors}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{attrs:{"required":"","items":_vm.timeEntryTypes,"label":_vm.$t('type_entry'),"item-color":"cyan darken-1","color":"cyan darken-1"},model:{value:(_vm.typeId),callback:function ($$v) {_vm.typeId=$$v},expression:"typeId"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.$t('date'),"readonly":"","color":"cyan darken-1"},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.dateMenuOpen),callback:function ($$v) {_vm.dateMenuOpen=$$v},expression:"dateMenuOpen"}},[_c(VDatePicker,{attrs:{"color":"cyan darken-1","no-title":""},on:{"input":function($event){_vm.dateMenuOpen = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"name":"start_hour","dir":"ltr","label":_vm.$t('start_hour'),"color":"cyan darken-1","placeholder":"00:00","required":""},on:{"blur":function($event){_vm.startHour = _vm.reformatTimeInput($event.target.value)}},model:{value:(_vm.startHour),callback:function ($$v) {_vm.startHour=$$v},expression:"startHour"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"hint":_vm.isMultiDate ? _vm.nextDay : '',"persistent-hint":"","name":"end_hour","dir":"ltr","label":_vm.$t('end_hour'),"color":"cyan darken-1","placeholder":"00:00","required":""},on:{"blur":function($event){_vm.endHour = _vm.reformatTimeInput($event.target.value)}},model:{value:(_vm.endHour),callback:function ($$v) {_vm.endHour=$$v},expression:"endHour"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"cyan","loading":_vm.submitting,"disabled":_vm.submitting,"type":"submit"}},[_vm._v("\n                "+_vm._s(_vm.$t('submit'))+"\n              ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }