












































import { Component, Prop } from "vue-property-decorator";
import { Plan } from "@/modules/treatment/types/plan.type";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { User } from "@/modules/users/types/user.type";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";

@Component({
})
export default class EditPlanDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public plan: Plan;
  @Getter("auth/isRtl") isRtl: boolean;
  public updatedPlanName = "";
  public userId: number = null;
  public createdAt: string = null;
  public displayTreatmentDatePicker = false;

  public mounted() {
    this.updatedPlanName = this.plan.name;
    this.userId = this.plan.user_id;
    this.createdAt = moment(this.plan.created_at).format("YYYY-MM-DD");
  }

  get usersOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter((user) => (user.id === this.userId || (user.type === "caregiver" && !user.deleted_at && user.is_document_treatments_enabled)));
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public closeDialog() {
    this.$emit("close");
  }

  get dateFormatted() {
    return moment(this.createdAt).format("DD-MM-YYYY");
  }

  public async onUpdateNameClicked() {
    this.$emit("planEdited", this.updatedPlanName, this.userId, this.createdAt);
    this.closeDialog();
  }

}
