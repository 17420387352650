











import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { SETTINGS_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({})

export default class SettingsHeader extends Vue {

    @Getter("auth/isRtl") isRtl: boolean;
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) icon: string;

    get settingsPageRouteName() {
        return SETTINGS_PAGE_ROUTE_NAME;
    }

}
