import * as FullStory from "@fullstory/browser";
import { AppConfig } from "../config/config";

export const initFullStory = (email: string) => {
  if (!AppConfig.inProductionMode) {
    return;
  }
  if (
    email === "support@smile-app.co.il" ||
    email === "maya.dvidi@gmail.com" ||
    email === "dr.noaregev@gmail.com" ||
    email === "liat.gil@gmail.com" ||
    email === "sahar_jb@hotmail.com" ||
    email === "yael.raviv@mail.huji.ac.il" ||
    email === "elizur.clinic@gmail.com" ||
    email === "clinic@dr-st.co.il" ||
    email === "lihik1210@gmail.com" ||
    email === "amitmoochie@gmail.com" ||
    email === "yuval@smilecloud.co.il" ||
    email === "julienn100@gmail.com" ||
    email === "esti@dr-st.co.il" ||
    email === "info@drromano.com" ||
    email === "hazuta@gmail.com" ||
    email === "modiindental@gmail.com" ||
    email === "galina6627428@gmail.com" ||
    email === "tzippy.smil@gmail.com" ||
    email === "kennybendik@gmail.com" ||
    email === "yosman1000@gmail.com" ||
    email === "rmikhli@gmail.com" ||
    email === "jteacher25@gmail.com" ||
    email === "devorahwigman@yahoo.com" ||
    email === "rafi@drromano.com" ||
    email === "clinic@drlavi.co.il" ||
    email === "maya.harran@gmail.com" ||
    email === "43perio@gmail.com" ||
    email === "doninyer1@gmail.com" ||
    email === "evelyn@drromano.com" ||
    email === "43dganit@gmail.com" ||
    email === "noah@smilecloud.co.il"
  ) {
    return;
  }

  FullStory.init({ orgId: "o-1DM7G1-na1" }); // TODO env
  FullStory.identify(email, { displayName: email, email });
};
