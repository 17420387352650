import store from "@/store";
import moment from "moment";

const TIME_ENTRY_KEY = "time-entry-checked-at";
const isTimeEntryRequired = store.getters["auth/isTimeEntryRequired"];

export function setTimeEntryCheckedAt() {
  if (isTimeEntryRequired) {
    localStorage.setItem(TIME_ENTRY_KEY, moment().format("YYYY-MM-DD HH:mm"));
  }
}

export function removeTimeEntryCheckedAt() {
  if (isTimeEntryRequired) {
    localStorage.removeItem(TIME_ENTRY_KEY);
  }
}
export function getTimeEntryCheckedAt() {
  return localStorage.getItem(TIME_ENTRY_KEY);
}
