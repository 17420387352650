import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  ContactBookmarkApiRouteParameters,
  ContactBookmarkApiRoutes,
} from "../constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";

class ContactBookmarkApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(): Promise<ContactBookmark[]> {
    try {
      const response: ApiResponse<ContactBookmark[]> = await this.httpClient.get(
        ContactBookmarkApiRoutes.getAll
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
  async update(
    id: number,
    name: string,
    displayType: string[],
    order: number
  ): Promise<ContactBookmark> {
    try {
      const response: ApiResponse<ContactBookmark> = await this.httpClient.put(
        ContactBookmarkApiRoutes.update.replace(
          ContactBookmarkApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          display_type: displayType,
          order,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    name: string,
    displayType: string[],
    order: number
  ): Promise<ContactBookmark> {
    return this.httpClient
      .post(ContactBookmarkApiRoutes.create, {
        name,
        display_type: displayType,
        order,
      })
      .then((response) => {
        return response.data;
      });
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        ContactBookmarkApiRoutes.delete.replace(
          ContactBookmarkApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new ContactBookmarkApi();
