import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":(_vm.isDocument && _vm.isPdf) ? 850 : 600},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"add-patient-file-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"cyan darken-2"}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":_vm.onDownloadFileClicked}},[_c(VListItemTitle,[_vm._v("\n              "+_vm._s(_vm.$t("share_file_download"))+"\n            ")]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-download")])],1)],1)],1)],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.isImage)?_c(VCardText,{staticClass:"d-flex justify-center"},[_c(VImg,{staticStyle:{"border":"1px solid #aaa"},attrs:{"contain":"","src":_vm.message.attributes.extra.image,"min-height":"100","max-height":"450","max-width":"450"}})],1):_vm._e(),(_vm.isDocument)?_c(VCardText,{staticClass:"d-flex justify-center"},[(_vm.isPdf)?_c('iframe',{staticStyle:{"width":"100%","height":"500px","border":"1px solid #aaa"},attrs:{"src":_vm.message.attributes.extra.document + '#zoom=80&navpanes=0'}}):_c('div',[_c(VBtn,{attrs:{"color":"cyan","outlined":"","large":""},on:{"click":_vm.onDownloadFileClicked}},[_c(VIcon,[_vm._v("mdi-download")]),_vm._v("\n          "+_vm._s(_vm.$t('share_file_download'))+"\n        ")],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }