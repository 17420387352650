



























































import { Component, Prop, } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import { Form } from "../types/form.type";
import FormApi from "../api/form.api";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    QrcodeVue,
  },
})
export default class CreateFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public form: Form;
  @Prop({ required: false }) public patient: Patient;
  @Prop({ required: false }) public planId: number;
  @Getter("auth/fullName") currentUserName: string;
  @Getter("auth/isRtl") isRtl: boolean;

  public isSendLoading = false;
  public isDirty = false;
  public showQrCodeUrl: string = null;
  public phone = "";
  public email = "";
  public sendMethod: "whatsapp" | "email" | "qr" = "whatsapp";
  public isInvalidWhatsapp = false;
  public isInvalidEmail = false;
  public initData: { [key: string]: string } = {};

  public markDirty() {
    this.isDirty = true;
  }

  public closeDialog() {
    this.$emit("close");
  }

  public mounted() {
    this.phone = this.patient.phone;
    this.email = this.patient.email;

    for (const initDataField of this.formInitDataFields) {
      this.initData[initDataField.name] = initDataField.default
        .replace(/{userName}/g, this.currentUserName)
        .replace(/{patientFirstName}/g, this.patient.first_name)
        .replace(/{patientLastName}/g, this.patient.last_name)
        .replace(/{patientPhone}/g, this.patient.phone ? this.patient.phone : "")
        .replace(/{patientIdNumber}/g, this.patient.id_number ? this.patient.id_number : "");
    }
  }

  public async submitForm() {
    this.isInvalidWhatsapp = false;
    this.isInvalidEmail = false;
    if (this.sendMethod === "whatsapp" && (!this.phone || this.phone.length < 9)) {
      this.isInvalidWhatsapp = true;
      return;
    } else if (this.sendMethod === "email" && !this.isValidEmail(this.email)) {
      this.isInvalidEmail = true;
      return;
    }

    try {
      this.isSendLoading = true;
      const url = await FormApi.generateUrl(this.patient.id, this.form.id, this.initData, this.planId);
      if (this.sendMethod === "whatsapp") {
        if (this.$store.getters["auth/isWhatsappBotEnabled"]) {
          await WhatsappApi.sendTemplate(this.phone, "send_form", [this.form.title_for_patient, this.patient.first_name], url);
        } else {
          const template = "נבקש למלא " + this.form.title_for_patient + " עבור " + this.patient.first_name + " " + this.patient.last_name + " 📝\n\n" + url;
          this.shareWhatsapp(this.phone, template);
        }
      } else if (this.sendMethod === "email") {
        await FormApi.sendEmail(this.patient.id, this.form.id, this.email, url);
      } else {
        this.showQrCodeUrl = url;
      }

      if (this.sendMethod !== "qr") {
        this.$toastr.s(this.$t("sent_success_toastr"));
        this.closeDialog();
      }
    } catch (err) {
      this.$toastr.e(err && err.smileMessage ? err.smileMessage : this.$t("temporary_error"));
      this.isSendLoading = false;
    }
  }

  get sendMethods() {
    const methods = ["whatsapp"];
    if (this.$store.getters["auth/isEmailEnabled"]) {
      methods.push("email");
    }
    methods.push("qr");

    return methods
      .map(sendMethodOption => ({
        value: sendMethodOption,
        text: this.$t(`send_method.${sendMethodOption}`),
      }));
  }

  get formInitDataFields() {
    const fields: { name: string; type: "text" | "textarea"; default: string; title: string }[] = [];
    for (const [fieldName, inputInfo] of Object.entries(this.form.fields)) {
      fields.push({
        name: fieldName,
        title: inputInfo.title,
        type: inputInfo.type,
        default: inputInfo.default,
      });
    }
    return fields;
  }
}
