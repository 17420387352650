













import ManageSentences from "../components/sentences/ManageSentences.vue";
import ManageSentenceCategories from "../components/sentences/ManageSentenceCategories.vue";
import Vue from "vue";
import Component from "vue-class-component";
import SettingsHeader from "../components/SettingsHeader.vue";
import { SENTENCES_CATAGORIES_PAGE_ROUTE_NAME, SENTENCES_TEMPLATES_PAGE_ROUTE_NAME } from "../constants/route-config";

@Component({
  components: {
    ManageSentences,
    ManageSentenceCategories,
    SettingsHeader
  },
})

export default class ManageSentencesAndCategoriesPage extends Vue {

  public sentenceCategoryId: number = null;

  get sentencesCategoriesPageName() {
    return SENTENCES_CATAGORIES_PAGE_ROUTE_NAME;
  }
  get sentencesTemplatesPageName() {
    return SENTENCES_TEMPLATES_PAGE_ROUTE_NAME;
  }

}
