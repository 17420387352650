








import { Component, Vue, Prop } from "vue-property-decorator";
import { CalendarViewTypes } from "../../../types/calendar.type";

@Component({
})
export default class CalendarViewSelect extends Vue {
  @Prop({ required: true }) calendarView: CalendarViewTypes;

  public onCalendarViewClicked() {
    if (this.calendarView === "day") {
      this.$emit("calendarViewChanged", "week");
    } else {
      this.$emit("calendarViewChanged", "day");
    }
  }

  get calendarViewOptions() {
    return ["day", "week",
      // TODO "month"
    ]
      .map(calendarViewOption => ({
        value: calendarViewOption,
        text: this.$t(`calendar_view_select.${calendarViewOption}`),
      }));
  }
}
