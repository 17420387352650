



















































































import EditAppointmentFormDialog from "@/modules/appointment/components/appointment/EditAppointmentFormDialog.vue";
import NewAppointmentFormDialog from "@/modules/appointment/components/appointment/NewAppointmentFormDialog.vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { age, date, time } from "@/filters/date.filter";
import { CALENDAR_ROUTE_NAME } from "@/modules/appointment/constants/route-config";
import moment from "moment";

@Component({
  components: {
    EditAppointmentFormDialog,
    NewAppointmentFormDialog,
  },
  filters: {
    age,
    date,
    time
  }
})
export default class PatientAppointments extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public headers = [
    { text: this.$t("headers.user"), value: "user", sortable: false },
    { text: this.$t("headers.date"), value: "date", sortable: false },
    { text: this.$t("headers.start_time"), value: "start_time", sortable: false },
    { text: this.$t("headers.duration"), value: "duration", sortable: false },
    { text: this.$t("headers.notes"), value: "notes", sortable: false },
    { text: this.$t("headers.status"), value: "status", sortable: false },
  ];

  public displayAppointmentDialog = false;
  public editAppointment: Appointment = null;

  public onEditAppointmentClicked(appointment: Appointment) {
    this.editAppointment = appointment;
  }

  public closeEditAppointmentDialog() {
    this.editAppointment = null;
  }

  public goToScheduleAppointment() {
    this.$router.push({
      name: CALENDAR_ROUTE_NAME,
      query: {
        patient: this.patient.id.toString(),
      }
    });
  }

  public closeAppointmentDialog() {
    this.displayAppointmentDialog = false;
  }

  public onAppointmentDeleted(appointmentId) {
    const toBeRemovedAppointmentIndex = this.patient.appointments.findIndex((appointment) => appointmentId === appointment.id);

    if (toBeRemovedAppointmentIndex !== -1) {
      this.patient.appointments.splice(toBeRemovedAppointmentIndex, 1);
    }
  }

  public onAppointmentUpdated(appointment: Appointment) {
    const toBeUpdatedAppointmentIndex = this.patient.appointments.findIndex(({ id }) => (id === appointment.id));

    if (toBeUpdatedAppointmentIndex !== -1) {
      this.patient.appointments[toBeUpdatedAppointmentIndex].status_id = appointment.status_id;
      this.patient.appointments[toBeUpdatedAppointmentIndex].patient_id = appointment.patient_id;
      this.patient.appointments[toBeUpdatedAppointmentIndex].user_id = appointment.user_id;
      this.patient.appointments[toBeUpdatedAppointmentIndex].type_id = appointment.type_id;
      this.patient.appointments[toBeUpdatedAppointmentIndex].start_date = appointment.start_date;
      this.patient.appointments[toBeUpdatedAppointmentIndex].end_date = appointment.end_date;
      this.patient.appointments[toBeUpdatedAppointmentIndex].notes = appointment.notes;
      this.patient.appointments[toBeUpdatedAppointmentIndex].reminder_notes = appointment.reminder_notes;
      this.patient.appointments[toBeUpdatedAppointmentIndex].changes_log = appointment.changes_log;
    }
  }

  public onAppointmentCreated(appointment: Appointment) {
    this.patient.appointments.unshift(appointment);
  }

  public appointmentCreated(appointment: Appointment) {
    appointment.treatments = [];
    this.patient.appointments.unshift(appointment);
  }

  public getDuration(appointment: Appointment) {
    const duration = moment.duration(moment(appointment.end_date).diff(appointment.start_date));
    let humanDuration = "";

    if (duration.hours() === 1 && duration.minutes() === 0) {
      humanDuration = this.$t("duration_one_hour").toString();
    } else if (duration.hours()) {
      humanDuration += `${duration.hours()} ${this.$t("duration_hours")}`;

      if (duration.minutes()) {
        humanDuration += ` ${this.$t("duration_concat")} `;
      }
    }

    if (duration.minutes()) {
      humanDuration += `${duration.minutes()} ${this.$t("duration_minutes")} `;
    }

    return humanDuration;
  }
}
