export enum PaymentVerifoneApiRoutes {
  getTransactions = "/verifone-provider",
  getTransaction = "/verifone-provider/{uid}",
  getTransactionVoucher = "/verifone-provider/{transactionId}/voucher",
}

export enum PaymentVerifoneApiRouteParameters {
  uid = "{uid}",
  transactionId = "{transactionId}",
}
