



































import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component } from "vue-property-decorator";
import { WhatsappContact } from "../types/whatsapp.type";
import { date, time } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { WHATSAPP_ROUTE_NAME } from "../constants/route-config";
import { Getter } from "vuex-class";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";

@Component({
  components: {
    ErrorLogo,
  },
  filters: {
    date,
    time,
  },
})
export default class WhatsappContactsList extends mixins(SmileMixin) {
  @Getter("whatsapp/contacts") contacts: WhatsappContact[];

public async onMarkAsUnread(phone: string) {
  await WhatsappApi.markAsRead(phone, false);
  this.$store.dispatch("whatsapp/markAsUnread", phone);
}

public async onMarkAsRead(phone: string) {
  await WhatsappApi.markAsRead(phone, true);
  this.$store.dispatch("whatsapp/markAsRead", phone);
}

  get whatsappRouteName() {
    return WHATSAPP_ROUTE_NAME;
  }
}
