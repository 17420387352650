import {LoggerInterface} from "@/core/logger/logger.interface";
import {AppConfig} from "@/core/config/config";

class Logger implements LoggerInterface {
    public log: (message?: any, ...optionalParams: any[]) => void;
    public debug: (message?: any, ...optionalParams: any[]) => void;
    public info: (message?: any, ...optionalParams: any[]) => void;
    public warn: (message?: any, ...optionalParams: any[]) => void;
    public error: (message?: any, ...optionalParams: any[]) => void;
    public logToServer: (message?: any, ...optionalParams: any[]) => void;

    /**
     * @constructor AppLogger
     */
    constructor() {
        this.init();
    }

    /**
     * Initializing the logger.
     */
    private init() {
        // Check if not running in production environment.
        if (!AppConfig.inProductionMode) {
            this.log = console.log.bind(console);
            this.debug = console.debug.bind(console);
            this.info = console.info.bind(console);
            this.warn = console.warn.bind(console);
            this.error = console.error.bind(console);
            this.logToServer = this.error;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.log = this.debug = this.info = this.warn = this.error = () => {
            };

            this.logToServer = (message) => {
                console.error(message);
            };
        }
    }
}

const AppLogger = new Logger();

export {
    Logger,
    AppLogger
};
