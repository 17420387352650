





































import { PatientStatus } from "@/modules/patient/types/patient-status.type";
import PatientStatusApi from "../../../../serivce/api/patient-status-api";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    ErrorLogo,
  },
})
export default class PatientStatusForm extends Vue {
  @Prop({ required: false }) editPatientStatus: PatientStatus;

  public errors: any = false;
  public name = "";
  public color = "#ffd7f1";
  public order: number = null;
  public loading = false;
  public deleteLoading = false;

  public mounted() {
    if (this.editPatientStatus) {
      this.name = this.editPatientStatus.name;
      this.order = this.editPatientStatus.order;
      this.color = this.editPatientStatus.color;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    if (this.loading) return;

    this.loading = true;
    this.errors = false;

    try {

      const patientStatus = this.editPatientStatus
        ? await PatientStatusApi.update(this.editPatientStatus.id, this.name, this.color, this.order)
        : await PatientStatusApi.create(this.name, this.color, this.order);

      this.loading = false;
      this.$store.commit("auth/setPatientStatus", patientStatus);
      this.$toastr.s(this.$t(this.editPatientStatus ? "patient_status_updated" : "patient_status_created"));
      this.closeDialog();
      this.$emit("reload", patientStatus);

    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    }
  }

  public async deleteButtonClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message", { "name": this.editPatientStatus.name }).toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });
    if (!res) {
      return;
    }

    this.deleteLoading = true;
    try {
      await PatientStatusApi.delete(this.editPatientStatus.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.$emit("reload");
      this.deleteLoading = false;
    }
  }
}
