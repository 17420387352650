const whatsappMutations = {
    setContacts(state, contacts) {
        state.contacts = contacts;
    },
    setMarkAsRead(state, phone) {
        const contact = state.contacts.find(contact => contact.phone === phone);
        if (contact) {
            contact.isUnread = false;
        }
    },
    setMarkAsUnread(state, phone) {
        const contact = state.contacts.find(contact => contact.phone === phone);
        if (contact) {
            contact.isUnread = true;
        }
    }
};

export default whatsappMutations;
