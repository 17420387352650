























































import TimeClockApi from "../../api/time-clock.api";
import moment from "moment";

import { Component, Prop, Vue } from "vue-property-decorator";
import { MandatoryTimeClockEntryData, TimeClockEntry } from "@/modules/time-clock/types/time-clock.type";
import get from "lodash/get";

@Component
export default class EditTimeClockEntry extends Vue {
  @Prop({ required: true }) public timeClock: TimeClockEntry;
  public open = true;
  public errors = null;
  public startDate: string = null;
  public submitting = false;
  public dateMenuOpen = false;
  public startHour: string = null;
  public endHour: string = null;
  public typeId = this.timeClock.type_id;

  created() {
    const start = new Date(this.timeClock.start);
    const end = new Date(this.timeClock.end);

    this.startDate = new Date(start).toISOString().substr(0, 10);
    this.startHour = (start.getHours() < 10 ? "0" : "") + start.getHours() + ":" + (start.getMinutes() < 10 ? "0" : "") + start.getMinutes();
    this.endHour = (end.getHours() < 10 ? "0" : "") + end.getHours() + ":" + (end.getMinutes() < 10 ? "0" : "") + end.getMinutes();
  }

  get computedDateFormatted() {
    if (!this.startDate) {
      return null;
    }

    const [year, month, day] = this.startDate.split("-");

    return `${day}/${month}/${year}`;
  }

  get timeEntryTypes() {
    return this.$store.getters["auth/timeEntryTypes"];
  }

  get isMultiDate() {
    const start = `${this.startDate} ${this.startHour}`;
    const end = `${this.startDate} ${this.endHour}`;

    return moment.duration(moment(end).diff(start)).asMinutes() <= 0;
  }

  get nextDay() {
    return moment(this.startDate).add(1, "day").format("DD-MM-YYYY");
  }

  public closeDialog() {
    this.open = false;
    this.$emit("close");
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  public getTimeEntryRecord(): MandatoryTimeClockEntryData {
    const start = moment(`${this.startDate} ${this.startHour}`);
    let end = moment(`${this.startDate} ${this.endHour}`);

    if (this.isMultiDate) {
      end = end.add(1, "day");
    }

    return {
      start: start.local().format("YYYY-MM-DD HH:mm:ss"),
      end: end.local().format("YYYY-MM-DD HH:mm:ss"),
      type_id: this.typeId
    };
  }

  public updateEntry() {
    this.submitting = true;
    this.errors = null;

    TimeClockApi
      .update(this.timeClock.id, this.getTimeEntryRecord())
      .then(timeEntry => {
        this.$toastr.s(this.$t("entry_updated"));
        this.closeDialog();
        this.$emit("updatedEntry", timeEntry);
      })
      .catch(error => {
        this.submitting = false;
        this.errors = get(error, "response.data.errors") || this.$t("temporary_error");
      })
      .finally(() => this.submitting = false);
  }
}
