import { render, staticRenderFns } from "./PatientFileListItem.vue?vue&type=template&id=561ff01e&scoped=true&"
import script from "./PatientFileListItem.vue?vue&type=script&lang=ts&"
export * from "./PatientFileListItem.vue?vue&type=script&lang=ts&"
import style0 from "./PatientFileListItem.vue?vue&type=style&index=0&id=561ff01e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561ff01e",
  null
  
)

/* custom blocks */
import block0 from "../../locales/display-patient-files.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpatient-file%2Fcomponents%2Fpatient-file%2FPatientFileListItem.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports