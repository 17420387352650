import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  PlanTreatmentApiRouteParameters,
  PlanTreatmentApiRoutes,
} from "@/modules/treatment/constants/api-routes";
import { PlanTreatment } from "@/modules/treatment/types/plan-treatment.type";
import { ApiResponse } from "@/core/http-client/http-client.types";

class PlanTreatmentApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async update(
    id: number,
    treatmentId: number,
    attributes: any,
    price: number,
    discount: number,
    notes: string,
    quantity: number
  ): Promise<PlanTreatment> {
    try {
      const response: ApiResponse<PlanTreatment> = await this.httpClient.put(
        PlanTreatmentApiRoutes.update.replace(
          PlanTreatmentApiRouteParameters.id,
          id.toString()
        ),
        {
          treatment_id: treatmentId,
          attributes,
          price,
          discount,
          notes,
          quantity,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async updateStatus(id: number, newStatus: boolean): Promise<PlanTreatment> {
    try {
      const response: ApiResponse<PlanTreatment> = await this.httpClient.put(
        PlanTreatmentApiRoutes.update.replace(
          PlanTreatmentApiRouteParameters.id,
          id.toString()
        ),
        { is_done: newStatus }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async updateStartedAt(
    id: number,
    newStatus: boolean
  ): Promise<PlanTreatment> {
    try {
      const response: ApiResponse<PlanTreatment> = await this.httpClient.put(
        PlanTreatmentApiRoutes.update.replace(
          PlanTreatmentApiRouteParameters.id,
          id.toString()
        ),
        { is_started: newStatus }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(
    planId: number,
    treatmentId: number,
    attributes: any,
    price: number,
    discount: number,
    notes: string,
    color: string,
    quantity: number
  ): Promise<PlanTreatment> {
    try {
      const response: ApiResponse<PlanTreatment> = await this.httpClient.post(
        PlanTreatmentApiRoutes.create,
        {
          plan_id: planId,
          treatment_id: treatmentId,
          attributes,
          price,
          discount,
          notes,
          color,
          quantity,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        PlanTreatmentApiRoutes.delete.replace(
          PlanTreatmentApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PlanTreatmentApi();
