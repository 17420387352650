






























import PatientNote from "../components/PatientNote.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Note } from "@/modules/patient/types/note.type";
import { date } from "@/filters/date.filter";
import { Getter } from "vuex-class";

@Component({
  components: {
    PatientNote
  },
  filters: {
    date
  }
})
export default class PatientNotes extends Vue {
  @Prop({ required: true }) public patientId: number;
  @Prop({ required: true }) public notes: Note[];
  @Prop({ required: false }) public patientHiddenNote: string;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isPatientHiddenNoteEnabled") isPatientHiddenNoteEnabled: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  public async onEditNoteClicked(note: Note) {
    this.$emit("editNote", note);
  }

  public hiddenNoteClicked() {
    this.$emit("openHiddenNote");
  }

  public onAddNoteClicked() {
    this.$emit("addNote");
  }
}
