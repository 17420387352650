


















































































import TimeClockApi from "../../api/time-clock.api";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MandatoryTimeClockEntryData, TimeClockEntry } from "@/modules/time-clock/types/time-clock.type";
import get from "lodash/get";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { User } from "@/modules/users/types/user.type";

@Component({
  components: { ErrorMessage }
})
export default class TimeClockManageForm extends Vue {
  @Prop({ required: false }) public timeClock: TimeClockEntry;
  public open = true;
  public errors = null;
  public isMultipleDates = false;
  public date = "";
  public dates: string[] = [];
  public submitting = false;
  public dateMenuOpen = false;
  public multipleDateMenuOpen = false;
  public startHour: string = null;
  public endHour: string = null;
  public typeId = null;
  public userId = null;
  public notes: string = null;
  public isDeleteLoading = false;
  public allDayNote = false;

  public async onDeleteTimeEntryClicked() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    try {
      this.isDeleteLoading = true;
      await TimeClockApi.delete(this.timeClock.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.isDeleteLoading = false;
      this.$emit("deleted", this.timeClock.id);
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.isDeleteLoading = false;
    }
  }

  get editMode() {
    return Boolean(this.timeClock);
  }

  @Watch("allDayNote")
  public onAllDayNoteChanged() {
    if (this.allDayNote) {
      this.startHour = "00:00";
      this.endHour = "";
    }
  }

  created() {
    if (this.editMode) {
      const start = new Date(this.timeClock.start);
      const end = new Date(this.timeClock.end);
      this.date = moment(start).format("YYYY-MM-DD");
      this.startHour = (start.getHours() < 10 ? "0" : "") + start.getHours() + ":" + (start.getMinutes() < 10 ? "0" : "") + start.getMinutes();
      if (this.timeClock.start === this.timeClock.end) {
        this.endHour = "";
      } else {
        this.endHour = (end.getHours() < 10 ? "0" : "") + end.getHours() + ":" + (end.getMinutes() < 10 ? "0" : "") + end.getMinutes();
      }

      this.typeId = this.timeClock.type_id;
      this.userId = this.timeClock.user_id;
      this.notes = this.timeClock.notes;
      if (this.startHour === "00:00" && (this.endHour === "00:00" || !this.endHour)) {
        this.allDayNote = true;
      }
    } else {
      this.userId = this.$store.getters["auth/userId"];
      this.date = new Date().toISOString().substr(0, 10);
      this.dates = [new Date().toISOString().substr(0, 10)];
      this.startHour = moment().add(-1, "hour").format("HH:mm");
      this.endHour = moment().format("HH:mm");
    }
  }

  get computedDateFormatted() {
    if (!this.date) {
      return null;
    }
    const [year, month, day] = this.date.split("-");

    return `${day}/${month}/${year}`;
  }

  get userOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at, id }) => (!deleted_at || id === this.userId))
      .filter(({ is_time_clock_enabled }) => (is_time_clock_enabled))
      .map(user => ({
        "text": user.name,
        "value": user.id
      }));
  }

  get timeEntryTypes() {
    return this.$store.getters["auth/timeEntryTypes"];
  }

  get isMultiDate() {
    if (!this.endHour) {
      return false;
    }
    const start = `${this.date} ${this.startHour}`;
    const end = `${this.date} ${this.endHour}`;

    return moment.duration(moment(end).diff(start)).asMinutes() < 0;
  }

  get nextDay() {
    return moment(this.date).add(1, "day").format("DD-MM-YYYY");
  }

  public closeDialog() {
    this.open = false;
    this.$emit("close");
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    if (!str) {
      return "";
    }

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  public getTimeEntryRecord(startDate: string): MandatoryTimeClockEntryData {
    const start = moment(`${startDate} ${this.startHour}`);
    let end = this.endHour ? moment(`${startDate} ${this.endHour}`) : moment(`${startDate} ${this.startHour}`);

    if (this.isMultiDate) {
      end = end.add(1, "day");
    }

    return {
      start: start.local().format("YYYY-MM-DD HH:mm:ss"),
      end: end.local().format("YYYY-MM-DD HH:mm:ss"),
      type_id: this.typeId,
      user_id: this.userId,
      notes: this.notes,
    };
  }

  public async save() {
    this.submitting = true;
    this.errors = null;
    try {
      if (this.isMultipleDates) {
        if (!this.dates.length) {
          this.errors = this.$t("no_dates_error");
          return;
        }
        await Promise.all(this.dates.map(date => TimeClockApi.create(this.getTimeEntryRecord(date))));
        this.$toastr.s(this.$t("entry_created"));
        this.closeDialog();
        this.$emit("createdEntry");
      } else {
        const timeEntry = this.editMode ?
          await TimeClockApi.update(this.timeClock.id, this.getTimeEntryRecord(this.date))
          : await TimeClockApi.create(this.getTimeEntryRecord(this.date));
        this.$toastr.s(this.$t(this.editMode ? "entry_updated" : "entry_created"));
        this.closeDialog();
        this.$emit(this.editMode ? "updatedEntry" : "createdEntry", timeEntry);
      }
    } catch (error) {
      this.submitting = false;
      this.errors = get(error, "response.data.errors") || this.$t("temporary_error");
    } finally {
      this.submitting = false;
    }
  }
}
