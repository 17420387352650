import {Module} from "vuex";
import patientState from "@/modules/patient/store/patient.state";
import patientGetters from "@/modules/patient/store/patient.getters";
import patientActions from "@/modules/patient/store/patient.actions";
import patientMutations from "@/modules/patient/store/patient.mutations";

const patientStore: Module<any, any> = {
    namespaced: true,
    state: patientState,
    getters: patientGetters,
    actions: patientActions,
    mutations: patientMutations,
};

export default patientStore;
