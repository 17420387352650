import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  PatientFileApiRouteParameters,
  PatientFileApiRoutes,
} from "@/modules/patient-file/constants/api-routes";
import {
  GetAllPatientFilesResponse,
  PatientFileCreationOrUpdateResponse,
  PatientFileRemovalResponse,
} from "@/modules/patient-file/types/api-response.type";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";

class PatientFileApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(params = {}): Promise<PatientFile[]> {
    try {
      const response: GetAllPatientFilesResponse = await this.httpClient.get(
        PatientFileApiRoutes.getAll,
        { params }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: FormData): Promise<PatientFile> {
    try {
      const response: PatientFileCreationOrUpdateResponse = await this.httpClient.post(
        PatientFileApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    name: string,
    folderId: number
  ): Promise<PatientFile> {
    try {
      const response: PatientFileCreationOrUpdateResponse = await this.httpClient.put(
        PatientFileApiRoutes.update.replace(
          PatientFileApiRouteParameters.id,
          id.toString()
        ),
        {
          name,
          folder_id: folderId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id): Promise<boolean> {
    try {
      const response: PatientFileRemovalResponse = await this.httpClient.delete(
        PatientFileApiRoutes.delete.replace(
          PatientFileApiRouteParameters.id,
          id
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientFileApi();
