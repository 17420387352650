export enum LabWorkApiRoutes {
  create = "/lab-work",
  update = "/lab-work/{id}",
  delete = "/lab-work/{id}",
  get = "/lab-work",
}

export enum LabWorkApiRouteParameters {
  id = "{id}",
}
