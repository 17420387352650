



































import TimeEntryTypeApi from "../../../../serivce/api/time-entry-type-api";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { TimeClockEntryType } from "../../types/time-clock.type";

@Component({
  components: {
    ErrorLogo,
  },
})
export default class TimeTntryTypeForm extends Vue {
  @Prop({ required: false }) editTimeEntryType: TimeClockEntryType;
  public errors: any = false;
  public name = "";
  public loading = false;
  public deleteErrors: any = false;
  public deleteLoading = false;

  mounted() {
    if (this.editTimeEntryType) {
      this.name = this.editTimeEntryType.name;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async deleteButtonClicked() {
    this.deleteErrors = null;
    this.$confirm(this.$t("delete_confirm_message", { "name": this.editTimeEntryType.name }).toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    })
      .then(res => {
        if (res) {
          this.deleteLoading = true;
          TimeEntryTypeApi.delete(this.editTimeEntryType.id)
            .then(() => {
              this.$toastr.s(this.$t("deleted_toastr"));
              this.$emit("reload");
              this.closeDialog();
            })
            .catch(err => {
              this.deleteLoading = false;
              if (err.response && err.response.data && err.response.data.errors) {
                this.deleteErrors = err.response.data.errors;
              } else {
                this.deleteErrors = this.$t("delete_error");
              }

              this.$emit("reload");
            });
        }
      });

    this.$vuetify.rtl = this.$store.getters["auth/isRtl"];
  }

  public async submitForm() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.errors = false;
    const payload = {
      name: this.name
    };
    const attempt = this.editTimeEntryType ? TimeEntryTypeApi.update(this.editTimeEntryType.id, payload) : TimeEntryTypeApi.create(payload);
    attempt
      .then(() => {
        this.loading = false;
        this.$toastr.s(this.$t(this.editTimeEntryType ? "type_updated" : "type_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        }
        else {
          this.errors = this.$t("temporary_error");
        }
      });
  }
}
