

































import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CalendarsPicker from "./CalendarsPicker.vue";
import { holidaysSmileCalendarEvent } from "../israel-holidays";
import moment from "moment";

@Component({
  components: {
    CalendarsPicker,
  }
})
export default class CalendarSide extends Vue {
  @Prop({ required: true }) width: number;
  @Prop({ required: true }) focusDate: string;
  @Prop({ required: true }) displayUserIds: number[];
  @Prop({ required: true }) isDisplayCanceled: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  public holidayEvents: string[] = [];

  public closeCalendarSide() {
    this.$emit("close");
  }

  public mounted() {
    for (let i = 0; i < holidaysSmileCalendarEvent.length; i++) {
      if (holidaysSmileCalendarEvent[i].start === holidaysSmileCalendarEvent[i].end) {
        this.holidayEvents.push(holidaysSmileCalendarEvent[i].start);
      } else {
        for (let d = moment(holidaysSmileCalendarEvent[i].start); d.format("YYYY-MM-DD") <= holidaysSmileCalendarEvent[i].end; d = d.add(1, "day")) {
          this.holidayEvents.push(d.format("YYYY-MM-DD"));
        }
      }
    }
  }

  public onFocusDateChanged(date: string) {
    this.enableAdjacentMonths();
    this.closeCalendarSide();
    this.$emit("focusDateChanged", date);
  }

  public onDisplayCanceledChange(newDisplayCanceledFilter: boolean) {
    this.$emit("updateDisplayCanceled", newDisplayCanceledFilter);
  }

  public onDisplayUserIdsChanged(userIds: number[]) {
    this.$emit("updateDisplayUserIds", userIds);
  }

  public onOpenCreateAppointmentDialogClicked() {
    this.$emit("openCreateAppointmentDialog");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
