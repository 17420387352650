import moment from "moment";

export function date(date) {
  return moment(date).format("DD-MM-YYYY");
}

export function time(date) {
  if (date && date.length === 8 && date[2] === ":" && date[5] === ":") {
    return moment("2000-01-01 " + date).format("HH:mm");
  }
  return moment(date).format("HH:mm");
}

export function age(birthDate) {
  const years = moment().diff(birthDate, "years");
  const months = moment().diff(birthDate, "months") % 12;

  if (years > 12) {
    return years;
  }

  return years.toString() + (months > 0 ? `.${months}` : "");
}
