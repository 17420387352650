



























































































































import { PATIENTS_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { WHATSAPP_ROUTE_NAME } from "@/modules/whatsapp/constants/route-config";
import { REPORTS_ROUTE_NAME } from "@/modules/reports/constants/route-config";
import { WAITING_LIST_ROUTE_NAME } from "@/modules/appointment/constants/route-config";
import { TASK_ROUTE_NAME } from "@/modules/task/constants/route-config";
import { EXPENSES_ROUTE_NAME } from "@/modules/supplier/constants/route-config";
import { VERIFONE_REPORT_NAME } from "@/modules/payment-verifone/constants/route-config";
import { TRANZILA_REPORT_NAME } from "@/modules/payment-tranzila/constants/route-config";

@Component
export default class SideMenu extends Vue {
  public drawerModel = false;
  public drawerMini = false;
  public drawerPermanent = false;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/clinicName") clinicName: string;
  @Getter("auth/clinicLogoUrl") clinicLogoUrl: string;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/shouldDisplayUnreadWhatsapp") shouldDisplayUnreadWhatsapp: boolean;
  @Getter("whatsapp/unreadCount") unreadWhatsappCount: number;
  @Getter("task/todoTasksCount") todoTasksCount: number;
  @Getter("auth/shouldDisplayTodoTasks") shouldDisplayTodoTasks: boolean;
  @Getter("auth/creditCardDriver") creditCardDriver: string;
  @Getter("auth/isTranzilaEnabled") isTranzilaEnabled: boolean;
  @Getter("auth/isExpensesEnabled") isExpensesEnabled: boolean;
  @Getter("auth/isAccountant") isAccountant: boolean;

  mounted() {
    this.drawerModel = !this.isMobile();
    this.drawerMini = localStorage.getItem("sideMenuDrawerMini") === "1";
    this.drawerPermanent = !this.isMobile();
  }

  public toggleDrawer() {
    this.drawerModel = !this.drawerModel;
    this.drawerMini = !this.drawerMini && !this.isMobile();
    localStorage.setItem("sideMenuDrawerMini", this.drawerMini ? "1" : "0");
  }

  public goToPatient() {
    this.$router.push({ name: PATIENTS_ROUTE_NAME });
  }

  public isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get taskPageRouteName() {
    return TASK_ROUTE_NAME;
  }

  get waitingListRouteName() {
    return WAITING_LIST_ROUTE_NAME;
  }

  get whatsappPageRouteName() {
    return WHATSAPP_ROUTE_NAME;
  }

  get reportsRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  get verifoneReportRouteName() {
    return VERIFONE_REPORT_NAME;
  }

  get tranzilaReportRouteName() {
    return TRANZILA_REPORT_NAME;
  }

  get expensesRouteName() {
    return EXPENSES_ROUTE_NAME;
  }
}
