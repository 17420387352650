




































import PriceListApi from "@/modules/treatment/api/price-list.api";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PriceList } from "@/modules/treatment/types/treatment.type";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";

@Component({
  components: {
    ErrorMessage,
  }
})
export default class PriceListForm extends Vue {
  @Prop({ required: false }) public editPriceList: PriceList;
  public errors: any = false;
  public name = "";
  public order = 0;
  public isLoading = false;
  public deleteLoading = false;

  mounted() {
    if (this.editPriceList) {
      this.name = this.editPriceList.name;
      this.order = this.editPriceList.order;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    if (this.isLoading) { return; }

    this.isLoading = true;
    this.errors = false;

    const attempt = this.editPriceList
      ? PriceListApi.update(this.editPriceList.id, this.name, this.order)
      : PriceListApi.create(this.name, this.order);

    attempt
      .then(() => {
        this.isLoading = false;

        this.$toastr.s(this.editPriceList ? this.$t("price_list_updated") : this.$t("price_list_created"));
        this.closeDialog();
        this.$emit("reload");
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  public async deletePriceList() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });

    if (!res) {
      return;
    }

    try {
      this.deleteLoading = true;
      await PriceListApi.delete(this.editPriceList.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.$emit("reload");
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.$emit("reload");
    }
  }

}
