import {AppModule} from "@/types/app-module";
import contactsRoutes from "@/modules/contacts/routes/routes";

const contactsModule: AppModule = {
    name: "contacts",
    routes: contactsRoutes,
    store: {}
};

export default contactsModule;
