import { AppModule } from "@/types/app-module";
import supplierRoutes from "@/modules/supplier/routes/routes";

const supplierModule: AppModule = {
  name: "supplier",
  routes: supplierRoutes,
  store: {},
};

export default supplierModule;
