import Request from "@/serivce/request";
import store from "@/store";
import Echo from "laravel-echo";

class EchoService {
  readonly channelName = "clinic";
  private echo: Echo;

  constructor() {
    (window as any).Pusher = require("pusher-js");
  }

  private getEcho() {
    if (this.echo) {
      return this.echo;
    }

    const config = store.getters["auth/pusherConfig"];
    if (!config) {
      return null;
    }

    return this.echo = new Echo({
      broadcaster: "pusher",
      cluster: config.appCluster,
      key: config.appKey,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            const request = new Request();

            request.post("/broadcasting/auth", {
              socket_id: socketId,
              channel_name: channel.name
            })
              .then((response: any) => {
                callback(false, response.data);
              })
              .catch(error => {
                callback(true, error);
              });
          }
        };
      }
    });
  }

  public listenFrontendRefreshPush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Events\\FrontendRefreshPush", () => {
        window.location.reload();
      });
  }

  public listenFrontendWhatsappPhonePush(callback: Function) {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Modules\\Whatsapp\\Events\\FrontendWhatsappPhonePush", callback);
  }

  public stopListenFrontendWhatsappPhonePush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .stopListening(".App\\Modules\\Whatsapp\\Events\\FrontendWhatsappPhonePush");
  }

  public listenFrontendWhatsappReloadContactsPush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Events\\FrontendWhatsappReloadContactsPush", () => {
        store.dispatch("whatsapp/loadContacts");
      });
  }

  public listenFrontendDataChangedPush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Events\\FrontendDataChangedPush", () => {
        store.dispatch("auth/loadInitData");
      });
  }

  public listenImagingScanUploadedPush(callback) {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Modules\\Imaging\\Events\\ImagingScanUploadedPush", callback);
  }

  public stopListenImagingScanUploadedPush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .stopListening(".App\\Modules\\Imaging\\Events\\ImagingScanUploadedPush");
  }

  public listenPaymentProviderVerifoneEventPush(callback) {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .listen(".App\\Modules\\Payment\\Events\\PaymentProviderVerifoneEventPush", callback);
  }

  public stopListenPaymentProviderVerifoneEventPush() {
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.private(this.channelName)
      .stopListening(".App\\Modules\\Payment\\Events\\PaymentProviderVerifoneEventPush");
  }

  public logout() {
    // Close WS connection with Pusher
    const echo = this.getEcho();
    if (!echo) {
      return;
    }
    echo.disconnect();

    this.echo = undefined;
  }
}

export default new EchoService();
