






















































































































































































import axios from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import AppointmentApi from "@/modules/appointment/api/appointment.api";
import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import PatientTreatmentFormDialog from "@/modules/patient/components/PatientTreatmentFormDialog.vue";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import moment from "moment";
import { AppointmentStatus } from "@/modules/appointment/types/appointment-status.type";

@Component({
  components: {
    PatientTreatmentFormDialog,
  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportAppointmentsMonthlyPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  private activeRequest;
  public resultAppointments: Appointment[] = [];
  public dispalyUserId: number = null;
  public dateFilter = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
  public dateMenuOpen = false;
  public isLoading = false;
  public statusIds: number[] = [];
  public notesFilter = "";

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get usersWithCalendar() {
    return [
      ...this.$store.getters["auth/usersWithCalendar"]
        .map(user => ({
          "text": user.name,
          "value": user.id
        }))
    ];
  }

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();
    const queryCalendar = parseInt(this.$route.query.calendar && this.$route.query.calendar.toString());
    const queryStatus = this.$route.query.status && this.$route.query.status.toString();
    const queryNotes = this.$route.query.notes && this.$route.query.notes.toString();

    if (queryStatus) {
      this.statusIds = queryStatus.split(",").map(id => (parseInt(id)));
    }
    if (queryDate) {
      this.dateFilter = queryDate;
    }
    if (queryCalendar) {
      this.dispalyUserId = queryCalendar;
    }
    if (queryNotes) {
      this.notesFilter = queryNotes;
    }
  }
  public displayTreatmentFormDialog = false;
  public editingTreatment: PatientTreatment = null;

  public onEditTreatmentClicked(treatment: PatientTreatment) {
    this.displayTreatmentFormDialog = true;
    this.editingTreatment = treatment;
  }
  @Watch("dispalyUserId")
  @Watch("statusIds")
  @Watch("dateFilter")
  @Watch("notesFilter")
  onFiltersChanged() {
    this.reload();
  }

  get headers() {
    const headers = [];

    if (!this.dispalyUserId) {
      headers.push({ text: this.$t("headers.calendar"), value: "user", sortable: false });
    }

    headers.push(
      { text: this.$t("headers.date"), value: "start_date", sortable: false },
      { text: this.$t("headers.time"), value: "start_time", sortable: false },
      { text: this.$t("headers.duration"), value: "duration", sortable: false },
      { text: this.$t("headers.patient"), value: "patient", sortable: false },
      { text: this.$t("headers.status"), value: "status", sortable: false },
      { text: this.$t("headers.notes"), value: "notes", sortable: false },
    );

    return headers;
  }

  public getAppointmentsCount(statusId?: number) {
    return this.resultAppointments
      .filter((appointment) => (appointment
        && appointment.patient_id
        && (!statusId || appointment.status_id === statusId))
      )
      .length;
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;
    this.resultAppointments = [];

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    try {
      const startOfMonth = `${this.dateFilter}-01`;
      const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");
      const statusIds = this.statusIds.length ? this.statusIds : this.appointmentStatuses.map(({ id }) => (id));

      const { appointments: resultAppointments } = await AppointmentApi
        .find(startOfMonth, endOfMonth, statusIds, this.activeRequest);

      this.resultAppointments = resultAppointments
        .filter(({ user_id }) => (user_id === this.dispalyUserId || !this.dispalyUserId))
        .filter(({ notes }) => ((notes && notes.includes(this.notesFilter)) || !this.notesFilter))
        ;

      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  get appointmentStatuses() {
    return this.$store.getters["auth/appointmentStatuses"]
      .filter((appointmentStatus: AppointmentStatus) => (appointmentStatus.is_enabled));
  }

  public filterTherapistUserByDefault() {
    if (this.dispalyUserId) {
      return;
    }

    if (!this.$store.getters["auth/isTherapist"]) {
      return;
    }

    this.dispalyUserId = this.$store.getters["auth/assignedCalendarUserId"];
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.dateFilter,
      calendar: this.dispalyUserId ? this.dispalyUserId.toString() : undefined,
      status: this.statusIds ? this.statusIds.join(",") : undefined,
      notes: this.notesFilter ? this.notesFilter : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    this.filterTherapistUserByDefault();
    this.reload();
  }

  public getDuration(appointment: Appointment) {
    const duration = moment.duration(moment(appointment.end_date).diff(appointment.start_date));
    let humanDuration = "";

    if (duration.hours() === 1 && duration.minutes() === 0) {
      humanDuration = this.$t("duration_one_hour").toString();
    } else if (duration.hours()) {
      humanDuration += `${duration.hours()} ${this.$t("duration_hours")}`;

      if (duration.minutes()) {
        humanDuration += ` ${this.$t("duration_concat")} `;
      }
    }

    if (duration.minutes()) {
      humanDuration += `${duration.minutes()} ${this.$t("duration_minutes")} `;
    }

    return humanDuration;
  }

  get formattedDateFilter() {
    const [year, month] = this.dateFilter.split("-");
    return this.$t(`months.${parseInt(month) - 1}`) + " " + year;
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }
}
