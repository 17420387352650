export enum TimeClockApiRoutes {
    getAll = "/time-clock",
    create = "/time-clock",
    update = "/time-clock/{id}",
    delete = "/time-clock/{id}",
    shareReport = "/time-clock/share",
    downloadReport = "/time-clock/download",
}

export enum TimeClockApiRouteParameters {
    id = "{id}",
}

export enum TimeClockTypeApiRoutes {
    getAll = "/time-entry-type",
    create = "/time-entry-type",
    update = "/time-entry-type/{id}",
    delete = "/time-entry-type/{id}",
    getLastUsedType = "/time-entry-type/last-used",
}
