import { render, staticRenderFns } from "./ExpensesPage.vue?vue&type=template&id=5cead6bd&"
import script from "./ExpensesPage.vue?vue&type=script&lang=ts&"
export * from "./ExpensesPage.vue?vue&type=script&lang=ts&"
import style0 from "./ExpensesPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/expenses-page.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fsupplier%2Fpages%2FExpensesPage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports