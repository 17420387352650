import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"360"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_vm._v("\n      "+_vm._s(_vm.$t('title'))+"\n      "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"hide-details":"","required":"","items":_vm.userOptions,"label":_vm.$t('user'),"color":"cyan darken-1","item-color":"cyan darken-1","loading":_vm.isLoading},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1),(_vm.lastTimeEntry)?_c(VCol,{staticClass:"text-center"},[_vm._v("\n          "+_vm._s(_vm.$t('enter_time'))+":\n          "),(_vm.dayDiff)?_c('span',[_vm._v("\n            "+_vm._s(_vm._f("date")(_vm.lastTimeEntry.start))+"\n          ")]):_vm._e(),_vm._v("\n          "+_vm._s(_vm._f("time")(_vm.lastTimeEntry.start))+"\n        ")]):_vm._e(),(_vm.userId && !_vm.isLoading && !_vm.lastTimeEntry)?_c(VCol,[_c(VSelect,{attrs:{"required":"","items":_vm.timeEntryTypes,"label":_vm.$t('type_entry'),"item-color":"cyan darken-1","color":"cyan darken-1"},model:{value:(_vm.typeId),callback:function ($$v) {_vm.typeId=$$v},expression:"typeId"}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.userId),expression:"userId"}],attrs:{"color":"cyan","loading":_vm.isLoading || _vm.isSubmitting},on:{"click":function($event){return _vm.logTimeClicked()}}},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.lastTimeEntry ? 'out_btn' : 'in_btn'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }