













































import TimeClockApi from "../../api/time-clock.api";
import { Component } from "vue-property-decorator";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
import { User } from "@/modules/users/types/user.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";

@Component({
  components: {
    ErrorMessage
  }
})
export default class SendTimeClockMonthlyReport extends mixins(SmileMixin) {
  public isLoading = false;
  public errors: any = false;
  public dateFilter = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
  public dateMenuOpen = false;
  public userId: number = null;

  get userOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at, id }) => (!deleted_at || id === this.userId))
      .filter(({ is_time_clock_enabled }) => (is_time_clock_enabled))
      .map(user => ({
        "text": user.name,
        "value": user.id
      }));
  }


  public async onExportClicked() {
    this.isLoading = true;

    try {
      const [year, month] = this.dateFilter.split("-");
      const url = await TimeClockApi.downloadMonthlyReport(year + "-" + month, this.userId);
      this.isLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, url.split("/").pop());
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }

  get formattedDateFilter() {
    const [year, month] = this.dateFilter.split("-");
    return this.$t(`months.${parseInt(month) - 1}`) + " " + year;
  }

  public closeDialog() {
    this.$emit("close");
  }
}
