import {Module} from "vuex";
import usersState from "@/modules/users/store/users.state";
import usersGetters from "@/modules/users/store/users.getters";
import usersActions from "@/modules/users/store/users.actions";
import usersMutations from "@/modules/users/store/users.mutations";

const usersStore: Module<any, any> = {
    namespaced: true,
    state: usersState,
    getters: usersGetters,
    actions: usersActions,
    mutations: usersMutations,
};

export default usersStore;
