import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { PatientLetterApiRoutes } from "@/modules/patient/constants/api-routes";
import { Drug } from "@/modules/settings/types/drug.type";

class PatientLetterApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async create(
    patientId: number,
    toName: string,
    signature: string,
    subject: string,
    content: string,
    filename: string,
    date: string,
    sendEmailTo: string,
    sendEmailCc: string,
    sendEmailCc2: string,
    sendWaToPatient: string,
    attachPatientFileIds: number[]
  ) {
    try {
      const response = await this.httpClient.post(
        PatientLetterApiRoutes.create,
        {
          date,
          toName,
          signature,
          subject,
          content,
          filename,
          sendEmailTo: sendEmailTo ? sendEmailTo : null,
          sendEmailCc: sendEmailCc ? sendEmailCc : null,
          sendEmailCc2: sendEmailCc2 ? sendEmailCc2 : null,
          sendWaToPatient: sendWaToPatient ? sendWaToPatient : null,
          patientId,
          attachPatientFileIds,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async createDrugPrescription(
    date: string,
    patientId: number,
    userId: number,
    drugs: Drug[],
    notes: string
  ) {
    try {
      const response = await this.httpClient.post(
        PatientLetterApiRoutes.createDrugPrescription,
        {
          date,
          userId,
          patientId,
          drugs,
          notes,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async sendEmailFile(
    sendEmailTo: string,
    patientId: number,
    attachPatientFileId: number
  ) {
    try {
      const response = await this.httpClient.post(
        PatientLetterApiRoutes.sendEmailFile,
        {
          sendEmailTo,
          patientId,
          attachPatientFileId,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async createExportPatient(
    patientId: number,
    exportData: any,
    patientBalance: number,
    isExportIncludePayments: boolean,
    sendEmailTo: string,
    sendEmailCc: string,
    sendWaToPatient: string,
    attachPatientFileIds: number[]
  ) {
    try {
      const response = await this.httpClient.post(
        PatientLetterApiRoutes.createExportPatient,
        {
          patientId,
          exportData,
          patientBalance,
          isExportIncludePayments,
          sendEmailTo: sendEmailTo ? sendEmailTo : null,
          sendEmailCc: sendEmailCc ? sendEmailCc : null,
          sendWaToPatient: sendWaToPatient ? sendWaToPatient : null,
          attachPatientFileIds,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async createDynamicLetter(
    templateName: string,
    subject: string,
    toName: string,
    patientId: number,
    letterData: any,
    sendEmailTo: string,
    sendEmailCc: string,
    sendEmailCc2: string,
    sendWaToPatient: string
  ) {
    try {
      const response = await this.httpClient.post(
        PatientLetterApiRoutes.createDynamicLetter,
        {
          templateName,
          subject,
          toName,
          patientId,
          letterData,
          sendEmailTo: sendEmailTo ? sendEmailTo : null,
          sendEmailCc: sendEmailCc ? sendEmailCc : null,
          sendEmailCc2: sendEmailCc2 ? sendEmailCc2 : null,
          sendWaToPatient: sendWaToPatient ? sendWaToPatient : null,
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientLetterApi();
