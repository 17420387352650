
















































import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Pinkas } from "../../pinkas/types/pinkas.type";

@Component({
})
export default class CancelPaymentConfirmDialog extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  public pinkasId: number = null;
  public amount: number = null;
  public paymentType: "credit_card" | "cash" | "check" | "bank_transfer" = null;
  public showErrors = false;
  public description = "";

  public mounted() {
    if (this.$store.getters["auth/isUsingFirstPinkasDefault"]) {
      this.pinkasId = this.pinkasList[0].id;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onPaymentRefundClicked() {
    if (!this.amount || parseFloat(this.amount.toString()) <= 0 || this.description.trim().length <= 1) {
      this.showErrors = true;
      return;
    }
    this.$emit("paymentRefundConfirmed", this.pinkasId, this.amount, this.paymentType, this.description);
    this.closeDialog();
  }

  get paymentTypes() {
    return [
      { text: this.$t("credit_card"), value: "credit_card" },
      { text: this.$t("cash"), value: "cash" },
      { text: this.$t("check"), value: "check" },
      { text: this.$t("bank_transfer"), value: "bank_transfer" },
    ];
  }
}
