import Request from "../request";

class AppointmentStatusApi {
  constructor () {
    this.request = new Request();
  }

  get () {
    return this.request.get("/appointment_status");
  }

  update (statusId, payload = {}) {
    return this.request.put(`/appointment_status/${statusId}`, payload);
  }

}

export default new AppointmentStatusApi();
