import Vue from "vue";
import Router, { Route } from "vue-router";
import Layout from "@/components/Layout.vue";
import store from "@/store";

import usersRoutes from "@/modules/users/routes/routes";
import timeClockRoutes from "@/modules/time-clock/routes/routes";
import patientRoutes from "@/modules/patient/routes/routes";
import settingsRoutes from "@/modules/settings/routes/routes";
import contactsRoutes from "@/modules/contacts/routes/routes";
import notificationsRoutes from "@/modules/notifications/routes/routes";
import authRoutes, { guardedAuthRoutes } from "@/modules/auth/routes/routes";
import appointmentRoutes from "@/modules/appointment/routes/routes";
import whatsappRoutes from "@/modules/whatsapp/routes/routes";
import reportsRoutes from "@/modules/reports/routes/routes";
import taskRoutes from "@/modules/task/routes/routes";
import paymentVerifoneRoutes from "@/modules/payment-verifone/routes/routes";
import paymentTranzilaRoutes from "@/modules/payment-tranzila/routes/routes";
import supplierRoutes from "@/modules/supplier/routes/routes";
import {
  TRANZILA_IFRAME_NAME,
  TRANZILA_IFRAME_PATH,
} from "@/modules/payment-tranzila/constants/route-config";
import TranzilaIframeResultsPage from "@/modules/payment-tranzila/pages/TranzilaIframeResultsPage.vue";
import { CALENDAR_ROUTE_NAME } from "@/modules/appointment/constants/route-config";
import { guardMiddleware } from "./guard-middleware";

Vue.use(Router);

if (window.location.pathname.startsWith("//")) {
  // Workaround to resolve error when entering with double stash at the beggining
  window.location.href =
    window.location.origin + window.location.pathname.replace(/\/\/+/g, "/");
}

const ifAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isLogged"]) {
    return next({ name: "login" });
  }
  return next();
};

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (from.name === CALENDAR_ROUTE_NAME && to.name === CALENDAR_ROUTE_NAME) {
      // temp fix fix scroll change when updating calendar filters
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    ...authRoutes,
    {
      name: TRANZILA_IFRAME_NAME,
      path: TRANZILA_IFRAME_PATH,
      component: TranzilaIframeResultsPage,
    },
    {
      path: "/",
      component: Layout,
      beforeEnter: ifAuthenticated,
      children: [
        ...notificationsRoutes,
        ...timeClockRoutes,
        ...usersRoutes,
        ...patientRoutes,
        ...settingsRoutes,
        ...guardedAuthRoutes,
        ...appointmentRoutes,
        ...whatsappRoutes,
        ...taskRoutes,
        ...contactsRoutes,
        ...reportsRoutes,
        ...paymentVerifoneRoutes,
        ...paymentTranzilaRoutes,
        ...supplierRoutes,
        {
          // TODO
          path: "/",
          redirect: "/calendar",
        },
        {
          path: "*",
          beforeEnter() {
            window.location.replace("/");
          },
        },
      ],
    },
  ],
});

router.beforeEach(async (to: Route, from: Route, next) => {
  guardMiddleware(to, from, next);
  return next();
});

export default router;
