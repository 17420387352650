





































































































import Support from "@/components/layout/Support.vue";
import SearchPatient from "@/components/layout/SearchPatient.vue";
import Notifications from "@/components/layout/Notifications.vue";
import { CONTACTS_ROUTE_NAME } from "@/modules/contacts/constants/route-config";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Patient } from "@/modules/patient/types/patient.type";
import { MANAGE_TIME_CLOCK_ROUTE_NAME } from "@/modules/time-clock/constants/route-config";
import { SETTINGS_PAGE_ROUTE_NAME } from "@/modules/settings/constants/route-config";

@Component({
  components: {
    Notifications,
    SearchPatient,
    Support
  },
})
export default class TopNav extends Vue {
  public supportOpen = false;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/userColor") userColor: string;
  @Getter("auth/fullName") currentUserName: string;
  @Getter("auth/isAdmin") isUserAdmin: boolean;
  @Getter("auth/isAllowedSettingsPage") isAllowedSettingsPage: boolean;
  @Getter("auth/isSmileSupport") isSmileSupport: boolean;
  @Getter("auth/isTimeClockFeatureEnabled") isTimeClockFeatureEnabled: boolean;
  @Getter("auth/isAccountant") isAccountant: boolean;

  get contactsPageRouteName() {
    return CONTACTS_ROUTE_NAME;
  }

  get timeClockRouteName() {
    return MANAGE_TIME_CLOCK_ROUTE_NAME;
  }

  get settingsPageRouteName() {
    return SETTINGS_PAGE_ROUTE_NAME;
  }

  public onSelectedPatient(patient: Patient) {
    this.$router.push({
      name: PATIENT_ROUTE_NAME,
      params: {
        id: patient.id.toString(),
      }
    });
  }

  public openDrawer() {
    this.$emit("toggleDrawer");
  }

  public logout() {
    this.$store.dispatch("auth/logout")
      .then(() => {
        this.$vuetify.rtl = true;
        this.$i18n.locale = "he";
        this.$root.$i18n.locale = "he";
        this.$vuetify.lang.current = "he";
        this.$router.push({ name: "login" });
      });
  }

  public toggleSupport(openStatus) {
    this.supportOpen = openStatus;
  }

  public isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
